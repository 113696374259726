import {
  Container,
  Grid,
  TextField,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import Logo from "src/assets/img/T20 logo_RGB - full color-small-min.png";
import firebase from "firebase/app";
import { useSnackbar } from "notistack";

export const ForgotPasswordPage = () => {
  const { register, errors, handleSubmit } = useForm({
    defaultValues: { email: "" },
  });
  const { enqueueSnackbar } = useSnackbar();

  const _handleSubmit = async (values: { email: string }) => {
    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
      enqueueSnackbar("Password reset email sent. Please check you email.", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(
        "An error occurred while sending reset email. Please try again later.",
        { variant: "error" },
      );
    }
  };

  return (
    <Container
      maxWidth="xs"
      style={{ height: "100vh", display: "flex", alignItems: "center" }}
    >
      <Helmet>
        <title>Think20 Labs Portal - Forgot Password</title>
      </Helmet>
      <Grid
        component="form"
        container
        spacing={3}
        onSubmit={handleSubmit(_handleSubmit)}
      >
        <Grid item xs={12} container justify="center">
          <Box mb={3}>
            <img src={Logo} alt="Think20 Labs Logo" style={{ width: "100%" }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Please enter your email so that we can send you a link to reset your
            password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email Address"
            type="email"
            autoFocus
            autoComplete="email"
            name="email"
            inputRef={register({
              required: true,
              pattern: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
            })}
            fullWidth
            required
            error={!!errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth color="primary" type="submit">
            Send password reset email
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
