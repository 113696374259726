import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import type { BusinessFieldsFragment } from "src/generated/graphql-hooks";

interface BusinessInfoCardProps extends CardProps {
  business?: BusinessFieldsFragment | null;
  showTitle?: boolean;
}

const BusinessInfoCard: FunctionComponent<BusinessInfoCardProps> = ({
  business,
  showTitle,
  ...rest
}) => (
  <Card {...rest}>
    {showTitle && <CardHeader subheader="Business Information" />}
    <CardContent>
      {business ? (
        <>
          <Typography component="div">
            <Box fontWeight="fontWeightBold">{business.name}</Box>
          </Typography>
          <Typography>{business.address.street1}</Typography>
          <Typography>
            {`${business.address.city} ${business.address.state}, ${business.address.zip}`}
          </Typography>
        </>
      ) : (
        <Typography component="div">
          <Box fontWeight="fontWeightBold">Unknown business</Box>
        </Typography>
      )}
    </CardContent>
  </Card>
);

export default BusinessInfoCard;
