import * as yup from "yup";

export const UsersEditFormSchema = yup.object({
  id: yup.string().required("This is a required field").trim(),
  firstName: yup.string().required("This is a required field").trim(),
  lastName: yup.string().required("This is a required field").trim(),
  email: yup.string().required("This is a required field").trim(),
  phoneNumber: yup.string().trim(),
  role: yup.object({
    id: yup.string().required("This is a required field").trim(),
    name: yup.string().required("This is a required field").trim(),
  }),
  business: yup.object({
    id: yup.string().required("This is a required field").trim(),
    name: yup.string().required("This is a required field").trim(),
  }),
});
