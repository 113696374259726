import startCase from "lodash/startCase";
import { DateTime } from "luxon";
import * as React from "react";
import type { Cell } from "react-table";
import type { PickupLogOrderFieldsFragment } from "src/generated/graphql-hooks";
import { graphQLDateToDateTime } from "src/utils";
import PickUpLogRowActions from "./RowActions";

const columns = [
  {
    Header: "Business",
    accessor: "sampleSubmission.submittedBy.business.name",
  },
  {
    Header: "License #",
    accessor: "scheduledPickup.businessLicenseNumber",
  },
  {
    Header: "On Site Contact",
    accessor: "scheduledPickup.onSiteContactName",
  },
  {
    Header: "Address",
    accessor: ({ scheduledPickup }: PickupLogOrderFieldsFragment) =>
      scheduledPickup?.address
        ? `${scheduledPickup.address.street1} ${scheduledPickup.address.city}, ${scheduledPickup.address.state} ${scheduledPickup.address.zip}`
        : null,
  },
  {
    Header: "Phone",
    accessor: "scheduledPickup.contactPhoneNumber",
  },
  {
    Header: "Samples in Order",
    accessor: (row: PickupLogOrderFieldsFragment) => row.samples.length,
  },
  {
    Header: "Order ID",
    accessor: "shortId",
  },
  {
    Header: "Submitted On",
    accessor: ({ sampleSubmission }: PickupLogOrderFieldsFragment) =>
      new Date(sampleSubmission?.submittedAt ?? "3000/01/01"),
    Cell: ({ row }: Cell<PickupLogOrderFieldsFragment>) =>
      row.original.sampleSubmission?.submittedAt
        ? DateTime.fromISO(
            row.original.sampleSubmission.submittedAt,
          ).toLocaleString(DateTime.DATE_SHORT)
        : null,
    sortType: "datetime",
  },
  {
    Header: "Ready For Pickup",
    accessor: ({ readyForPickupDate }: PickupLogOrderFieldsFragment) =>
      new Date(readyForPickupDate || "3000/01/01"),
    Cell: ({ row }: Cell<PickupLogOrderFieldsFragment>) =>
      row.original.readyForPickupDate
        ? graphQLDateToDateTime(row.original.readyForPickupDate).toLocaleString(
            DateTime.DATE_SHORT,
          )
        : null,
    sortType: "datetime",
  },
  {
    Header: "Pickup Date & Time",
    accessor: ({ scheduledPickup }: PickupLogOrderFieldsFragment) =>
      new Date(scheduledPickup?.dateTime ?? "3000/01/01"),
    Cell: ({ row }: Cell<PickupLogOrderFieldsFragment>) =>
      row.original.scheduledPickup
        ? DateTime.fromISO(
            row.original.scheduledPickup.dateTime,
          ).toLocaleString(DateTime.DATETIME_SHORT)
        : null,
    sortType: "datetime",
  },
  {
    Header: "Field Technician",
    accessor: ({ scheduledPickup }: PickupLogOrderFieldsFragment) =>
      scheduledPickup
        ? `${scheduledPickup.fieldTechnician.firstName} ${scheduledPickup.fieldTechnician.lastName}`
        : null,
  },
  {
    Header: "Vehicle",
    accessor: ({ scheduledPickup }: PickupLogOrderFieldsFragment) =>
      scheduledPickup
        ? `${scheduledPickup?.pickupVehicle.make} ${scheduledPickup?.pickupVehicle.model} - ${scheduledPickup?.pickupVehicle?.licensePlateNumber}`
        : null,
  },
  {
    Header: "Status",
    accessor: (row: PickupLogOrderFieldsFragment) =>
      startCase(row.status ?? ""),
  },
  {
    Header: "Actions",
    id: "actions",
    Cell: (cell: Cell<PickupLogOrderFieldsFragment>) => (
      <PickUpLogRowActions {...cell} />
    ),
    disableSortBy: true,
  },
];

export default columns;
