import { Button, CircularProgress } from "@material-ui/core";
import type { ButtonProps } from "@material-ui/core/Button";
import React from "react";

interface Props extends ButtonProps {
  loading: boolean;
  children: string;
}

const LoadingButton = ({ loading, children, ...rest }: Props) => {
  return (
    <Button {...rest} disabled={loading || rest.disabled}>
      {loading ? <CircularProgress color="inherit" size={24} /> : children}
    </Button>
  );
};

export default LoadingButton;
