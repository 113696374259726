import { TextField } from "@material-ui/core";
import type { StandardTextFieldProps } from "@material-ui/core/TextField";
import { Field, FieldProps } from "formik";
import React, { FunctionComponent } from "react";

interface FormTextFieldProps extends StandardTextFieldProps {
  name: string;
}

const FormTextField: FunctionComponent<FormTextFieldProps> = ({
  name,
  ...rest
}) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <TextField
        {...field}
        {...rest}
        error={meta.touched && !!meta.error}
        helperText={(meta.touched && meta.error) || rest.helperText}
      />
    )}
  </Field>
);

export default FormTextField;
