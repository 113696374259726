import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    },
  },
  typography: {
    fontFamily: ["'Quicksand'", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: "#3c4789",
      main: "#00205b",
      dark: "#000031",
      contrastText: "#fff",
    },
    secondary: {
      light: "#cf5ce9",
      main: "#9b26b6",
      dark: "#680085",
      contrastText: "#fff",
    },
    accent: {
      light: "#63f1d2",
      main: "#1ABEA1",
      dark: "#008d73",
      contrastText: "#000",
    },
  },
});
declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    accent: Palette["primary"];
  }
  interface PaletteOptions {
    accent: PaletteOptions["primary"];
  }
}

export default theme;
