import { ErrorMessage } from "@hookform/error-message";
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
} from "@material-ui/core";
import React, { ChangeEvent, FunctionComponent } from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  get,
  useFormContext,
} from "react-hook-form";

interface HookFormProps {
  control?: Control;
  name: string;
  defaultValue?: any;
  errors?: DeepMap<Record<string, any>, FieldError>;
  onChangeCallback?: (
    value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  formatValue?: (value: any) => any;
}

export type FormTextFieldProps =
  | (StandardTextFieldProps & HookFormProps)
  | (FilledTextFieldProps & HookFormProps)
  | (OutlinedTextFieldProps & HookFormProps);

const FormTextField: FunctionComponent<FormTextFieldProps> = ({
  name,
  control,
  defaultValue,
  onChangeCallback = (value) => {},
  formatValue = (value) => value,
  errors: passedErrors,
  ...rest
}) => {
  const formContext = useFormContext();
  const errors = passedErrors ?? formContext?.errors ?? {};
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <TextField
          {...props}
          value={formatValue(props.value)}
          helperText={<ErrorMessage errors={errors} name={name} />}
          error={!!get(errors, name)}
          margin="dense"
          {...rest}
          onChange={(e) => {
            props.onChange(e);
            onChangeCallback(e);
          }}
        />
      )}
    />
  );
};

export default FormTextField;
