import * as React from "react";
import * as R from "ramda";

interface UseTabledDataParams<T> {
  remoteData?: T;
  isFetching: boolean;
}

export const useTableData = <T>({
  remoteData,
  isFetching,
}: UseTabledDataParams<T>) => {
  const [updateDataCount, setUpdateDataCount] = React.useState(0);
  const data = React.useMemo(() => {
    return remoteData;
  }, [updateDataCount, isFetching]); // eslint-disable-line

  const triggerDataUpdate = React.useCallback(() => {
    setUpdateDataCount(R.inc);
  }, [setUpdateDataCount]);

  return { data, triggerDataUpdate };
};
