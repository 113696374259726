import {
  AnalysisCompleteColumn,
  DueDateColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  DilutionFactorColumn,
  DispenserColumn,
  getComponentAmount,
  InstrumentColumn,
  SampleAmountColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  SolventColumn,
  TotalDilutionFactorColumn,
} from "./index";

const getChemicalResidueComponentAmount = getComponentAmount(
  "Chemical Residue",
  "analyte",
);

const resultsHeaders = [
  "Abamectin (ppb)",
  "Acephate (ppb)",
  "Acequinocyl (ppb)",
  "Acetamiprid (ppb)",
  "Aldicarb (ppb)",
  "Azoxystrobin (ppb)",
  "Bifenazate (ppb)",
  "Bifenthrin (ppb)",
  "Boscalid (ppb)",
  "Captan (ppb)",
  "Carbaryl (ppb)",
  "Carbofuran (ppb)",
  "Chlorantraniliprole (ppb)",
  "Chlordane (ppb)",
  "Chlorfenapyr (ppb)",
  "Chlorpyrifos (ppb)",
  "Cinerin-I (ppb)",
  "Cinerin-II (ppb)",
  "Clofentezine (ppb)",
  "Coumaphos (ppb)",
  "Cyfluthrin (ppb)",
  "Cypermethrin (ppb)",
  "Daminozide (ppb)",
  "Diazinon (ppb)",
  "Dichlorvos (ppb)",
  "Dimethoate (ppb)",
  "Dimethomorph (ppb)",
  "Ethoprophos (ppb)",
  "Etofenprox (ppb)",
  "Etoxazole (ppb)",
  "Fenhexamid (ppb)",
  "Fenoxycarb (ppb)",
  "Fenpyroximate (ppb)",
  "Fipronil (ppb)",
  "Fludioxinil (ppb)",
  { displayName: "Flonicamid (ppb)", rawName: "flunicamide" },
  "Hexythiazox (ppb)",
  "Imazalil (ppb)",
  "Imidacloprid (ppb)",
  "Jasmolin-I (ppb)",
  "Jasmolin-II (ppb)",
  "Kresoxim-methyl (ppb)",
  "Malathion (ppb)",
  "Metalaxyl (ppb)",
  "Methiocarb (ppb)",
  "Methomyl (ppb)",
  "Methyl parathion (ppb)",
  "Mevinphos (ppb)",
  "Myclobutanil (ppb)",
  "Mycotoxin B1 (ppb)",
  "Mycotoxin B2 (ppb)",
  "Mycotoxin G1 (ppb)",
  "Mycotoxin G2 (ppb)",
  "Naled (ppb)",
  "Ochratoxin A (ppb)",
  "Oxamyl (ppb)",
  "Paclobutrazole (ppb)",
  "PCNB (ppb)",
  "Permethrin (ppb)",
  "Phosmet (ppb)",
  "Piperonyl butoxide (ppb)",
  "Prallethrin (ppb)",
  "Propiconazole (ppb)",
  "Propoxur (ppb)",
  "Pyrethrin-I (ppb)",
  "Pyrethrin-II (ppb)",
  "Pyridaben (ppb)",
  "Spinetoram-J 2 (ppb)",
  "Spinetoram-L 2 (ppb)",
  "Spinosyn A (ppb)",
  "Spinosyn D (ppb)",
  "Spiromesifen (ppb)",
  "Spirotetramat (ppb)",
  "Spiroxamine (ppb)",
  "Tebuconazole (ppb)",
  "Thiachloprid (ppb)",
  "Thiamethoxam (ppb)",
  "Trifloxystrobin (ppb)",
];

const chemicalResidueResultColumnFactory = (
  Header: string | { displayName: string; rawName: string },
) => {
  return {
    Header: typeof Header === "string" ? Header : Header.displayName,
    id:
      typeof Header === "string"
        ? Header.replace("(ppb)", "").trim().toLocaleLowerCase()
        : Header.rawName,
    Cell: getChemicalResidueComponentAmount,
  };
};

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("MFV"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  DispenserColumn,
  SolventColumn,
  DilutionFactorColumn,
  TotalDilutionFactorColumn,
  InstrumentColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  ...resultsHeaders.map(chemicalResidueResultColumnFactory),
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
