import { Box, Paper, Typography, useTheme } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import * as React from "react";

interface ErrorCardProps {
  message?: string;
}

const ErrorCard: React.FunctionComponent<ErrorCardProps> = ({
  message = "Something is terribly amiss",
}) => {
  const theme = useTheme();
  return (
    <Paper>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection="column"
        padding={theme.spacing(5, 0)}
      >
        <ErrorIcon color="error" />
        <Typography>{message}</Typography>
      </Box>
    </Paper>
  );
};

export default ErrorCard;
