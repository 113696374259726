import { Collapse } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import React, { FunctionComponent } from "react";

interface CollapseAlertProps extends AlertProps {
  open: boolean;
}

const CollapseAlert: FunctionComponent<CollapseAlertProps> = ({
  severity,
  variant = "filled",
  children,
  open,
}) => {
  return (
    <Collapse in={open}>
      <Alert severity={severity} variant={variant}>
        {children}
      </Alert>
    </Collapse>
  );
};

export default CollapseAlert;
