import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface UserInfoCardProps {
  name: string;
  email: string;
  phoneNumber?: string | null;
}

const UserInfoCard: FunctionComponent<UserInfoCardProps> = ({
  name,
  email,
  phoneNumber,
}) => (
  <Card>
    <CardContent>
      <Typography component="div">
        <Box fontWeight="fontWeightBold">{name}</Box>
      </Typography>
      <Typography>{email}</Typography>
      <Typography>{phoneNumber}</Typography>
    </CardContent>
  </Card>
);

export default UserInfoCard;
