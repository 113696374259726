import { Container, useTheme } from "@material-ui/core";
import kebabCase from "lodash/kebabCase";
import * as React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import type { Column } from "react-table";
import PanelTable from "src/components/PanelTable";
import CannabinoidsColumns from "src/components/PanelTable/columns/CannabinoidsColumns";
import ChemicalResidueColumns from "src/components/PanelTable/columns/ChemicalResidueColumns";
import DensityColumns from "src/components/PanelTable/columns/DensityColumns";
import HeavyMetalsColumns from "src/components/PanelTable/columns/HeavyMetalsColumns";
import ResidualSolventsColumns from "src/components/PanelTable/columns/ResidualSolventsColumns";
import TerpenesColumns from "src/components/PanelTable/columns/TerpenesColumns";
import WaterActivityColumns from "src/components/PanelTable/columns/WaterActivityColumns";
import {
  FOREIGN_MATERIAL,
  MICROBIAL,
  MOISTURE_CONTENT,
  SPECIFIC_PANEL,
} from "src/config/routes";
import { useAvailablePanelsQuery } from "src/generated/graphql-hooks";
import ForeignMaterialPage from "./ForeignMaterial";
import MicrobialPage from "./Microbial";
import MoistureContentPage from "./MoistureContent";

const ROUTE_COLUMNS_MAP: {
  [routeEnd: string]: Column[] | undefined;
} = {
  //@ts-ignore
  cannabinoids: CannabinoidsColumns,
  //@ts-ignore
  terpenes: TerpenesColumns,
  //@ts-ignore
  "chemical-residue": ChemicalResidueColumns,
  //@ts-ignore
  "residual-solvents": ResidualSolventsColumns,
  //@ts-ignore
  "heavy-metals": HeavyMetalsColumns,
  //@ts-ignore
  density: DensityColumns,
  //@ts-ignore
  "water-activity": WaterActivityColumns,
};

const PanelsPage: React.FunctionComponent = () => {
  const theme = useTheme();

  const { data } = useAvailablePanelsQuery();
  const panels = data?.availablePanels ?? [];
  const { name: routeName } = useParams<{ name: string }>();

  const columns = ROUTE_COLUMNS_MAP[routeName] ?? [];
  const panel = panels.find(
    ({ name: dbName }) => kebabCase(dbName) === routeName,
  );

  if (!panel) return null;

  return (
    <Container style={{ paddingTop: theme.spacing(3) }} maxWidth="xl">
      <Switch>
        <Route path={FOREIGN_MATERIAL}>
          <ForeignMaterialPage {...panel} />
        </Route>
        <Route path={MICROBIAL}>
          <MicrobialPage {...panel} />
        </Route>
        <Route path={MOISTURE_CONTENT}>
          <MoistureContentPage {...panel} />
        </Route>
        <Route path={SPECIFIC_PANEL}>
          <PanelTable columns={columns} {...panel} />
        </Route>
      </Switch>
    </Container>
  );
};

export default PanelsPage;
