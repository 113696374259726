import { AppBar as MUIAppBar, Box, Toolbar } from "@material-ui/core";
import React from "react";
import Logo from "src/assets/img/Think20 logo_RGB - solid - white-small-min.png";
import AccountButton from "../AccountButton";
import DrawerButton from "../DrawerButton";

const AppBar = () => {
  return (
    <MUIAppBar position="sticky">
      <Toolbar>
        <DrawerButton />
        <img
          src={Logo}
          alt="Think20 Labs Logo"
          style={{ height: "1.75rem", marginLeft: 10 }}
        />
        <Box display="flex" flexGrow={1} />
        <AccountButton />
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;
