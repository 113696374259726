import { ErrorMessage } from "@hookform/error-message";
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
} from "@material-ui/core";
import type { ChangeEvent, FunctionComponent } from "react";
import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  get,
  useFormContext,
} from "react-hook-form";

interface FormCheckboxFieldProps {
  control?: Control;
  label?: string;
  name: string;
  defaultValue?: boolean;
  onChangeCallback?: (value: ChangeEvent<HTMLInputElement>) => void;
  FormControlProps?: FormControlProps;
  CheckBoxProps?: CheckboxProps;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

const FormCheckboxField: FunctionComponent<FormCheckboxFieldProps> = ({
  control,
  label,
  name,
  defaultValue,
  onChangeCallback = (e) => {},
  FormControlProps = {},
  CheckBoxProps = {},
  errors: passedErrors,
}) => {
  const formContext = useFormContext();
  const errors = passedErrors ?? formContext?.errors ?? {};

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <FormControl {...FormControlProps} error={!!get(errors, name)}>
          <FormControlLabel
            control={
              <Checkbox
                {...CheckBoxProps}
                {...props}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  onChangeCallback(e);
                }}
              />
            }
            label={label}
          />
          <FormHelperText>
            <ErrorMessage errors={errors} name={name} />
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormCheckboxField;
