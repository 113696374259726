import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { DateTime } from "luxon";
import * as React from "react";
import { useSampleQuery } from "src/generated/graphql-hooks";

interface SampleDetailModalProps {
  sampleId: string | null;
  onClose: () => void;
}

const SampleDetailModal: React.FunctionComponent<SampleDetailModalProps> = ({
  sampleId,
  onClose,
}) => {
  const [currentSampleId, setCurrentSampleId] = React.useState(sampleId);
  React.useEffect(() => {
    if (!!sampleId) setCurrentSampleId(sampleId);
  }, [sampleId]);

  return (
    <Dialog open={!!sampleId} onClose={onClose} maxWidth="lg">
      <DialogTitle>Sample Detail</DialogTitle>
      <DialogContent>
        <SampleDetailView sampleId={currentSampleId} />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

const SampleDetailView: React.FunctionComponent<Pick<
  SampleDetailModalProps,
  "sampleId"
>> = ({ sampleId: id }) => {
  const { data, loading, error } = useSampleQuery({
    variables: { where: { id: id ?? "" } },
    skip: !id,
  });

  if (loading) return <CircularProgress />;
  if (error || !data?.sample)
    return (
      <Box color="error.main" display="flex">
        <Box mr={1}>
          <ErrorIcon />
        </Box>
        <Typography variant="body1">Unable to fetch sample detail</Typography>
      </Box>
    );

  const {
    sample: {
      sampleId,
      productName,
      isCompliance,
      sourceMaterial,
      matrix: {
        name: matrixName,
        sampleCategory: { name: category },
      },
      metrcPackage,
      customerNotes,
      order,
      producer,
      distributor,
    },
  } = data;

  return (
    <Grid container spacing={3}>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          id="sample-id-display"
          label="ID"
          defaultValue={sampleId}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          label="Sample Name"
          defaultValue={productName}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          label="Source Material"
          defaultValue={sourceMaterial}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          label="Category"
          defaultValue={category}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          label="Matrix"
          defaultValue={matrixName}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          label="Metrc Source Package ID"
          defaultValue={metrcPackage?.sourcePackageLabels ?? " "}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <TextField
          label="Client Notes"
          defaultValue={customerNotes || " "}
          inputProps={{ readOnly: true }}
          fullWidth
          margin="dense"
          variant="outlined"
          multiline
        />
      </Grid>

      <Grid item xs={12} style={{ padding: 0 }} />
      {producer && (
        <Grid item md={3} xs={6}>
          <Card variant="outlined">
            <CardHeader subheader="Producer" style={{ paddingBottom: 0 }} />
            <CardContent>
              <Typography component="div">
                <Box fontWeight={700}>{`${producer.name}`}</Box>
                {`${producer.licenseNumber}`}
                <Box fontSize="small">
                  {`${producer.address.street1} ${producer.address.city}, ${producer.address.state} ${producer.address.zip}`}
                </Box>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      {distributor && (
        <Grid item md={3} xs={6}>
          <Card variant="outlined">
            <CardHeader subheader="Distributor" style={{ paddingBottom: 0 }} />
            <CardContent>
              <Typography component="div">
                <Box fontWeight={700}>{`${distributor.name}`}</Box>
                {`${distributor.licenseNumber}`}
                <Box fontSize="small">
                  {`${distributor.address.street1} ${distributor.address.city}, ${distributor.address.state} ${distributor.address.zip}`}
                </Box>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <Card variant="outlined">
          <CardHeader subheader="Timeline" style={{ paddingBottom: 0 }} />
          <CardContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Step</TableCell>
                  <TableCell>Completed At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.sampleSubmission?.submittedAt && (
                  <TableRow>
                    <TableCell>Submitted</TableCell>
                    <TableCell>
                      {DateTime.fromISO(
                        order?.sampleSubmission.submittedAt,
                      ).toLocaleString(DateTime.DATETIME_SHORT)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      {isCompliance && (
        <Grid item xs={12}>
          <Chip color="secondary" label="Compliance" />
        </Grid>
      )}
    </Grid>
  );
};

export default SampleDetailModal;
