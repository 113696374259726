import { ApolloProvider } from "@apollo/client";
import LuxonUtils from "@date-io/luxon";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./hooks/useCurrentUser";
import Root from "./Root";
import createApolloClient from "./services/apollo";
import theme from "./theme";

firebase.initializeApp({
  projectId: process.env.REACT_APP_PROJECT_ID,
  appId: process.env.REACT_APP_APP_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <BrowserRouter>
          <ApolloProvider client={createApolloClient()}>
            <SnackbarProvider maxSnack={3}>
              <ConfirmProvider>
                <UserProvider>
                  <CssBaseline />
                  <Root />
                </UserProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
