import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import FilterBox from "src/components/FilterBox";
import {
  AnalyticsPayload,
  useSampleStatisticsQuery,
} from "src/generated/graphql-hooks";
import PageLoadingIndicator from "../../../components/PageLoadingInicator";
const EMPTY_ANALYTICS: AnalyticsPayload = {
  sampleCount: 0,
  completedSampleCount: 0,
  complianceSampleCount: 0,
  uniqueClientCount: 0,
  sampleStatusCount: [],
  averageDaysToCompletion: 0,
  rushedSampleCount: 0,
  completedClientSampleCount: { dataKeys: [] },
  completedSamplesCountByMonth: [],
};

const colorMap: Record<string, string> = {
  Jan: "#f44336",
  Feb: "#e91e63",
  Mar: "#00bcd4",
  Apr: "#cddc39",
  May: "#009688",
  Jun: "#ffc107",
  Jul: "#ff9800",
  Aug: "#673ab7",
  Sep: "#9c27b0",
  Oct: "#ff5722",
  Nov: "#bf360c",
  Dec: "#1b5e20",
};

interface Range {
  startDate: DateTime;
  endDate: DateTime;
}

export const AnalyticsDashboard = () => {
  const [filter, setFilter] = React.useState({
    startDate: DateTime.now(),
    endDate: DateTime.now().minus({ month: 12 }),
  });

  const { data, loading } = useSampleStatisticsQuery({
    variables: {
      input: {
        startDate: filter.startDate.toUTC().toISO(),
        endDate: filter.endDate.toUTC().toISO(),
      },
    },
  });

  const {
    sampleCount,
    completedSampleCount,
    complianceSampleCount,
    uniqueClientCount,
    sampleStatusCount,
    averageDaysToCompletion,
    rushedSampleCount,
    completedClientSampleCount,
    completedSamplesCountByMonth,
  } = data ? (data?.sampleStatistics as AnalyticsPayload) : EMPTY_ANALYTICS;

  const handleFilter = ({ startDate, endDate }: Range) => {
    setFilter({
      startDate,
      endDate,
    });
  };

  return (
    <Container>
      <Grid container spacing={1} xs={12}>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={2}>
            <DashboardTile>
              <FilterBox onFilterChange={handleFilter} />
            </DashboardTile>
          </Grid>
        </Grid>
        {loading ? (
          <PageLoadingIndicator />
        ) : (
          <>
            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} md={6} lg={2}>
                <DashboardTile title={"Samples Completed"}>
                  <Typography variant="h3" align="center">
                    {completedSampleCount}
                  </Typography>
                </DashboardTile>
              </Grid>
              {!!averageDaysToCompletion && (
                <Grid item xs={12} md={6} lg={2}>
                  <DashboardTile title={"Turnaround Time"}>
                    <Typography variant="h3" align="center">
                      {averageDaysToCompletion.toFixed(1)}
                      <Typography variant="caption" align="center">
                        Days
                      </Typography>
                    </Typography>
                  </DashboardTile>
                </Grid>
              )}

              <Grid item xs={12} md={6} lg={2}>
                <DashboardTile title="Compliance Samples">
                  <Typography variant="h3" align="center">
                    {complianceSampleCount}
                  </Typography>
                </DashboardTile>
              </Grid>
              {!!uniqueClientCount && (
                <Grid item xs={12} md={6} lg={2}>
                  <DashboardTile title="Unique Client Count">
                    <Typography variant="h3" align="center">
                      {uniqueClientCount}
                    </Typography>
                  </DashboardTile>
                </Grid>
              )}
              {!!rushedSampleCount && (
                <Grid item xs={2}>
                  <DashboardTile title="Rushed Sample Count">
                    <Typography variant="h3" align="center">
                      {rushedSampleCount}
                    </Typography>
                    <ResponsiveContainer height={40} width="100%">
                      <BarChart
                        data={[
                          {
                            rushRatio: {
                              rushed: rushedSampleCount,
                              nonRushed: sampleCount,
                            },
                          },
                        ]}
                        layout="vertical"
                      >
                        <Tooltip position={{ x: -10, y: -75 }} />
                        <YAxis
                          dataKey="rushRatio"
                          type="category"
                          hide={true}
                        />
                        <XAxis type="number" hide={true} />
                        <Bar
                          dataKey="rushRatio.rushed"
                          stackId="x"
                          fill="#ff9800"
                          name="Rushed Samples"
                        />
                        <Bar
                          dataKey="rushRatio.nonRushed"
                          stackId="x"
                          fill="#e91e63"
                          name={"Non Rushed Samples"}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </DashboardTile>
                </Grid>
              )}
            </Grid>

            <Grid item xs={6}>
              <DashboardTile title="Number of Samples by  Status">
                <ResponsiveContainer minHeight={250}>
                  <BarChart width={930} data={sampleStatusCount}>
                    <XAxis
                      dataKey="status"
                      interval={0}
                      angle={-35}
                      tick={{ fontSize: 8, x: 0, y: 0, textAnchor: "end" }}
                      height={55}
                    />
                    <CartesianGrid strokeDasharray="4 4" />

                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </DashboardTile>
            </Grid>
            <Grid item xs={6}>
              <DashboardTile title="Completed Sample By Month">
                <ResponsiveContainer height={250} width="100%">
                  <BarChart data={completedSamplesCountByMonth}>
                    <XAxis
                      dataKey="month"
                      interval={0}
                      angle={-35}
                      tick={{ fontSize: 8, x: 0, y: 0, textAnchor: "end" }}
                      height={55}
                    />
                    <CartesianGrid strokeDasharray="4 4" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </DashboardTile>
            </Grid>
            {!!completedClientSampleCount && (
              <Grid item xs={12}>
                <DashboardTile title="Completed Samples by Month per Client">
                  <ResponsiveContainer height={250}>
                    <BarChart
                      width={930}
                      height={250}
                      data={completedClientSampleCount.data}
                    >
                      <XAxis
                        dataKey="client"
                        interval={0}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="4 4" />
                      <Tooltip />
                      {completedClientSampleCount.dataKeys.map(
                        (month: string) => (
                          <Bar
                            stackId="a"
                            dataKey={month}
                            fill={colorMap[month.slice(0, 3)]}
                          />
                        ),
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                </DashboardTile>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};
interface TileProps {
  children: React.ReactNode;
  title?: string;
}

const DashboardTile = ({ children, title }: TileProps) => {
  return (
    <Card
      elevation={1}
      square
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {title && (
        <CardHeader
          subheader={title}
          subheaderTypographyProps={{ align: "center" }}
        ></CardHeader>
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default AnalyticsDashboard;
