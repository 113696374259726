import { DateTime } from "luxon";
import * as math from "mathjs";
import * as R from "ramda";
import * as RA from "ramda-adjunct";
import {
  CaMetrcAnalyteFieldsFragment,
  SampleBccInfoFieldsFragment,
} from "src/generated/graphql-hooks";
import {
  convertPpmToPercent,
  correctForDensity,
  correctForMoisture,
  deriveTotalCBD,
  deriveTotalTHC,
  getDensity,
  getMoistureContent,
} from "./utils";

interface NormalizedResult {
  name: string;
  final_amount: string | number;
}

interface FinalValueDeterminatorParams {
  finalAmount: number;
  sample: SampleBccInfoFieldsFragment;
  name: string;
}

const formatNumber = (value?: number | null) =>
  math.format(value || 0, {
    precision: 5,
    notation: "fixed",
  });

const AFLATOXINS = [
  "mycotoxin b1",
  "mycotoxin b2",
  "mycotoxin g1",
  "mycotoxin g2",
  "ochratoxin a",
];

const RESIDUAL_SOLVENTS = [
  "Ethanol",
  "Propane",
  "Butane",
  "Methanol",
  "Ethylene oxide",
  "Pentane",
  "Ethyl ether",
  "Acetone",
  "Isopropyl alcohol",
  "Acetonitrile",
  "Methylene chloride",
  "Hexane",
  "Ethyl acetate",
  "Chloroform",
  "Benzene",
  "1,2-Dichloroethane",
  "Heptane",
  "Trichloroethylene",
  "Toluene",
  "m,p- xylenes",
  "o-xylene",
];

const PASS_THROUGHS = ["water_activity", "moisture content"];

const XYLENES = ["m,p- xylenes", "o-xylene"];

const PYRETHRINS = ["pyrethrin-i", "pyrethrin-ii"];

const SPINOSADS = ["spinosyn a", "spinosyn d"];

const FINAL_VALUE_DETERMINATOR_MAP: {
  [rawComponentName: string]:
    | {
        [reportingFormat: string]:
          | ((params: FinalValueDeterminatorParams) => number | string | null)
          | undefined;
      }
    | undefined;
} = {
  THCA: {
    "mg/g": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    "mg/mL": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    percent: ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        convertPpmToPercent(
          correctForDensity(
            correctForMoisture(finalAmount, getMoistureContent(sample)),
            getDensity(sample),
          ),
        ),
      ),
  },
  CBD: {
    "mg/g": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    "mg/mL": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    "mg/serving": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerServing
        ? formatNumber(
            (sample.accession.amountPerServing.value *
              correctForDensity(
                correctForMoisture(finalAmount, getMoistureContent(sample)),
                getDensity(sample),
              )) /
              1_000,
          )
        : null,
    "mg/package": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerPackage
        ? formatNumber(
            (sample.accession.amountPerPackage.value *
              correctForDensity(
                correctForMoisture(finalAmount, getMoistureContent(sample)),
                getDensity(sample),
              )) /
              1_000,
          )
        : null,
    percent: ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        convertPpmToPercent(
          correctForDensity(
            correctForMoisture(finalAmount, getMoistureContent(sample)),
            getDensity(sample),
          ),
        ),
      ),
    "total mg/g": ({ sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        deriveTotalCBD(
          sample.panelResults.find(
            (panelResult) => panelResult.panel.name === "Cannabinoids",
          ),
          getMoistureContent(sample),
          getDensity(sample),
        ) / 1000,
      ),
    "total mg/mL": ({ sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        deriveTotalCBD(
          sample.panelResults.find(
            (panelResult) => panelResult.panel.name === "Cannabinoids",
          ),
          getMoistureContent(sample),
          getDensity(sample),
        ) / 1000,
      ),
    "total mg/serving": ({ sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerServing
        ? formatNumber(
            (deriveTotalCBD(
              sample.panelResults.find(
                (panelResult) => panelResult.panel.name === "Cannabinoids",
              ),
              getMoistureContent(sample),
              getDensity(sample),
            ) *
              sample.accession.amountPerServing.value) /
              1000,
          )
        : null,
    "total mg/package": ({ sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerPackage
        ? formatNumber(
            (deriveTotalCBD(
              sample.panelResults.find(
                (panelResult) => panelResult.panel.name === "Cannabinoids",
              ),
              getMoistureContent(sample),
              getDensity(sample),
            ) *
              sample.accession.amountPerPackage.value) /
              1000,
          )
        : null,
    "total percent": ({ sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        convertPpmToPercent(
          deriveTotalCBD(
            sample.panelResults.find(
              (panelResult) => panelResult.panel.name === "Cannabinoids",
            ),
            getMoistureContent(sample),
            getDensity(sample),
          ),
        ),
      ),
  },
  CBDA: {
    "mg/g": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    "mg/mL": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    percent: ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        convertPpmToPercent(
          correctForDensity(
            correctForMoisture(finalAmount, getMoistureContent(sample)),
            getDensity(sample),
          ),
        ),
      ),
  },
  "d9-THC": {
    "mg/g": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    "mg/mL": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        correctForDensity(
          correctForMoisture(finalAmount, getMoistureContent(sample)),
          getDensity(sample),
        ) / 1_000,
      ),
    "mg/serving": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerServing
        ? formatNumber(
            (sample.accession.amountPerServing.value *
              correctForDensity(
                correctForMoisture(finalAmount, getMoistureContent(sample)),
              ),
            getDensity(sample)) / 1_000,
          )
        : null,
    "mg/package": ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerPackage
        ? formatNumber(
            (sample.accession.amountPerPackage.value *
              correctForDensity(
                correctForMoisture(finalAmount, getMoistureContent(sample)),
                getDensity(sample),
              )) /
              1_000,
          )
        : null,
    percent: ({ finalAmount, sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        convertPpmToPercent(
          correctForDensity(
            correctForMoisture(finalAmount, getMoistureContent(sample)),
            getDensity(sample),
          ),
        ),
      ),
    "total mg/g": ({ sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        deriveTotalTHC(
          sample.panelResults.find(
            (panelResult) => panelResult.panel.name === "Cannabinoids",
          ),
          getMoistureContent(sample),
          getDensity(sample),
        ) / 1000,
      ),
    "total mg/mL": ({ sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        deriveTotalTHC(
          sample.panelResults.find(
            (panelResult) => panelResult.panel.name === "Cannabinoids",
          ),
          getMoistureContent(sample),
          getDensity(sample),
        ) / 1000,
      ),
    "total mg/serving": ({ sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerServing
        ? formatNumber(
            (deriveTotalTHC(
              sample.panelResults.find(
                (panelResult) => panelResult.panel.name === "Cannabinoids",
              ),
              getMoistureContent(sample),
              getDensity(sample),
            ) *
              sample.accession.amountPerServing.value) /
              1000,
          )
        : null,
    "total mg/package": ({ sample }: FinalValueDeterminatorParams) =>
      !!sample.accession?.amountPerPackage
        ? formatNumber(
            (deriveTotalTHC(
              sample.panelResults.find(
                (panelResult) => panelResult.panel.name === "Cannabinoids",
              ),
              getMoistureContent(sample),
              getDensity(sample),
            ) *
              sample.accession.amountPerPackage.value) /
              1000,
          )
        : null,
    "total percent": ({ sample }: FinalValueDeterminatorParams) =>
      formatNumber(
        convertPpmToPercent(
          deriveTotalTHC(
            sample.panelResults.find(
              (panelResult) => panelResult.panel.name === "Cannabinoids",
            ),
            getMoistureContent(sample),
            getDensity(sample),
          ),
        ),
      ),
  },
  "Total Aflatoxins": {
    "μg/kg": ({ sample }: FinalValueDeterminatorParams) => {
      const chemicalResiduePanelResult = sample.panelResults.find(
        (panelResult) => panelResult.panel.name === "Chemical Residue",
      );
      return defaultDetermineFinalValue({
        name: "Total Aflatoxins",
        finalAmount: chemicalResiduePanelResult?.results.components.reduce(
          (acc: number, component: any) => {
            if (AFLATOXINS.includes(component.analyte)) {
              const loq =
                chemicalResiduePanelResult.panel.instrumentParameters.find(
                  ({ referenceComponent: { rawName } }) =>
                    rawName === component.analyte,
                )?.levelOfQuantitation ?? 0;
              if ((component.final_amount || 0) > loq)
                return acc + (component.final_amount || 0);
              return acc;
            }
            return acc;
          },
          0,
        ),
        sample,
      });
    },
  },
  "Total Xylenes": {
    "μg/g": ({ sample }: FinalValueDeterminatorParams) => {
      const residualSolventPanelResult = sample.panelResults.find(
        (panelResult) => panelResult.panel.name === "Residual Solvents",
      );
      const totalXylenesLoq =
        residualSolventPanelResult?.panel.instrumentParameters.find(
          ({ referenceComponent: { rawName } }) => rawName === "Total Xylenes",
        ) ?? 0;

      const finalAmount = residualSolventPanelResult?.results.components.reduce(
        (acc: number, component: any) => {
          if (XYLENES.includes(component.name)) {
            const loq =
              residualSolventPanelResult.panel.instrumentParameters.find(
                ({ referenceComponent: { rawName } }) =>
                  rawName === component.name,
              )?.levelOfQuantitation ?? 0;
            if ((component.final_amount || 0) > loq)
              return acc + (component.final_amount || 0);
            return acc;
          }
          return acc;
        },
        0,
      );
      return defaultDetermineFinalValue({
        name: "Total Xylenes",
        finalAmount: finalAmount > totalXylenesLoq ? finalAmount : 0,
        sample,
      });
    },
  },
  pyrethrins: {
    "μg/g": ({ sample }: FinalValueDeterminatorParams) => {
      const residualSolventPanelResult = sample.panelResults.find(
        (panelResult) => panelResult.panel.name === "Chemical Residue",
      );

      const pyrethrinsLoq =
        residualSolventPanelResult?.panel.instrumentParameters.find(
          ({ referenceComponent: { rawName } }) => rawName === "pyrethrins",
        ) ?? 0;

      const finalAmount = residualSolventPanelResult?.results.components.reduce(
        (acc: number, component: any) => {
          if (PYRETHRINS.includes(component.analyte)) {
            const loq =
              residualSolventPanelResult.panel.instrumentParameters.find(
                ({ referenceComponent: { rawName } }) =>
                  rawName === component.analyte,
              )?.levelOfQuantitation ?? 0;
            if ((component.final_amount || 0) > loq)
              return acc + (component.final_amount || 0);
            return acc;
          }
          return acc;
        },
        0,
      );

      return defaultDetermineFinalValue({
        name: "pyrethrins",
        finalAmount: finalAmount > pyrethrinsLoq ? finalAmount : 0,
        sample,
      });
    },
  },
  spinosad: {
    "μg/g": ({ sample }: FinalValueDeterminatorParams) => {
      const residualSolventPanelResult = sample.panelResults.find(
        (panelResult) => panelResult.panel.name === "Chemical Residue",
      );

      const spinosadLoq =
        residualSolventPanelResult?.panel.instrumentParameters.find(
          ({ referenceComponent: { rawName } }) => rawName === "spinosad",
        ) ?? 0;

      const finalAmount = residualSolventPanelResult?.results.components.reduce(
        (acc: number, component: any) => {
          if (SPINOSADS.includes(component.analyte)) {
            const loq =
              residualSolventPanelResult.panel.instrumentParameters.find(
                ({ referenceComponent: { rawName } }) =>
                  rawName === component.analyte,
              )?.levelOfQuantitation ?? 0;
            if ((component.final_amount || 0) > loq)
              return acc + (component.final_amount || 0);
            return acc;
          }
          return acc;
        },
        0,
      );

      return defaultDetermineFinalValue({
        name: "spinosad",
        finalAmount: finalAmount > spinosadLoq ? finalAmount : 0,
        sample,
      });
    },
  },
};

const determineIfPassed = ({
  sample,
  result,
  caMetrcAnalyte,
}: {
  sample: SampleBccInfoFieldsFragment;
  result: NormalizedResult;
  caMetrcAnalyte: CaMetrcAnalyteFieldsFragment;
}): "TRUE" | "FALSE" => {
  const finalValue = determineFinalValue({ sample, result, caMetrcAnalyte });
  if (!finalValue) return "TRUE";
  if (finalValue === "PASS") return "TRUE";
  const {
    actionLimit,
    actionLimitPerPackage,
    actionLimitPerServing,
    weightUnitOfMeasure,
  } =
    sample.complianceType?.stateReportingRegulations.find(
      (reg) => reg.referenceComponent.rawName === result.name,
    ) ?? {};
  if (caMetrcAnalyte.reportingFormat.includes("%")) return "TRUE";
  if (caMetrcAnalyte.reportingFormat.includes("mg/serving"))
    return !actionLimitPerServing || finalValue < actionLimitPerServing / 1_000
      ? "TRUE"
      : "FALSE";
  if (caMetrcAnalyte.reportingFormat.includes("mg/package"))
    return !actionLimitPerPackage || finalValue < actionLimitPerPackage / 1_000
      ? "TRUE"
      : "FALSE";
  if (caMetrcAnalyte.reportingFormat.includes("serving"))
    return !actionLimitPerServing || finalValue < actionLimitPerServing
      ? "TRUE"
      : "FALSE";
  if (caMetrcAnalyte.reportingFormat.includes("package"))
    return !actionLimitPerPackage || finalValue < actionLimitPerPackage
      ? "TRUE"
      : "FALSE";
  if (!actionLimit) return "TRUE";
  if (caMetrcAnalyte.reportingFormat.includes("mg"))
    return finalValue < actionLimit / 1_000 ? "TRUE" : "FALSE";
  if (weightUnitOfMeasure?.includes("kg")) {
    return finalValue < actionLimit / 1_000 ? "TRUE" : "FALSE";
  }
  if (finalValue < actionLimit) return "TRUE";
  return "FALSE";
};

const defaultDetermineFinalValue = ({
  finalAmount,
  sample,
  name,
}: FinalValueDeterminatorParams) => {
  if (PASS_THROUGHS.includes(name)) return formatNumber(finalAmount);
  const densityCorrectedValue = correctForDensity(
    finalAmount,
    getDensity(sample),
  );
  if ([...AFLATOXINS, "Total Aflatoxins", ...RESIDUAL_SOLVENTS].includes(name))
    return formatNumber(densityCorrectedValue);
  return formatNumber(densityCorrectedValue / 1_000);
};

const determineFinalValue = ({
  sample,
  result,
  caMetrcAnalyte,
}: {
  sample: SampleBccInfoFieldsFragment;
  result: NormalizedResult;
  caMetrcAnalyte: CaMetrcAnalyteFieldsFragment;
}) => {
  return typeof result.final_amount === "number" || result.final_amount === null
    ? (
        FINAL_VALUE_DETERMINATOR_MAP[
          caMetrcAnalyte.referenceComponent.rawName
        ]?.[caMetrcAnalyte.reportingFormat] ?? defaultDetermineFinalValue
      )({ finalAmount: result.final_amount, sample, name: result.name })
    : result.final_amount;
};

const generateBccSampleCsvRows = (sample: SampleBccInfoFieldsFragment) =>
  R.uniqBy(
    R.prop("caMetrcAnalyte"),
    sample.panelResults
      .flatMap(({ results, panel: { instrumentParameters } }) => {
        if (!!results.moistureContent) {
          return {
            name: "moisture content",
            final_amount: results.moistureContent,
          };
        }
        if (!!results.inspectionResult) {
          return {
            name: "ForeignMatterResult",
            final_amount: 0,
          };
        }
        if (!!results.components) {
          return results.components.map((component: any) => {
            const loq =
              instrumentParameters.find(
                ({ referenceComponent: { rawName } }) =>
                  rawName === (component.name ?? component.analyte),
              )?.levelOfQuantitation ?? 0;
            return {
              name: component.name ?? component.analyte,
              final_amount:
                component.final_amount >= loq ? component.final_amount : 0,
            };
          });
        }
        if (typeof results === "object") {
          return Object.entries(results).map(([key, value]) => {
            if (typeof value !== "number") {
              return {
                name: key,
                final_amount: 0,
              };
            }
            return {
              name: key,
              final_amount: value,
            };
          });
        }
        return null;
      })
      .filter(RA.isNotNil)
      .concat([
        { name: "Total Aflatoxins", final_amount: 0 },
        { name: "Total Xylenes", final_amount: 0 },
        { name: "pyrethrins", final_amount: 0 },
        { name: "spinosad", final_amount: 0 },
      ])
      .flatMap((result) =>
        sample.matrix.sampleCategory.caMetrcAnalytes
          .filter(
            (analyte) => analyte.referenceComponent.rawName === result.name,
          )
          .map((caMetrcAnalyte) => ({
            completedDate: DateTime.fromISO(
              sample.sampleApproval!.approvedAt,
            ).toISODate(),
            metrcBarcode: sample.metrcPackage?.packageLabel,
            caMetrcAnalyte: caMetrcAnalyte.display,
            finalValue: determineFinalValue({ sample, result, caMetrcAnalyte }),
            passed: determineIfPassed({ sample, result, caMetrcAnalyte }),
          })),
      )
      .filter(
        (item) =>
          RA.isNotNil(item.caMetrcAnalyte) && RA.isNotNil(item.finalValue),
      ),
  );

export default generateBccSampleCsvRows;
