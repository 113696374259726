import {
  AnalysisCompleteColumn,
  DueDateColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  AliquotVolumeColumn,
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  getComponentAmount,
  InstrumentColumn,
  PipetteColumn,
  PipetteTypeColumn,
  SampleAmountColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  SolventColumn,
  SolventVolumeColumnFactory,
} from "./index";

const getResidualSolventsComponentAmount = getComponentAmount(
  "Residual Solvents",
);

const resultsColumnsHeaderAndId: {
  Header: string;
  id: string;
}[] = [
  { Header: "Propane (μg/mL)", id: "Propane" },
  { Header: "Butane (μg/mL)", id: "Butane" },
  { Header: "Methanol (μg/mL)", id: "Methanol" },
  { Header: "Ethylene Oxide (μg/mL)", id: "Ethylene oxide" },
  { Header: "Pentane (μg/mL)", id: "Pentane" },
  { Header: "Ethanol (μg/mL)", id: "Ethanol" },
  { Header: "Ethyl Ether  (μg/mL)", id: "Ethyl ether" },
  { Header: "Acetone (μg/mL)", id: "Acetone" },
  { Header: "Isopropyl Alcohol (μg/mL)", id: "Isopropyl alcohol" },
  { Header: "Acetonitrile (μg/mL)", id: "Acetonitrile" },
  { Header: "Methylene Chloride (μg/mL)", id: "Methylene chloride" },
  { Header: "Hexane (μg/mL)", id: "Hexane" },
  { Header: "Ethyl Acetate (μg/mL)", id: "Ethyl acetate" },
  { Header: "Chloroform (μg/mL)", id: "Chloroform" },
  { Header: "Benzene (μg/mL)", id: "Benzene" },
  { Header: "1,2\u2011Dichloroethane (μg/mL)", id: "1,2-Dichloroethane" },
  { Header: "Heptane (μg/mL)", id: "Heptane" },
  { Header: "Trichloroethylene (μg/mL)", id: "Trichloroethylene" },
  { Header: "Toluene (μg/mL)", id: "Toluene" },
  { Header: "m,p\u2011Xylenes (μg/mL)", id: "m,p- xylenes" },
  { Header: "o\u2011Xylene (μg/mL)", id: "o-xylene" },
];

const residualSolventsResultColumnFactory = ({
  Header,
  id,
}: {
  Header: string;
  id: string;
}) => ({
  Header,
  id,
  Cell: getResidualSolventsComponentAmount,
});

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("RES"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  SolventColumn,
  SolventVolumeColumnFactory(),
  PipetteTypeColumn(0),
  PipetteColumn(0),
  PipetteTypeColumn(1),
  PipetteColumn(1),
  AliquotVolumeColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  InstrumentColumn,
  ...resultsColumnsHeaderAndId.map(residualSolventsResultColumnFactory),
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
