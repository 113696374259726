import { Box, Container, Grid, Typography } from "@material-ui/core";
import firebase from "firebase/app";
import { Form, Formik } from "formik";
import pick from "lodash/pick";
import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import Logo from "src/assets/img/T20 logo_RGB - full color-small-min.png";
import CollapseAlert from "src/components/CollapseAlert";
import FormTextField from "src/components/forms/components/FormikTextField";
import LoadingButton from "src/components/LoadingButton";
import { useSignUpCustomerWithNewBusinessMutation } from "src/generated/graphql-hooks";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import theme from "src/theme";
import { object, ref, string } from "yup";

const validationSchema = object().shape({
  firstName: string().required("This is a required field"),
  lastName: string().required("This is a required field"),
  email: string()
    .email("Please enter a valid email")
    .required("This is a required field"),
  phoneNumber: string(),
  password: string()
    .min(8, "Please choose a password that is at least 8 characters long")
    .required("This is a required field"),
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("This is a required field"),
  business: object().shape({
    name: string().required("This is a required field"),
    address: object().shape({
      street: string().required("This is a required field"),
      city: string().required("This is a required field"),
      state: string().required("This is a required field"),
      zip: string().required("This is a required field"),
    }),
  }),
});

const SignUp = () => {
  const [signUp] = useSignUpCustomerWithNewBusinessMutation();
  const { loading, currentUser } = useCurrentUser();

  if (loading) return null;

  if (currentUser) return <Redirect to="/app" />;

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Think20 Labs Portal - Sign Up</title>
      </Helmet>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
          business: {
            name: "",
            address: {
              street: "",
              city: "",
              state: "",
              zip: "",
            },
          },
        }}
        onSubmit={async (values, { setStatus }) => {
          const input = pick(values, [
            "firstName",
            "lastName",
            "email",
            "phoneNumber",
            "password",
            "business",
          ]);
          try {
            const payload = await signUp({
              variables: { input },
            });
            payload.data?.signUpWithNewBusiness?.loginToken &&
              (await firebase
                .auth()
                .signInWithCustomToken(
                  payload.data.signUpWithNewBusiness.loginToken,
                ));
          } catch (err) {
            setStatus({
              alert: {
                show: true,
                message:
                  "An error occurred during sign up. Please try again later.",
                severity: "error",
              },
            });
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form noValidate data-testid="sign-up-form">
            <Grid
              container
              spacing={2}
              style={{
                marginBlockStart: theme.spacing(5),
                marginBlockEnd: theme.spacing(5),
              }}
            >
              <Grid item container xs={12} justify="center">
                <img
                  src={Logo}
                  alt="Think20 Labs Logo"
                  style={{ maxWidth: "50%", alignSelf: "flex-start" }}
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <Typography variant="h6" component="h2">
                  Sign Up
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CollapseAlert
                  open={!!status?.alert?.show}
                  severity={status?.alert?.severity}
                >
                  {status?.alert?.message}
                </CollapseAlert>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="h3">
                  <Box fontWeight="fontWeightBold">Personal Info</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  id="first-name-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  id="last-name-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="email"
                  label="Email Address"
                  fullWidth
                  id="email-input"
                  margin="dense"
                  autoComplete="email"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="phoneNumber"
                  label="Phone #"
                  fullWidth
                  id="phone-number-input"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="password"
                  label="Password"
                  fullWidth
                  id="password-input"
                  margin="dense"
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  id="confirm-password-input"
                  margin="dense"
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="h3">
                  <Box fontWeight="fontWeightBold">Business Info</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="business.name"
                  label="Business Name"
                  fullWidth
                  id="business-name-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="business.address.street"
                  label="Street Address"
                  fullWidth
                  id="business-address-street-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="business.address.city"
                  label="City"
                  fullWidth
                  id="business-address-city-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="business.address.state"
                  label="State"
                  fullWidth
                  id="business-address-state-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="business.address.zip"
                  label="Zip"
                  fullWidth
                  id="business-address-zip-input"
                  margin="dense"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="center"
                style={{ marginTop: theme.spacing(3) }}
              >
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-testid="form-submit-button"
                  id="create-account-button"
                  loading={isSubmitting}
                >
                  Create account
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default SignUp;
