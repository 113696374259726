import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import get from "lodash/get";
import * as React from "react";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import GlobalTableFilter from "src/components/GlobalTableFilter";
import TablePaginationActions from "../TablePaginationActions";

interface BasicTableProps<T extends object> {
  title: string;
  columns: Column[];
  data: T[];
  loading?: boolean;
  onRefresh?: () => void;
  toolbarButtons?: JSX.Element[];
  editingRowId?: string | null;
  customActions?: { [action: string]: (args: any) => void };
  globalFilter?: string;
  setGlobalFilter?: (value: string) => void;
}

const BasicTable = <T extends object>({
  title,
  columns,
  data,
  loading = false,
  onRefresh,
  toolbarButtons = [],
  editingRowId,
  customActions = {},
  globalFilter,
  setGlobalFilter = () => {},
}: BasicTableProps<T>) => {
  const theme = useTheme();
  const [autoResetPage, setAutoResetPage] = React.useState(true);
  React.useEffect(
    () => (editingRowId ? setAutoResetPage(false) : setAutoResetPage(true)),
    [editingRowId],
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable(
    {
      columns,
      data,
      ...customActions,
      autoResetPage,
      useControlledState: (state) =>
        React.useMemo(
          () => ({
            ...state,
            editingRowId,
            globalFilter,
          }),
          [state, editingRowId, globalFilter], // eslint-disable-line
        ),
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Paper>
      <Toolbar
        style={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Box color={theme.palette.primary.contrastText} mr={5}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <GlobalTableFilter
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
        <Box display="flex" flex="1" />
        {toolbarButtons}
        {onRefresh && (
          <IconButton
            style={{ color: theme.palette.primary.contrastText }}
            onClick={() => {
              onRefresh();
            }}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </Toolbar>
      <TableContainer
        style={{
          maxHeight: `calc(90vh - ${theme.spacing(8) * 2}px - ${theme.spacing(
            3,
          )}px)`,
        }}
      >
        <Table {...getTableProps()} size="small" stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...(column.id === "selection"
                        ? column.getHeaderProps({
                            style: {
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                              ...get(column, "style", {}),
                            },
                          })
                        : column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                            style: {
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                              ...get(column, "style", {}),
                            },
                          }))}
                      {...column.getHeaderProps()}
                    >
                      {!column.disableSortBy && column.id !== "selection" ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.render("Header")}
                        </TableSortLabel>
                      ) : (
                        column.render("Header")
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            maxWidth: cell.column.maxWidth,
                            ...get(cell.column, "style"),
                            ...get(cell.column, "cellStyle"),
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        colSpan={columns.length}
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

export default BasicTable;
