import { Container, useTheme } from "@material-ui/core";
import * as React from "react";
import { Helmet } from "react-helmet";
import PickupLog from "./PickUpLog";

const PickupLogPage: React.FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Container style={{ paddingTop: theme.spacing(3) }} maxWidth="xl">
      <Helmet>
        <title>Think20 Labs Portal - Pickup Log</title>
      </Helmet>
      <PickupLog />
    </Container>
  );
};

export default PickupLogPage;
