import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UsersList from "./UsersList";
import UsersEdit from "./UsersEdit";

const UsersContainer = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:userId/edit`}>
        <UsersEdit basePath={path} />
      </Route>
      <Route path={`${path}`}>
        <UsersList />
      </Route>
    </Switch>
  );
};

export default UsersContainer;
