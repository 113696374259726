import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { Row } from "react-table";
import { UserInfoFieldsFragment } from "src/generated/graphql-hooks";

interface AdminRowValues {
  row: Row<UserInfoFieldsFragment>;
}

export const AdminRow = ({ row }: AdminRowValues) => {
  return (
    <TableRow {...row.getRowProps()}>
      {row.cells.map((cell, index) => {
        return (
          <TableCell
            {...cell.getCellProps()}
            key={index}
            style={{
              background: index === 0 ? "transparent" : "white",
            }}
          >
            {cell.render("Cell")}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
