import { Checkbox, MenuItem } from "@material-ui/core";
import { DateTime } from "luxon";
import React from "react";
import { Controller } from "react-hook-form";
import type { Cell } from "react-table";
import FormTextField from "src/components/forms/components/FormTextField";
import BalanceSelect from "src/components/forms/uploadFormFields/BalanceSelect";
import {
  editableCell,
  measurementColumnFactory,
  UOM_ABBREVIATIONS,
} from "src/components/tableColumns";
import type { HomogenizationLogEntryFragment } from "src/generated/graphql-hooks";

export const IntakeAmountColumn = measurementColumnFactory<
  HomogenizationLogEntryFragment
>({
  Header: "Intake Amount",
  idField: {
    name: "intakeMeasurement.id",
  },
  valueField: {
    name: "intakeMeasurement.value",
    defaultValue: (cell) =>
      cell.row.original.homogenization?.intakeMeasurement?.value ?? 0,
  },
  unitsField: {
    name: "intakeMeasurement.units",
    defaultValue: (cell) =>
      cell.row.original.homogenization?.intakeMeasurement?.units ?? "grams",
  },
  display: (cell) => {
    const homogenization = cell.row.original.homogenization;
    return homogenization?.intakeMeasurement
      ? `${homogenization.intakeMeasurement?.value} ${
          UOM_ABBREVIATIONS[homogenization.intakeMeasurement?.units ?? ""] ??
          homogenization.intakeMeasurement?.units
        }`
      : null;
  },
});

export const GrossAmountColumn = measurementColumnFactory<
  HomogenizationLogEntryFragment
>({
  Header: "Gross Amount",
  idField: {
    name: "grossMeasurement.id",
  },
  valueField: {
    name: "grossMeasurement.value",
    defaultValue: (cell) =>
      cell.row.original.homogenization?.grossMeasurement?.value ?? 0,
  },
  unitsField: {
    name: "grossMeasurement.units",
    defaultValue: (cell) =>
      cell.row.original.homogenization?.grossMeasurement?.units ?? "grams",
  },
  display: (cell) => {
    const homogenization = cell.row.original.homogenization;
    return homogenization?.grossMeasurement
      ? `${homogenization.grossMeasurement?.value} ${
          UOM_ABBREVIATIONS[homogenization.grossMeasurement?.units ?? ""] ??
          homogenization.grossMeasurement?.units
        }`
      : null;
  },
});

export const PackageAmountColumn = {
  Header: "Package Amount",
  accessor: "accession.amountPerPackage.value",
  Cell: ({
    row: {
      original: { accession },
    },
  }: Cell<HomogenizationLogEntryFragment>) =>
    accession?.amountPerPackage
      ? `${accession.amountPerPackage.value} ${accession.amountPerPackage?.units}`
      : null,
};

export const ServingAmountColumn = {
  Header: "Serving Amount",
  accessor: "accession.amountPerServing.value",
  Cell: ({
    row: {
      original: { accession },
    },
  }: Cell<HomogenizationLogEntryFragment>) =>
    accession?.amountPerServing
      ? `${accession.amountPerServing.value} ${accession.amountPerServing?.units}`
      : null,
};

export const HomogenizationMethodColumn = {
  Header: "Homogenization Method",
  accessor: "homogenization.method",
  Cell: editableCell<HomogenizationLogEntryFragment>((cell) => (
    <FormTextField
      name="method"
      select
      fullWidth
      defaultValue={cell.row.original.homogenization?.method ?? ""}
    >
      <MenuItem value="Meat Grinder">Meat Grinder</MenuItem>
      <MenuItem value="Nutribullet">Nutribullet</MenuItem>
      <MenuItem value="Chopped">Chopped</MenuItem>
      <MenuItem value="Mallet">Mallet</MenuItem>
      <MenuItem value="Stirring">Stirring</MenuItem>
      <MenuItem value="Other">Other (add to notes)</MenuItem>
    </FormTextField>
  ))((cell) => cell.row.original.homogenization?.method ?? null),
};

export const NotesColumn = {
  Header: "Notes",
  minWidth: 300,
  Cell: editableCell<HomogenizationLogEntryFragment>((cell) => (
    <FormTextField
      name="notes"
      multiline
      fullWidth
      defaultValue={cell.row.original.homogenization?.notes ?? ""}
    />
  ))((cell) => cell.row.original.homogenization?.notes ?? null),
};

export const CompletedColumn = {
  Header: "Homogenization Complete",
  Cell: editableCell<HomogenizationLogEntryFragment>((cell) => (
    <Controller
      name="completed"
      defaultValue={!!cell.row.original.homogenization?.complete}
      render={(props) => (
        <Checkbox
          {...props}
          checked={!!props.value}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      )}
    />
  ))((cell) => (
    <Checkbox
      checked={!!cell.row.original.homogenization?.complete}
      readOnly
      disableRipple
      style={{ cursor: "default" }}
    />
  )),
  disableSortBy: true,
};

export const CompletedByColumn = {
  Header: "Completed By",
  Cell: (cell: Cell<HomogenizationLogEntryFragment>) => {
    const { completedBy } = cell.row.original.homogenization ?? {};
    return completedBy
      ? `${completedBy.firstName} ${completedBy.lastName}`
      : null;
  },
};

export const CompletedAtColumn = {
  Header: "Complete Date",
  Cell: (cell: Cell<HomogenizationLogEntryFragment>) => {
    const { completedAt } = cell.row.original.homogenization ?? {};
    return completedAt
      ? DateTime.fromISO(completedAt).toLocaleString(DateTime.DATE_SHORT)
      : null;
  },
};

export const BalanceColumn = (editable = false) => ({
  Header: "Balance",
  minWidth: 150,
  Cell: (cell: Cell<HomogenizationLogEntryFragment>) =>
    editable && cell.state.editingRowId === cell.row.original.id ? (
      <BalanceSelect
        defaultValue={cell.row.original.homogenization?.balance.id ?? ""}
      />
    ) : (
      cell.row.original.homogenization?.balance.name ?? null
    ),
});
