import { ErrorMessage } from "@hookform/error-message";
import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { map } from "ramda";
import React, { ChangeEvent, FunctionComponent } from "react";
import { Control, Controller, DeepMap, FieldError, get } from "react-hook-form";

interface FormRadioGroupFieldProps {
  control: Control;
  options: RadioGroupOption[];
  label: string;
  name: string;
  defaultValue?: string;
  onChangeCallback?: (value: ChangeEvent<HTMLInputElement>) => void;
  FormControlProps?: FormControlProps;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

interface RadioGroupOption {
  value: string | boolean | number;
  label: string;
}

const FormRadioGroupField: FunctionComponent<FormRadioGroupFieldProps> = ({
  name,
  control,
  options,
  label,
  defaultValue,
  onChangeCallback = (value) => {},
  FormControlProps = {},
  errors = {},
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={(props) => (
      <FormControl {...FormControlProps} error={!!get(errors, name)}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          {...props}
          onChange={(e) => {
            props.onChange(e);
            onChangeCallback(e);
          }}
          aria-label={name}
        >
          {renderRadioOptions(options)}
        </RadioGroup>
        <FormHelperText>
          <ErrorMessage errors={errors} name={name} />
        </FormHelperText>
      </FormControl>
    )}
  />
);

const renderRadioOptions = map(({ value, label }) => (
  <FormControlLabel
    key={value}
    value={value}
    control={<Radio color="primary" />}
    label={label}
  />
));

export default FormRadioGroupField;
