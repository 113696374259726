import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Fab, Grid, useTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import useBusinessPartners from "src/hooks/useBusinessPartners";
import { useSubmitSamples } from "src/hooks/useSubmitSamples";
import type { AnySchema } from "yup";
import { array, boolean, date, number, object, string } from "yup";
import AddPartnerDialog from "./AddPartnerDialog";
import { BLANK_SAMPLE } from "./config";
import SampleSection from "./SampleSection";

export interface SampleValues {
  sourceMaterial: string;
  compliance: string;
  forMedicalUse: boolean;
  productName: string;
  lotOrBatchNumber: string;
  category: string;
  matrix: string;
  harvestOrProductionDate: Date;
  weightOrCount: number;
  unitOfMeasure: string;
  reportTemplateOptionId: string;
  intendedUse: string;
  analysisOptionIds: string[];
  deliveryMethodId: string;
  notes: string;
  distributorId: string;
  producerId: string;
  readyForPickup: Date;
}
export interface SampleSubmissionFormValues {
  samples: SampleValues[];
}

const requiredForCompliance = (type: AnySchema) =>
  type.when("compliance", {
    is: "Yes",
    then: type.required(REQUIRED_FOR_COMPLIANCE_MESSAGE),
    otherwise: type.notRequired(),
  });

const REQUIRED_MESSAGE = "This field is required";
const REQUIRED_FOR_COMPLIANCE_MESSAGE =
  "This field is required for compliance samples";

const validationSchema = object()
  .shape({
    samples: array()
      .of(
        object()
          .shape({
            sourceMaterial: string().required(REQUIRED_MESSAGE),
            compliance: string().required(REQUIRED_MESSAGE),
            forMedicalUse: boolean().required(REQUIRED_MESSAGE),
            productName: string().required(REQUIRED_MESSAGE),
            lotOrBatchNumber: string().required(REQUIRED_MESSAGE),
            category: string().required(REQUIRED_MESSAGE),
            matrix: string().required(REQUIRED_MESSAGE),
            harvestOrProductionDate: requiredForCompliance(
              date().max(new Date(), "Select a date in the past"),
            ),
            weightOrCount: number().when("compliance", {
              is: "Yes",
              then: number()
                .required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
                .typeError("Enter a number")
                .positive("Enter a positive number"),
              otherwise: number()
                .max(0, "Leave 0 if not compliance")
                .min(0, "Leave 0 is not compliance")
                .typeError("Leave 0 if not compliance"),
            }),
            unitOfMeasure: requiredForCompliance(string()),
            reportTemplateOptionId: string().required(REQUIRED_MESSAGE),
            intendedUse: string().required(REQUIRED_MESSAGE),
            analysisOptionIds: array()
              .of(string())
              .min(1, "Select at least 1 analysis option"),
            deliveryMethodId: string()
              .required(REQUIRED_MESSAGE)
              .typeError("Select a delivery method"),
            notes: string(),
            distributorId: requiredForCompliance(string()),
            producerId: requiredForCompliance(string()),
            readyForPickup: requiredForCompliance(
              date().min(
                DateTime.local().startOf("day").toJSDate(),
                "Select a future date",
              ),
            ),
          })
          .required(),
      )
      .min(1)
      .required(),
  })
  .required();

const removeSample = (
  remove: (index?: number | number[] | undefined) => void,
) => (numberOfSamples: number) => (index: number) => {
  if (numberOfSamples <= 1) return;
  remove(index);
};

const SampleSubmissionForm = () => {
  const theme = useTheme();
  const methods = useForm<SampleSubmissionFormValues & { formError: string }>({
    defaultValues: {
      samples: [BLANK_SAMPLE],
    },
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "samples",
  });
  const [addPartnerDialog, setAddPartnerDialog] = useState<{
    open: boolean;
    type: "producer" | "distributor";
    onSubmit: ({
      name,
      address,
      licenseNumber,
    }: {
      name: string;
      address: {
        street: string;
        city: string;
        state: string;
        zip: string;
      };
      licenseNumber: string;
    }) => Promise<void>;
  }>({
    open: false,
    type: "producer",
    onSubmit: async () => {},
  });

  const {
    producers,
    addProducer,
    distributors,
    addDistributor,
  } = useBusinessPartners();
  const [submitSamples] = useSubmitSamples();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit(async (data) => {
          try {
            await submitSamples(data);
            enqueueSnackbar(
              "Your sample submission has been received successfully!",
              { variant: "success" },
            );
            methods.reset();
          } catch (err) {
            enqueueSnackbar(
              "An error occurred during submission. Please try again.",
              { variant: "error" },
            );

            methods.setError("formError", {
              message: "An error occurred during submission. Please try again.",
            });
          }
        })}
        data-testid="sample-submission-form"
      >
        <Grid container spacing={3}>
          {fields.map((field, index) => (
            <SampleSection
              key={field.id}
              index={index}
              onDelete={(index) => removeSample(remove)(fields.length)(index)}
              allowDelete={fields.length > 1}
              distributors={distributors ?? []}
              producers={producers ?? []}
              onAddDistributor={(index: number) =>
                setAddPartnerDialog({
                  type: "distributor",
                  open: true,
                  onSubmit: async ({ name, address, licenseNumber }) => {
                    const { data } = await addDistributor({
                      variables: { input: { name, address, licenseNumber } },
                    });
                    setAddPartnerDialog({
                      ...addPartnerDialog,
                      open: false,
                    });
                    methods.setValue(
                      `samples[${index}].distributorId`,
                      data?.createDistributor?.id ?? "",
                    );
                  },
                })
              }
              onAddProducer={(index: number) =>
                setAddPartnerDialog({
                  type: "producer",
                  open: true,
                  onSubmit: async ({ name, address, licenseNumber }) => {
                    const { data } = await addProducer({
                      variables: { input: { name, address, licenseNumber } },
                    });
                    setAddPartnerDialog({
                      ...addPartnerDialog,
                      open: false,
                    });
                    methods.setValue(
                      `samples[${index}].producerId`,
                      data?.createProducer?.id ?? "",
                    );
                  },
                })
              }
            />
          ))}
          <Grid
            item
            xs={12}
            container
            justify="center"
            style={{ marginBottom: theme.spacing(10) }}
          >
            <Button
              onClick={() => append({ ...BLANK_SAMPLE })}
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add Sample
            </Button>
          </Grid>
        </Grid>
        <Fab
          type="submit"
          style={{
            position: "fixed",
            right: theme.spacing(3),
            bottom: theme.spacing(3),
            backgroundColor: theme.palette.accent.main,
            color: theme.palette.common.white,
          }}
          variant="extended"
          disabled={methods.formState.isSubmitting}
        >
          <PublishIcon style={{ marginRight: 5 }} />
          Submit Samples
        </Fab>
      </form>
      <AddPartnerDialog
        open={addPartnerDialog.open}
        onSubmit={addPartnerDialog.onSubmit}
        onCancel={() => {
          setAddPartnerDialog({
            ...addPartnerDialog,
            open: false,
          });
        }}
        type={addPartnerDialog.type}
      />
    </FormProvider>
  );
};

export default SampleSubmissionForm;
