import {
  Box,
  Divider,
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIconTypeMap,
  Typography,
} from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import CheckIcon from "@material-ui/icons/CheckCircle";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import ListAltIcon from "@material-ui/icons/ListAlt";
import NoteIcon from "@material-ui/icons/Note";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import TimelineIcon from "@material-ui/icons/Timeline";
import ViewListIcon from "@material-ui/icons/ViewList";
import { map } from "ramda";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "src/assets/img/T20 logo_RGB - full color-small-min.png";
import {
  ACCESSION_SAMPLES,
  APPROVE_RESULTS_PERMISSION,
  HOMOGENIZE_SAMPLES_PERMISSION,
  MANAGE_USERS_PERMISSION,
  SUBMIT_SAMPLES_PERMISSION,
  UPLOAD_RESULTS_PERMISSION,
  VIEW_PANELS_PERMISSION,
  VIEW_PICKUP_LOG,
  VIEW_QA_RESULTS_PERMISSION,
  VIEW_SAMPLE_LOG,
  VIEW_SAMPLE_TRACKER_PERMISSION,
} from "src/config/permissions";
import {
  APPROVE_RESULTS,
  HOMOGENIZATION,
  PICKUP_LOG,
  PROCESSING_JOBS,
  QA_RESULTS,
  SAMPLE_ACCESSION,
  SAMPLE_LOG,
  SAMPLE_SUBMISSION,
  SAMPLE_TRACKER,
  USER_MANAGEMENT,
} from "src/config/routes";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useDrawer } from "src/hooks/useDrawer";
import { GlassesIcon, TestTubeIcon } from "../icons";
import PanelsSection from "./PanelsSection";

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  toolbar: { ...theme.mixins.toolbar, display: "flex" },
  logo: {
    width: "50%",
    marginTop: "auto",
    marginLeft: theme.spacing(3),
    marginBottom: "auto",
  },
}));

const Drawer = () => {
  const classes = useStyles();
  const { isOpen, toggleDrawer } = useDrawer();
  const { pathname } = useLocation();
  const { currentUser } = useCurrentUser();

  const drawerItems = map(
    ({ text, path, Icon, requiredPermission }: DrawerItemConfig) =>
      currentUser?.role.permissions?.includes(requiredPermission) && (
        <ListItem
          button
          key={text}
          component={Link}
          to={path}
          onClick={toggleDrawer}
          selected={pathname.includes(path)}
        >
          <ListItemIcon>
            <Icon color="primary" />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ),
  )(DRAWER_ITEMS_CONFIG);

  return (
    <MUIDrawer
      anchor="left"
      open={isOpen}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-testid="drawer"
      keepMounted
    >
      <Box className={classes.toolbar}>
        <img src={Logo} className={classes.logo} alt="Think20 Labs Logo" />
      </Box>
      <Divider />
      <List component="nav" style={{ overflowY: "auto" }}>
        {drawerItems}
        {currentUser?.role.permissions?.includes(VIEW_PANELS_PERMISSION) && (
          <PanelsSection toggleDrawer={toggleDrawer} />
        )}
      </List>
      <Box display="flex" flexGrow={1} />
      <Divider />
      <Box py={1} px={2}>
        <Typography variant="subtitle2">
          v{process.env.REACT_APP_APP_VERSION}
        </Typography>
      </Box>
    </MUIDrawer>
  );
};

interface DrawerItemConfig {
  text: string;
  path: string;
  requiredPermission: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

export const DRAWER_ITEMS_CONFIG = [
  {
    text: "Sample Submission",
    Icon: NoteIcon,
    path: SAMPLE_SUBMISSION,
    requiredPermission: SUBMIT_SAMPLES_PERMISSION,
  },
  {
    text: "Sample Tracker",
    Icon: TimelineIcon,
    path: SAMPLE_TRACKER,
    requiredPermission: VIEW_SAMPLE_TRACKER_PERMISSION,
  },
  {
    text: "Pickup Log",
    Icon: DirectionsCarIcon,
    path: PICKUP_LOG,
    requiredPermission: VIEW_PICKUP_LOG,
  },
  {
    text: "Sample Log",
    Icon: ListAltIcon,
    path: SAMPLE_LOG,
    requiredPermission: VIEW_SAMPLE_LOG,
  },
  {
    text: "Accessioning",
    Icon: AddToQueueIcon,
    path: SAMPLE_ACCESSION,
    requiredPermission: ACCESSION_SAMPLES,
  },
  {
    text: "Homogenization",
    Icon: TestTubeIcon,
    path: HOMOGENIZATION,
    requiredPermission: HOMOGENIZE_SAMPLES_PERMISSION,
  },
  {
    text: "Processing Jobs",
    Icon: ViewListIcon,
    path: PROCESSING_JOBS,
    requiredPermission: UPLOAD_RESULTS_PERMISSION,
  },
  {
    text: "QA",
    Icon: GlassesIcon,
    path: QA_RESULTS,
    requiredPermission: VIEW_QA_RESULTS_PERMISSION,
  },
  {
    text: "Approve Results",
    Icon: CheckIcon,
    path: APPROVE_RESULTS,
    requiredPermission: APPROVE_RESULTS_PERMISSION,
  },
  {
    text: "User Management",
    Icon: SupervisorAccountIcon,
    path: USER_MANAGEMENT,
    requiredPermission: MANAGE_USERS_PERMISSION,
  },
];

export default Drawer;
