import { Badge, IconButton } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImageIcon from "@material-ui/icons/Image";
import firebase from "firebase/app";
import { DropzoneDialogBase } from "material-ui-dropzone";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NotesColumn } from "src/components/PanelTable/columns";
import {
  AnalysisCompleteColumn,
  DueDateColumn,
  editableCell,
  EditableInstrumentColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  PassOrFailDisplay,
  PassOrFailSelect,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  FileFieldsFragment,
  PanelLogFieldsFragment,
  UrlSchema,
} from "src/generated/graphql-hooks";

const AddImages = () => {
  const [open, setOpen] = React.useState(false);
  const { watch } = useFormContext();
  const images = watch("images", []);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Badge badgeContent={images.length} color="secondary">
          <CloudUploadIcon />
        </Badge>
      </IconButton>
      <Controller
        name="images"
        defaultValue={[]}
        render={(props) => (
          <DropzoneDialogBase
            acceptedFiles={["image/*"]}
            fileObjects={props.value ?? []}
            filesLimit={3}
            open={open}
            onAdd={(files) => {
              props.onChange(files);
            }}
            onDelete={(_, index) => {
              props.onChange([
                ...props.value.slice(0, index),
                ...props.value.slice(index + 1),
              ]);
            }}
            onSave={() => setOpen(false)}
            onClose={() => {
              props.onChange([]);
              setOpen(false);
            }}
            showAlerts={false}
          />
        )}
      />
    </>
  );
};

const ImageButtonLinks = ({ images }: { images: FileFieldsFragment[] }) => {
  const _handleImageButtonClick = async ({
    url,
    urlSchema,
  }: FileFieldsFragment) => {
    if (urlSchema === UrlSchema.Http) {
      window.open(url, "_blank");
      return;
    }
    if (urlSchema === UrlSchema.GcsRef) {
      const downloadUrl = await firebase
        .storage()
        .refFromURL(url)
        .getDownloadURL();
      window.open(downloadUrl, "_blank");
      return;
    }
  };
  return (
    <>
      {images.map((image) => (
        <IconButton
          onClick={() => _handleImageButtonClick(image)}
          key={image.id}
        >
          <ImageIcon />
        </IconButton>
      ))}
    </>
  );
};

const ForeignMaterialImagesColumn = {
  Header: "Images",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <AddImages />
  ))((cell) => (
    <ImageButtonLinks
      images={cell.row.original.panelResults?.[0]?.images ?? []}
    />
  )),
};

const InspectionResultColumn = {
  Header: "Inspection Result",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <PassOrFailSelect
      name="inspectionResult"
      defaultValue={
        cell.row.original.panelResults?.[0]?.results?.inspectionResult ?? ""
      }
    />
  ))((cell) => (
    <PassOrFailDisplay
      value={
        cell.row.original.panelResults?.[0]?.results?.inspectionResult ?? null
      }
    />
  )),
};

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  RushColumn,
  DueDateColumn,
  EditableInstrumentColumn,
  ForeignMaterialImagesColumn,
  NotesColumn,
  InspectionResultColumn,
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
