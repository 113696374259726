import { NetworkStatus } from "@apollo/client";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { Column } from "react-table";
import {
  PanelFieldsFragment,
  SampleStatus,
  useConfirmPanelResultMutation,
  usePanelLogQuery,
  useUpsertTestPrepInfoMutation,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import { identityIfDefined, parseFloatIfDefined } from "src/utils";
import { useDebounce } from "use-debounce";
import BasicTable from "../BasicTable";
import ErrorCard from "../ErrorCard";
import LoadingCard from "../LoadingCard";
import UploadResultsModal from "../UploadResultsModal";

interface PanelTableProps {
  columns: Column[];
  pollInterval?: number;
}

interface TestPrepFields {
  dispenserId?: string;
  solventId?: string;
  solventMasses?: [string, string];
  dilutionFactor?: string;
  pipetteIds: [string?, string?];
  solventDensity?: string;
  totalDilutionFactor?: string;
  solventVolume?: string;
  aliquotVolume?: string;
  tsbVolume?: string;
  samplePrepComplete?: boolean;
  finalVolume?: string;
  goldSpikeVolume?: string;
  aliquotAmount?: string;
  aliquotUnitOfMeasure?: string;
  balanceId?: string;
  analysisComplete?: boolean;
}

const DEFAULT_POLL_INTERVAL = 10_000;

const PanelTable: React.FunctionComponent<
  PanelTableProps & PanelFieldsFragment
> = ({ id: panelId, name, columns, pollInterval = DEFAULT_POLL_INTERVAL }) => {
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [uploadModalOpen, setUploadModalOpen] = React.useState(false);
  useSearchQueryParam(setGlobalFilter);
  const { enqueueSnackbar } = useSnackbar();

  const { data: remoteData, loading, error, networkStatus } = usePanelLogQuery({
    variables: {
      panelId,
      statusFilter: {
        in: [
          SampleStatus.InProcess,
          SampleStatus.QaReview,
          SampleStatus.ReadyForQaReview,
          SampleStatus.PendingComplete,
        ],
      },
    },
    pollInterval,
    notifyOnNetworkStatusChange: true,
  });

  const { data, triggerDataUpdate } = useTableData({
    remoteData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  const [debouncedNetworkStatus, { flush }] = useDebounce(networkStatus, 1000);

  React.useEffect(() => {
    triggerDataUpdate();
    flush();
  }, [panelId, flush, triggerDataUpdate]);

  const [saveTestPrepFields] = useUpsertTestPrepInfoMutation();
  const [confirmTestResults] = useConfirmPanelResultMutation();

  const memoizedColumns: Column[] = React.useMemo(() => columns, [columns]);

  const methods = useForm<TestPrepFields>();

  const { handleSubmit, watch, setValue } = methods;

  const { solventId } = watch(["solventId"]);

  React.useEffect(() => {
    // TODO: Populate default values from DB
    if (solventId === "ckjlymxsv00144qmva1odfpci") {
      setValue("solventDensity", 0.786);
    } else if (solventId === "ckmm6bzhk00121cmvp3bc8nu3") {
      setValue("solventDensity", 0.792);
    } else {
      setValue("solventDensity", 0);
    }
  }, [solventId, setValue]);

  if (
    debouncedNetworkStatus === NetworkStatus.loading ||
    debouncedNetworkStatus === NetworkStatus.setVariables
  )
    return (
      <LoadingCard message={`Loading ${name.toLocaleLowerCase()} results...`} />
    );

  if (error) return <ErrorCard />;
  const currentSelectedSample = data?.samples.find(
    (sample) => sample.id === editingRowId,
  );

  return (
    <>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={handleSubmit(
            async ({
              solventId,
              dispenserId,
              solventMasses,
              dilutionFactor,
              pipetteIds,
              solventDensity,
              totalDilutionFactor,
              solventVolume,
              aliquotVolume,
              tsbVolume,
              samplePrepComplete,
              finalVolume,
              goldSpikeVolume,
              aliquotAmount,
              aliquotUnitOfMeasure,
              balanceId,
              analysisComplete,
            }) => {
              try {
                if (
                  !editingRowId ||
                  !balanceId ||
                  !aliquotUnitOfMeasure ||
                  !aliquotAmount
                )
                  return;
                await saveTestPrepFields({
                  variables: {
                    panelId,
                    testPrepInput: {
                      solventApplication: {
                        solventId: identityIfDefined(solventId),
                        masses: identityIfDefined(
                          solventMasses?.map(parseFloat),
                        ),
                        dilutionFactor: parseFloatIfDefined(dilutionFactor),
                        density: parseFloatIfDefined(solventDensity),
                        totalDilutionFactor: parseFloatIfDefined(
                          totalDilutionFactor,
                        ),
                        volume: parseFloatIfDefined(solventVolume),
                      },
                      samplePrepComplete: identityIfDefined(samplePrepComplete),
                      tsbVolume: parseFloatIfDefined(tsbVolume),
                      dispenserId: identityIfDefined(dispenserId),
                      pipette1Id: identityIfDefined(pipetteIds?.[0]),
                      pipette2Id: identityIfDefined(pipetteIds?.[1]),
                      aliquotVolume: parseFloatIfDefined(aliquotVolume),
                      goldSpikeVolume: parseFloatIfDefined(goldSpikeVolume),
                      finalVolume: parseFloatIfDefined(finalVolume),
                      referencePanelId: panelId,
                      sampleId: editingRowId,
                    },
                    aliquotInput: {
                      balanceId,
                      sampleId: editingRowId,
                      aliquots: [
                        {
                          panelId,
                          amount: parseFloatIfDefined(aliquotAmount),
                          unitOfMeasure: aliquotUnitOfMeasure,
                        },
                      ],
                    },
                  },
                });
                if (
                  !currentSelectedSample?.panelResults[0]?.analysisComplete &&
                  analysisComplete
                ) {
                  await confirmTestResults({
                    variables: {
                      input: {
                        analysisComplete,
                        referencePanelId: panelId,
                        sampleId: editingRowId,
                      },
                      panelId,
                    },
                  });
                }

                triggerDataUpdate();
                setEditingRowId(null);
                enqueueSnackbar("Row successfully saved", {
                  variant: "success",
                });
              } catch (err) {
                enqueueSnackbar("An error occurred while saving row", {
                  variant: "error",
                });
                console.error(err);
              }
            },
          )}
        >
          <BasicTable
            title={name}
            columns={memoizedColumns}
            data={data?.samples ?? []}
            editingRowId={editingRowId}
            loading={loading}
            customActions={{
              onEditRowClick: (rowId) => setEditingRowId(rowId),
              onCancelRowClick: () => setEditingRowId(null),
            }}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            toolbarButtons={[
              <Box color="white" key="uploadResults">
                <Tooltip title="Upload results">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setUploadModalOpen(true);
                    }}
                  >
                    <CloudUpload />
                  </IconButton>
                </Tooltip>
              </Box>,
            ]}
          />
        </form>
      </FormProvider>
      <UploadResultsModal
        id={panelId}
        name={name}
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
      />
    </>
  );
};

export default PanelTable;
