import type { TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { useAvailableBalancesQuery } from "src/generated/graphql-hooks";
import FormTextField from "../../components/FormTextField";
import { generateMenuItems } from "../utils";

const BalanceSelect: React.FunctionComponent<TextFieldProps> = (props) => {
  const { data } = useAvailableBalancesQuery();
  const balances = data?.availableBalances ?? [];
  return (
    <FormTextField name="balanceId" select fullWidth {...props}>
      {generateMenuItems(balances)}
    </FormTextField>
  );
};

export default BalanceSelect;
