import { DateTime } from "luxon";
import * as R from "ramda";
import * as RA from "ramda-adjunct";

export const actionIfDefined = (action: (value: any) => any) => <T>(
  value?: T,
) =>
  RA.isNotEmpty(value) && RA.isNotUndefined(value)
    ? action(value)
    : RA.stubUndefined();

export const identityIfDefined = actionIfDefined(R.identity);
export const parseFloatIfDefined = actionIfDefined(parseFloat);

export function graphQLDateToDateTime(isoDate: string) {
  return DateTime.fromSQL(
    DateTime.fromISO(isoDate, { zone: "utc" }).toISODate(),
  );
}
