import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import kebabCase from "lodash/kebabCase";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import ExpandButton from "src/components/ExpandButton";
import { FlaskIcon } from "src/components/icons";
import { PANELS } from "src/config/routes";
import { useAvailablePanelsQuery } from "src/generated/graphql-hooks";

interface PanelsSectionProps {
  toggleDrawer: () => void;
}

const PanelsSection: React.FunctionComponent<PanelsSectionProps> = ({
  toggleDrawer,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const { data } = useAvailablePanelsQuery();
  const { pathname } = useLocation();

  const panels = data?.availablePanels ?? [];
  return (
    <>
      <ListItem button onClick={() => setExpanded(!expanded)}>
        <ListItemIcon>
          <FlaskIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Panels" />
        <ExpandButton isExpanded={expanded} />
      </ListItem>
      <Collapse in={expanded}>
        <List dense disablePadding>
          {panels.map(({ id, name }) => (
            <ListItem
              button
              key={id}
              component={Link}
              to={`${PANELS}/${kebabCase(name)}`}
              onClick={toggleDrawer}
              selected={pathname.includes(`${PANELS}/${kebabCase(name)}`)}
            >
              <ListItemText secondary={name} inset />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default PanelsSection;
