import {
  AnalysisCompleteColumn,
  DueDateColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  getComponentAmount,
  InstrumentColumn,
  SampleAmountColumn,
} from "./index";

const getDensityComponentAmount = getComponentAmount("Density");

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("DEN"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  InstrumentColumn,
  {
    Header: "Density (g/mL)",
    id: "density",
    Cell: getDensityComponentAmount,
  },
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
