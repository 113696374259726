import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import LoadingButton from "../../../LoadingButton";
import FormTextField from "../../components/FormTextField";

interface Props {
  open: boolean;
  type: "producer" | "distributor";
  onSubmit: (data: PartnerInfo) => Promise<void>;
  onCancel: () => void;
}

interface PartnerInfo {
  name: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  licenseNumber: string;
}

const defaultValues = {
  name: "",
  address: {
    street: "",
    city: "",
    state: "",
    zip: "",
  },
  licenseNumber: "",
};

const getValidationSchema = (type: "producer" | "distributor") =>
  object().shape({
    name: string().required(`Please enter a name for this ${type}`),
    address: object().shape({
      street: string().required("Please enter a street address"),
      city: string().required("Please enter a city"),
      state: string().required("Please enter a state"),
      zip: string().required("Please enter a zip code"),
    }),
    licenseNumber: string()
      .required(`Please enter the license number for this ${type}`)
      .matches(
        getLicenseRegExp(type),
        `Please enter a valid ${type} license number`,
      ),
  });

const getLicenseRegExp = (type: "producer" | "distributor") =>
  type === "producer"
    ? /C12-\d{7}-LIC|(CCL\d{2}|CDPH)-\d{7}/
    : /(C11|C12)-\d{7}-LIC/;

const AddPartnerDialog: FunctionComponent<Props> = ({
  open,
  type,
  onSubmit,
  onCancel,
}) => {
  const {
    control,
    formState,
    errors,
    handleSubmit,
    setError,
    clearErrors,
    reset,
  } = useForm<PartnerInfo & { formError: any }>({
    defaultValues,
    resolver: yupResolver(getValidationSchema(type)),
  });

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box p={2}>
        <DialogTitle>{`Add new ${type}`}</DialogTitle>
        <form
          noValidate
          onSubmit={handleSubmit(async (data) => {
            try {
              await onSubmit(data);
              reset();
            } catch (err) {
              console.error(err);
              setError("formError", {
                message: `Failed to create ${type}. Please try again.`,
                type: "manual",
              });
            }
          })}
        >
          <DialogContent>
            <Collapse in={errors.formError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      clearErrors("formError");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errors.formError?.message}
              </Alert>
            </Collapse>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextField
                  name="name"
                  control={control}
                  errors={errors}
                  label="Name"
                  fullWidth
                  autoFocus
                  id={`${type}-name-input`}
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="licenseNumber"
                  control={control}
                  errors={errors}
                  label="License #"
                  fullWidth
                  id={`${type}-license-number-input`}
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="address.street"
                  control={control}
                  errors={errors}
                  label="Street Address"
                  fullWidth
                  id={`${type}-address-street-input`}
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="address.city"
                  control={control}
                  errors={errors}
                  label="City"
                  fullWidth
                  id={`${type}-address-city-input`}
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="address.state"
                  control={control}
                  errors={errors}
                  label="State"
                  fullWidth
                  id={`${type}-address-state-input`}
                  margin="dense"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="address.zip"
                  control={control}
                  errors={errors}
                  label="Zip Code"
                  fullWidth
                  id={`${type}-address-zip-input`}
                  margin="dense"
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <LoadingButton loading={formState.isSubmitting} type="submit">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default AddPartnerDialog;
