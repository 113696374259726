import { useApolloClient } from "@apollo/client";
import { IconButton, Tooltip } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import DescriptionIcon from "@material-ui/icons/Description";
import DetailsIcon from "@material-ui/icons/Details";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import stringify from "csv-stringify/lib/sync";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import type { FunctionComponent } from "react";
import * as React from "react";
import type { Cell } from "react-table";
import { CsvIcon } from "src/components/icons";
import {
  SampleBccInfoDocument,
  SampleBccInfoQuery,
  SampleDocument,
  SampleLogQuery,
  SampleStatus,
  useAcceptSampleMutation,
  useCancelSampleMutation,
  useReleaseReportToClientMutation,
} from "src/generated/graphql-hooks";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import generateBccSampleCsvRows from "./bccCsvGeneration";

const RowActions: FunctionComponent<Cell<
  SampleLogQuery["samples"][number]
>> = ({ row, onDataUpdate = () => {}, onSampleDetailClick = () => {} }) => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const { currentUser } = useCurrentUser();
  const [acceptSample] = useAcceptSampleMutation();
  const [cancelSample] = useCancelSampleMutation();
  const [releaseReport] = useReleaseReportToClientMutation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const _handleAcceptSampleClick = async () => {
    try {
      const res = await acceptSample({
        variables: { input: { id: row.original.id } },
      });
      enqueueSnackbar(
        `Generated Sample ID ${res.data?.acceptSample.sampleId}`,
        { variant: "info" },
      );
      onDataUpdate();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Error occurred during sample acceptance", {
        variant: "error",
      });
    }
  };

  const _handleCancelSampleClick = async () => {
    await confirm({
      title: "Canceling sample",
      description: row.original.sampleId
        ? `Please confirm that you wish to cancel sample ${row.original.sampleId}`
        : "Please confirm you with to cancel this unaccepted sample",
    });
    await cancelSample({
      variables: { input: { id: row.original.id } },
    });
    enqueueSnackbar("Canceled sample", { variant: "info" });
    onDataUpdate();
  };

  const _handleViewSampleDetailsClick = () =>
    onSampleDetailClick(row.original.id);

  const _handleReleaseReportClick = async () => {
    await confirm({
      title: "Releasing report",
      description:
        "This will release the report to the client. Please confirm that this report is ready to release.",
    });
    await releaseReport({ variables: { sampleId: row.original.id } });
    onDataUpdate();
  };

  const _generateBccCsv = async () => {
    const {
      data: { sample },
    } = await apolloClient.query<SampleBccInfoQuery>({
      query: SampleBccInfoDocument,
      variables: { id: row.original.id },
    });
    if (!sample || !sample.sampleApproval) return;

    const csv = stringify(generateBccSampleCsvRows(sample), {
      header: true,
      columns: [
        { key: "completedDate", header: "Sample Completed Date" },
        { key: "metrcBarcode", header: "Metrc Barcode" },
        { key: "caMetrcAnalyte", header: "CA Metrc Analyte" },
        { key: "finalValue", header: "Final Value for Metrc CSV" },
        { key: "passed", header: "Overall Pass/Fail for Metrc CSV" },
      ],
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${row.original.sampleId}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      {!row.original.sampleId &&
        (row.original.status === SampleStatus.InTransitToLab ||
          row.original.status === SampleStatus.OrderPlaced) && (
          <Tooltip title="Accept Sample">
            <IconButton size="small" onClick={_handleAcceptSampleClick}>
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        )}
      <Tooltip title="View Sample Details">
        <IconButton
          size="small"
          onClick={_handleViewSampleDetailsClick}
          onMouseOver={() =>
            client.query({
              query: SampleDocument,
              variables: { where: { id: row.original.id } },
              fetchPolicy: "cache-first",
            })
          }
        >
          <DetailsIcon />
        </IconButton>
      </Tooltip>
      {currentUser?.role.permissions?.includes("cancel_sample") &&
        row.original.status !== SampleStatus.Canceled && (
          <Tooltip title="Cancel Sample">
            <IconButton size="small" onClick={_handleCancelSampleClick}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        )}
      {row.original.status === SampleStatus.PendingComplete &&
        row.original.isCompliance && (
          <Tooltip title="Generate BCC CSV">
            <IconButton size="small" onClick={_generateBccCsv}>
              <CsvIcon />
            </IconButton>
          </Tooltip>
        )}
      {currentUser?.role.permissions?.includes("release_report") &&
        row.original.status === SampleStatus.PendingComplete &&
        !row.original.reportReleasedToClient && (
          <Tooltip title="Release Report">
            <IconButton size="small" onClick={_handleReleaseReportClick}>
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        )}
    </>
  );
};

export default RowActions;
