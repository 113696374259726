import { MenuItem } from "@material-ui/core";
import * as R from "ramda";
import * as React from "react";

interface HasIdAndName {
  id: string;
  name: string;
}

export const generateMenuItems = R.map<HasIdAndName, JSX.Element>(
  ({ id, name }) => (
    <MenuItem key={id} value={id}>
      {name}
    </MenuItem>
  ),
);
