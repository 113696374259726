import type { TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { useAvailableSolventsQuery } from "src/generated/graphql-hooks";
import FormTextField from "../../components/FormTextField";
import { generateMenuItems } from "../utils";

const SolventSelect: React.FunctionComponent<TextFieldProps> = (props) => {
  const { data } = useAvailableSolventsQuery();
  const solvents = data?.availableSolvents ?? [];
  return (
    <FormTextField name="solventId" select fullWidth {...props}>
      {generateMenuItems(solvents)}
    </FormTextField>
  );
};

export default SolventSelect;
