import type {
  MutationFunctionOptions,
  MutationHookOptions,
} from "@apollo/client";
import { DateTime } from "luxon";
import type { SampleSubmissionFormValues } from "src/components/forms/SampleSubmissionForm";
import {
  CreateSampleSubmissionInput,
  Exact,
  SampleLogDocument,
  SampleSourceMaterial,
  SubmitSamplesMutation,
  useSubmitSamplesMutation,
} from "src/generated/graphql-hooks";

export const useSubmitSamples = (
  baseOptions?:
    | Omit<
        MutationHookOptions<
          SubmitSamplesMutation,
          Exact<{
            input: CreateSampleSubmissionInput;
          }>
        >,
        "variables"
      >
    | undefined,
) => {
  const [submitSamples] = useSubmitSamplesMutation({
    refetchQueries: [{ query: SampleLogDocument }],
    ...baseOptions,
  });

  return [
    (
      data: SampleSubmissionFormValues,
      options?:
        | Omit<
            MutationFunctionOptions<
              SubmitSamplesMutation,
              Exact<{
                input: CreateSampleSubmissionInput;
              }>
            >,
            "variables"
          >
        | undefined,
    ) =>
      submitSamples({
        ...options,
        variables: {
          input: {
            order: {
              samples: data.samples.map(
                ({
                  productName,
                  lotOrBatchNumber,
                  sourceMaterial,
                  category,
                  matrix,
                  harvestOrProductionDate,
                  compliance,
                  weightOrCount,
                  unitOfMeasure,
                  reportTemplateOptionId,
                  deliveryMethodId,
                  readyForPickup,
                  notes,
                  analysisOptionIds,
                  intendedUse,
                  producerId,
                  distributorId,
                  forMedicalUse,
                }) => ({
                  productName,
                  lotOrBatchNumber,
                  sourceMaterial:
                    sourceMaterial === "Hemp"
                      ? SampleSourceMaterial.Hemp
                      : SampleSourceMaterial.Cannabis,
                  sampleMatrixId: matrix,
                  isCompliance: compliance === "Yes",
                  harvestOrProductionDate: DateTime.fromJSDate(
                    harvestOrProductionDate,
                  ).toISODate(),
                  ...(weightOrCount !== 0 && { weightOrCount }),
                  unitOfMeasure,
                  deliveryOptionId: deliveryMethodId,
                  ...(compliance === "Yes" && {
                    readyForPickupDate: DateTime.fromJSDate(
                      readyForPickup,
                    ).toISODate(),
                  }),
                  reportTemplateOptionId,
                  notes,
                  analysisOptionIds,
                  producerId,
                  distributorId,
                  forMedicalUse,
                }),
              ),
            },
          },
        },
      }),
  ];
};
