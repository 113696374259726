import { Box, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { DateTime } from "luxon";
import * as React from "react";
import type { Cell, Column } from "react-table";
import {
  JobFieldsFragment,
  PanelProcessingJobStatus,
} from "src/generated/graphql-hooks";

const columns: Column[] = [
  {
    Header: "Batch ID",
    accessor: "batch.id",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (cell: Cell<JobFieldsFragment>) =>
      cell.row.original.status === PanelProcessingJobStatus.Error ? (
        <Box fontSize="p.fontSize" display="flex" alignItems="center">
          {cell.row.original.status}
          <Tooltip
            title={cell.row.original.failureReason ?? "Unknown failure reason"}
          >
            <InfoIcon fontSize="inherit" />
          </Tooltip>
        </Box>
      ) : (
        cell.row.original.status
      ),
  },
  {
    Header: "Panel",
    accessor: "batch.panel.name",
  },
  {
    Header: "Instrument",
    accessor: "batch.instrument.name",
  },
  {
    Header: "Started At",
    //@ts-ignore
    accessor: (row: JobFieldsFragment) =>
      new Date(row.startedAt ?? "3000/01/01"),
    Cell: ({ row }: Cell<JobFieldsFragment>) =>
      DateTime.fromISO(row.original.startedAt).toLocaleString(
        DateTime.DATETIME_FULL,
      ),
    sortType: "datetime",
  },
  {
    Header: "Completed At",
    //@ts-ignore
    accessor: (row: JobFieldsFragment) => new Date(row.completedAt),
    Cell: ({ row }: Cell<JobFieldsFragment>) =>
      row.original.completedAt
        ? DateTime.fromISO(row.original.completedAt).toLocaleString(
            DateTime.DATETIME_FULL,
          )
        : null,
    sortType: "datetime",
  },
  {
    Header: "Notes",
    accessor: "batch.notes",
  },
  {
    Header: "Initiated By",
    //@ts-ignore
    accessor(row: JobFieldsFragment) {
      return `${row.initiatedBy.firstName} ${row.initiatedBy.lastName}`;
    },
  },
];

export default columns;
