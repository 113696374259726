import { Input, Paper } from "@material-ui/core";
import React from "react";
import { useAsyncDebounce } from "react-table";

const GlobalTableFilter = ({
  setGlobalFilter,
  globalFilter,
  placeholder = "Search",
}: {
  setGlobalFilter: (filterValue: any) => void;
  globalFilter: any;
  placeholder?: string;
}) => {
  const [value, setValue] = React.useState(globalFilter);
  React.useEffect(() => setValue(globalFilter), [globalFilter]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <Paper style={{ paddingLeft: "1em", paddingRight: "1em" }}>
      <Input
        value={value || ""}
        placeholder={placeholder}
        disableUnderline
        fullWidth
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </Paper>
  );
};

export default GlobalTableFilter;
