import camelCase from "lodash/camelCase";
import { DateTime } from "luxon";
import * as React from "react";
import type { Cell } from "react-table";
import FormTextField from "src/components/forms/components/FormTextField";
import {
  AnalysisCompleteColumn,
  DueDateColumn,
  editableCell,
  editableCheckboxCellFactory,
  EditableInstrumentColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  PassOrFailDisplay,
  PassOrFailSelect,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import type { PanelLogFieldsFragment } from "src/generated/graphql-hooks";
import {
  BalanceColumn,
  BarcodeColumn,
  PipetteColumn,
  PipetteTypeColumn,
  SampleAmountColumn,
} from "../../../../components/PanelTable/columns/index";

const TSBVolumeColumn = {
  Header: "TSB Volume (mL)",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="tsbVolume"
      defaultValue={cell.row.original.testPreps?.[0]?.tsbVolume ?? ""}
    />
  ))((cell) => cell.row.original.testPreps?.[0]?.tsbVolume ?? null),
};

const IncubationStartedColum = {
  Header: "Incubation Started",
  Cell: editableCheckboxCellFactory<PanelLogFieldsFragment>({
    name: "incubationStarted",
    defaultValuePath: "testPreps[0].incubationStarted",
  }),
  disableSortBy: true,
};

const IncubationStartedAtColumn = {
  Header: "Incubation Started At",
  minWidth: 150,
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    const { incubationStartedAt } = cell.row.original.testPreps?.[0] ?? {};
    return incubationStartedAt
      ? DateTime.fromISO(incubationStartedAt).toLocaleString(
          DateTime.DATETIME_MED,
        )
      : null;
  },
};

const IncubationStartedByColumn = {
  Header: "Incubation Started By",
  minWidth: 150,
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    const { firstName, lastName } =
      cell.row.original.testPreps?.[0]?.incubationStartedBy ?? {};
    return firstName && lastName ? `${firstName} ${lastName}` : null;
  },
};

const microbialResultColumnFactory = (name: string) => ({
  Header: name,
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <PassOrFailSelect
      name={camelCase(name)}
      defaultValue={
        cell.row.original.panelResults?.[0]?.results?.[camelCase(name)] ?? ""
      }
    />
  ))((cell) => (
    <PassOrFailDisplay
      value={
        cell.row.original.panelResults?.[0]?.results?.[camelCase(name)] ?? null
      }
    />
  )),
});

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("MIC"),
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  TSBVolumeColumn,
  PipetteTypeColumn(0),
  PipetteColumn(0),
  PipetteTypeColumn(1),
  PipetteColumn(1),
  PipetteTypeColumn(2),
  PipetteColumn(2),
  IncubationStartedColum,
  IncubationStartedByColumn,
  IncubationStartedAtColumn,
  EditableInstrumentColumn,
  microbialResultColumnFactory("Aspergillus niger"),
  microbialResultColumnFactory("Aspergillus terreus"),
  microbialResultColumnFactory("Aspergillus flavus"),
  microbialResultColumnFactory("Aspergillus fumigatus"),
  microbialResultColumnFactory("Escherichia coli"),
  microbialResultColumnFactory("Salmonella spp."),
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
