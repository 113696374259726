import * as React from "react";
import { DateTime } from "luxon";
import { Cell } from "react-table";
import {
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import { SampleLogQuery } from "src/generated/graphql-hooks";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton, Tooltip } from "@material-ui/core";

const columns = [
  {
    Header: () => null,
    id: "view",
    Cell: (cell: Cell<SampleLogQuery["samples"][number]>) => (
      <Tooltip title="View" key="view">
        <IconButton
          size="small"
          onClick={() =>
            cell.onSampleDetailClick &&
            cell.onSampleDetailClick(`${cell.row.original.sampleId}`)
          }
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  SampleNameColumn,
  SampleIdColumn,
  {
    Header: "Submitted On",
    accessor: (sample: SampleLogQuery["samples"][number]) =>
      new Date(sample.order?.sampleSubmission?.submittedAt),

    Cell: ({ row }: Cell<SampleLogQuery["samples"][number]>) =>
      DateTime.fromISO(
        row.original.order.sampleSubmission!.submittedAt,
      ).toLocaleString(DateTime.DATE_SHORT),
    sortType: "datetime",
  },
  RushColumn,
  { Header: "Notes", accessor: "customerNotes" },

  {
    Header: "Business",
    accessor: "order.sampleSubmission.submittedBy.business.name",
  },
];
export default columns;
