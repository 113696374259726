import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Cell,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import TablePaginationActions from "src/components/TablePaginationActions";
import {
  UserInfoFieldsFragment,
  UsersQuery,
  useToggleUserMutation,
} from "src/generated/graphql-hooks";
import GlobalTableFilter from "../../../../../../components/GlobalTableFilter";
import UpdateUserRowActions from "../../../RowActions";
import { AdminRow } from "../UserRow";

interface UserTableProps {
  data: UsersQuery | undefined;

  _triggerDataUpdate: () => void;
}

export function UserTable({ data, _triggerDataUpdate }: UserTableProps) {
  const confirm = useConfirm();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [toggleUser] = useToggleUserMutation();

  const handleEdit = React.useCallback(
    (id: string) => {
      history.push(`${path}/${id}/edit`);
    },
    [path, history],
  );

  const handleActiveStatus = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      try {
        await confirm({
          description: `Are you sure you want to ${
            checked ? "activate" : "deactive"
          } this user`,
        });

        await toggleUser({
          variables: { userId: e.target.value },
          optimisticResponse: {
            toggleUser: {
              id: e.target.value,
              __typename: "User",
              active: checked,
            },
          },
        });
      } catch {}
    },
    [confirm, toggleUser],
  );

  const columns = React.useMemo(
    () => [
      { Header: "First Name", accessor: "firstName" },
      { Header: "Last Name", accessor: "lastName" },
      { Header: "Title", accessor: "title" },
      { Header: "Phone Number", accessor: "phoneNumber" },
      { Header: "Email", accessor: "email" },
      { Header: "Role", accessor: "role.name" },
      { Header: "Business", accessor: "business.name" },
      {
        Header: "Active",
        accessor: "active",
        Cell: (cell: Cell<UserInfoFieldsFragment>) => (
          <Switch
            onChange={handleActiveStatus}
            checked={cell.row.values.active}
            value={cell.row.original.id}
          />
        ),
      },
      {
        id: "edit",

        Cell: (cell: Cell<UserInfoFieldsFragment>) => (
          <UpdateUserRowActions {...cell} onEditClick={handleEdit} />
        ),
      },
    ],
    [handleActiveStatus, handleEdit],
  );
  const theme = useTheme();
  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: data?.users ?? [],
      autoResetPage: false,
      onDataUpdate: _triggerDataUpdate,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, pageIndex, globalFilter },
  } = tableInstance;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(Number(event.target.value));
  };

  React.useEffect(() => {
    globalFilter && gotoPage(0);
  }, [globalFilter, gotoPage]);

  return (
    <>
      <GlobalTableFilter
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      <TableContainer
        style={{
          maxHeight: `calc(90vh - ${theme.spacing(8) * 2}px - ${theme.spacing(
            3,
          )}px)`,
        }}
      >
        <Table {...getTableProps()} size="small" stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...(column.id === "selection"
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    {!column.disableSortBy && column.id !== "selection" ? (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    ) : (
                      column.render("Header")
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return <AdminRow row={row} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        colSpan={columns.length}
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default UserTable;
