export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const FORGOT_PASSWORD = "/forgot-password";
export const APP = "/app";
export const ANALYTICS = `${APP}/analytics`;
export const BLOCKED_ACCESS = `${APP}/blocked-access`;
export const SAMPLE_SUBMISSION = `${APP}/sample-submission`;
export const SAMPLE_TRACKER = `${APP}/sample-tracker`;
export const SAMPLE_LOG = `${APP}/sample-log`;
export const PICKUP_LOG = `${APP}/pickup-log`;
export const SAMPLE_ACCESSION = `${APP}/accession-sample`;
export const PANELS = `${APP}/panels`;
export const FOREIGN_MATERIAL = `${PANELS}/foreign-material`;
export const MICROBIAL = `${PANELS}/microbial`;
export const MOISTURE_CONTENT = `${PANELS}/moisture-content`;
export const SPECIFIC_PANEL = `${PANELS}/:name`;
export const PROCESSING_JOBS = `${APP}/processing-jobs`;
export const ALIQUOT = `${APP}/aliquot`;
export const HOMOGENIZATION = `${APP}/homogenization`;
export const APPROVE_RESULTS_GENERIC = `${APP}/approve-results/:mode`;
export const APPROVE_RESULTS = `${APP}/approve-results/final-approval`;
export const QA_RESULTS = `${APP}/approve-results/qa`;
export const USER_MANAGEMENT = `${APP}/admin/users`;
