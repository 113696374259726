import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useDrawer } from "src/hooks/useDrawer";

const DrawerButton = () => {
  const { toggleDrawer } = useDrawer();

  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={toggleDrawer}
      data-testid="drawer-button"
    >
      <MenuIcon />
    </IconButton>
  );
};

export default DrawerButton;
