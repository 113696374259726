import { Box, Grid, MenuItem, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DateTime, Duration } from "luxon";
import * as React from "react";
import { useForm } from "react-hook-form";
import {
  PickupLogOrderFieldsFragment,
  useFieldTechniciansQuery,
  usePickupVehiclesQuery,
} from "src/generated/graphql-hooks";
import FormDatetimePicker from "../components/FormDatetimePicker";
import FormTextField from "../components/FormTextField";

interface SchedulePickupFormProps {
  id: string;
  onSubmit: (values: SchedulePickupFormFields) => void;
  order: PickupLogOrderFieldsFragment | null;
}

export interface SchedulePickupFormFields {
  businessLicenseNumber: string;
  onSiteContactName: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  contactPhoneNumber: string;
  dateTime: Date;
  fieldTechnicianId: string;
  pickupVehicleId: string;
}

const SchedulePickupForm: React.FunctionComponent<SchedulePickupFormProps> = ({
  id,
  order,
  onSubmit,
}) => {
  const { data: vehildeData } = usePickupVehiclesQuery();
  const { data: fieldTechData } = useFieldTechniciansQuery();
  const { handleSubmit, control, errors } = useForm<SchedulePickupFormFields>({
    defaultValues: {
      businessLicenseNumber: "",
      onSiteContactName: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      contactPhoneNumber: "",
      dateTime: DateTime.fromSQL(order?.readyForPickupDate?.split("T")[0])
        .plus(Duration.fromObject({ hours: 9 }))
        .toJSDate(),
      fieldTechnicianId: "",
      pickupVehicleId: "",
    },
  });

  const submittedByAndOnMessage = `Scheduling order ${
    order?.shortId
  } submitted by ${order?.sampleSubmission?.submittedBy.business?.name} on 
  ${DateTime.fromISO(order?.sampleSubmission?.submittedAt).toLocaleString(
    DateTime.DATE_MED,
  )}`;
  const orderReadyOnMessage =
    order?.readyForPickupDate &&
    `Order will be ready for pickup on 
  ${DateTime.fromISO(order?.readyForPickupDate).toLocaleString(
    DateTime.DATE_MED,
  )}`;

  return (
    <Grid
      container
      spacing={3}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      id={id}
    >
      <Grid item xs={12}>
        <Alert variant="outlined" severity="info">
          {submittedByAndOnMessage}
          <br />
          {orderReadyOnMessage}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Customer Info</Typography>
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        <FormTextField
          label="License #"
          name="businessLicenseNumber"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormTextField
          label="On Site Contact"
          name="onSiteContactName"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormTextField
          label="Phone Number #"
          name="contactPhoneNumber"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Address</Typography>
      </Grid>
      <Grid item md={5} xs={12}>
        <FormTextField
          label="Street"
          name="address.street"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <FormTextField
          label="City"
          name="address.city"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item md={2} sm={3} xs={12}>
        <FormTextField
          label="State"
          name="address.state"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item md={2} sm={3} xs={12}>
        <FormTextField
          label="Zip"
          name="address.zip"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Pickup Info</Typography>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormDatetimePicker
          label="Pickup Date & Time"
          name="dateTime"
          control={control}
          defaultValue={DateTime.fromSQL(
            order?.readyForPickupDate?.split("T")[0],
          )
            .plus(Duration.fromObject({ hours: 9 }))
            .toJSDate()}
          variant="inline"
          fullWidth
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormTextField
          label="Field Technician"
          name="fieldTechnicianId"
          control={control}
          errors={errors}
          fullWidth
          select
        >
          {fieldTechData?.fieldTechnicians.map(
            ({ id, firstName, lastName }) => (
              <MenuItem key={id} value={id}>
                {firstName} {lastName}
              </MenuItem>
            ),
          ) ?? <MenuItem />}
        </FormTextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormTextField
          label="Vehicle"
          name="pickupVehicleId"
          control={control}
          errors={errors}
          fullWidth
          select
        >
          {vehildeData?.vehicles.map(
            ({ id, make, model, licensePlateNumber }) => (
              <MenuItem key={id} value={id}>
                <Box display="flex" flexDirection="column">
                  <Typography>
                    {make} {model}
                  </Typography>
                  <Typography variant="subtitle2">
                    {licensePlateNumber}
                  </Typography>
                </Box>
              </MenuItem>
            ),
          )}
        </FormTextField>
      </Grid>
    </Grid>
  );
};

export default SchedulePickupForm;
