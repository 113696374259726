import {
  AnalysisCompleteColumn,
  DueDateColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  AliquotVolumeColumn,
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  DispenserColumn,
  getComponentAmount,
  InstrumentColumn,
  PipetteColumn,
  PipetteTypeColumn,
  SampleAmountColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  SolventColumn,
  SolventVolumeColumnFactory,
} from "./index";

const getTerpenesComponentAmount = getComponentAmount("Terpenes");

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("TRP"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  DispenserColumn,
  SolventColumn,
  SolventVolumeColumnFactory(),
  AliquotVolumeColumn,
  PipetteTypeColumn(0),
  PipetteColumn(0),
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  InstrumentColumn,
  {
    Header: `α\u2011Pinene (μg/mL)`,
    id: "a-Pinene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Camphene (μg/mL)",
    id: "Camphene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "β\u2011Myrcene (μg/mL)",
    id: "b-Myrcene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "β\u2011Pinene (μg/mL)",
    id: "b-Pinene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "3\u2011Carene (μg/mL)",
    id: "3-Carene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "α\u2011Terpinene (μg/mL)",
    id: "a-Terpinene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Limonene (μg/mL)",
    id: "Limonene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "p\u2011Cymene (μg/mL)",
    id: "p-Cymene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Ocimene (μg/mL)",
    id: "Ocimene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Eucalyptol (μg/mL)",
    id: "Eucalyptol",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "γ\u2011Terpinene (μg/mL)",
    id: "y-Terpinene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Terpinolene (μg/mL)",
    id: "Terpinolene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Linalool (μg/mL)",
    id: "Linalool",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Isopulegol (μg/mL)",
    id: "Isopulegol",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Geraniol (μg/mL)",
    id: "Geraniol",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Caryophyllene (μg/mL)",
    id: "Caryophyllene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "α\u2011Humelene (μg/mL)",
    id: "a-Humelene",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "c\u2011Nerolidol (μg/mL)",
    id: "c-Nerolidol",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "t\u2011Nerolidol (μg/mL)",
    id: "t-Nerolidol",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Guaiol (μg/mL)",
    id: "Guaiol",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "Caryophyllene Oxide (μg/mL)",
    id: "Caryophyllene Oxide",
    Cell: getTerpenesComponentAmount,
  },
  {
    Header: "α\u2011Bisabolol (μg/mL)",
    id: "a-Bisabolol",
    Cell: getTerpenesComponentAmount,
  },
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
