import { Box, Card, CardContent, Typography } from "@material-ui/core";
import * as React from "react";

interface PageTitleCardProps {
  title: string;
}

const PageTitleCard: React.FunctionComponent<PageTitleCardProps> = ({
  title,
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h1">
          <Box fontWeight="fontWeightBold">{title}</Box>
          <Box display="flex" flexGrow={1} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PageTitleCard;
