import React, { ReactElement } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { SIGN_IN } from "src/config/routes";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import PageLoadingIndicator from "../PageLoadingInicator";

type Props = {
  path: string;
  children: ReactElement;
  redirectTo?: string;
  requiredPermission?: string;
};

const PrivateRoute = ({
  children,
  redirectTo = SIGN_IN,
  requiredPermission,
  ...rest
}: Props) => {
  const { loading, currentUser } = useCurrentUser();
  const location = useLocation();
  if (loading) return <PageLoadingIndicator />;

  const userHasAccess = requiredPermission
    ? currentUser?.role.permissions?.includes(requiredPermission)
    : true;

  const allowAccess = !!currentUser;

  return (
    <Route {...rest}>
      {allowAccess ? (
        userHasAccess ? (
          children
        ) : null
      ) : (
        <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
      )}
    </Route>
  );
};

export default PrivateRoute;
