import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import firebase from "firebase/app";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import * as RA from "ramda-adjunct";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Controller, get, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import BusinessInfoCard from "src/components/BusinessInfoCard";
import {
  REQUIRED_FOR_COMPLIANCE_MESSAGE,
  REQUIRED_MESSAGE,
} from "src/components/forms";
import FormCheckboxField from "src/components/forms/components/FormCheckboxField";
import FormDatePicker from "src/components/forms/components/FormDatePicker";
import FormTextField from "src/components/forms/components/FormTextField";
import {
  COA_OPTIONS,
  weightOrCountUnits,
} from "src/components/forms/SampleSubmissionForm/config";
import { QrCodeIcon } from "src/components/icons";
import LoadingButton from "src/components/LoadingButton";
import PageTitleCard from "src/components/PageTitleCard";
import ProcessStepChip from "src/components/ProcessStepChip";
import SearchBox from "src/components/SearchBox";
import {
  ProcessStepFieldsFragment,
  SampleCategoryFieldsFragment,
  SampleMatrixFieldsFragment,
  SampleQuery,
  SampleSourceMaterial,
  useAccessionOptionsQuery,
  useAccessionSampleMutation,
  useSampleQuery,
} from "src/generated/graphql-hooks";
import {
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  SchemaOf,
  string,
} from "yup";
import { useMetrcPackageCheck } from "./useMetrcPackageCheck";

const BASE_SAMPLE_ID = "4000000";

interface AccessioningFormFields {
  sourceMaterial: string;
  category: string;
  matrix: string;
  lotOrBatchNumber?: string;
  metrcPackageUID?: string | null;
  customerNotes?: string | null;
  batchWeightOrCount?: number;
  batchWeightOrCountUoM?: string;
  pickupAmount?: number;
  pickupAmountUoM?: string;
  accessionAmount?: number;
  accessionAmountUoM?: string;
  isPrepackaged: boolean;
  labelClaim?: string | null;
  amountPerServing?: number | null;
  amountPerServingUoM?: string | null;
  amountPerPackage?: number | null;
  amountPerPackageUoM?: string | null;
  reportTemplateOptionId: string;
  reportFirstPage: string;
  photoObjectUrl: string;
  qrCodeObjectUrl?: string | null;
  isRushOrder: boolean;
  dueDate?: Date | null;
  processSteps: ProcessStepFieldsFragment[];
  complianceSampleType: string;
}

const SampleAccession: React.FunctionComponent = () => {
  const [accessionSample] = useAccessionSampleMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const { data: optionsData } = useAccessionOptionsQuery({
    fetchPolicy: "cache-first",
  });

  const [searchValue, setSearchValue] = React.useState<string>(BASE_SAMPLE_ID);
  const [sampleId, setSampleId] = React.useState<string | null>(null);
  const [qrCodeFile, setQrCodeFile] = React.useState<File | null>(null);
  const [photoFile, setPhotoFile] = React.useState<File | null>(null);

  React.useEffect(() => {
    const queryStirngSampleId = new URLSearchParams(search).get("sample_id");
    if (queryStirngSampleId) {
      setSampleId(queryStirngSampleId);
      setSearchValue(queryStirngSampleId);
    }
  }, [search]);

  const { data: remoteData, loading, error } = useSampleQuery({
    variables: { where: { sampleId: sampleId || "" } },
    skip: !sampleId,
    fetchPolicy: "network-only",
  });

  const prevData = React.useRef<SampleQuery | undefined>();

  const data = React.useMemo(() => {
    if (!loading) {
      prevData.current = remoteData;
      return remoteData;
    }
    return prevData.current;
  }, [loading, sampleId]); // eslint-disable-line

  const { sample } = data ?? {};

  const validationSchema: SchemaOf<AccessioningFormFields> = object({
    sourceMaterial: string().required(REQUIRED_MESSAGE),
    category: string().required(REQUIRED_MESSAGE),
    matrix: string().required(REQUIRED_MESSAGE),
    lotOrBatchNumber: string(),
    metrcPackageUID: sample?.isCompliance
      ? string().required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : string(),
    customerNotes: string(),
    batchWeightOrCount: sample?.isCompliance
      ? number()
          .positive("Enter a positive number")
          .required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : number().typeError("Leave as 0 for non compliance samples"),
    batchWeightOrCountUoM: sample?.isCompliance
      ? string().required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : string(),
    pickupAmount: sample?.isCompliance
      ? number()
          .positive("Enter a positive number")
          .required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : number().typeError("Leave as 0 for non compliance samples"),
    pickupAmountUoM: sample?.isCompliance
      ? string().required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : string(),
    accessionAmount: sample?.isCompliance
      ? number()
          .positive("Enter a positive number")
          .required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : number().typeError("Leave as 0 for non compliance samples"),
    accessionAmountUoM: sample?.isCompliance
      ? string().required(REQUIRED_FOR_COMPLIANCE_MESSAGE)
      : string(),
    isPrepackaged: boolean().required(),
    labelClaim: string(),
    amountPerServing: number(),
    amountPerServingUoM: string(),
    amountPerPackage: number(),
    amountPerPackageUoM: string(),
    reportTemplateOptionId: string().required(REQUIRED_MESSAGE),
    reportFirstPage: string().required(REQUIRED_MESSAGE),
    photoObjectUrl: string().required(REQUIRED_MESSAGE),
    qrCodeObjectUrl: string(),
    isRushOrder: boolean().required(),
    dueDate: date().when("isRushOrder", {
      is: true,
      then: date().required("Select a due date for this rush order"),
      otherwise: date(),
    }),
    processSteps: array().of(mixed().required()).required(),
    complianceSampleType: string().required(REQUIRED_MESSAGE),
  });

  const {
    control,
    errors,
    watch,
    reset,
    handleSubmit,
    formState,
    setValue,
  } = useForm<AccessioningFormFields>({
    defaultValues: {
      sourceMaterial: "",
      category: "",
      matrix: "",
      lotOrBatchNumber: "",
      metrcPackageUID: "",
      customerNotes: "",
      batchWeightOrCount: 0,
      batchWeightOrCountUoM: "",
      pickupAmount: 0,
      accessionAmount: 0,
      accessionAmountUoM: "",
      isPrepackaged: false,
      labelClaim: "",
      amountPerServing: 0,
      amountPerPackage: 0,
      reportTemplateOptionId: "",
      reportFirstPage: "",
      photoObjectUrl: "",
      qrCodeObjectUrl: "",
      isRushOrder: false,
      dueDate: new Date(),
      processSteps: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    photoObjectUrl,
    qrCodeObjectUrl,
    category,
    isRushOrder,
    processSteps,
    metrcPackageUID,
  } = watch([
    "photoObjectUrl",
    "qrCodeObjectUrl",
    "category",
    "isRushOrder",
    "processSteps",
    "metrcPackageUID",
  ]);

  const {
    packageFound,
    checking: checkingForPackage,
    hasBeenChecked: hasCheckedForPackage,
  } = useMetrcPackageCheck(metrcPackageUID);

  React.useEffect(() => {
    reset({
      sourceMaterial: data?.sample?.sourceMaterial ?? "",
      category: data?.sample?.matrix.sampleCategory.id ?? "",
      lotOrBatchNumber: data?.sample?.lotOrBatchNumber ?? "",
      matrix: data?.sample?.matrix.id ?? "",
      metrcPackageUID: data?.sample?.metrcPackage?.packageLabel ?? "",
      customerNotes: data?.sample?.customerNotes ?? "",
      batchWeightOrCount: data?.sample?.weightOrCount ?? 0,
      batchWeightOrCountUoM: data?.sample?.unitOfMeasure ?? "",
      pickupAmount: data?.sample?.accession?.pickupAmount?.value ?? 0,
      pickupAmountUoM:
        data?.sample?.accession?.pickupAmount?.units ??
        data?.sample?.unitOfMeasure ??
        "",
      accessionAmount: data?.sample?.accession?.accessionAmount?.value ?? 0,
      accessionAmountUoM:
        data?.sample?.accession?.accessionAmount?.units ??
        data?.sample?.unitOfMeasure ??
        "",
      isPrepackaged: data?.sample?.accession?.isPrepackaged ?? false,
      labelClaim: data?.sample?.accession?.labelClaim ?? "",
      amountPerServing: !!data?.sample?.accession?.amountPerServing?.value
        ? parseFloat(
            new Intl.NumberFormat().format(
              data?.sample?.accession?.amountPerServing?.value,
            ),
          )
        : 0,
      amountPerServingUoM:
        data?.sample?.accession?.amountPerServing?.units ?? "g",
      amountPerPackage: !!data?.sample?.accession?.amountPerPackage?.value
        ? parseFloat(
            new Intl.NumberFormat().format(
              data?.sample?.accession?.amountPerPackage?.value,
            ),
          )
        : 0,
      amountPerPackageUoM:
        data?.sample?.accession?.amountPerPackage?.units ?? "g",
      reportTemplateOptionId: data?.sample?.reportTemplateOption?.id ?? "",
      reportFirstPage: data?.sample?.reportFirstPage ?? "",
      photoObjectUrl: data?.sample?.photo?.url ?? "",
      qrCodeObjectUrl: data?.sample?.qrCode?.url ?? "",
      isRushOrder: data?.sample?.accession?.isRushOrder ?? false,
      dueDate: data?.sample?.accession?.dueDate
        ? DateTime.fromSQL(
            DateTime.fromISO(data?.sample?.accession?.dueDate, {
              zone: "utc",
            }).toISODate(),
          ).toJSDate()
        : new Date(),
      processSteps: data?.sample?.processSteps ?? [],
      complianceSampleType: data?.sample?.complianceType?.id ?? "",
    });
    setPhotoFile(null);
    setQrCodeFile(null);
  }, [data, reset, setPhotoFile, setQrCodeFile]);

  // Needs to be invoked to properly populate during submission https://react-hook-form.com/api/#formState
  const dirtyFields = formState.dirtyFields; // eslint-disable-line

  const _handleSubmission = async ({
    sourceMaterial,
    reportFirstPage,
    isPrepackaged,
    matrix,
    metrcPackageUID,
    customerNotes,
    batchWeightOrCount,
    batchWeightOrCountUoM,
    pickupAmount,
    pickupAmountUoM,
    accessionAmount,
    accessionAmountUoM,
    labelClaim,
    amountPerServing,
    amountPerServingUoM,
    amountPerPackage,
    amountPerPackageUoM,
    isRushOrder,
    dueDate,
    processSteps,
    complianceSampleType,
    reportTemplateOptionId,
  }: AccessioningFormFields) => {
    if (!sample?.id) return;
    try {
      let qrCodeUrl: string | null = null;
      let photoUrl: string | null = null;
      if (!sample) throw new Error("Unable to determine current sample");
      if (photoFile) {
        const photoUploadResult = await firebase
          .storage()
          .ref(`samlePhotos/${sample.id}`)
          .put(photoFile);

        photoUrl = await photoUploadResult.ref.getDownloadURL();
      }
      if (qrCodeFile) {
        const qrCodeUploadResult = await firebase
          .storage()
          .ref(`qrCodes/${sample.id}`)
          .put(qrCodeFile);
        qrCodeUrl = await qrCodeUploadResult.ref.getDownloadURL();
      }
      await accessionSample({
        variables: {
          input: {
            id: sample.id,
            accession: {
              reportFirstPage: reportFirstPage,
              isPrepackaged: isPrepackaged,
              ...(photoFile &&
                photoUrl && {
                  photo: {
                    url: photoUrl,
                    extension: photoFile.type,
                    size: photoFile.size,
                  },
                }),
              ...(qrCodeFile &&
                qrCodeUrl && {
                  qrCode: {
                    url: qrCodeUrl,
                    extension: qrCodeFile.type,
                    size: qrCodeFile.size,
                  },
                }),
              ...(pickupAmount &&
                pickupAmountUoM && {
                  pickupAmount: {
                    value: pickupAmount,
                    units: pickupAmountUoM,
                  },
                }),
              ...(accessionAmount &&
                accessionAmountUoM && {
                  accessionAmount: {
                    value: accessionAmount,
                    units: accessionAmountUoM,
                  },
                }),
              ...(amountPerPackage &&
                amountPerPackageUoM && {
                  amountPerPackage: {
                    value: amountPerPackage,
                    units: amountPerPackageUoM,
                  },
                }),
              ...(amountPerServing &&
                amountPerServingUoM && {
                  amountPerServing: {
                    value: amountPerServing,
                    units: amountPerServingUoM,
                  },
                }),
              ...(labelClaim && { labelClaim }),
              isRushOrder,
              ...(isRushOrder &&
                dueDate && {
                  dueDate: DateTime.fromJSDate(dueDate).toISODate(),
                }),
            },
            updates: {
              complianceSampleTypeId: complianceSampleType,
              ...(formState.dirtyFields.sourceMaterial && {
                sourceMaterial:
                  sourceMaterial === "HEMP"
                    ? SampleSourceMaterial.Hemp
                    : SampleSourceMaterial.Cannabis,
              }),
              ...(formState.dirtyFields.matrix && { sampleMatrixId: matrix }),
              ...(formState.dirtyFields.metrcPackageUID && {
                metrcPackageUID,
              }),
              ...(formState.dirtyFields.customerNotes && { customerNotes }),
              ...(formState.dirtyFields.reportTemplateOptionId && {
                reportTemplateOptionId,
              }),
              ...(formState.dirtyFields.batchWeightOrCount && {
                weightOrCount: batchWeightOrCount,
              }),
              ...(formState.dirtyFields.batchWeightOrCountUoM && {
                unitOfMeasure: batchWeightOrCountUoM,
              }),
              ...(formState.dirtyFields.processSteps && {
                orderedPanelOverrides: processSteps
                  .filter(
                    (_, index) => formState.dirtyFields.processSteps?.[index],
                  )
                  .map((processStep) => ({
                    shouldBeTested: processStep.required,
                    panelId:
                      optionsData?.availablePanels.find(
                        (panel) => panel.name === processStep.name,
                      )?.id ?? "",
                  })),
              }),
            },
          },
        },
      });
      enqueueSnackbar("Sample accessioned", { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Error occurred while accessioning sample", {
        variant: "error",
      });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Think20 Labs Portal - Accessioning</title>
      </Helmet>
      <Box py={3}>
        <PageTitleCard title="Accession Sample" />
      </Box>
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <SearchBox
            label="Sample ID"
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={setSampleId}
            searchProcessing={loading}
          />
        </Grid>
        {error && (
          <>
            <Grid item xs={12}>
              <Container maxWidth="xs">
                <Paper>
                  <Box
                    color="error.main"
                    display="flex"
                    justifyContent="center"
                    py={2}
                  >
                    <Box mr={1}>
                      <ErrorIcon />
                    </Box>
                    <Typography variant="body1">
                      Unable to fetch sample
                    </Typography>
                  </Box>
                </Paper>
              </Container>
            </Grid>
          </>
        )}
        {sample && (
          <Grid
            item
            xs={12}
            component="form"
            noValidate
            onSubmit={handleSubmit(_handleSubmission)}
          >
            <Card>
              <CardHeader
                subheader={
                  <Typography variant="body1" color="textSecondary">
                    {sample.sampleId}
                  </Typography>
                }
                title={
                  <Box display="flex">
                    <Typography variant="h5">{sample.productName}</Typography>
                    {sample.isCompliance && (
                      <Chip
                        label="Compliance"
                        color="secondary"
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </Box>
                }
                disableTypography
                action={
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Controller
                        control={control}
                        defaultValue={isRushOrder}
                        name="isRushOrder"
                        render={(props) => (
                          <Switch
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label="Rush Order"
                  />
                }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={3} sm={6} xs={12}>
                    <BusinessInfoCard
                      business={
                        sample.order?.sampleSubmission?.submittedBy.business
                      }
                      elevation={4}
                      showTitle
                    />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Card elevation={4}>
                      <CardHeader subheader="Panels" />
                      <CardContent>
                        <Grid item container spacing={1}>
                          {processSteps.map((step, index) => {
                            return (
                              <Grid
                                item
                                key={step.id}
                                style={{
                                  display: step.name.match(/Accessioning/)
                                    ? "none"
                                    : RA.stubUndefined(),
                                }}
                              >
                                <Controller
                                  name={`processSteps[${index}]`}
                                  control={control}
                                  render={({
                                    value = { name: "", required: false },
                                    onChange,
                                  }) => {
                                    return (
                                      <ProcessStepChip
                                        processStep={value}
                                        onClick={() => {
                                          if (
                                            !!optionsData?.availablePanels?.find(
                                              ({ name }) => name === step.name,
                                            )
                                          ) {
                                            onChange({
                                              ...value,
                                              required: !value.required,
                                            });
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card elevation={4}>
                      <CardHeader subheader="Sample Detail" />
                      <CardContent>
                        <FormTextField
                          name="sourceMaterial"
                          control={control}
                          errors={errors}
                          label="Source Material"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          select
                        >
                          <MenuItem value={SampleSourceMaterial.Hemp}>
                            {SampleSourceMaterial.Hemp}
                          </MenuItem>
                          <MenuItem value={SampleSourceMaterial.Cannabis}>
                            {SampleSourceMaterial.Cannabis}
                          </MenuItem>
                        </FormTextField>
                        <FormTextField
                          name="category"
                          control={control}
                          errors={errors}
                          label="Category"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          select
                          onChangeCallback={() => setValue("matrix", "")}
                        >
                          {optionsData?.intendedUses
                            .reduce(
                              (acc, intendedUse) => [
                                ...acc,
                                ...intendedUse.sampleCategories,
                              ],
                              [] as SampleCategoryFieldsFragment[],
                            )
                            ?.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            )) ?? <MenuItem />}
                        </FormTextField>
                        <FormTextField
                          name="matrix"
                          control={control}
                          errors={errors}
                          label="Matrix"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          select
                        >
                          {optionsData?.intendedUses
                            .reduce(
                              (acc, intendedUse) => [
                                ...acc,
                                ...intendedUse.sampleCategories,
                              ],
                              [] as (SampleCategoryFieldsFragment & {
                                sampleMatrices: SampleMatrixFieldsFragment[];
                              })[],
                            )
                            ?.find(({ id }) => id === category)
                            ?.sampleMatrices?.map(({ id, name }) => (
                              <MenuItem value={id} key={id}>
                                {name}
                              </MenuItem>
                            )) ?? <MenuItem />}
                        </FormTextField>
                        <FormTextField
                          name="lotOrBatchNumber"
                          control={control}
                          label="Lot or batch #"
                          InputProps={{ readOnly: true }}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                        <FormTextField
                          name="metrcPackageUID"
                          control={control}
                          errors={errors}
                          label="Metrc Package UID"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (() => {
                              if (checkingForPackage) {
                                return <CircularProgress size="1rem" />;
                              }
                              if (hasCheckedForPackage) {
                                if (packageFound) {
                                  return (
                                    <Box color="success.main">
                                      <CheckIcon color="inherit" />
                                    </Box>
                                  );
                                } else {
                                  return (
                                    <Box color="error.main">
                                      <Tooltip title="Unable to determine Metrc Source Package UID from Metrc Package UID">
                                        <ErrorIcon color="inherit" />
                                      </Tooltip>
                                    </Box>
                                  );
                                }
                              }
                              return null;
                            })(),
                          }}
                        />
                        <FormTextField
                          name="customerNotes"
                          control={control}
                          errors={errors}
                          label="Customer Notes"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          multiline
                          rowsMax={4}
                        />
                        <Collapse in={isRushOrder}>
                          <FormDatePicker
                            name="dueDate"
                            control={control}
                            defaultValue={new Date()}
                            fullWidth
                            margin="dense"
                            label="Due Date"
                            inputVariant="outlined"
                            variant="inline"
                            autoOk
                          />
                        </Collapse>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Card elevation={4}>
                      <CardHeader
                        subheader={`Sample ${amountQualifier(
                          sample.unitOfMeasure,
                        )}`}
                      />

                      <CardContent>
                        <Box display="flex">
                          <FormTextField
                            name="batchWeightOrCount"
                            control={control}
                            errors={errors}
                            label={`Batch Amount`}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                          <FormTextField
                            name="batchWeightOrCountUoM"
                            control={control}
                            errors={errors}
                            label="UoM"
                            margin="dense"
                            variant="outlined"
                            select
                            style={{ width: 150 }}
                          >
                            {weightOrCountUnits.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {unit}
                              </MenuItem>
                            ))}
                          </FormTextField>
                        </Box>
                        <Box display="flex">
                          <FormTextField
                            name="pickupAmount"
                            control={control}
                            errors={errors}
                            label={`Pick Up Amount`}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                          <FormTextField
                            name="pickupAmountUoM"
                            control={control}
                            errors={errors}
                            label="UoM"
                            margin="dense"
                            variant="outlined"
                            select
                            style={{ width: 150 }}
                          >
                            {weightOrCountUnits.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {unit}
                              </MenuItem>
                            ))}
                          </FormTextField>
                        </Box>
                        <Box display="flex">
                          <FormTextField
                            name="accessionAmount"
                            control={control}
                            errors={errors}
                            label={`Accession Amount`}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                          <FormTextField
                            name="accessionAmountUoM"
                            control={control}
                            errors={errors}
                            label="UoM"
                            margin="dense"
                            variant="outlined"
                            select
                            style={{ width: 150 }}
                          >
                            {weightOrCountUnits.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {unit}
                              </MenuItem>
                            ))}
                          </FormTextField>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Card elevation={4}>
                      <CardHeader subheader="COA Info" />
                      <CardContent>
                        <FormCheckboxField
                          name="isPrepackaged"
                          control={control}
                          errors={errors}
                          label="Sample is prepackaged"
                        />
                        <FormTextField
                          name="labelClaim"
                          control={control}
                          errors={errors}
                          label="Label Claim"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                        <Box display="flex">
                          <FormTextField
                            name="amountPerServing"
                            type="number"
                            control={control}
                            errors={errors}
                            label="Amount per Serving"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                          <FormTextField
                            name="amountPerServingUoM"
                            control={control}
                            errors={errors}
                            label="UoM"
                            margin="dense"
                            variant="outlined"
                            select
                            style={{ width: 150 }}
                          >
                            {weightOrCountUnits
                              .filter((unit) => unit === "g" || unit === "mL")
                              .map((unit) => (
                                <MenuItem key={unit} value={unit}>
                                  {unit}
                                </MenuItem>
                              ))}
                          </FormTextField>
                        </Box>
                        <Box display="flex">
                          <FormTextField
                            name="amountPerPackage"
                            type="number"
                            control={control}
                            errors={errors}
                            label="Amount per Package"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                          <FormTextField
                            name="amountPerPackageUoM"
                            control={control}
                            errors={errors}
                            label="UoM"
                            margin="dense"
                            variant="outlined"
                            select
                            style={{ width: 150 }}
                          >
                            {weightOrCountUnits
                              .filter((unit) => unit === "g" || unit === "mL")
                              .map((unit) => (
                                <MenuItem key={unit} value={unit}>
                                  {unit}
                                </MenuItem>
                              ))}
                          </FormTextField>
                        </Box>
                        <FormTextField
                          label="Report Template"
                          name="reportTemplateOptionId"
                          control={control}
                          errors={errors}
                          required
                          margin="dense"
                          fullWidth
                          select
                          variant="outlined"
                        >
                          {COA_OPTIONS.map(({ id, label }) => (
                            <MenuItem key={id} value={id}>
                              {label}
                            </MenuItem>
                          ))}
                        </FormTextField>
                        <FormTextField
                          name="reportFirstPage"
                          control={control}
                          errors={errors}
                          label="Report First Page"
                          select
                          fullWidth
                          variant="outlined"
                          margin="dense"
                        >
                          <MenuItem value="Standard">Standard</MenuItem>
                          <MenuItem value="Δ8">Δ8</MenuItem>
                          <MenuItem value="Δ9">Δ9</MenuItem>
                          <MenuItem value="Edible">Edible</MenuItem>
                        </FormTextField>
                        <FormTextField
                          name="complianceSampleType"
                          control={control}
                          errors={errors}
                          label="Compliance Type"
                          select
                          fullWidth
                          variant="outlined"
                          margin="dense"
                        >
                          {optionsData?.complianceSampleTypes?.map(
                            ({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ),
                          ) ?? <MenuItem />}
                        </FormTextField>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Card elevation={4}>
                      <Controller
                        name="photoObjectUrl"
                        control={control}
                        render={(props) => (
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="upload-photo-button"
                            type="file"
                            onChange={(e) => {
                              setPhotoFile(e.target.files?.[0] ?? null);
                              props.onChange(
                                URL.createObjectURL(e.target.files?.[0]),
                              );
                            }}
                          />
                        )}
                      />
                      <label htmlFor="upload-photo-button">
                        <CardActionArea component="span">
                          <Box
                            p={2}
                            display="flex"
                            justifyContent="space-between"
                            flexGrow={1}
                            color={
                              get(errors, "photoObjectUrl")
                                ? "error.main"
                                : "inherit"
                            }
                          >
                            <Typography variant="body1" color="textSecondary">
                              Upload Photo
                            </Typography>
                            <Box display="flex" flexGrow="1" />
                            <PhotoCamera />
                          </Box>
                        </CardActionArea>
                      </label>
                      <CardMedia
                        image={photoObjectUrl}
                        style={{ height: photoObjectUrl ? 150 : 0 }}
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Card elevation={4}>
                      <Controller
                        name="qrCodeObjectUrl"
                        control={control}
                        render={(props) => (
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="upload-qr-code-button"
                            type="file"
                            onChange={(e) => {
                              setQrCodeFile(e.target.files?.[0] ?? null);
                              props.onChange(
                                URL.createObjectURL(e.target.files?.[0]),
                              );
                            }}
                          />
                        )}
                      />
                      <label htmlFor="upload-qr-code-button">
                        <CardActionArea component="span">
                          <Box
                            p={2}
                            display="flex"
                            justifyContent="space-between"
                            flexGrow={1}
                          >
                            <Typography variant="body1" color="textSecondary">
                              Upload QR Code
                            </Typography>
                            <Box display="flex" flexGrow="1" />
                            <QrCodeIcon />
                          </Box>
                        </CardActionArea>
                      </label>

                      <CardMedia
                        image={qrCodeObjectUrl || ""}
                        style={{ height: qrCodeObjectUrl ? 150 : 0 }}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box display="flex" flexDirection="row-reverse" flexGrow={1}>
                  <LoadingButton
                    loading={formState.isSubmitting}
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Accession Sample
                  </LoadingButton>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

const isCount = (unitOfMeasure?: string | null) => unitOfMeasure === "units";
const isVolume = (unitOfMeasure?: string | null) => unitOfMeasure === "mL";

const amountQualifier = (unitOfMeasure?: string | null) =>
  isCount(unitOfMeasure)
    ? "Count"
    : isVolume(unitOfMeasure)
    ? "Volume"
    : "Weight";

export default SampleAccession;
