import type { FunctionComponent } from "react";
import * as React from "react";
import type { Cell } from "react-table";
import ExpandButton from "src/components/ExpandButton";
import type { SampleLogQuery } from "src/generated/graphql-hooks";

const RowExpandButton: FunctionComponent<Cell<
  SampleLogQuery["samples"][number]
>> = ({ row }) => (
  <span {...row.getToggleRowExpandedProps()}>
    <ExpandButton isExpanded={row.isExpanded} />
  </span>
);

export default RowExpandButton;
