import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import firebase from "firebase/app";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import * as React from "react";
import {
  InitiatePanelProcessingJobInput,
  PanelFieldsFragment,
  UrlSchema,
  useInitiatePanelProcessingJobMutation,
} from "src/generated/graphql-hooks";
import UploadResultsForm, {
  UploadResultsFormValues,
} from "../forms/UploadResultsForm";
import LoadingButton from "../LoadingButton";

interface UploadResultsModalProps {
  open: boolean;
  onClose?: () => void;
}

const UPLOAD_RESULTS_FORM_ID = "upload-results-form";

const UploadResultsModal: React.FunctionComponent<
  UploadResultsModalProps & PanelFieldsFragment
> = ({ id: panelId, name: panelName, open, onClose = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    initiatePanelProcessingJob,
    { loading },
  ] = useInitiatePanelProcessingJobMutation();
  const _handleSubmit = async (values: UploadResultsFormValues) => {
    if (!values.resultsFile) {
      enqueueSnackbar("No file has been selected", { variant: "error" });
      throw new Error();
    }
    try {
      const fileUpload = await firebase
        .storage()
        .ref(
          `instrumentResults/${DateTime.local().toISO()}-${
            values.resultsFile.file.name
          }`,
        )
        .put(values.resultsFile.file);

      await initiatePanelProcessingJob({
        variables: {
          input: convertFieldValuesToInitiatePanelProcessingJobInput(
            panelId,
            values,
            fileUpload.ref.toString(),
          ),
        },
      });
      enqueueSnackbar("Processing initiated", { variant: "info" });
    } catch (err) {
      enqueueSnackbar("Unable to initiate processing", { variant: "error" });
      console.error(err);
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload {panelName} Results</DialogTitle>
      <DialogContent>
        <UploadResultsForm
          id={UPLOAD_RESULTS_FORM_ID}
          onSubmit={_handleSubmit}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          form={UPLOAD_RESULTS_FORM_ID}
          type="submit"
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const convertFieldValuesToInitiatePanelProcessingJobInput = (
  panelId: string,
  { instrumentId, resultsFile, batchId, notes }: UploadResultsFormValues,
  fileRef: string,
): InitiatePanelProcessingJobInput => ({
  file: {
    size: resultsFile?.file.size ?? 0,
    extension: resultsFile?.file.type ?? "unknown",
    url: fileRef,
    urlSchema: UrlSchema.GcsRef,
  },
  instrumentId,
  notes,
  batchId,
  panelId,
});

export default UploadResultsModal;
