import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: any;
};

export type AccessionSampleInput = {
  accession: CreateAccessionInput;
  /** ID of sample to accession */
  id: Scalars["ID"];
  updates?: Maybe<UpdateSampleInput>;
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  id: Scalars["ID"];
  state: Scalars["String"];
  street1: Scalars["String"];
  street2?: Maybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type AnalysisOption = {
  __typename?: "AnalysisOption";
  id: Scalars["ID"];
  name: Scalars["String"];
  price: Scalars["Float"];
};

export type AnalysisOptionWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
};

export type AnalyticsDateInput = {
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type AnalyticsPayload = {
  __typename?: "AnalyticsPayload";
  averageDaysToCompletion?: Maybe<Scalars["Float"]>;
  completedClientSampleCount?: Maybe<Scalars["Json"]>;
  completedSampleCount: Scalars["Int"];
  completedSamplesCountByMonth: Array<CompletedSampleCount>;
  complianceSampleCount: Scalars["Int"];
  rushedSampleCount?: Maybe<Scalars["Int"]>;
  sampleCount: Scalars["Int"];
  sampleStatusCount: Array<SampleStatusCount>;
  uniqueClientCount?: Maybe<Scalars["Int"]>;
};

export type Balance = {
  __typename?: "Balance";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type BoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type Business = {
  __typename?: "Business";
  address: Address;
  distributors: Array<Distributor>;
  id: Scalars["ID"];
  name: Scalars["String"];
  producers: Array<Producer>;
  samples: Array<Sample>;
};

export type BusinessDistributorsArgs = {
  after?: Maybe<DistributorWhereUniqueInput>;
  before?: Maybe<DistributorWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type BusinessProducersArgs = {
  after?: Maybe<ProducerWhereUniqueInput>;
  before?: Maybe<ProducerWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type CaMetrcAnalyte = {
  __typename?: "CaMetrcAnalyte";
  display: Scalars["String"];
  referenceComponent: ReferenceComponent;
  reportingFormat: Scalars["String"];
};

export type CaMetrcAnalyteSampleCategoryIdReferenceComponentIdReportingFormatCompoundUniqueInput =
  {
    referenceComponentId: Scalars["String"];
    reportingFormat: Scalars["String"];
    sampleCategoryId: Scalars["String"];
  };

export type CaMetrcAnalyteWhereUniqueInput = {
  sampleCategoryId_referenceComponentId_reportingFormat?: Maybe<CaMetrcAnalyteSampleCategoryIdReferenceComponentIdReportingFormatCompoundUniqueInput>;
};

export type CompletedSampleCount = {
  __typename?: "CompletedSampleCount";
  client?: Maybe<Scalars["String"]>;
  count: Scalars["Int"];
  month: Scalars["String"];
};

export type ComplianceSampleType = {
  __typename?: "ComplianceSampleType";
  id: Scalars["ID"];
  name: Scalars["String"];
  stateReportingRegulations: Array<StateReportingRegulation>;
};

export type ComplianceSampleTypeStateReportingRegulationsArgs = {
  after?: Maybe<StateReportingRegulationWhereUniqueInput>;
  before?: Maybe<StateReportingRegulationWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type ConfirmPanelResultsInput = {
  analysisComplete?: Maybe<Scalars["Boolean"]>;
  referencePanelId: Scalars["ID"];
  sampleId: Scalars["ID"];
};

export type CreateAccessionInput = {
  accessionAmount?: Maybe<CreateMeasurementInput>;
  amountPerPackage?: Maybe<CreateMeasurementInput>;
  amountPerServing?: Maybe<CreateMeasurementInput>;
  dueDate?: Maybe<Scalars["Date"]>;
  isPrepackaged: Scalars["Boolean"];
  isRushOrder?: Maybe<Scalars["Boolean"]>;
  labelClaim?: Maybe<Scalars["String"]>;
  photo?: Maybe<CreateFileInput>;
  pickupAmount?: Maybe<CreateMeasurementInput>;
  qrCode?: Maybe<CreateFileInput>;
  reportFirstPage: Scalars["String"];
};

export type CreateAddressInput = {
  city: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
  zip: Scalars["String"];
};

export type CreateBusinessInput = {
  address: CreateAddressInput;
  name: Scalars["String"];
};

export type CreateFileInput = {
  extension: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  size: Scalars["Int"];
  url: Scalars["String"];
  urlSchema?: Maybe<UrlSchema>;
};

export type CreateMeasurementInput = {
  units: Scalars["String"];
  value: Scalars["Float"];
};

export type CreateOrderInput = {
  samples: Array<CreateSampleInput>;
};

export type CreatePanelResultInput = {
  createdById: Scalars["ID"];
  data: Scalars["Json"];
  instrumentUsedId: Scalars["ID"];
};

export type CreatePartnerInput = {
  address: CreateAddressInput;
  licenseNumber: Scalars["String"];
  name: Scalars["String"];
};

export type CreateSampleInput = {
  analysisOptionIds: Array<Scalars["ID"]>;
  deliveryOptionId: Scalars["ID"];
  distributorId?: Maybe<Scalars["ID"]>;
  forMedicalUse?: Maybe<Scalars["Boolean"]>;
  harvestOrProductionDate?: Maybe<Scalars["Date"]>;
  isCompliance: Scalars["Boolean"];
  lotOrBatchNumber: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  producerId?: Maybe<Scalars["ID"]>;
  productName: Scalars["String"];
  readyForPickupDate?: Maybe<Scalars["Date"]>;
  reportTemplateOptionId: Scalars["String"];
  sampleMatrixId: Scalars["String"];
  sourceMaterial: SampleSourceMaterial;
  unitOfMeasure?: Maybe<Scalars["String"]>;
  weightOrCount?: Maybe<Scalars["Float"]>;
};

export type CreateSamplePanelInput = {
  panelType: PanelType;
  result: CreatePanelResultInput;
  sampleId: Scalars["ID"];
};

export type CreateSampleSubmissionInput = {
  discountCode?: Maybe<Scalars["String"]>;
  order: CreateOrderInput;
};

export type CreateSolventApplicationInput = {
  density?: Maybe<Scalars["Float"]>;
  dilutionFactor?: Maybe<Scalars["Float"]>;
  lot?: Maybe<Scalars["String"]>;
  masses?: Maybe<Array<Scalars["Float"]>>;
  solventId?: Maybe<Scalars["ID"]>;
  totalDilutionFactor?: Maybe<Scalars["Float"]>;
  volume?: Maybe<Scalars["Float"]>;
};

export type DeliveryOption = {
  __typename?: "DeliveryOption";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type Dispenser = {
  __typename?: "Dispenser";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Distributor = {
  __typename?: "Distributor";
  address: Address;
  id: Scalars["ID"];
  licenseNumber: Scalars["String"];
  name: Scalars["String"];
};

export type DistributorWhereUniqueInput = {
  addressId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type File = {
  __typename?: "File";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  size: Scalars["Int"];
  url: Scalars["String"];
  urlSchema: UrlSchema;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type InitiatePanelProcessingJobInput = {
  batchId: Scalars["String"];
  file: CreateFileInput;
  instrumentId: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  panelId: Scalars["ID"];
};

export type Instrument = {
  __typename?: "Instrument";
  id: Scalars["ID"];
  instrumentParameters: Array<InstrumentParameter>;
  name: Scalars["String"];
};

export type InstrumentInstrumentParametersArgs = {
  after?: Maybe<InstrumentParameterWhereUniqueInput>;
  before?: Maybe<InstrumentParameterWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type InstrumentParameter = {
  __typename?: "InstrumentParameter";
  displayOnCOA: Scalars["Boolean"];
  id: Scalars["ID"];
  levelOfDetection?: Maybe<Scalars["Float"]>;
  levelOfQuantitation?: Maybe<Scalars["Float"]>;
  panel: ReferencePanel;
  referenceComponent: ReferenceComponent;
  volumeUnitOfMeasure: Scalars["String"];
  weightUnitOfMeasure: Scalars["String"];
};

export type InstrumentParameterWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
};

export type IntendedUse = {
  __typename?: "IntendedUse";
  id: Scalars["ID"];
  name: Scalars["String"];
  sampleCategories: Array<SampleCategory>;
};

export type IntendedUseSampleCategoriesArgs = {
  after?: Maybe<SampleCategoryWhereUniqueInput>;
  before?: Maybe<SampleCategoryWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type LinkCocPaperworkInput = {
  files: Array<CreateFileInput>;
  orderId: Scalars["ID"];
};

export type Measurement = {
  __typename?: "Measurement";
  id: Scalars["String"];
  units: Scalars["String"];
  value: Scalars["Float"];
};

export type MetrcPackage = {
  __typename?: "MetrcPackage";
  packageLabel: Scalars["String"];
  sourcePackageLabels?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Accepts a sample into the lab workflow */
  acceptSample: Sample;
  /** Accepts the result of the accessioning process */
  accessionSample: Sample;
  /** Accept sample results after QA */
  approveSampleQA: Sample;
  /** Approves sample results and generates final report */
  approveSampleReport: Sample;
  /** Cancels sample for testing */
  cancelSample: Sample;
  /** Marks panel as complete */
  confirmPanelResults: Sample;
  /** Creates a new distributor for the user's business */
  createDistributor: Distributor;
  /** Creates a new producer for the user's business */
  createProducer: Producer;
  /** Kicks off a processing job for a given panel */
  initiatePanelProcessingJob: PanelProcessingJob;
  /** Register links of chain of custody documentation */
  linkCOCPaperwork: Order;
  /** Notifies that a field technician is on the way to lab with order */
  markInTransitToLab: Order;
  /** Notifies that a field technician is on the way to pick up the specified order */
  markOutForPickup: Order;
  /** Releases report to client for viewing and download */
  releaseReport: Sample;
  /** Report results for a processing job for storage and display */
  reportPanelProcessingJobResults: PanelProcessingJob;
  /** Schedules pickup and save scheduled pickup information */
  schedulePickup: Order;
  /** Creates a new user account with a new business. Returns the new user along with a token that can be used for authentication. */
  signUpWithNewBusiness: SignUpPayload;
  submitPastCOAsRequest?: Maybe<Scalars["Boolean"]>;
  /** Submits samples for testing */
  submitSamples: SampleSubmission;
  /** Changes active status on user */
  toggleUser: User;
  /** Updates info on user */
  updateUser: User;
  /** Adds information regarding foreign material testing for a given sample */
  upsertForeignMaterialInfo: Sample;
  /** Adds information regarding foreign material testing for a given sample */
  upsertMicrobialResults: Sample;
  /** Adds information regarding moisture content testing for a given sample */
  upsertMoistureContentResults: Sample;
  /** Adds aliquoting information for a given sample */
  upsertSampleAliquots: Sample;
  /** Adds information about homogenization for a given sample */
  upsertSampleHomogenization: Sample;
  /** Adds information regarding test prep for a given sample and panel */
  upsertSamplePanelTestPrep: Sample;
};

export type MutationAcceptSampleArgs = {
  input: SampleActionInput;
};

export type MutationAccessionSampleArgs = {
  input: AccessionSampleInput;
};

export type MutationApproveSampleQaArgs = {
  input: SampleActionInput;
};

export type MutationApproveSampleReportArgs = {
  input: SampleActionInput;
};

export type MutationCancelSampleArgs = {
  input: SampleActionInput;
};

export type MutationConfirmPanelResultsArgs = {
  input: ConfirmPanelResultsInput;
};

export type MutationCreateDistributorArgs = {
  input: CreatePartnerInput;
};

export type MutationCreateProducerArgs = {
  input: CreatePartnerInput;
};

export type MutationInitiatePanelProcessingJobArgs = {
  input: InitiatePanelProcessingJobInput;
};

export type MutationLinkCocPaperworkArgs = {
  input: LinkCocPaperworkInput;
};

export type MutationMarkInTransitToLabArgs = {
  input: PickupActionInput;
};

export type MutationMarkOutForPickupArgs = {
  input: PickupActionInput;
};

export type MutationReleaseReportArgs = {
  sampleId: Scalars["ID"];
};

export type MutationReportPanelProcessingJobResultsArgs = {
  input: ReportPanelProcessingJobResultsInput;
};

export type MutationSchedulePickupArgs = {
  input: SchedulePickupInput;
};

export type MutationSignUpWithNewBusinessArgs = {
  input: SignUpWithNewBusinessInput;
};

export type MutationSubmitPastCoAsRequestArgs = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
};

export type MutationSubmitSamplesArgs = {
  input: CreateSampleSubmissionInput;
};

export type MutationToggleUserArgs = {
  userId: Scalars["ID"];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpsertForeignMaterialInfoArgs = {
  input: UpsertForeignMaterialInfoInput;
};

export type MutationUpsertMicrobialResultsArgs = {
  input: UpsertMicrobialResultsInput;
};

export type MutationUpsertMoistureContentResultsArgs = {
  input: UpsertMoistureContentResultsInput;
};

export type MutationUpsertSampleAliquotsArgs = {
  input: UpsertSampleAliquotsInput;
};

export type MutationUpsertSampleHomogenizationArgs = {
  input: UpsertSampleHomogenizationInput;
};

export type MutationUpsertSamplePanelTestPrepArgs = {
  input: UpsertSamplePanelTestPrepInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  equals?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type Order = {
  __typename?: "Order";
  chainOfCustodyDocuments: Array<File>;
  id: Scalars["ID"];
  readyForPickupDate?: Maybe<Scalars["Date"]>;
  sampleSubmission?: Maybe<SampleSubmission>;
  samples: Array<Sample>;
  scheduledPickup?: Maybe<ScheduledPickup>;
  shortId: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
};

export type OrderChainOfCustodyDocumentsArgs = {
  after?: Maybe<FileWhereUniqueInput>;
  before?: Maybe<FileWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type OrderSamplesArgs = {
  after?: Maybe<SampleWhereUniqueInput>;
  before?: Maybe<SampleWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type OrderWhereInput = {
  notReceived?: Maybe<Scalars["Boolean"]>;
};

export type OrderedPanelOverrideInput = {
  panelId: Scalars["ID"];
  shouldBeTested: Scalars["Boolean"];
};

export type PanelProcessingJob = {
  __typename?: "PanelProcessingJob";
  batch: SampleBatch;
  completedAt?: Maybe<Scalars["DateTime"]>;
  failureReason?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  initiatedBy: User;
  startedAt: Scalars["DateTime"];
  status: PanelProcessingJobStatus;
};

export enum PanelProcessingJobStatus {
  Completed = "COMPLETED",
  Error = "ERROR",
  Processing = "PROCESSING",
}

export enum PanelType {
  Cannabinoids = "CANNABINOIDS",
  ChemicalResidue = "CHEMICAL_RESIDUE",
  Density = "DENSITY",
  ForeignMaterial = "FOREIGN_MATERIAL",
  HeavyMetals = "HEAVY_METALS",
  Homogenization = "HOMOGENIZATION",
  Microbial = "MICROBIAL",
  MoistureContent = "MOISTURE_CONTENT",
  Mycotoxin = "MYCOTOXIN",
  ResidualSolvents = "RESIDUAL_SOLVENTS",
  Terpenes = "TERPENES",
  WaterActivity = "WATER_ACTIVITY",
}

export enum PassOrFail {
  Fail = "FAIL",
  Pass = "PASS",
}

export type PickupActionInput = {
  orderId: Scalars["ID"];
};

export type Pipette = {
  __typename?: "Pipette";
  id: Scalars["ID"];
  name: Scalars["String"];
  type: PipetteType;
};

export enum PipetteType {
  FiveThousand = "FIVE_THOUSAND",
  OneThousand = "ONE_THOUSAND",
  Ten = "TEN",
  Twenty = "TWENTY",
  TwoHundred = "TWO_HUNDRED",
}

export type PipetteUsage = {
  __typename?: "PipetteUsage";
  index: Scalars["Int"];
  pipette: Pipette;
  testPrep: SamplePanelTestPrep;
};

export type PipetteUsageSamplePanelTestPrepSampleIdSamplePanelTestPrepReferencePanelIdIndexCompoundUniqueInput =
  {
    index: Scalars["Int"];
    samplePanelTestPrepReferencePanelId: Scalars["String"];
    samplePanelTestPrepSampleId: Scalars["String"];
  };

export type PipetteUsageWhereUniqueInput = {
  samplePanelTestPrepSampleId_samplePanelTestPrepReferencePanelId_index?: Maybe<PipetteUsageSamplePanelTestPrepSampleIdSamplePanelTestPrepReferencePanelIdIndexCompoundUniqueInput>;
};

export type ProcessStep = {
  __typename?: "ProcessStep";
  completed: Scalars["Boolean"];
  id: Scalars["ID"];
  inProgress: Scalars["Boolean"];
  name: Scalars["String"];
  required: Scalars["Boolean"];
};

export type Producer = {
  __typename?: "Producer";
  address: Address;
  id: Scalars["ID"];
  licenseNumber: Scalars["String"];
  name: Scalars["String"];
};

export type ProducerWhereUniqueInput = {
  addressId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  availableBalances: Array<Balance>;
  availableDispensers: Array<Dispenser>;
  /** Retrieves available testing instruments */
  availableInstruments: Array<Instrument>;
  /** Retrieves all panels for sample testing */
  availablePanels: Array<ReferencePanel>;
  availablePipettes: Array<Pipette>;
  availableSolvents: Array<Solvent>;
  businesses: Array<Business>;
  complianceSampleTypes: Array<ComplianceSampleType>;
  correspondingMetrcSourcePackageUID?: Maybe<Scalars["String"]>;
  /** Retrieves all users with permission to pick up samples */
  fieldTechnicians: Array<User>;
  intendedUses: Array<IntendedUse>;
  /** Retrieves information for an authenticated user as determined by the authorization header. Returns null if no valid authorization header is provided. */
  me?: Maybe<User>;
  /** Retrieve all orders */
  orders: Array<Order>;
  panelProcessingJobs: Array<PanelProcessingJob>;
  /** Retrives all samples that are ready for QA */
  readyForQaSamples: Array<Sample>;
  roles: Array<Role>;
  /** Retrieve data for a specific sample */
  sample?: Maybe<Sample>;
  /** Returns an array of samples filtered by date. */
  sampleStatistics: AnalyticsPayload;
  /** Retrieves all samples submitted by all users */
  samples: Array<Sample>;
  user?: Maybe<User>;
  users: Array<User>;
  /** Retrieves all available vehicles */
  vehicles: Array<Vehicle>;
};

export type QueryCorrespondingMetrcSourcePackageUidArgs = {
  metrcPackageUID: Scalars["String"];
};

export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
};

export type QuerySampleArgs = {
  where: SampleWhereUniqueInput;
};

export type QuerySampleStatisticsArgs = {
  dateRange?: Maybe<AnalyticsDateInput>;
};

export type QuerySamplesArgs = {
  where?: Maybe<SampleWhereInput>;
};

export type QueryUserArgs = {
  id: Scalars["String"];
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export type ReferenceComponent = {
  __typename?: "ReferenceComponent";
  displayName: Scalars["String"];
  id: Scalars["ID"];
  rawName: Scalars["String"];
};

export type ReferencePanel = {
  __typename?: "ReferencePanel";
  id: Scalars["ID"];
  instrumentParameters: Array<InstrumentParameter>;
  name: Scalars["String"];
  standardOperatingProcedures: Array<StandardOperatingProcedure>;
};

export type ReferencePanelInstrumentParametersArgs = {
  after?: Maybe<InstrumentParameterWhereUniqueInput>;
  before?: Maybe<InstrumentParameterWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type ReferencePanelStandardOperatingProceduresArgs = {
  after?: Maybe<StandardOperatingProcedureWhereUniqueInput>;
  before?: Maybe<StandardOperatingProcedureWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  where?: Maybe<ReferencePanelStandardOperatingProceduresWhereInput>;
};

export type ReferencePanelStandardOperatingProceduresWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type ReportPanelProcessingJobResultsInput = {
  errorMessage?: Maybe<Scalars["String"]>;
  jobId: Scalars["ID"];
  results?: Maybe<Array<ReportSamplePanelResultsInput>>;
};

export type ReportSamplePanelResultsInput = {
  data: Scalars["Json"];
  sampleId: Scalars["ID"];
};

export type ReportTemplate = {
  __typename?: "ReportTemplate";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ReportTemplateOption = {
  __typename?: "ReportTemplateOption";
  id: Scalars["ID"];
  name: Scalars["String"];
  reportTemplate: ReportTemplate;
};

export type Role = {
  __typename?: "Role";
  id: Scalars["ID"];
  name: Scalars["String"];
  permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Sample = {
  __typename?: "Sample";
  accession?: Maybe<SampleAccession>;
  aliquots: Array<SampleAliquot>;
  analysisOptions: Array<AnalysisOption>;
  complianceType?: Maybe<ComplianceSampleType>;
  customerNotes: Scalars["String"];
  deliveryOption: DeliveryOption;
  distributor?: Maybe<Distributor>;
  harvestOrProductionDate?: Maybe<Scalars["DateTime"]>;
  homogenization?: Maybe<SampleHomogenization>;
  id: Scalars["String"];
  isCompliance: Scalars["Boolean"];
  lotOrBatchNumber: Scalars["String"];
  matrix: SampleMatrix;
  metrcPackage?: Maybe<MetrcPackage>;
  order: Order;
  orderedPanels: Array<ReferencePanel>;
  panelResults: Array<SamplePanelResult>;
  photo?: Maybe<File>;
  processSteps: Array<ProcessStep>;
  producer?: Maybe<Producer>;
  productName: Scalars["String"];
  qrCode?: Maybe<File>;
  readyForPickupDate?: Maybe<Scalars["DateTime"]>;
  receivedAt?: Maybe<Scalars["DateTime"]>;
  report?: Maybe<File>;
  reportFirstPage?: Maybe<Scalars["String"]>;
  reportReleasedToClient: Scalars["Boolean"];
  reportTemplateOption: ReportTemplateOption;
  sampleApproval?: Maybe<SampleApproval>;
  sampleId?: Maybe<Scalars["Int"]>;
  sampleQAEntry?: Maybe<SampleQaEntry>;
  sourceMaterial: SampleSourceMaterial;
  status: SampleStatus;
  testPreps: Array<SamplePanelTestPrep>;
  unitOfMeasure?: Maybe<Scalars["String"]>;
  weightOrCount?: Maybe<Scalars["Float"]>;
};

export type SampleAliquotsArgs = {
  after?: Maybe<SampleAliquotWhereUniqueInput>;
  before?: Maybe<SampleAliquotWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  where?: Maybe<SampleAliquotsWhereInput>;
};

export type SampleAnalysisOptionsArgs = {
  after?: Maybe<AnalysisOptionWhereUniqueInput>;
  before?: Maybe<AnalysisOptionWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type SamplePanelResultsArgs = {
  after?: Maybe<SamplePanelResultWhereUniqueInput>;
  before?: Maybe<SamplePanelResultWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  where?: Maybe<SamplePanelResultsWhereInput>;
};

export type SampleTestPrepsArgs = {
  after?: Maybe<SamplePanelTestPrepWhereUniqueInput>;
  before?: Maybe<SamplePanelTestPrepWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  where?: Maybe<SampleTestPrepsWhereInput>;
};

export type SampleAccession = {
  __typename?: "SampleAccession";
  accessionAmount?: Maybe<Measurement>;
  accessionedAt: Scalars["DateTime"];
  amountPerPackage?: Maybe<Measurement>;
  amountPerServing?: Maybe<Measurement>;
  dueDate?: Maybe<Scalars["DateTime"]>;
  isPrepackaged: Scalars["Boolean"];
  isRushOrder: Scalars["Boolean"];
  labelClaim?: Maybe<Scalars["String"]>;
  pickupAmount?: Maybe<Measurement>;
};

export type SampleActionInput = {
  /** ID of sample on which the action is being performed */
  id: Scalars["ID"];
};

export type SampleAliquot = {
  __typename?: "SampleAliquot";
  balanceUsed: Balance;
  measurement: Measurement;
  panel: ReferencePanel;
  sample: Sample;
};

export type SampleAliquotSampleIdReferencePanelIdCompoundUniqueInput = {
  referencePanelId: Scalars["String"];
  sampleId: Scalars["String"];
};

export type SampleAliquotWhereUniqueInput = {
  measurementId?: Maybe<Scalars["String"]>;
  sampleId_referencePanelId?: Maybe<SampleAliquotSampleIdReferencePanelIdCompoundUniqueInput>;
};

export type SampleAliquotsWhereInput = {
  referencePanelId?: Maybe<StringFilter>;
};

export type SampleApproval = {
  __typename?: "SampleApproval";
  approvedAt: Scalars["DateTime"];
  approvedBy: User;
};

export type SampleBatch = {
  __typename?: "SampleBatch";
  id: Scalars["ID"];
  instrument: Instrument;
  notes?: Maybe<Scalars["String"]>;
  panel: ReferencePanel;
  panelProcessingJob?: Maybe<PanelProcessingJob>;
};

export type SampleCategory = {
  __typename?: "SampleCategory";
  caMetrcAnalytes: Array<CaMetrcAnalyte>;
  id: Scalars["ID"];
  intendedUse: IntendedUse;
  name: Scalars["String"];
  sampleMatrices: Array<SampleMatrix>;
};

export type SampleCategoryCaMetrcAnalytesArgs = {
  after?: Maybe<CaMetrcAnalyteWhereUniqueInput>;
  before?: Maybe<CaMetrcAnalyteWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type SampleCategorySampleMatricesArgs = {
  after?: Maybe<SampleMatrixWhereUniqueInput>;
  before?: Maybe<SampleMatrixWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type SampleCategoryWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
};

export type SampleHomogenization = {
  __typename?: "SampleHomogenization";
  balance: Balance;
  complete: Scalars["Boolean"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  completedBy?: Maybe<User>;
  grossMeasurement?: Maybe<Measurement>;
  intakeMeasurement?: Maybe<Measurement>;
  method?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type SampleMatrix = {
  __typename?: "SampleMatrix";
  id: Scalars["ID"];
  name: Scalars["String"];
  sampleCategory: SampleCategory;
};

export type SampleMatrixWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
};

export type SamplePanelResult = {
  __typename?: "SamplePanelResult";
  analysisComplete?: Maybe<Scalars["Boolean"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  completedBy?: Maybe<User>;
  images: Array<File>;
  instrumentUsed: Instrument;
  panel: ReferencePanel;
  results: Scalars["Json"];
  sampleBatch?: Maybe<SampleBatch>;
};

export type SamplePanelResultImagesArgs = {
  after?: Maybe<FileWhereUniqueInput>;
  before?: Maybe<FileWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type SamplePanelResultSampleIdReferencePanelIdCompoundUniqueInput = {
  referencePanelId: Scalars["String"];
  sampleId: Scalars["String"];
};

export type SamplePanelResultWhereUniqueInput = {
  sampleId_referencePanelId?: Maybe<SamplePanelResultSampleIdReferencePanelIdCompoundUniqueInput>;
};

export type SamplePanelResultsWhereInput = {
  referencePanelId?: Maybe<StringFilter>;
};

export type SamplePanelTestPrep = {
  __typename?: "SamplePanelTestPrep";
  aliquotVolume?: Maybe<Scalars["Float"]>;
  dispenser?: Maybe<Dispenser>;
  finalVolume?: Maybe<Scalars["Float"]>;
  goldSpikeVolume?: Maybe<Scalars["Float"]>;
  incubationStarted: Scalars["Boolean"];
  incubationStartedAt?: Maybe<Scalars["DateTime"]>;
  incubationStartedBy?: Maybe<User>;
  notes?: Maybe<Scalars["String"]>;
  pipetteUsages: Array<PipetteUsage>;
  referencePanel: ReferencePanel;
  sample: Sample;
  samplePrepComplete: Scalars["Boolean"];
  samplePrepCompletedAt?: Maybe<Scalars["DateTime"]>;
  samplePrepCompletedBy?: Maybe<User>;
  solventApplication?: Maybe<SolventApplication>;
  tsbVolume?: Maybe<Scalars["Float"]>;
  vesselNumber?: Maybe<Scalars["String"]>;
};

export type SamplePanelTestPrepPipetteUsagesArgs = {
  after?: Maybe<PipetteUsageWhereUniqueInput>;
  before?: Maybe<PipetteUsageWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type SamplePanelTestPrepSampleIdReferencePanelIdCompoundUniqueInput = {
  referencePanelId: Scalars["String"];
  sampleId: Scalars["String"];
};

export type SamplePanelTestPrepWhereUniqueInput = {
  sampleId_referencePanelId?: Maybe<SamplePanelTestPrepSampleIdReferencePanelIdCompoundUniqueInput>;
};

export type SampleQaEntry = {
  __typename?: "SampleQAEntry";
  verifiedAt: Scalars["DateTime"];
  verifiedBy: User;
};

export enum SampleSourceMaterial {
  Cannabis = "CANNABIS",
  Hemp = "HEMP",
}

export enum SampleStatus {
  CoaRevised = "COA_Revised",
  Canceled = "Canceled",
  Complete = "Complete",
  InProcess = "In_Process",
  InTransitToLab = "In_Transit_To_Lab",
  OrderPlaced = "Order_Placed",
  OutForPickup = "Out_For_Pickup",
  PendingComplete = "Pending_Complete",
  QaReview = "QA_Review",
  Received = "Received",
  SampleIntake = "Sample_Intake",
  SamplePrep = "Sample_Prep",
  SchedulePending = "Schedule_Pending",
  Scheduled = "Scheduled",
  ReadyForQaReview = "ready_for_qa_review",
}

export type SampleStatusCount = {
  __typename?: "SampleStatusCount";
  count: Scalars["Int"];
  status: SampleStatus;
};

export type SampleStatusListFilter = {
  in?: Maybe<Array<SampleStatus>>;
  notIn?: Maybe<Array<SampleStatus>>;
};

export type SampleSubmission = {
  __typename?: "SampleSubmission";
  id: Scalars["ID"];
  order: Order;
  submittedAt: Scalars["DateTime"];
  submittedBy: User;
};

export type SampleTestPrepsWhereInput = {
  referencePanelId?: Maybe<StringFilter>;
};

export type SampleWhereInput = {
  completedPanelNames?: Maybe<StringListFilter>;
  orderedPanelIds?: Maybe<StringListFilter>;
  status?: Maybe<SampleStatusListFilter>;
};

export type SampleWhereUniqueInput = {
  id?: Maybe<Scalars["ID"]>;
  sampleId?: Maybe<Scalars["ID"]>;
};

export type SchedulePickupInput = {
  address: CreateAddressInput;
  businessLicenseNumber: Scalars["String"];
  contactPhoneNumber: Scalars["String"];
  dateTime: Scalars["DateTime"];
  fieldTechnicianId: Scalars["ID"];
  onSiteContactName: Scalars["String"];
  orderId: Scalars["ID"];
  pickupVehicleId: Scalars["ID"];
};

export type ScheduledPickup = {
  __typename?: "ScheduledPickup";
  address: Address;
  businessLicenseNumber: Scalars["String"];
  contactPhoneNumber: Scalars["String"];
  dateTime: Scalars["DateTime"];
  fieldTechnician: User;
  id: Scalars["ID"];
  onSiteContactName: Scalars["String"];
  pickupVehicle: Vehicle;
};

export type SignUpPayload = {
  __typename?: "SignUpPayload";
  /** Token for new user that can be exchanged for an ID token and a refresh token. */
  loginToken: Scalars["String"];
  /** Newly created user */
  newUser: User;
};

export type SignUpWithNewBusinessInput = {
  business: CreateBusinessInput;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type Solvent = {
  __typename?: "Solvent";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SolventApplication = {
  __typename?: "SolventApplication";
  density?: Maybe<Scalars["Float"]>;
  dilutionFactor?: Maybe<Scalars["Float"]>;
  lot?: Maybe<Scalars["String"]>;
  masses: Array<Scalars["Float"]>;
  solventUsed?: Maybe<Solvent>;
  totalDilutionFactor?: Maybe<Scalars["Float"]>;
  volume?: Maybe<Scalars["Float"]>;
};

export type StandardOperatingProcedure = {
  __typename?: "StandardOperatingProcedure";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type StandardOperatingProcedureWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
};

export type StateReportingRegulation = {
  __typename?: "StateReportingRegulation";
  actionLimit?: Maybe<Scalars["Float"]>;
  actionLimitPerPackage?: Maybe<Scalars["Float"]>;
  actionLimitPerServing?: Maybe<Scalars["Float"]>;
  referenceComponent: ReferenceComponent;
  volumeUnitOfMeasure?: Maybe<Scalars["String"]>;
  weightUnitOfMeasure?: Maybe<Scalars["String"]>;
};

export type StateReportingRegulationReferenceComponentIdComplianceSampleTypeIdCompoundUniqueInput =
  {
    complianceSampleTypeId: Scalars["String"];
    referenceComponentId: Scalars["String"];
  };

export type StateReportingRegulationWhereUniqueInput = {
  referenceComponentId_complianceSampleTypeId?: Maybe<StateReportingRegulationReferenceComponentIdComplianceSampleTypeIdCompoundUniqueInput>;
};

export type StringFilter = {
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  equals?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type StringListFilter = {
  contains?: Maybe<Scalars["String"]>;
};

export type UpdateSampleInput = {
  complianceSampleTypeId?: Maybe<Scalars["ID"]>;
  customerNotes?: Maybe<Scalars["String"]>;
  metrcPackageUID?: Maybe<Scalars["String"]>;
  orderedPanelOverrides?: Maybe<Array<OrderedPanelOverrideInput>>;
  reportTemplateOptionId?: Maybe<Scalars["ID"]>;
  sampleMatrixId?: Maybe<Scalars["String"]>;
  sourceMaterial?: Maybe<SampleSourceMaterial>;
  unitOfMeasure?: Maybe<Scalars["String"]>;
  weightOrCount?: Maybe<Scalars["Float"]>;
};

export type UpdateUserInput = {
  businessId?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type UpsertAliquotInput = {
  amount: Scalars["Float"];
  panelId: Scalars["ID"];
  unitOfMeasure: Scalars["String"];
};

export type UpsertForeignMaterialInfoInput = {
  analysisComplete?: Maybe<Scalars["Boolean"]>;
  images?: Maybe<Array<CreateFileInput>>;
  inspectionResult?: Maybe<PassOrFail>;
  instrumentId: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  sampleId: Scalars["ID"];
};

export type UpsertMicrobialResultsInput = {
  analysisComplete?: Maybe<Scalars["Boolean"]>;
  incubationStarted?: Maybe<Scalars["Boolean"]>;
  instrumentId?: Maybe<Scalars["ID"]>;
  pipette1Id?: Maybe<Scalars["ID"]>;
  pipette2Id?: Maybe<Scalars["ID"]>;
  pipette3Id?: Maybe<Scalars["ID"]>;
  results?: Maybe<Scalars["Json"]>;
  sampleId: Scalars["ID"];
  tsbVolume?: Maybe<Scalars["Float"]>;
};

export type UpsertMoistureContentResultsInput = {
  analysisComplete?: Maybe<Scalars["Boolean"]>;
  instrumentId: Scalars["ID"];
  moistureContent?: Maybe<Scalars["Float"]>;
  sampleId: Scalars["ID"];
};

export type UpsertSampleAliquotsInput = {
  aliquots?: Maybe<Array<UpsertAliquotInput>>;
  balanceId: Scalars["ID"];
  sampleId: Scalars["ID"];
};

export type UpsertSampleHomogenizationInput = {
  balanceId: Scalars["ID"];
  completed?: Maybe<Scalars["Boolean"]>;
  grossAmount?: Maybe<CreateMeasurementInput>;
  intakeAmount?: Maybe<CreateMeasurementInput>;
  method?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  sampleId: Scalars["ID"];
};

export type UpsertSamplePanelTestPrepInput = {
  aliquotVolume?: Maybe<Scalars["Float"]>;
  dispenserId?: Maybe<Scalars["String"]>;
  finalVolume?: Maybe<Scalars["Float"]>;
  goldSpikeVolume?: Maybe<Scalars["Float"]>;
  incubationStarted?: Maybe<Scalars["Boolean"]>;
  notes?: Maybe<Scalars["String"]>;
  pipette1Id?: Maybe<Scalars["ID"]>;
  pipette2Id?: Maybe<Scalars["ID"]>;
  pipette3Id?: Maybe<Scalars["ID"]>;
  referencePanelId: Scalars["ID"];
  sampleId: Scalars["ID"];
  samplePrepComplete?: Maybe<Scalars["Boolean"]>;
  solventApplication?: Maybe<CreateSolventApplicationInput>;
  tsbVolume?: Maybe<Scalars["Float"]>;
  vesselNumber?: Maybe<Scalars["String"]>;
};

export enum UrlSchema {
  GcsRef = "GCS_REF",
  Http = "HTTP",
}

export type User = {
  __typename?: "User";
  active: Scalars["Boolean"];
  business?: Maybe<Business>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  role: Role;
  signatureFile?: Maybe<File>;
  title?: Maybe<Scalars["String"]>;
};

export type Vehicle = {
  __typename?: "Vehicle";
  id: Scalars["ID"];
  licensePlateNumber: Scalars["String"];
  make: Scalars["String"];
  model: Scalars["String"];
};

export type AcceptSampleMutationVariables = Exact<{
  input: SampleActionInput;
}>;

export type AcceptSampleMutation = {
  __typename?: "Mutation";
  acceptSample: {
    __typename?: "Sample";
    id: string;
    sampleId?: Maybe<number>;
    status: SampleStatus;
  };
};

export type AccessionSampleMutationVariables = Exact<{
  input: AccessionSampleInput;
}>;

export type AccessionSampleMutation = {
  __typename?: "Mutation";
  accessionSample: {
    __typename?: "Sample";
    id: string;
    status: SampleStatus;
    sourceMaterial: SampleSourceMaterial;
    lotOrBatchNumber: string;
    weightOrCount?: Maybe<number>;
    reportTemplateOption: { __typename?: "ReportTemplateOption"; id: string };
    matrix: {
      __typename?: "SampleMatrix";
      sampleCategory: {
        __typename?: "SampleCategory";
        intendedUse: { __typename?: "IntendedUse" } & IntendedUseFieldsFragment;
      } & SampleCategoryFieldsFragment;
    } & SampleMatrixFieldsFragment;
    metrcPackage?: Maybe<{
      __typename?: "MetrcPackage";
      packageLabel: string;
      sourcePackageLabels?: Maybe<string>;
    }>;
    photo?: Maybe<{ __typename?: "File"; id: string; url: string }>;
    processSteps: Array<{
      __typename?: "ProcessStep";
      id: string;
      required: boolean;
      inProgress: boolean;
      completed: boolean;
    }>;
  };
};

export type AccessionOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AccessionOptionsQuery = {
  __typename?: "Query";
  intendedUses: Array<
    {
      __typename?: "IntendedUse";
      sampleCategories: Array<
        {
          __typename?: "SampleCategory";
          sampleMatrices: Array<
            { __typename?: "SampleMatrix" } & SampleMatrixFieldsFragment
          >;
        } & SampleCategoryFieldsFragment
      >;
    } & IntendedUseFieldsFragment
  >;
  availablePanels: Array<
    { __typename?: "ReferencePanel" } & PanelFieldsFragment
  >;
  complianceSampleTypes: Array<
    { __typename?: "ComplianceSampleType" } & ComplianceSampleTypeFieldsFragment
  >;
};

export type CorrespondingMetrcSourcePackageUidQueryVariables = Exact<{
  metrcPackageUID: Scalars["String"];
}>;

export type CorrespondingMetrcSourcePackageUidQuery = {
  __typename?: "Query";
  correspondingMetrcSourcePackageUID?: Maybe<string>;
};

export type CreateProducerMutationVariables = Exact<{
  input: CreatePartnerInput;
}>;

export type CreateProducerMutation = {
  __typename?: "Mutation";
  createProducer: {
    __typename?: "Producer";
    id: string;
    name: string;
    licenseNumber: string;
    address: {
      __typename?: "Address";
      id: string;
      street1: string;
      city: string;
      state: string;
      zip: string;
    };
  };
};

export type CreateDistributorMutationVariables = Exact<{
  input: CreatePartnerInput;
}>;

export type CreateDistributorMutation = {
  __typename?: "Mutation";
  createDistributor: {
    __typename?: "Distributor";
    id: string;
    name: string;
    licenseNumber: string;
    address: {
      __typename?: "Address";
      id: string;
      street1: string;
      city: string;
      state: string;
      zip: string;
    };
  };
};

export type AliquotLogQueryVariables = Exact<{
  where?: Maybe<SampleWhereInput>;
}>;

export type AliquotLogQuery = {
  __typename?: "Query";
  samples: Array<{ __typename?: "Sample" } & AliquotLogEntryFragment>;
  availablePanels: Array<
    { __typename?: "ReferencePanel" } & PanelFieldsFragment
  >;
};

export type UpsertAliquotsMutationVariables = Exact<{
  input: UpsertSampleAliquotsInput;
}>;

export type UpsertAliquotsMutation = {
  __typename?: "Mutation";
  upsertSampleAliquots: { __typename?: "Sample" } & AliquotLogEntryFragment;
};

export type AliquotLogEntryFragment = {
  __typename?: "Sample";
  aliquots: Array<{ __typename?: "SampleAliquot" } & AliquotFieldsFragment>;
  accession?: Maybe<{ __typename?: "SampleAccession" } & RushFieldsFragment>;
} & BaseSampleFieldsFragment;

export type AliquotFieldsFragment = {
  __typename?: "SampleAliquot";
  panel: { __typename?: "ReferencePanel" } & PanelFieldsFragment;
  balanceUsed: { __typename?: "Balance" } & BalanceFieldsFragment;
  measurement: { __typename?: "Measurement" } & MeasurementFieldsFragment;
};

export type ApproveSampleReportMutationVariables = Exact<{
  sampleId: Scalars["ID"];
}>;

export type ApproveSampleReportMutation = {
  __typename?: "Mutation";
  approveSampleReport: { __typename?: "Sample"; id: string };
};

export type ApproveSampleQaMutationVariables = Exact<{
  sampleId: Scalars["ID"];
}>;

export type ApproveSampleQaMutation = {
  __typename?: "Mutation";
  approveSampleQA: { __typename?: "Sample"; id: string };
};

export type BusinessesQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessesQuery = {
  __typename?: "Query";
  businesses: Array<{ __typename?: "Business" } & BusinessFieldsFragment>;
};

export type BusinessPartnersQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessPartnersQuery = {
  __typename?: "Query";
  me?: Maybe<{
    __typename?: "User";
    id: string;
    business?: Maybe<{
      __typename?: "Business";
      id: string;
      producers: Array<{
        __typename?: "Producer";
        id: string;
        name: string;
        licenseNumber: string;
        address: {
          __typename?: "Address";
          street1: string;
          city: string;
          state: string;
          zip: string;
        };
      }>;
      distributors: Array<{
        __typename?: "Distributor";
        id: string;
        name: string;
        licenseNumber: string;
        address: {
          __typename?: "Address";
          street1: string;
          city: string;
          state: string;
          zip: string;
        };
      }>;
    }>;
  }>;
};

export type CancelSampleMutationVariables = Exact<{
  input: SampleActionInput;
}>;

export type CancelSampleMutation = {
  __typename?: "Mutation";
  cancelSample: { __typename?: "Sample" } & BaseSampleFieldsFragment;
};

export type ConfirmPanelResultMutationVariables = Exact<{
  input: ConfirmPanelResultsInput;
  panelId: Scalars["String"];
}>;

export type ConfirmPanelResultMutation = {
  __typename?: "Mutation";
  confirmPanelResults: {
    __typename?: "Sample";
    id: string;
    panelResults: Array<
      { __typename?: "SamplePanelResult" } & PanelResultsFieldsFragment
    >;
  };
};

export type AvailableInstrumentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AvailableInstrumentsQuery = {
  __typename?: "Query";
  availableInstruments: Array<
    { __typename?: "Instrument" } & InstrumentFieldsFragment
  >;
};

export type InstrumentFieldsFragment = {
  __typename?: "Instrument";
  id: string;
  name: string;
};

export type AvailableBalancesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableBalancesQuery = {
  __typename?: "Query";
  availableBalances: Array<{ __typename?: "Balance" } & BalanceFieldsFragment>;
};

export type BalanceFieldsFragment = {
  __typename?: "Balance";
  id: string;
  name: string;
};

export type AvailableSolventsQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableSolventsQuery = {
  __typename?: "Query";
  availableSolvents: Array<{ __typename?: "Solvent" } & SolventFieldsFragment>;
};

export type SolventFieldsFragment = {
  __typename?: "Solvent";
  id: string;
  name: string;
};

export type AvailablePipettesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailablePipettesQuery = {
  __typename?: "Query";
  availablePipettes: Array<{ __typename?: "Pipette" } & PipetteFieldsFragment>;
};

export type PipetteFieldsFragment = {
  __typename?: "Pipette";
  id: string;
  name: string;
  type: PipetteType;
};

export type AvailableDispensersQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableDispensersQuery = {
  __typename?: "Query";
  availableDispensers: Array<
    { __typename?: "Dispenser" } & DispenserFieldsFragment
  >;
};

export type DispenserFieldsFragment = {
  __typename?: "Dispenser";
  id: string;
  name: string;
};

export type UpsertForeignMaterialResultMutationVariables = Exact<{
  input: UpsertForeignMaterialInfoInput;
  panelId: Scalars["String"];
}>;

export type UpsertForeignMaterialResultMutation = {
  __typename?: "Mutation";
  upsertForeignMaterialInfo: { __typename?: "Sample" } & PanelLogFieldsFragment;
};

export type UpsertForeignMaterialInfoResultFragment = {
  __typename?: "Sample";
  testPreps: Array<
    { __typename?: "SamplePanelTestPrep" } & TestPrepFieldsFragment
  >;
  panelResults: Array<
    {
      __typename?: "SamplePanelResult";
      results: any;
      panel: { __typename?: "ReferencePanel" } & PanelFieldsFragment;
    } & ForeignMaterialResultsFieldsFragment
  >;
} & BaseSampleFieldsFragment;

export type AddressFieldsFragment = {
  __typename?: "Address";
  id: string;
  street1: string;
  city: string;
  state: string;
  zip: string;
};

export type BusinessFieldsFragment = {
  __typename?: "Business";
  id: string;
  name: string;
  address: { __typename?: "Address" } & AddressFieldsFragment;
};

export type ProcessStepFieldsFragment = {
  __typename?: "ProcessStep";
  id: string;
  name: string;
  required: boolean;
  inProgress: boolean;
  completed: boolean;
};

export type ProducerFieldsFragment = {
  __typename?: "Producer";
  id: string;
  name: string;
  licenseNumber: string;
  address: { __typename?: "Address" } & AddressFieldsFragment;
};

export type DistributorFieldsFragment = {
  __typename?: "Distributor";
  id: string;
  name: string;
  licenseNumber: string;
  address: { __typename?: "Address" } & AddressFieldsFragment;
};

export type UserNameFragment = {
  __typename?: "User";
  firstName: string;
  lastName: string;
};

export type MeasurementFieldsFragment = {
  __typename?: "Measurement";
  id: string;
  value: number;
  units: string;
};

export type FileFieldsFragment = {
  __typename?: "File";
  id: string;
  url: string;
  urlSchema: UrlSchema;
};

export type ComplianceSampleTypeFieldsFragment = {
  __typename?: "ComplianceSampleType";
  id: string;
  name: string;
};

export type HomogenizationLogQueryVariables = Exact<{
  where?: Maybe<SampleWhereInput>;
}>;

export type HomogenizationLogQuery = {
  __typename?: "Query";
  samples: Array<{ __typename?: "Sample" } & HomogenizationLogEntryFragment>;
  availablePanels: Array<
    { __typename?: "ReferencePanel" } & PanelFieldsFragment
  >;
};

export type UpsertHomogenizationMutationVariables = Exact<{
  input: UpsertSampleHomogenizationInput;
}>;

export type UpsertHomogenizationMutation = {
  __typename?: "Mutation";
  upsertSampleHomogenization: {
    __typename?: "Sample";
  } & HomogenizationLogEntryFragment;
};

export type HomogenizationLogEntryFragment = {
  __typename?: "Sample";
  homogenization?: Maybe<
    { __typename?: "SampleHomogenization" } & HomogenizationFieldsFragment
  >;
  accession?: Maybe<
    {
      __typename?: "SampleAccession";
      isPrepackaged: boolean;
      amountPerServing?: Maybe<
        { __typename?: "Measurement" } & MeasurementFieldsFragment
      >;
      amountPerPackage?: Maybe<
        { __typename?: "Measurement" } & MeasurementFieldsFragment
      >;
    } & RushFieldsFragment
  >;
} & BaseSampleFieldsFragment;

export type HomogenizationFieldsFragment = {
  __typename?: "SampleHomogenization";
  method?: Maybe<string>;
  notes?: Maybe<string>;
  complete: boolean;
  completedAt?: Maybe<any>;
  balance: { __typename?: "Balance" } & BalanceFieldsFragment;
  intakeMeasurement?: Maybe<
    { __typename?: "Measurement" } & MeasurementFieldsFragment
  >;
  grossMeasurement?: Maybe<
    { __typename?: "Measurement" } & MeasurementFieldsFragment
  >;
  completedBy?: Maybe<{ __typename?: "User"; id: string } & UserNameFragment>;
};

export type IntendedUsesQueryVariables = Exact<{ [key: string]: never }>;

export type IntendedUsesQuery = {
  __typename?: "Query";
  intendedUses: Array<
    {
      __typename?: "IntendedUse";
      sampleCategories: Array<
        {
          __typename?: "SampleCategory";
          sampleMatrices: Array<
            { __typename?: "SampleMatrix" } & SampleMatrixFieldsFragment
          >;
        } & SampleCategoryFieldsFragment
      >;
    } & IntendedUseFieldsFragment
  >;
};

export type LoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type LoggedInUserQuery = {
  __typename?: "Query";
  me?: Maybe<{ __typename?: "User" } & BasicUserFieldsFragment>;
};

export type BasicUserFieldsFragment = {
  __typename?: "User";
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: {
    __typename?: "Role";
    id: string;
    name: string;
    permissions?: Maybe<Array<Maybe<string>>>;
  };
  business?: Maybe<{ __typename?: "Business" } & BusinessFieldsFragment>;
};

export type UpsertMicrobialResultsMutationVariables = Exact<{
  microbialInput: UpsertMicrobialResultsInput;
  aliquotInput: UpsertSampleAliquotsInput;
  panelId: Scalars["String"];
}>;

export type UpsertMicrobialResultsMutation = {
  __typename?: "Mutation";
  upsertSampleAliquots: { __typename?: "Sample"; id: string };
  upsertMicrobialResults: { __typename?: "Sample" } & PanelLogFieldsFragment;
};

export type UpsertMoistureContentResultsMutationVariables = Exact<{
  moistureInput: UpsertMoistureContentResultsInput;
  aliquotInput: UpsertSampleAliquotsInput;
  panelId: Scalars["String"];
}>;

export type UpsertMoistureContentResultsMutation = {
  __typename?: "Mutation";
  upsertSampleAliquots: { __typename?: "Sample"; id: string };
  upsertMoistureContentResults: {
    __typename?: "Sample";
  } & PanelLogFieldsFragment;
};

export type InitiatePanelProcessingJobMutationVariables = Exact<{
  input: InitiatePanelProcessingJobInput;
}>;

export type InitiatePanelProcessingJobMutation = {
  __typename?: "Mutation";
  initiatePanelProcessingJob: {
    __typename?: "PanelProcessingJob";
  } & JobFieldsFragment;
};

export type PanelProcessingJobsQueryVariables = Exact<{ [key: string]: never }>;

export type PanelProcessingJobsQuery = {
  __typename?: "Query";
  panelProcessingJobs: Array<
    { __typename?: "PanelProcessingJob" } & JobFieldsFragment
  >;
};

export type JobFieldsFragment = {
  __typename?: "PanelProcessingJob";
  id: string;
  status: PanelProcessingJobStatus;
  startedAt: any;
  completedAt?: Maybe<any>;
  failureReason?: Maybe<string>;
  batch: { __typename?: "SampleBatch" } & BatchFieldsFragment;
  initiatedBy: { __typename?: "User"; id: string } & UserNameFragment;
};

export type BatchFieldsFragment = {
  __typename?: "SampleBatch";
  id: string;
  notes?: Maybe<string>;
  panel: { __typename?: "ReferencePanel" } & PanelFieldsFragment;
  instrument: { __typename?: "Instrument" } & InstrumentFieldsFragment;
};

export type AvailablePanelsQueryVariables = Exact<{ [key: string]: never }>;

export type AvailablePanelsQuery = {
  __typename?: "Query";
  availablePanels: Array<
    { __typename?: "ReferencePanel" } & PanelFieldsFragment
  >;
};

export type PanelFieldsFragment = {
  __typename?: "ReferencePanel";
  id: string;
  name: string;
};

export type SubmitPastCoAsRequestMutationVariables = Exact<{
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
}>;

export type SubmitPastCoAsRequestMutation = {
  __typename?: "Mutation";
  submitPastCOAsRequest?: Maybe<boolean>;
};

export type PickupLogOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type PickupLogOrdersQuery = {
  __typename?: "Query";
  orders: Array<{ __typename?: "Order" } & PickupLogOrderFieldsFragment>;
};

export type SchedulePickupMutationVariables = Exact<{
  input: SchedulePickupInput;
}>;

export type SchedulePickupMutation = {
  __typename?: "Mutation";
  schedulePickup: { __typename?: "Order" } & PickupLogOrderFieldsFragment;
};

export type MarkOutForPickupMutationVariables = Exact<{
  input: PickupActionInput;
}>;

export type MarkOutForPickupMutation = {
  __typename?: "Mutation";
  markOutForPickup: { __typename?: "Order" } & PickupLogOrderFieldsFragment;
};

export type MarkInTransitToLabMutationVariables = Exact<{
  input: PickupActionInput;
}>;

export type MarkInTransitToLabMutation = {
  __typename?: "Mutation";
  markInTransitToLab: { __typename?: "Order" } & PickupLogOrderFieldsFragment;
};

export type LinkCocPaperworkMutationVariables = Exact<{
  input: LinkCocPaperworkInput;
}>;

export type LinkCocPaperworkMutation = {
  __typename?: "Mutation";
  linkCOCPaperwork: { __typename?: "Order" } & PickupLogOrderFieldsFragment;
};

export type PickupVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type PickupVehiclesQuery = {
  __typename?: "Query";
  vehicles: Array<{ __typename?: "Vehicle" } & VehicleFieldsFragment>;
};

export type FieldTechniciansQueryVariables = Exact<{ [key: string]: never }>;

export type FieldTechniciansQuery = {
  __typename?: "Query";
  fieldTechnicians: Array<
    { __typename?: "User" } & FieldTechnicianFieldsFragment
  >;
};

export type PickupLogOrderFieldsFragment = {
  __typename?: "Order";
  id: string;
  shortId: string;
  status?: Maybe<string>;
  readyForPickupDate?: Maybe<any>;
  scheduledPickup?: Maybe<
    { __typename?: "ScheduledPickup" } & ScheduledPickupFieldsFragment
  >;
  samples: Array<{ __typename?: "Sample"; id: string }>;
  sampleSubmission?: Maybe<{
    __typename?: "SampleSubmission";
    id: string;
    submittedAt: any;
    submittedBy: {
      __typename?: "User";
      id: string;
      business?: Maybe<{ __typename?: "Business"; id: string; name: string }>;
    };
  }>;
};

export type ScheduledPickupFieldsFragment = {
  __typename?: "ScheduledPickup";
  id: string;
  businessLicenseNumber: string;
  onSiteContactName: string;
  contactPhoneNumber: string;
  dateTime: any;
  address: { __typename?: "Address" } & AddressFieldsFragment;
  fieldTechnician: { __typename?: "User" } & FieldTechnicianFieldsFragment;
  pickupVehicle: { __typename?: "Vehicle" } & VehicleFieldsFragment;
};

export type FieldTechnicianFieldsFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  lastName: string;
};

export type VehicleFieldsFragment = {
  __typename?: "Vehicle";
  id: string;
  make: string;
  model: string;
  licensePlateNumber: string;
};

export type RoleFieldsFragment = {
  __typename?: "Role";
  id: string;
  name: string;
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = {
  __typename?: "Query";
  roles: Array<{ __typename?: "Role" } & RoleFieldsFragment>;
};

export type SampleBccInfoQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SampleBccInfoQuery = {
  __typename?: "Query";
  sample?: Maybe<{ __typename?: "Sample" } & SampleBccInfoFieldsFragment>;
};

export type SampleBccInfoFieldsFragment = {
  __typename?: "Sample";
  id: string;
  sampleApproval?: Maybe<{ __typename?: "SampleApproval"; approvedAt: any }>;
  accession?: Maybe<{
    __typename?: "SampleAccession";
    amountPerServing?: Maybe<
      { __typename?: "Measurement" } & MeasurementFieldsFragment
    >;
    amountPerPackage?: Maybe<
      { __typename?: "Measurement" } & MeasurementFieldsFragment
    >;
  }>;
  metrcPackage?: Maybe<{
    __typename?: "MetrcPackage";
    sourcePackageLabels?: Maybe<string>;
    packageLabel: string;
  }>;
  matrix: {
    __typename?: "SampleMatrix";
    sampleCategory: {
      __typename?: "SampleCategory";
      caMetrcAnalytes: Array<
        { __typename?: "CaMetrcAnalyte" } & CaMetrcAnalyteFieldsFragment
      >;
    };
  };
  panelResults: Array<
    {
      __typename?: "SamplePanelResult";
      panel: {
        __typename?: "ReferencePanel";
        id: string;
        name: string;
        instrumentParameters: Array<{
          __typename?: "InstrumentParameter";
          levelOfDetection?: Maybe<number>;
          levelOfQuantitation?: Maybe<number>;
          referenceComponent: {
            __typename?: "ReferenceComponent";
          } & ReferenceComponentFieldsFragment;
        }>;
      };
    } & PanelResultFieldsFragment
  >;
  complianceType?: Maybe<{
    __typename?: "ComplianceSampleType";
    name: string;
    stateReportingRegulations: Array<
      {
        __typename?: "StateReportingRegulation";
      } & SampleBccStateReportingRegulationFragmentFragment
    >;
  }>;
};

export type CaMetrcAnalyteFieldsFragment = {
  __typename?: "CaMetrcAnalyte";
  display: string;
  reportingFormat: string;
  referenceComponent: {
    __typename?: "ReferenceComponent";
  } & ReferenceComponentFieldsFragment;
};

export type SampleBccStateReportingRegulationFragmentFragment = {
  __typename?: "StateReportingRegulation";
  referenceComponent: {
    __typename?: "ReferenceComponent";
  } & ReferenceComponentFieldsFragment;
} & StateReportingRegulationFieldsFragment;

export type PanelResultFieldsFragment = {
  __typename?: "SamplePanelResult";
  results: any;
};

export type SampleReportDataQueryVariables = Exact<{
  sampleId: Scalars["ID"];
}>;

export type SampleReportDataQuery = {
  __typename?: "Query";
  sample?: Maybe<{
    __typename?: "Sample";
    id: string;
    sampleId?: Maybe<number>;
    productName: string;
    lotOrBatchNumber: string;
    harvestOrProductionDate?: Maybe<any>;
    weightOrCount?: Maybe<number>;
    unitOfMeasure?: Maybe<string>;
    isCompliance: boolean;
    receivedAt?: Maybe<any>;
    reportFirstPage?: Maybe<string>;
    metrcPackage?: Maybe<{
      __typename?: "MetrcPackage";
      sourcePackageLabels?: Maybe<string>;
      packageLabel: string;
    }>;
    photo?: Maybe<{ __typename?: "File"; url: string; urlSchema: UrlSchema }>;
    qrCode?: Maybe<{ __typename?: "File"; url: string; urlSchema: UrlSchema }>;
    reportTemplateOption: {
      __typename?: "ReportTemplateOption";
      reportTemplate: { __typename?: "ReportTemplate"; name: string };
    };
    matrix: {
      __typename?: "SampleMatrix";
      sampleCategory: {
        __typename?: "SampleCategory";
      } & SampleCategoryFieldsFragment;
    } & SampleMatrixFieldsFragment;
    distributor?: Maybe<
      { __typename?: "Distributor" } & DistributorFieldsFragment
    >;
    producer?: Maybe<{ __typename?: "Producer" } & ProducerFieldsFragment>;
    orderedPanels: Array<{
      __typename?: "ReferencePanel";
      id: string;
      name: string;
      standardOperatingProcedures: Array<{
        __typename?: "StandardOperatingProcedure";
        id: string;
        name: string;
      }>;
    }>;
    order: {
      __typename?: "Order";
      id: string;
      scheduledPickup?: Maybe<{
        __typename?: "ScheduledPickup";
        dateTime: any;
      }>;
      sampleSubmission?: Maybe<{
        __typename?: "SampleSubmission";
        id: string;
        submittedBy: {
          __typename?: "User";
          id: string;
          business?: Maybe<
            { __typename?: "Business" } & BusinessFieldsFragment
          >;
        };
      }>;
    };
    accession?: Maybe<{
      __typename?: "SampleAccession";
      accessionedAt: any;
      amountPerServing?: Maybe<
        { __typename?: "Measurement" } & MeasurementFieldsFragment
      >;
      amountPerPackage?: Maybe<
        { __typename?: "Measurement" } & MeasurementFieldsFragment
      >;
      accessionAmount?: Maybe<
        { __typename?: "Measurement" } & MeasurementFieldsFragment
      >;
    }>;
    homogenization?: Maybe<{
      __typename?: "SampleHomogenization";
      intakeMeasurement?: Maybe<
        { __typename?: "Measurement" } & MeasurementFieldsFragment
      >;
    }>;
    sampleApproval?: Maybe<{
      __typename?: "SampleApproval";
      approvedAt: any;
      approvedBy: {
        __typename?: "User";
        title?: Maybe<string>;
        signatureFile?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
      } & UserNameFragment &
        UserNameFragment;
    }>;
    complianceType?: Maybe<{
      __typename?: "ComplianceSampleType";
      id: string;
      name: string;
      stateReportingRegulations: Array<
        {
          __typename?: "StateReportingRegulation";
          referenceComponent: {
            __typename?: "ReferenceComponent";
          } & ReferenceComponentFieldsFragment;
        } & StateReportingRegulationFieldsFragment
      >;
    }>;
    panelResults: Array<{
      __typename?: "SamplePanelResult";
      results: any;
      completedAt?: Maybe<any>;
      instrumentUsed: {
        __typename?: "Instrument";
        instrumentParameters: Array<
          {
            __typename?: "InstrumentParameter";
            referenceComponent: {
              __typename?: "ReferenceComponent";
            } & ReferenceComponentFieldsFragment;
            panel: { __typename?: "ReferencePanel" } & PanelFieldsFragment;
          } & InstrumentParameterFieldsFragment
        >;
      } & InstrumentFieldsFragment;
      panel: {
        __typename?: "ReferencePanel";
        standardOperatingProcedures: Array<
          { __typename?: "StandardOperatingProcedure" } & SopFieldsFragment
        >;
      } & PanelFieldsFragment;
    }>;
    sampleQAEntry?: Maybe<{
      __typename?: "SampleQAEntry";
      verifiedAt: any;
      verifiedBy: {
        __typename?: "User";
        title?: Maybe<string>;
        signatureFile?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
      } & UserNameFragment;
    }>;
  }>;
};

export type ReleaseReportToClientMutationVariables = Exact<{
  sampleId: Scalars["ID"];
}>;

export type ReleaseReportToClientMutation = {
  __typename?: "Mutation";
  releaseReport: {
    __typename?: "Sample";
    id: string;
    status: SampleStatus;
    reportReleasedToClient: boolean;
  };
};

export type ReferenceComponentFieldsFragment = {
  __typename?: "ReferenceComponent";
  id: string;
  rawName: string;
  displayName: string;
};

export type SopFieldsFragment = {
  __typename?: "StandardOperatingProcedure";
  id: string;
  name: string;
};

export type StateReportingRegulationFieldsFragment = {
  __typename?: "StateReportingRegulation";
  actionLimit?: Maybe<number>;
  actionLimitPerPackage?: Maybe<number>;
  actionLimitPerServing?: Maybe<number>;
  weightUnitOfMeasure?: Maybe<string>;
  volumeUnitOfMeasure?: Maybe<string>;
};

export type InstrumentParameterFieldsFragment = {
  __typename?: "InstrumentParameter";
  id: string;
  displayOnCOA: boolean;
  weightUnitOfMeasure: string;
  volumeUnitOfMeasure: string;
  levelOfDetection?: Maybe<number>;
  levelOfQuantitation?: Maybe<number>;
};

export type SampleTrackerQueryVariables = Exact<{ [key: string]: never }>;

export type SampleTrackerQuery = {
  __typename?: "Query";
  me?: Maybe<{
    __typename?: "User";
    id: string;
    business?: Maybe<{
      __typename?: "Business";
      id: string;
      samples: Array<{ __typename?: "Sample" } & SampleTrackerFieldsFragment>;
    }>;
  }>;
};

export type SampleTrackerFieldsFragment = {
  __typename?: "Sample";
  lotOrBatchNumber: string;
  reportReleasedToClient: boolean;
  metrcPackage?: Maybe<{
    __typename?: "MetrcPackage";
    sourcePackageLabels?: Maybe<string>;
    packageLabel: string;
  }>;
  photo?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
  qrCode?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
  report?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
  matrix: {
    __typename?: "SampleMatrix";
    sampleCategory: {
      __typename?: "SampleCategory";
    } & SampleCategoryFieldsFragment;
  } & SampleMatrixFieldsFragment;
  processSteps: Array<{
    __typename?: "ProcessStep";
    id: string;
    required: boolean;
  }>;
  sampleApproval?: Maybe<{ __typename?: "SampleApproval"; approvedAt: any }>;
} & BaseSampleFieldsFragment;

export type SampleLogQueryVariables = Exact<{
  where?: Maybe<SampleWhereInput>;
}>;

export type SampleLogQuery = {
  __typename?: "Query";
  samples: Array<
    {
      __typename?: "Sample";
      status: SampleStatus;
      receivedAt?: Maybe<any>;
      isCompliance: boolean;
      customerNotes: string;
      reportReleasedToClient: boolean;
      matrix: {
        __typename?: "SampleMatrix";
        sampleCategory: {
          __typename?: "SampleCategory";
          intendedUse: {
            __typename?: "IntendedUse";
          } & IntendedUseFieldsFragment;
        } & SampleCategoryFieldsFragment;
      } & SampleMatrixFieldsFragment;
      processSteps: Array<
        { __typename?: "ProcessStep" } & ProcessStepFieldsFragment
      >;
      order: {
        __typename?: "Order";
        id: string;
        shortId: string;
        sampleSubmission?: Maybe<{
          __typename?: "SampleSubmission";
          id: string;
          submittedAt: any;
          submittedBy: {
            __typename?: "User";
            id: string;
            business?: Maybe<{
              __typename?: "Business";
              id: string;
              name: string;
            }>;
          };
        }>;
      };
      accession?: Maybe<{
        __typename?: "SampleAccession";
        isRushOrder: boolean;
        dueDate?: Maybe<any>;
      }>;
      photo?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
      report?: Maybe<{ __typename?: "File" } & FileFieldsFragment>;
    } & BaseSampleFieldsFragment
  >;
};

export type PanelLogQueryVariables = Exact<{
  panelId: Scalars["String"];
  statusFilter?: Maybe<SampleStatusListFilter>;
}>;

export type PanelLogQuery = {
  __typename?: "Query";
  samples: Array<{ __typename?: "Sample" } & PanelLogFieldsFragment>;
};

export type ReadyForQaSamplesQueryVariables = Exact<{
  statusFilter?: Maybe<SampleStatusListFilter>;
}>;

export type ReadyForQaSamplesQuery = {
  __typename?: "Query";
  samples: Array<{
    __typename?: "Sample";
    id: string;
    productName: string;
    sampleId?: Maybe<number>;
    customerNotes: string;
    order: {
      __typename?: "Order";
      sampleSubmission?: Maybe<{
        __typename?: "SampleSubmission";
        submittedAt: any;
        submittedBy: {
          __typename?: "User";
          business?: Maybe<{ __typename?: "Business"; name: string }>;
        };
      }>;
    };
  }>;
};

export type SampleStatisticsQueryVariables = Exact<{
  input?: Maybe<AnalyticsDateInput>;
}>;

export type SampleStatisticsQuery = {
  __typename?: "Query";
  sampleStatistics: {
    __typename?: "AnalyticsPayload";
    sampleCount: number;
    uniqueClientCount?: Maybe<number>;
    completedSampleCount: number;
    complianceSampleCount: number;
    rushedSampleCount?: Maybe<number>;
    averageDaysToCompletion?: Maybe<number>;
    completedClientSampleCount?: Maybe<any>;
    sampleStatusCount: Array<{
      __typename?: "SampleStatusCount";
      count: number;
      status: SampleStatus;
    }>;
    completedSamplesCountByMonth: Array<{
      __typename?: "CompletedSampleCount";
      month: string;
      count: number;
    }>;
  };
};

export type UpsertTestPrepInfoMutationVariables = Exact<{
  testPrepInput: UpsertSamplePanelTestPrepInput;
  aliquotInput: UpsertSampleAliquotsInput;
  panelId: Scalars["String"];
}>;

export type UpsertTestPrepInfoMutation = {
  __typename?: "Mutation";
  upsertSampleAliquots: { __typename?: "Sample"; id: string };
  upsertSamplePanelTestPrep: { __typename?: "Sample" } & PanelLogFieldsFragment;
};

export type UpsertTestPrepUpdateFragment = {
  __typename?: "Sample";
  testPreps: Array<
    { __typename?: "SamplePanelTestPrep" } & TestPrepFieldsFragment
  >;
} & BaseSampleFieldsFragment;

export type PanelLogFieldsFragment = {
  __typename?: "Sample";
  panelResults: Array<
    { __typename?: "SamplePanelResult" } & PanelResultsFieldsFragment
  >;
  aliquots: Array<{ __typename?: "SampleAliquot" } & AliquotFieldsFragment>;
  testPreps: Array<
    { __typename?: "SamplePanelTestPrep" } & TestPrepFieldsFragment
  >;
  accession?: Maybe<{ __typename?: "SampleAccession" } & RushFieldsFragment>;
} & BaseSampleFieldsFragment;

export type PanelResultsFieldsFragment = {
  __typename?: "SamplePanelResult";
  results: any;
  sampleBatch?: Maybe<{
    __typename?: "SampleBatch";
    id: string;
    instrument: { __typename?: "Instrument" } & InstrumentFieldsFragment;
    panelProcessingJob?: Maybe<
      { __typename?: "PanelProcessingJob" } & JobFieldsFragment
    >;
  }>;
  panel: { __typename?: "ReferencePanel" } & PanelFieldsFragment;
  images: Array<{ __typename?: "File" } & FileFieldsFragment>;
} & ForeignMaterialResultsFieldsFragment;

export type RushFieldsFragment = {
  __typename?: "SampleAccession";
  isRushOrder: boolean;
  dueDate?: Maybe<any>;
};

export type ForeignMaterialResultsFieldsFragment = {
  __typename?: "SamplePanelResult";
  analysisComplete?: Maybe<boolean>;
  completedAt?: Maybe<any>;
  completedBy?: Maybe<{ __typename?: "User"; id: string } & UserNameFragment>;
  images: Array<{ __typename?: "File" } & FileFieldsFragment>;
  instrumentUsed: { __typename?: "Instrument" } & InstrumentFieldsFragment;
};

export type BaseSampleFieldsFragment = {
  __typename?: "Sample";
  id: string;
  sampleId?: Maybe<number>;
  productName: string;
  status: SampleStatus;
};

export type TestPrepFieldsFragment = {
  __typename?: "SamplePanelTestPrep";
  notes?: Maybe<string>;
  aliquotVolume?: Maybe<number>;
  tsbVolume?: Maybe<number>;
  vesselNumber?: Maybe<string>;
  finalVolume?: Maybe<number>;
  goldSpikeVolume?: Maybe<number>;
  incubationStarted: boolean;
  incubationStartedAt?: Maybe<any>;
  samplePrepComplete: boolean;
  samplePrepCompletedAt?: Maybe<any>;
  dispenser?: Maybe<{ __typename?: "Dispenser" } & DispenserFieldsFragment>;
  solventApplication?: Maybe<
    { __typename?: "SolventApplication" } & SolventApplicationFieldsFragment
  >;
  pipetteUsages: Array<{
    __typename?: "PipetteUsage";
    index: number;
    pipette: { __typename?: "Pipette" } & PipetteFieldsFragment;
  }>;
  incubationStartedBy?: Maybe<
    { __typename?: "User"; id: string } & UserNameFragment
  >;
  samplePrepCompletedBy?: Maybe<
    { __typename?: "User"; id: string } & UserNameFragment
  >;
};

export type SolventApplicationFieldsFragment = {
  __typename?: "SolventApplication";
  volume?: Maybe<number>;
  masses: Array<number>;
  density?: Maybe<number>;
  dilutionFactor?: Maybe<number>;
  totalDilutionFactor?: Maybe<number>;
  solventUsed?: Maybe<{ __typename?: "Solvent" } & SolventFieldsFragment>;
};

export type SampleQueryVariables = Exact<{
  where: SampleWhereUniqueInput;
}>;

export type SampleQuery = {
  __typename?: "Query";
  sample?: Maybe<
    {
      __typename?: "Sample";
      status: SampleStatus;
      productName: string;
      sourceMaterial: SampleSourceMaterial;
      isCompliance: boolean;
      lotOrBatchNumber: string;
      harvestOrProductionDate?: Maybe<any>;
      weightOrCount?: Maybe<number>;
      unitOfMeasure?: Maybe<string>;
      customerNotes: string;
      readyForPickupDate?: Maybe<any>;
      reportFirstPage?: Maybe<string>;
      reportTemplateOption: { __typename?: "ReportTemplateOption"; id: string };
      matrix: {
        __typename?: "SampleMatrix";
        sampleCategory: {
          __typename?: "SampleCategory";
          intendedUse: {
            __typename?: "IntendedUse";
          } & IntendedUseFieldsFragment;
        } & SampleCategoryFieldsFragment;
      } & SampleMatrixFieldsFragment;
      processSteps: Array<
        { __typename?: "ProcessStep" } & ProcessStepFieldsFragment
      >;
      analysisOptions: Array<{
        __typename?: "AnalysisOption";
        id: string;
        name: string;
        price: number;
      }>;
      deliveryOption: {
        __typename?: "DeliveryOption";
        id: string;
        description: string;
      };
      producer?: Maybe<{ __typename?: "Producer" } & ProducerFieldsFragment>;
      distributor?: Maybe<
        { __typename?: "Distributor" } & DistributorFieldsFragment
      >;
      metrcPackage?: Maybe<{
        __typename?: "MetrcPackage";
        sourcePackageLabels?: Maybe<string>;
        packageLabel: string;
      }>;
      order: {
        __typename?: "Order";
        id: string;
        status?: Maybe<string>;
        shortId: string;
        sampleSubmission?: Maybe<{
          __typename?: "SampleSubmission";
          id: string;
          submittedAt: any;
          submittedBy: {
            __typename?: "User";
            id: string;
            email: string;
            business?: Maybe<
              { __typename?: "Business" } & BusinessFieldsFragment
            >;
          };
        }>;
      };
      photo?: Maybe<{ __typename?: "File"; id: string; url: string }>;
      qrCode?: Maybe<{ __typename?: "File"; id: string; url: string }>;
      accession?: Maybe<{
        __typename?: "SampleAccession";
        isPrepackaged: boolean;
        labelClaim?: Maybe<string>;
        isRushOrder: boolean;
        dueDate?: Maybe<any>;
        accessionAmount?: Maybe<
          { __typename?: "Measurement" } & MeasurementFieldsFragment
        >;
        amountPerServing?: Maybe<
          { __typename?: "Measurement" } & MeasurementFieldsFragment
        >;
        amountPerPackage?: Maybe<
          { __typename?: "Measurement" } & MeasurementFieldsFragment
        >;
        pickupAmount?: Maybe<
          { __typename?: "Measurement" } & MeasurementFieldsFragment
        >;
      }>;
      complianceType?: Maybe<{
        __typename?: "ComplianceSampleType";
        id: string;
        name: string;
      }>;
    } & BaseSampleFieldsFragment
  >;
};

export type SampleMatrixFieldsFragment = {
  __typename?: "SampleMatrix";
  id: string;
  name: string;
};

export type SampleCategoryFieldsFragment = {
  __typename?: "SampleCategory";
  id: string;
  name: string;
};

export type IntendedUseFieldsFragment = {
  __typename?: "IntendedUse";
  id: string;
  name: string;
};

export type SignUpCustomerWithNewBusinessMutationVariables = Exact<{
  input: SignUpWithNewBusinessInput;
}>;

export type SignUpCustomerWithNewBusinessMutation = {
  __typename?: "Mutation";
  signUpWithNewBusiness: {
    __typename?: "SignUpPayload";
    loginToken: string;
    newUser: { __typename?: "User"; id: string };
  };
};

export type SubmitSamplesMutationVariables = Exact<{
  input: CreateSampleSubmissionInput;
}>;

export type SubmitSamplesMutation = {
  __typename?: "Mutation";
  submitSamples: {
    __typename?: "SampleSubmission";
    id: string;
    order: {
      __typename?: "Order";
      id: string;
      samples: Array<{ __typename?: "Sample"; id: string }>;
    };
  };
};

export type UserInfoFieldsFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: Maybe<string>;
  title?: Maybe<string>;
  email: string;
  active: boolean;
  role: { __typename?: "Role"; id: string; name: string };
  business?: Maybe<{ __typename?: "Business"; id: string; name: string }>;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
  __typename?: "Query";
  users: Array<{ __typename?: "User" } & UserInfoFieldsFragment>;
};

export type UserQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type UserQuery = {
  __typename?: "Query";
  user?: Maybe<{ __typename?: "User" } & UserInfoFieldsFragment>;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "User" } & UserInfoFieldsFragment;
};

export type ToggleUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ToggleUserMutation = {
  __typename?: "Mutation";
  toggleUser: { __typename?: "User"; id: string; active: boolean };
};

export const BaseSampleFieldsFragmentDoc = gql`
  fragment BaseSampleFields on Sample {
    id
    sampleId
    productName
    status
  }
`;
export const PanelFieldsFragmentDoc = gql`
  fragment PanelFields on ReferencePanel {
    id
    name
  }
`;
export const BalanceFieldsFragmentDoc = gql`
  fragment BalanceFields on Balance {
    id
    name
  }
`;
export const MeasurementFieldsFragmentDoc = gql`
  fragment MeasurementFields on Measurement {
    id
    value
    units
  }
`;
export const AliquotFieldsFragmentDoc = gql`
  fragment AliquotFields on SampleAliquot {
    panel {
      ...PanelFields
    }
    balanceUsed {
      ...BalanceFields
    }
    measurement {
      ...MeasurementFields
    }
  }
  ${PanelFieldsFragmentDoc}
  ${BalanceFieldsFragmentDoc}
  ${MeasurementFieldsFragmentDoc}
`;
export const RushFieldsFragmentDoc = gql`
  fragment RushFields on SampleAccession {
    isRushOrder
    dueDate
  }
`;
export const AliquotLogEntryFragmentDoc = gql`
  fragment AliquotLogEntry on Sample {
    ...BaseSampleFields
    aliquots {
      ...AliquotFields
    }
    accession {
      ...RushFields
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${AliquotFieldsFragmentDoc}
  ${RushFieldsFragmentDoc}
`;
export const DispenserFieldsFragmentDoc = gql`
  fragment DispenserFields on Dispenser {
    id
    name
  }
`;
export const SolventFieldsFragmentDoc = gql`
  fragment SolventFields on Solvent {
    id
    name
  }
`;
export const SolventApplicationFieldsFragmentDoc = gql`
  fragment SolventApplicationFields on SolventApplication {
    solventUsed {
      ...SolventFields
    }
    volume
    masses
    density
    dilutionFactor
    totalDilutionFactor
  }
  ${SolventFieldsFragmentDoc}
`;
export const PipetteFieldsFragmentDoc = gql`
  fragment PipetteFields on Pipette {
    id
    name
    type
  }
`;
export const UserNameFragmentDoc = gql`
  fragment UserName on User {
    firstName
    lastName
  }
`;
export const TestPrepFieldsFragmentDoc = gql`
  fragment TestPrepFields on SamplePanelTestPrep {
    dispenser {
      ...DispenserFields
    }
    solventApplication {
      ...SolventApplicationFields
    }
    pipetteUsages {
      index
      pipette {
        ...PipetteFields
      }
    }
    notes
    aliquotVolume
    tsbVolume
    vesselNumber
    finalVolume
    goldSpikeVolume
    incubationStarted
    incubationStartedAt
    incubationStartedBy {
      id
      ...UserName
    }
    samplePrepComplete
    samplePrepCompletedAt
    samplePrepCompletedBy {
      id
      ...UserName
    }
  }
  ${DispenserFieldsFragmentDoc}
  ${SolventApplicationFieldsFragmentDoc}
  ${PipetteFieldsFragmentDoc}
  ${UserNameFragmentDoc}
`;
export const FileFieldsFragmentDoc = gql`
  fragment FileFields on File {
    id
    url
    urlSchema
  }
`;
export const InstrumentFieldsFragmentDoc = gql`
  fragment InstrumentFields on Instrument {
    id
    name
  }
`;
export const ForeignMaterialResultsFieldsFragmentDoc = gql`
  fragment ForeignMaterialResultsFields on SamplePanelResult {
    analysisComplete
    completedAt
    completedBy {
      id
      ...UserName
    }
    images {
      ...FileFields
    }
    instrumentUsed {
      ...InstrumentFields
    }
  }
  ${UserNameFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${InstrumentFieldsFragmentDoc}
`;
export const UpsertForeignMaterialInfoResultFragmentDoc = gql`
  fragment UpsertForeignMaterialInfoResult on Sample {
    ...BaseSampleFields
    testPreps(where: { referencePanelId: { equals: $panelId } }) {
      ...TestPrepFields
    }
    panelResults(where: { referencePanelId: { equals: $panelId } }) {
      ...ForeignMaterialResultsFields
      results
      panel {
        ...PanelFields
      }
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${TestPrepFieldsFragmentDoc}
  ${ForeignMaterialResultsFieldsFragmentDoc}
  ${PanelFieldsFragmentDoc}
`;
export const ProcessStepFieldsFragmentDoc = gql`
  fragment ProcessStepFields on ProcessStep {
    id
    name
    required
    inProgress
    completed
  }
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    street1
    city
    state
    zip
  }
`;
export const ProducerFieldsFragmentDoc = gql`
  fragment ProducerFields on Producer {
    id
    name
    licenseNumber
    address {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const DistributorFieldsFragmentDoc = gql`
  fragment DistributorFields on Distributor {
    id
    name
    licenseNumber
    address {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const ComplianceSampleTypeFieldsFragmentDoc = gql`
  fragment ComplianceSampleTypeFields on ComplianceSampleType {
    id
    name
  }
`;
export const HomogenizationFieldsFragmentDoc = gql`
  fragment HomogenizationFields on SampleHomogenization {
    balance {
      ...BalanceFields
    }
    intakeMeasurement {
      ...MeasurementFields
    }
    method
    grossMeasurement {
      ...MeasurementFields
    }
    notes
    complete
    completedBy {
      id
      ...UserName
    }
    completedAt
  }
  ${BalanceFieldsFragmentDoc}
  ${MeasurementFieldsFragmentDoc}
  ${UserNameFragmentDoc}
`;
export const HomogenizationLogEntryFragmentDoc = gql`
  fragment HomogenizationLogEntry on Sample {
    ...BaseSampleFields
    homogenization {
      ...HomogenizationFields
    }
    accession {
      isPrepackaged
      amountPerServing {
        ...MeasurementFields
      }
      amountPerPackage {
        ...MeasurementFields
      }
      ...RushFields
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${HomogenizationFieldsFragmentDoc}
  ${MeasurementFieldsFragmentDoc}
  ${RushFieldsFragmentDoc}
`;
export const BusinessFieldsFragmentDoc = gql`
  fragment BusinessFields on Business {
    id
    name
    address {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const BasicUserFieldsFragmentDoc = gql`
  fragment BasicUserFields on User {
    id
    email
    firstName
    lastName
    role {
      id
      name
      permissions
    }
    business {
      ...BusinessFields
    }
  }
  ${BusinessFieldsFragmentDoc}
`;
export const FieldTechnicianFieldsFragmentDoc = gql`
  fragment FieldTechnicianFields on User {
    id
    firstName
    lastName
  }
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on Vehicle {
    id
    make
    model
    licensePlateNumber
  }
`;
export const ScheduledPickupFieldsFragmentDoc = gql`
  fragment ScheduledPickupFields on ScheduledPickup {
    id
    businessLicenseNumber
    onSiteContactName
    address {
      ...AddressFields
    }
    contactPhoneNumber
    dateTime
    fieldTechnician {
      ...FieldTechnicianFields
    }
    pickupVehicle {
      ...VehicleFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${FieldTechnicianFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const PickupLogOrderFieldsFragmentDoc = gql`
  fragment PickupLogOrderFields on Order {
    id
    shortId
    status
    readyForPickupDate
    scheduledPickup {
      ...ScheduledPickupFields
    }
    samples {
      id
    }
    sampleSubmission {
      id
      submittedAt
      submittedBy {
        id
        business {
          id
          name
        }
      }
    }
  }
  ${ScheduledPickupFieldsFragmentDoc}
`;
export const RoleFieldsFragmentDoc = gql`
  fragment RoleFields on Role {
    id
    name
  }
`;
export const ReferenceComponentFieldsFragmentDoc = gql`
  fragment ReferenceComponentFields on ReferenceComponent {
    id
    rawName
    displayName
  }
`;
export const CaMetrcAnalyteFieldsFragmentDoc = gql`
  fragment CaMetrcAnalyteFields on CaMetrcAnalyte {
    display
    reportingFormat
    referenceComponent {
      ...ReferenceComponentFields
    }
  }
  ${ReferenceComponentFieldsFragmentDoc}
`;
export const PanelResultFieldsFragmentDoc = gql`
  fragment PanelResultFields on SamplePanelResult {
    results
  }
`;
export const StateReportingRegulationFieldsFragmentDoc = gql`
  fragment StateReportingRegulationFields on StateReportingRegulation {
    actionLimit
    actionLimitPerPackage
    actionLimitPerServing
    weightUnitOfMeasure
    volumeUnitOfMeasure
  }
`;
export const SampleBccStateReportingRegulationFragmentFragmentDoc = gql`
  fragment SampleBccStateReportingRegulationFragment on StateReportingRegulation {
    ...StateReportingRegulationFields
    referenceComponent {
      ...ReferenceComponentFields
    }
  }
  ${StateReportingRegulationFieldsFragmentDoc}
  ${ReferenceComponentFieldsFragmentDoc}
`;
export const SampleBccInfoFieldsFragmentDoc = gql`
  fragment SampleBccInfoFields on Sample {
    id
    sampleApproval {
      approvedAt
    }
    accession {
      amountPerServing {
        ...MeasurementFields
      }
      amountPerPackage {
        ...MeasurementFields
      }
    }
    metrcPackage {
      sourcePackageLabels
      packageLabel
    }
    matrix {
      sampleCategory {
        caMetrcAnalytes {
          ...CaMetrcAnalyteFields
        }
      }
    }
    panelResults {
      ...PanelResultFields
      panel {
        id
        name
        instrumentParameters {
          levelOfDetection
          levelOfQuantitation
          referenceComponent {
            ...ReferenceComponentFields
          }
        }
      }
    }
    complianceType {
      name
      stateReportingRegulations {
        ...SampleBccStateReportingRegulationFragment
      }
    }
  }
  ${MeasurementFieldsFragmentDoc}
  ${CaMetrcAnalyteFieldsFragmentDoc}
  ${PanelResultFieldsFragmentDoc}
  ${ReferenceComponentFieldsFragmentDoc}
  ${SampleBccStateReportingRegulationFragmentFragmentDoc}
`;
export const SopFieldsFragmentDoc = gql`
  fragment SOPFields on StandardOperatingProcedure {
    id
    name
  }
`;
export const InstrumentParameterFieldsFragmentDoc = gql`
  fragment InstrumentParameterFields on InstrumentParameter {
    id
    displayOnCOA
    weightUnitOfMeasure
    volumeUnitOfMeasure
    levelOfDetection
    levelOfQuantitation
  }
`;
export const SampleMatrixFieldsFragmentDoc = gql`
  fragment SampleMatrixFields on SampleMatrix {
    id
    name
  }
`;
export const SampleCategoryFieldsFragmentDoc = gql`
  fragment SampleCategoryFields on SampleCategory {
    id
    name
  }
`;
export const SampleTrackerFieldsFragmentDoc = gql`
  fragment SampleTrackerFields on Sample {
    ...BaseSampleFields
    lotOrBatchNumber
    metrcPackage {
      sourcePackageLabels
      packageLabel
    }
    photo {
      ...FileFields
    }
    qrCode {
      ...FileFields
    }
    report {
      ...FileFields
    }
    reportReleasedToClient
    matrix {
      ...SampleMatrixFields
      sampleCategory {
        ...SampleCategoryFields
      }
    }
    processSteps {
      id
      required
    }
    sampleApproval {
      approvedAt
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${SampleMatrixFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
`;
export const UpsertTestPrepUpdateFragmentDoc = gql`
  fragment UpsertTestPrepUpdate on Sample {
    ...BaseSampleFields
    testPreps(where: { referencePanelId: { equals: $panelId } }) {
      ...TestPrepFields
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${TestPrepFieldsFragmentDoc}
`;
export const BatchFieldsFragmentDoc = gql`
  fragment BatchFields on SampleBatch {
    id
    notes
    panel {
      ...PanelFields
    }
    instrument {
      ...InstrumentFields
    }
  }
  ${PanelFieldsFragmentDoc}
  ${InstrumentFieldsFragmentDoc}
`;
export const JobFieldsFragmentDoc = gql`
  fragment JobFields on PanelProcessingJob {
    id
    status
    startedAt
    completedAt
    batch {
      ...BatchFields
    }
    failureReason
    initiatedBy {
      id
      ...UserName
    }
  }
  ${BatchFieldsFragmentDoc}
  ${UserNameFragmentDoc}
`;
export const PanelResultsFieldsFragmentDoc = gql`
  fragment PanelResultsFields on SamplePanelResult {
    results
    ...ForeignMaterialResultsFields
    sampleBatch {
      id
      instrument {
        ...InstrumentFields
      }
      panelProcessingJob {
        ...JobFields
      }
    }
    panel {
      ...PanelFields
    }
    images {
      ...FileFields
    }
  }
  ${ForeignMaterialResultsFieldsFragmentDoc}
  ${InstrumentFieldsFragmentDoc}
  ${JobFieldsFragmentDoc}
  ${PanelFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
`;
export const PanelLogFieldsFragmentDoc = gql`
  fragment PanelLogFields on Sample {
    ...BaseSampleFields
    panelResults(where: { referencePanelId: { equals: $panelId } }) {
      ...PanelResultsFields
    }
    aliquots(where: { referencePanelId: { equals: $panelId } }) {
      ...AliquotFields
    }
    testPreps(where: { referencePanelId: { equals: $panelId } }) {
      ...TestPrepFields
    }
    accession {
      ...RushFields
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${PanelResultsFieldsFragmentDoc}
  ${AliquotFieldsFragmentDoc}
  ${TestPrepFieldsFragmentDoc}
  ${RushFieldsFragmentDoc}
`;
export const IntendedUseFieldsFragmentDoc = gql`
  fragment IntendedUseFields on IntendedUse {
    id
    name
  }
`;
export const UserInfoFieldsFragmentDoc = gql`
  fragment UserInfoFields on User {
    id
    firstName
    lastName
    role {
      id
      name
    }
    phoneNumber
    title
    email
    business {
      id
      name
    }
    active
  }
`;
export const AcceptSampleDocument = gql`
  mutation acceptSample($input: SampleActionInput!) {
    acceptSample(input: $input) {
      id
      sampleId
      status
    }
  }
`;
export type AcceptSampleMutationFn = Apollo.MutationFunction<
  AcceptSampleMutation,
  AcceptSampleMutationVariables
>;

/**
 * __useAcceptSampleMutation__
 *
 * To run a mutation, you first call `useAcceptSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSampleMutation, { data, loading, error }] = useAcceptSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptSampleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptSampleMutation,
    AcceptSampleMutationVariables
  >,
) {
  return Apollo.useMutation<
    AcceptSampleMutation,
    AcceptSampleMutationVariables
  >(AcceptSampleDocument, baseOptions);
}
export type AcceptSampleMutationHookResult = ReturnType<
  typeof useAcceptSampleMutation
>;
export type AcceptSampleMutationResult =
  Apollo.MutationResult<AcceptSampleMutation>;
export type AcceptSampleMutationOptions = Apollo.BaseMutationOptions<
  AcceptSampleMutation,
  AcceptSampleMutationVariables
>;
export const AccessionSampleDocument = gql`
  mutation accessionSample($input: AccessionSampleInput!) {
    accessionSample(input: $input) {
      id
      status
      sourceMaterial
      lotOrBatchNumber
      reportTemplateOption {
        id
      }
      matrix {
        ...SampleMatrixFields
        sampleCategory {
          ...SampleCategoryFields
          intendedUse {
            ...IntendedUseFields
          }
        }
      }
      weightOrCount
      metrcPackage {
        packageLabel
        sourcePackageLabels
      }
      photo {
        id
        url
      }
      processSteps {
        id
        required
        inProgress
        completed
      }
    }
  }
  ${SampleMatrixFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
  ${IntendedUseFieldsFragmentDoc}
`;
export type AccessionSampleMutationFn = Apollo.MutationFunction<
  AccessionSampleMutation,
  AccessionSampleMutationVariables
>;

/**
 * __useAccessionSampleMutation__
 *
 * To run a mutation, you first call `useAccessionSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccessionSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accessionSampleMutation, { data, loading, error }] = useAccessionSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessionSampleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccessionSampleMutation,
    AccessionSampleMutationVariables
  >,
) {
  return Apollo.useMutation<
    AccessionSampleMutation,
    AccessionSampleMutationVariables
  >(AccessionSampleDocument, baseOptions);
}
export type AccessionSampleMutationHookResult = ReturnType<
  typeof useAccessionSampleMutation
>;
export type AccessionSampleMutationResult =
  Apollo.MutationResult<AccessionSampleMutation>;
export type AccessionSampleMutationOptions = Apollo.BaseMutationOptions<
  AccessionSampleMutation,
  AccessionSampleMutationVariables
>;
export const AccessionOptionsDocument = gql`
  query accessionOptions {
    intendedUses {
      ...IntendedUseFields
      sampleCategories {
        ...SampleCategoryFields
        sampleMatrices {
          ...SampleMatrixFields
        }
      }
    }
    availablePanels {
      ...PanelFields
    }
    complianceSampleTypes {
      ...ComplianceSampleTypeFields
    }
  }
  ${IntendedUseFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
  ${SampleMatrixFieldsFragmentDoc}
  ${PanelFieldsFragmentDoc}
  ${ComplianceSampleTypeFieldsFragmentDoc}
`;

/**
 * __useAccessionOptionsQuery__
 *
 * To run a query within a React component, call `useAccessionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessionOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessionOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccessionOptionsQuery,
    AccessionOptionsQueryVariables
  >,
) {
  return Apollo.useQuery<AccessionOptionsQuery, AccessionOptionsQueryVariables>(
    AccessionOptionsDocument,
    baseOptions,
  );
}
export function useAccessionOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessionOptionsQuery,
    AccessionOptionsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AccessionOptionsQuery,
    AccessionOptionsQueryVariables
  >(AccessionOptionsDocument, baseOptions);
}
export type AccessionOptionsQueryHookResult = ReturnType<
  typeof useAccessionOptionsQuery
>;
export type AccessionOptionsLazyQueryHookResult = ReturnType<
  typeof useAccessionOptionsLazyQuery
>;
export type AccessionOptionsQueryResult = Apollo.QueryResult<
  AccessionOptionsQuery,
  AccessionOptionsQueryVariables
>;
export const CorrespondingMetrcSourcePackageUidDocument = gql`
  query correspondingMetrcSourcePackageUID($metrcPackageUID: String!) {
    correspondingMetrcSourcePackageUID(metrcPackageUID: $metrcPackageUID)
  }
`;

/**
 * __useCorrespondingMetrcSourcePackageUidQuery__
 *
 * To run a query within a React component, call `useCorrespondingMetrcSourcePackageUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorrespondingMetrcSourcePackageUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorrespondingMetrcSourcePackageUidQuery({
 *   variables: {
 *      metrcPackageUID: // value for 'metrcPackageUID'
 *   },
 * });
 */
export function useCorrespondingMetrcSourcePackageUidQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CorrespondingMetrcSourcePackageUidQuery,
    CorrespondingMetrcSourcePackageUidQueryVariables
  >,
) {
  return Apollo.useQuery<
    CorrespondingMetrcSourcePackageUidQuery,
    CorrespondingMetrcSourcePackageUidQueryVariables
  >(CorrespondingMetrcSourcePackageUidDocument, baseOptions);
}
export function useCorrespondingMetrcSourcePackageUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CorrespondingMetrcSourcePackageUidQuery,
    CorrespondingMetrcSourcePackageUidQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    CorrespondingMetrcSourcePackageUidQuery,
    CorrespondingMetrcSourcePackageUidQueryVariables
  >(CorrespondingMetrcSourcePackageUidDocument, baseOptions);
}
export type CorrespondingMetrcSourcePackageUidQueryHookResult = ReturnType<
  typeof useCorrespondingMetrcSourcePackageUidQuery
>;
export type CorrespondingMetrcSourcePackageUidLazyQueryHookResult = ReturnType<
  typeof useCorrespondingMetrcSourcePackageUidLazyQuery
>;
export type CorrespondingMetrcSourcePackageUidQueryResult = Apollo.QueryResult<
  CorrespondingMetrcSourcePackageUidQuery,
  CorrespondingMetrcSourcePackageUidQueryVariables
>;
export const CreateProducerDocument = gql`
  mutation createProducer($input: CreatePartnerInput!) {
    createProducer(input: $input) {
      id
      name
      licenseNumber
      address {
        id
        street1
        city
        state
        zip
      }
    }
  }
`;
export type CreateProducerMutationFn = Apollo.MutationFunction<
  CreateProducerMutation,
  CreateProducerMutationVariables
>;

/**
 * __useCreateProducerMutation__
 *
 * To run a mutation, you first call `useCreateProducerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProducerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProducerMutation, { data, loading, error }] = useCreateProducerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProducerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProducerMutation,
    CreateProducerMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateProducerMutation,
    CreateProducerMutationVariables
  >(CreateProducerDocument, baseOptions);
}
export type CreateProducerMutationHookResult = ReturnType<
  typeof useCreateProducerMutation
>;
export type CreateProducerMutationResult =
  Apollo.MutationResult<CreateProducerMutation>;
export type CreateProducerMutationOptions = Apollo.BaseMutationOptions<
  CreateProducerMutation,
  CreateProducerMutationVariables
>;
export const CreateDistributorDocument = gql`
  mutation createDistributor($input: CreatePartnerInput!) {
    createDistributor(input: $input) {
      id
      name
      licenseNumber
      address {
        id
        street1
        city
        state
        zip
      }
    }
  }
`;
export type CreateDistributorMutationFn = Apollo.MutationFunction<
  CreateDistributorMutation,
  CreateDistributorMutationVariables
>;

/**
 * __useCreateDistributorMutation__
 *
 * To run a mutation, you first call `useCreateDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistributorMutation, { data, loading, error }] = useCreateDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDistributorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDistributorMutation,
    CreateDistributorMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateDistributorMutation,
    CreateDistributorMutationVariables
  >(CreateDistributorDocument, baseOptions);
}
export type CreateDistributorMutationHookResult = ReturnType<
  typeof useCreateDistributorMutation
>;
export type CreateDistributorMutationResult =
  Apollo.MutationResult<CreateDistributorMutation>;
export type CreateDistributorMutationOptions = Apollo.BaseMutationOptions<
  CreateDistributorMutation,
  CreateDistributorMutationVariables
>;
export const AliquotLogDocument = gql`
  query aliquotLog($where: SampleWhereInput) {
    samples(where: $where) {
      ...AliquotLogEntry
    }
    availablePanels {
      ...PanelFields
    }
  }
  ${AliquotLogEntryFragmentDoc}
  ${PanelFieldsFragmentDoc}
`;

/**
 * __useAliquotLogQuery__
 *
 * To run a query within a React component, call `useAliquotLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAliquotLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAliquotLogQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAliquotLogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AliquotLogQuery,
    AliquotLogQueryVariables
  >,
) {
  return Apollo.useQuery<AliquotLogQuery, AliquotLogQueryVariables>(
    AliquotLogDocument,
    baseOptions,
  );
}
export function useAliquotLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AliquotLogQuery,
    AliquotLogQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AliquotLogQuery, AliquotLogQueryVariables>(
    AliquotLogDocument,
    baseOptions,
  );
}
export type AliquotLogQueryHookResult = ReturnType<typeof useAliquotLogQuery>;
export type AliquotLogLazyQueryHookResult = ReturnType<
  typeof useAliquotLogLazyQuery
>;
export type AliquotLogQueryResult = Apollo.QueryResult<
  AliquotLogQuery,
  AliquotLogQueryVariables
>;
export const UpsertAliquotsDocument = gql`
  mutation upsertAliquots($input: UpsertSampleAliquotsInput!) {
    upsertSampleAliquots(input: $input) {
      ...AliquotLogEntry
    }
  }
  ${AliquotLogEntryFragmentDoc}
`;
export type UpsertAliquotsMutationFn = Apollo.MutationFunction<
  UpsertAliquotsMutation,
  UpsertAliquotsMutationVariables
>;

/**
 * __useUpsertAliquotsMutation__
 *
 * To run a mutation, you first call `useUpsertAliquotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAliquotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAliquotsMutation, { data, loading, error }] = useUpsertAliquotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAliquotsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertAliquotsMutation,
    UpsertAliquotsMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpsertAliquotsMutation,
    UpsertAliquotsMutationVariables
  >(UpsertAliquotsDocument, baseOptions);
}
export type UpsertAliquotsMutationHookResult = ReturnType<
  typeof useUpsertAliquotsMutation
>;
export type UpsertAliquotsMutationResult =
  Apollo.MutationResult<UpsertAliquotsMutation>;
export type UpsertAliquotsMutationOptions = Apollo.BaseMutationOptions<
  UpsertAliquotsMutation,
  UpsertAliquotsMutationVariables
>;
export const ApproveSampleReportDocument = gql`
  mutation approveSampleReport($sampleId: ID!) {
    approveSampleReport(input: { id: $sampleId }) {
      id
    }
  }
`;
export type ApproveSampleReportMutationFn = Apollo.MutationFunction<
  ApproveSampleReportMutation,
  ApproveSampleReportMutationVariables
>;

/**
 * __useApproveSampleReportMutation__
 *
 * To run a mutation, you first call `useApproveSampleReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSampleReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSampleReportMutation, { data, loading, error }] = useApproveSampleReportMutation({
 *   variables: {
 *      sampleId: // value for 'sampleId'
 *   },
 * });
 */
export function useApproveSampleReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveSampleReportMutation,
    ApproveSampleReportMutationVariables
  >,
) {
  return Apollo.useMutation<
    ApproveSampleReportMutation,
    ApproveSampleReportMutationVariables
  >(ApproveSampleReportDocument, baseOptions);
}
export type ApproveSampleReportMutationHookResult = ReturnType<
  typeof useApproveSampleReportMutation
>;
export type ApproveSampleReportMutationResult =
  Apollo.MutationResult<ApproveSampleReportMutation>;
export type ApproveSampleReportMutationOptions = Apollo.BaseMutationOptions<
  ApproveSampleReportMutation,
  ApproveSampleReportMutationVariables
>;
export const ApproveSampleQaDocument = gql`
  mutation approveSampleQA($sampleId: ID!) {
    approveSampleQA(input: { id: $sampleId }) {
      id
    }
  }
`;
export type ApproveSampleQaMutationFn = Apollo.MutationFunction<
  ApproveSampleQaMutation,
  ApproveSampleQaMutationVariables
>;

/**
 * __useApproveSampleQaMutation__
 *
 * To run a mutation, you first call `useApproveSampleQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSampleQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSampleQaMutation, { data, loading, error }] = useApproveSampleQaMutation({
 *   variables: {
 *      sampleId: // value for 'sampleId'
 *   },
 * });
 */
export function useApproveSampleQaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveSampleQaMutation,
    ApproveSampleQaMutationVariables
  >,
) {
  return Apollo.useMutation<
    ApproveSampleQaMutation,
    ApproveSampleQaMutationVariables
  >(ApproveSampleQaDocument, baseOptions);
}
export type ApproveSampleQaMutationHookResult = ReturnType<
  typeof useApproveSampleQaMutation
>;
export type ApproveSampleQaMutationResult =
  Apollo.MutationResult<ApproveSampleQaMutation>;
export type ApproveSampleQaMutationOptions = Apollo.BaseMutationOptions<
  ApproveSampleQaMutation,
  ApproveSampleQaMutationVariables
>;
export const BusinessesDocument = gql`
  query businesses {
    businesses {
      ...BusinessFields
    }
  }
  ${BusinessFieldsFragmentDoc}
`;

/**
 * __useBusinessesQuery__
 *
 * To run a query within a React component, call `useBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessesQuery,
    BusinessesQueryVariables
  >,
) {
  return Apollo.useQuery<BusinessesQuery, BusinessesQueryVariables>(
    BusinessesDocument,
    baseOptions,
  );
}
export function useBusinessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessesQuery,
    BusinessesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<BusinessesQuery, BusinessesQueryVariables>(
    BusinessesDocument,
    baseOptions,
  );
}
export type BusinessesQueryHookResult = ReturnType<typeof useBusinessesQuery>;
export type BusinessesLazyQueryHookResult = ReturnType<
  typeof useBusinessesLazyQuery
>;
export type BusinessesQueryResult = Apollo.QueryResult<
  BusinessesQuery,
  BusinessesQueryVariables
>;
export const BusinessPartnersDocument = gql`
  query businessPartners {
    me {
      id
      business {
        id
        producers {
          id
          name
          licenseNumber
          address {
            street1
            city
            state
            zip
          }
        }
        distributors {
          id
          name
          licenseNumber
          address {
            street1
            city
            state
            zip
          }
        }
      }
    }
  }
`;

/**
 * __useBusinessPartnersQuery__
 *
 * To run a query within a React component, call `useBusinessPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessPartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessPartnersQuery,
    BusinessPartnersQueryVariables
  >,
) {
  return Apollo.useQuery<BusinessPartnersQuery, BusinessPartnersQueryVariables>(
    BusinessPartnersDocument,
    baseOptions,
  );
}
export function useBusinessPartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessPartnersQuery,
    BusinessPartnersQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BusinessPartnersQuery,
    BusinessPartnersQueryVariables
  >(BusinessPartnersDocument, baseOptions);
}
export type BusinessPartnersQueryHookResult = ReturnType<
  typeof useBusinessPartnersQuery
>;
export type BusinessPartnersLazyQueryHookResult = ReturnType<
  typeof useBusinessPartnersLazyQuery
>;
export type BusinessPartnersQueryResult = Apollo.QueryResult<
  BusinessPartnersQuery,
  BusinessPartnersQueryVariables
>;
export const CancelSampleDocument = gql`
  mutation cancelSample($input: SampleActionInput!) {
    cancelSample(input: $input) {
      ...BaseSampleFields
    }
  }
  ${BaseSampleFieldsFragmentDoc}
`;
export type CancelSampleMutationFn = Apollo.MutationFunction<
  CancelSampleMutation,
  CancelSampleMutationVariables
>;

/**
 * __useCancelSampleMutation__
 *
 * To run a mutation, you first call `useCancelSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSampleMutation, { data, loading, error }] = useCancelSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSampleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSampleMutation,
    CancelSampleMutationVariables
  >,
) {
  return Apollo.useMutation<
    CancelSampleMutation,
    CancelSampleMutationVariables
  >(CancelSampleDocument, baseOptions);
}
export type CancelSampleMutationHookResult = ReturnType<
  typeof useCancelSampleMutation
>;
export type CancelSampleMutationResult =
  Apollo.MutationResult<CancelSampleMutation>;
export type CancelSampleMutationOptions = Apollo.BaseMutationOptions<
  CancelSampleMutation,
  CancelSampleMutationVariables
>;
export const ConfirmPanelResultDocument = gql`
  mutation confirmPanelResult(
    $input: ConfirmPanelResultsInput!
    $panelId: String!
  ) {
    confirmPanelResults(input: $input) {
      id
      panelResults(where: { referencePanelId: { equals: $panelId } }) {
        ...PanelResultsFields
      }
    }
  }
  ${PanelResultsFieldsFragmentDoc}
`;
export type ConfirmPanelResultMutationFn = Apollo.MutationFunction<
  ConfirmPanelResultMutation,
  ConfirmPanelResultMutationVariables
>;

/**
 * __useConfirmPanelResultMutation__
 *
 * To run a mutation, you first call `useConfirmPanelResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPanelResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPanelResultMutation, { data, loading, error }] = useConfirmPanelResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useConfirmPanelResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPanelResultMutation,
    ConfirmPanelResultMutationVariables
  >,
) {
  return Apollo.useMutation<
    ConfirmPanelResultMutation,
    ConfirmPanelResultMutationVariables
  >(ConfirmPanelResultDocument, baseOptions);
}
export type ConfirmPanelResultMutationHookResult = ReturnType<
  typeof useConfirmPanelResultMutation
>;
export type ConfirmPanelResultMutationResult =
  Apollo.MutationResult<ConfirmPanelResultMutation>;
export type ConfirmPanelResultMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPanelResultMutation,
  ConfirmPanelResultMutationVariables
>;
export const AvailableInstrumentsDocument = gql`
  query availableInstruments {
    availableInstruments {
      ...InstrumentFields
    }
  }
  ${InstrumentFieldsFragmentDoc}
`;

/**
 * __useAvailableInstrumentsQuery__
 *
 * To run a query within a React component, call `useAvailableInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableInstrumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableInstrumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableInstrumentsQuery,
    AvailableInstrumentsQueryVariables
  >,
) {
  return Apollo.useQuery<
    AvailableInstrumentsQuery,
    AvailableInstrumentsQueryVariables
  >(AvailableInstrumentsDocument, baseOptions);
}
export function useAvailableInstrumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableInstrumentsQuery,
    AvailableInstrumentsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AvailableInstrumentsQuery,
    AvailableInstrumentsQueryVariables
  >(AvailableInstrumentsDocument, baseOptions);
}
export type AvailableInstrumentsQueryHookResult = ReturnType<
  typeof useAvailableInstrumentsQuery
>;
export type AvailableInstrumentsLazyQueryHookResult = ReturnType<
  typeof useAvailableInstrumentsLazyQuery
>;
export type AvailableInstrumentsQueryResult = Apollo.QueryResult<
  AvailableInstrumentsQuery,
  AvailableInstrumentsQueryVariables
>;
export const AvailableBalancesDocument = gql`
  query availableBalances {
    availableBalances {
      ...BalanceFields
    }
  }
  ${BalanceFieldsFragmentDoc}
`;

/**
 * __useAvailableBalancesQuery__
 *
 * To run a query within a React component, call `useAvailableBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableBalancesQuery,
    AvailableBalancesQueryVariables
  >,
) {
  return Apollo.useQuery<
    AvailableBalancesQuery,
    AvailableBalancesQueryVariables
  >(AvailableBalancesDocument, baseOptions);
}
export function useAvailableBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableBalancesQuery,
    AvailableBalancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AvailableBalancesQuery,
    AvailableBalancesQueryVariables
  >(AvailableBalancesDocument, baseOptions);
}
export type AvailableBalancesQueryHookResult = ReturnType<
  typeof useAvailableBalancesQuery
>;
export type AvailableBalancesLazyQueryHookResult = ReturnType<
  typeof useAvailableBalancesLazyQuery
>;
export type AvailableBalancesQueryResult = Apollo.QueryResult<
  AvailableBalancesQuery,
  AvailableBalancesQueryVariables
>;
export const AvailableSolventsDocument = gql`
  query availableSolvents {
    availableSolvents {
      ...SolventFields
    }
  }
  ${SolventFieldsFragmentDoc}
`;

/**
 * __useAvailableSolventsQuery__
 *
 * To run a query within a React component, call `useAvailableSolventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSolventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSolventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableSolventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableSolventsQuery,
    AvailableSolventsQueryVariables
  >,
) {
  return Apollo.useQuery<
    AvailableSolventsQuery,
    AvailableSolventsQueryVariables
  >(AvailableSolventsDocument, baseOptions);
}
export function useAvailableSolventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableSolventsQuery,
    AvailableSolventsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AvailableSolventsQuery,
    AvailableSolventsQueryVariables
  >(AvailableSolventsDocument, baseOptions);
}
export type AvailableSolventsQueryHookResult = ReturnType<
  typeof useAvailableSolventsQuery
>;
export type AvailableSolventsLazyQueryHookResult = ReturnType<
  typeof useAvailableSolventsLazyQuery
>;
export type AvailableSolventsQueryResult = Apollo.QueryResult<
  AvailableSolventsQuery,
  AvailableSolventsQueryVariables
>;
export const AvailablePipettesDocument = gql`
  query availablePipettes {
    availablePipettes {
      ...PipetteFields
    }
  }
  ${PipetteFieldsFragmentDoc}
`;

/**
 * __useAvailablePipettesQuery__
 *
 * To run a query within a React component, call `useAvailablePipettesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePipettesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePipettesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailablePipettesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailablePipettesQuery,
    AvailablePipettesQueryVariables
  >,
) {
  return Apollo.useQuery<
    AvailablePipettesQuery,
    AvailablePipettesQueryVariables
  >(AvailablePipettesDocument, baseOptions);
}
export function useAvailablePipettesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailablePipettesQuery,
    AvailablePipettesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AvailablePipettesQuery,
    AvailablePipettesQueryVariables
  >(AvailablePipettesDocument, baseOptions);
}
export type AvailablePipettesQueryHookResult = ReturnType<
  typeof useAvailablePipettesQuery
>;
export type AvailablePipettesLazyQueryHookResult = ReturnType<
  typeof useAvailablePipettesLazyQuery
>;
export type AvailablePipettesQueryResult = Apollo.QueryResult<
  AvailablePipettesQuery,
  AvailablePipettesQueryVariables
>;
export const AvailableDispensersDocument = gql`
  query availableDispensers {
    availableDispensers {
      ...DispenserFields
    }
  }
  ${DispenserFieldsFragmentDoc}
`;

/**
 * __useAvailableDispensersQuery__
 *
 * To run a query within a React component, call `useAvailableDispensersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDispensersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDispensersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableDispensersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableDispensersQuery,
    AvailableDispensersQueryVariables
  >,
) {
  return Apollo.useQuery<
    AvailableDispensersQuery,
    AvailableDispensersQueryVariables
  >(AvailableDispensersDocument, baseOptions);
}
export function useAvailableDispensersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableDispensersQuery,
    AvailableDispensersQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AvailableDispensersQuery,
    AvailableDispensersQueryVariables
  >(AvailableDispensersDocument, baseOptions);
}
export type AvailableDispensersQueryHookResult = ReturnType<
  typeof useAvailableDispensersQuery
>;
export type AvailableDispensersLazyQueryHookResult = ReturnType<
  typeof useAvailableDispensersLazyQuery
>;
export type AvailableDispensersQueryResult = Apollo.QueryResult<
  AvailableDispensersQuery,
  AvailableDispensersQueryVariables
>;
export const UpsertForeignMaterialResultDocument = gql`
  mutation upsertForeignMaterialResult(
    $input: UpsertForeignMaterialInfoInput!
    $panelId: String!
  ) {
    upsertForeignMaterialInfo(input: $input) {
      ...PanelLogFields
    }
  }
  ${PanelLogFieldsFragmentDoc}
`;
export type UpsertForeignMaterialResultMutationFn = Apollo.MutationFunction<
  UpsertForeignMaterialResultMutation,
  UpsertForeignMaterialResultMutationVariables
>;

/**
 * __useUpsertForeignMaterialResultMutation__
 *
 * To run a mutation, you first call `useUpsertForeignMaterialResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertForeignMaterialResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertForeignMaterialResultMutation, { data, loading, error }] = useUpsertForeignMaterialResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useUpsertForeignMaterialResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertForeignMaterialResultMutation,
    UpsertForeignMaterialResultMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpsertForeignMaterialResultMutation,
    UpsertForeignMaterialResultMutationVariables
  >(UpsertForeignMaterialResultDocument, baseOptions);
}
export type UpsertForeignMaterialResultMutationHookResult = ReturnType<
  typeof useUpsertForeignMaterialResultMutation
>;
export type UpsertForeignMaterialResultMutationResult =
  Apollo.MutationResult<UpsertForeignMaterialResultMutation>;
export type UpsertForeignMaterialResultMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertForeignMaterialResultMutation,
    UpsertForeignMaterialResultMutationVariables
  >;
export const HomogenizationLogDocument = gql`
  query homogenizationLog($where: SampleWhereInput) {
    samples(where: $where) {
      ...HomogenizationLogEntry
    }
    availablePanels {
      ...PanelFields
    }
  }
  ${HomogenizationLogEntryFragmentDoc}
  ${PanelFieldsFragmentDoc}
`;

/**
 * __useHomogenizationLogQuery__
 *
 * To run a query within a React component, call `useHomogenizationLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomogenizationLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomogenizationLogQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useHomogenizationLogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomogenizationLogQuery,
    HomogenizationLogQueryVariables
  >,
) {
  return Apollo.useQuery<
    HomogenizationLogQuery,
    HomogenizationLogQueryVariables
  >(HomogenizationLogDocument, baseOptions);
}
export function useHomogenizationLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomogenizationLogQuery,
    HomogenizationLogQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    HomogenizationLogQuery,
    HomogenizationLogQueryVariables
  >(HomogenizationLogDocument, baseOptions);
}
export type HomogenizationLogQueryHookResult = ReturnType<
  typeof useHomogenizationLogQuery
>;
export type HomogenizationLogLazyQueryHookResult = ReturnType<
  typeof useHomogenizationLogLazyQuery
>;
export type HomogenizationLogQueryResult = Apollo.QueryResult<
  HomogenizationLogQuery,
  HomogenizationLogQueryVariables
>;
export const UpsertHomogenizationDocument = gql`
  mutation upsertHomogenization($input: UpsertSampleHomogenizationInput!) {
    upsertSampleHomogenization(input: $input) {
      ...HomogenizationLogEntry
    }
  }
  ${HomogenizationLogEntryFragmentDoc}
`;
export type UpsertHomogenizationMutationFn = Apollo.MutationFunction<
  UpsertHomogenizationMutation,
  UpsertHomogenizationMutationVariables
>;

/**
 * __useUpsertHomogenizationMutation__
 *
 * To run a mutation, you first call `useUpsertHomogenizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHomogenizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHomogenizationMutation, { data, loading, error }] = useUpsertHomogenizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertHomogenizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertHomogenizationMutation,
    UpsertHomogenizationMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpsertHomogenizationMutation,
    UpsertHomogenizationMutationVariables
  >(UpsertHomogenizationDocument, baseOptions);
}
export type UpsertHomogenizationMutationHookResult = ReturnType<
  typeof useUpsertHomogenizationMutation
>;
export type UpsertHomogenizationMutationResult =
  Apollo.MutationResult<UpsertHomogenizationMutation>;
export type UpsertHomogenizationMutationOptions = Apollo.BaseMutationOptions<
  UpsertHomogenizationMutation,
  UpsertHomogenizationMutationVariables
>;
export const IntendedUsesDocument = gql`
  query intendedUses {
    intendedUses {
      ...IntendedUseFields
      sampleCategories {
        ...SampleCategoryFields
        sampleMatrices {
          ...SampleMatrixFields
        }
      }
    }
  }
  ${IntendedUseFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
  ${SampleMatrixFieldsFragmentDoc}
`;

/**
 * __useIntendedUsesQuery__
 *
 * To run a query within a React component, call `useIntendedUsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntendedUsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntendedUsesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntendedUsesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IntendedUsesQuery,
    IntendedUsesQueryVariables
  >,
) {
  return Apollo.useQuery<IntendedUsesQuery, IntendedUsesQueryVariables>(
    IntendedUsesDocument,
    baseOptions,
  );
}
export function useIntendedUsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntendedUsesQuery,
    IntendedUsesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<IntendedUsesQuery, IntendedUsesQueryVariables>(
    IntendedUsesDocument,
    baseOptions,
  );
}
export type IntendedUsesQueryHookResult = ReturnType<
  typeof useIntendedUsesQuery
>;
export type IntendedUsesLazyQueryHookResult = ReturnType<
  typeof useIntendedUsesLazyQuery
>;
export type IntendedUsesQueryResult = Apollo.QueryResult<
  IntendedUsesQuery,
  IntendedUsesQueryVariables
>;
export const LoggedInUserDocument = gql`
  query loggedInUser {
    me {
      ...BasicUserFields
    }
  }
  ${BasicUserFieldsFragmentDoc}
`;

/**
 * __useLoggedInUserQuery__
 *
 * To run a query within a React component, call `useLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoggedInUserQuery,
    LoggedInUserQueryVariables
  >,
) {
  return Apollo.useQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(
    LoggedInUserDocument,
    baseOptions,
  );
}
export function useLoggedInUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoggedInUserQuery,
    LoggedInUserQueryVariables
  >,
) {
  return Apollo.useLazyQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(
    LoggedInUserDocument,
    baseOptions,
  );
}
export type LoggedInUserQueryHookResult = ReturnType<
  typeof useLoggedInUserQuery
>;
export type LoggedInUserLazyQueryHookResult = ReturnType<
  typeof useLoggedInUserLazyQuery
>;
export type LoggedInUserQueryResult = Apollo.QueryResult<
  LoggedInUserQuery,
  LoggedInUserQueryVariables
>;
export const UpsertMicrobialResultsDocument = gql`
  mutation upsertMicrobialResults(
    $microbialInput: UpsertMicrobialResultsInput!
    $aliquotInput: UpsertSampleAliquotsInput!
    $panelId: String!
  ) {
    upsertSampleAliquots(input: $aliquotInput) {
      id
    }
    upsertMicrobialResults(input: $microbialInput) {
      ...PanelLogFields
    }
  }
  ${PanelLogFieldsFragmentDoc}
`;
export type UpsertMicrobialResultsMutationFn = Apollo.MutationFunction<
  UpsertMicrobialResultsMutation,
  UpsertMicrobialResultsMutationVariables
>;

/**
 * __useUpsertMicrobialResultsMutation__
 *
 * To run a mutation, you first call `useUpsertMicrobialResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMicrobialResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMicrobialResultsMutation, { data, loading, error }] = useUpsertMicrobialResultsMutation({
 *   variables: {
 *      microbialInput: // value for 'microbialInput'
 *      aliquotInput: // value for 'aliquotInput'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useUpsertMicrobialResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMicrobialResultsMutation,
    UpsertMicrobialResultsMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpsertMicrobialResultsMutation,
    UpsertMicrobialResultsMutationVariables
  >(UpsertMicrobialResultsDocument, baseOptions);
}
export type UpsertMicrobialResultsMutationHookResult = ReturnType<
  typeof useUpsertMicrobialResultsMutation
>;
export type UpsertMicrobialResultsMutationResult =
  Apollo.MutationResult<UpsertMicrobialResultsMutation>;
export type UpsertMicrobialResultsMutationOptions = Apollo.BaseMutationOptions<
  UpsertMicrobialResultsMutation,
  UpsertMicrobialResultsMutationVariables
>;
export const UpsertMoistureContentResultsDocument = gql`
  mutation upsertMoistureContentResults(
    $moistureInput: UpsertMoistureContentResultsInput!
    $aliquotInput: UpsertSampleAliquotsInput!
    $panelId: String!
  ) {
    upsertSampleAliquots(input: $aliquotInput) {
      id
    }
    upsertMoistureContentResults(input: $moistureInput) {
      ...PanelLogFields
    }
  }
  ${PanelLogFieldsFragmentDoc}
`;
export type UpsertMoistureContentResultsMutationFn = Apollo.MutationFunction<
  UpsertMoistureContentResultsMutation,
  UpsertMoistureContentResultsMutationVariables
>;

/**
 * __useUpsertMoistureContentResultsMutation__
 *
 * To run a mutation, you first call `useUpsertMoistureContentResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMoistureContentResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMoistureContentResultsMutation, { data, loading, error }] = useUpsertMoistureContentResultsMutation({
 *   variables: {
 *      moistureInput: // value for 'moistureInput'
 *      aliquotInput: // value for 'aliquotInput'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useUpsertMoistureContentResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMoistureContentResultsMutation,
    UpsertMoistureContentResultsMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpsertMoistureContentResultsMutation,
    UpsertMoistureContentResultsMutationVariables
  >(UpsertMoistureContentResultsDocument, baseOptions);
}
export type UpsertMoistureContentResultsMutationHookResult = ReturnType<
  typeof useUpsertMoistureContentResultsMutation
>;
export type UpsertMoistureContentResultsMutationResult =
  Apollo.MutationResult<UpsertMoistureContentResultsMutation>;
export type UpsertMoistureContentResultsMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertMoistureContentResultsMutation,
    UpsertMoistureContentResultsMutationVariables
  >;
export const InitiatePanelProcessingJobDocument = gql`
  mutation initiatePanelProcessingJob(
    $input: InitiatePanelProcessingJobInput!
  ) {
    initiatePanelProcessingJob(input: $input) {
      ...JobFields
    }
  }
  ${JobFieldsFragmentDoc}
`;
export type InitiatePanelProcessingJobMutationFn = Apollo.MutationFunction<
  InitiatePanelProcessingJobMutation,
  InitiatePanelProcessingJobMutationVariables
>;

/**
 * __useInitiatePanelProcessingJobMutation__
 *
 * To run a mutation, you first call `useInitiatePanelProcessingJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePanelProcessingJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePanelProcessingJobMutation, { data, loading, error }] = useInitiatePanelProcessingJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiatePanelProcessingJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiatePanelProcessingJobMutation,
    InitiatePanelProcessingJobMutationVariables
  >,
) {
  return Apollo.useMutation<
    InitiatePanelProcessingJobMutation,
    InitiatePanelProcessingJobMutationVariables
  >(InitiatePanelProcessingJobDocument, baseOptions);
}
export type InitiatePanelProcessingJobMutationHookResult = ReturnType<
  typeof useInitiatePanelProcessingJobMutation
>;
export type InitiatePanelProcessingJobMutationResult =
  Apollo.MutationResult<InitiatePanelProcessingJobMutation>;
export type InitiatePanelProcessingJobMutationOptions =
  Apollo.BaseMutationOptions<
    InitiatePanelProcessingJobMutation,
    InitiatePanelProcessingJobMutationVariables
  >;
export const PanelProcessingJobsDocument = gql`
  query panelProcessingJobs {
    panelProcessingJobs {
      ...JobFields
    }
  }
  ${JobFieldsFragmentDoc}
`;

/**
 * __usePanelProcessingJobsQuery__
 *
 * To run a query within a React component, call `usePanelProcessingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelProcessingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelProcessingJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePanelProcessingJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PanelProcessingJobsQuery,
    PanelProcessingJobsQueryVariables
  >,
) {
  return Apollo.useQuery<
    PanelProcessingJobsQuery,
    PanelProcessingJobsQueryVariables
  >(PanelProcessingJobsDocument, baseOptions);
}
export function usePanelProcessingJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PanelProcessingJobsQuery,
    PanelProcessingJobsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    PanelProcessingJobsQuery,
    PanelProcessingJobsQueryVariables
  >(PanelProcessingJobsDocument, baseOptions);
}
export type PanelProcessingJobsQueryHookResult = ReturnType<
  typeof usePanelProcessingJobsQuery
>;
export type PanelProcessingJobsLazyQueryHookResult = ReturnType<
  typeof usePanelProcessingJobsLazyQuery
>;
export type PanelProcessingJobsQueryResult = Apollo.QueryResult<
  PanelProcessingJobsQuery,
  PanelProcessingJobsQueryVariables
>;
export const AvailablePanelsDocument = gql`
  query availablePanels {
    availablePanels {
      ...PanelFields
    }
  }
  ${PanelFieldsFragmentDoc}
`;

/**
 * __useAvailablePanelsQuery__
 *
 * To run a query within a React component, call `useAvailablePanelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePanelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePanelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailablePanelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailablePanelsQuery,
    AvailablePanelsQueryVariables
  >,
) {
  return Apollo.useQuery<AvailablePanelsQuery, AvailablePanelsQueryVariables>(
    AvailablePanelsDocument,
    baseOptions,
  );
}
export function useAvailablePanelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailablePanelsQuery,
    AvailablePanelsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AvailablePanelsQuery,
    AvailablePanelsQueryVariables
  >(AvailablePanelsDocument, baseOptions);
}
export type AvailablePanelsQueryHookResult = ReturnType<
  typeof useAvailablePanelsQuery
>;
export type AvailablePanelsLazyQueryHookResult = ReturnType<
  typeof useAvailablePanelsLazyQuery
>;
export type AvailablePanelsQueryResult = Apollo.QueryResult<
  AvailablePanelsQuery,
  AvailablePanelsQueryVariables
>;
export const SubmitPastCoAsRequestDocument = gql`
  mutation submitPastCOAsRequest($startDate: Date!, $endDate: Date!) {
    submitPastCOAsRequest(startDate: $startDate, endDate: $endDate)
  }
`;
export type SubmitPastCoAsRequestMutationFn = Apollo.MutationFunction<
  SubmitPastCoAsRequestMutation,
  SubmitPastCoAsRequestMutationVariables
>;

/**
 * __useSubmitPastCoAsRequestMutation__
 *
 * To run a mutation, you first call `useSubmitPastCoAsRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPastCoAsRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPastCoAsRequestMutation, { data, loading, error }] = useSubmitPastCoAsRequestMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSubmitPastCoAsRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitPastCoAsRequestMutation,
    SubmitPastCoAsRequestMutationVariables
  >,
) {
  return Apollo.useMutation<
    SubmitPastCoAsRequestMutation,
    SubmitPastCoAsRequestMutationVariables
  >(SubmitPastCoAsRequestDocument, baseOptions);
}
export type SubmitPastCoAsRequestMutationHookResult = ReturnType<
  typeof useSubmitPastCoAsRequestMutation
>;
export type SubmitPastCoAsRequestMutationResult =
  Apollo.MutationResult<SubmitPastCoAsRequestMutation>;
export type SubmitPastCoAsRequestMutationOptions = Apollo.BaseMutationOptions<
  SubmitPastCoAsRequestMutation,
  SubmitPastCoAsRequestMutationVariables
>;
export const PickupLogOrdersDocument = gql`
  query pickupLogOrders {
    orders {
      ...PickupLogOrderFields
    }
  }
  ${PickupLogOrderFieldsFragmentDoc}
`;

/**
 * __usePickupLogOrdersQuery__
 *
 * To run a query within a React component, call `usePickupLogOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupLogOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupLogOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickupLogOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PickupLogOrdersQuery,
    PickupLogOrdersQueryVariables
  >,
) {
  return Apollo.useQuery<PickupLogOrdersQuery, PickupLogOrdersQueryVariables>(
    PickupLogOrdersDocument,
    baseOptions,
  );
}
export function usePickupLogOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickupLogOrdersQuery,
    PickupLogOrdersQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    PickupLogOrdersQuery,
    PickupLogOrdersQueryVariables
  >(PickupLogOrdersDocument, baseOptions);
}
export type PickupLogOrdersQueryHookResult = ReturnType<
  typeof usePickupLogOrdersQuery
>;
export type PickupLogOrdersLazyQueryHookResult = ReturnType<
  typeof usePickupLogOrdersLazyQuery
>;
export type PickupLogOrdersQueryResult = Apollo.QueryResult<
  PickupLogOrdersQuery,
  PickupLogOrdersQueryVariables
>;
export const SchedulePickupDocument = gql`
  mutation schedulePickup($input: SchedulePickupInput!) {
    schedulePickup(input: $input) {
      ...PickupLogOrderFields
    }
  }
  ${PickupLogOrderFieldsFragmentDoc}
`;
export type SchedulePickupMutationFn = Apollo.MutationFunction<
  SchedulePickupMutation,
  SchedulePickupMutationVariables
>;

/**
 * __useSchedulePickupMutation__
 *
 * To run a mutation, you first call `useSchedulePickupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchedulePickupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schedulePickupMutation, { data, loading, error }] = useSchedulePickupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchedulePickupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchedulePickupMutation,
    SchedulePickupMutationVariables
  >,
) {
  return Apollo.useMutation<
    SchedulePickupMutation,
    SchedulePickupMutationVariables
  >(SchedulePickupDocument, baseOptions);
}
export type SchedulePickupMutationHookResult = ReturnType<
  typeof useSchedulePickupMutation
>;
export type SchedulePickupMutationResult =
  Apollo.MutationResult<SchedulePickupMutation>;
export type SchedulePickupMutationOptions = Apollo.BaseMutationOptions<
  SchedulePickupMutation,
  SchedulePickupMutationVariables
>;
export const MarkOutForPickupDocument = gql`
  mutation markOutForPickup($input: PickupActionInput!) {
    markOutForPickup(input: $input) {
      ...PickupLogOrderFields
    }
  }
  ${PickupLogOrderFieldsFragmentDoc}
`;
export type MarkOutForPickupMutationFn = Apollo.MutationFunction<
  MarkOutForPickupMutation,
  MarkOutForPickupMutationVariables
>;

/**
 * __useMarkOutForPickupMutation__
 *
 * To run a mutation, you first call `useMarkOutForPickupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOutForPickupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOutForPickupMutation, { data, loading, error }] = useMarkOutForPickupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkOutForPickupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkOutForPickupMutation,
    MarkOutForPickupMutationVariables
  >,
) {
  return Apollo.useMutation<
    MarkOutForPickupMutation,
    MarkOutForPickupMutationVariables
  >(MarkOutForPickupDocument, baseOptions);
}
export type MarkOutForPickupMutationHookResult = ReturnType<
  typeof useMarkOutForPickupMutation
>;
export type MarkOutForPickupMutationResult =
  Apollo.MutationResult<MarkOutForPickupMutation>;
export type MarkOutForPickupMutationOptions = Apollo.BaseMutationOptions<
  MarkOutForPickupMutation,
  MarkOutForPickupMutationVariables
>;
export const MarkInTransitToLabDocument = gql`
  mutation markInTransitToLab($input: PickupActionInput!) {
    markInTransitToLab(input: $input) {
      ...PickupLogOrderFields
    }
  }
  ${PickupLogOrderFieldsFragmentDoc}
`;
export type MarkInTransitToLabMutationFn = Apollo.MutationFunction<
  MarkInTransitToLabMutation,
  MarkInTransitToLabMutationVariables
>;

/**
 * __useMarkInTransitToLabMutation__
 *
 * To run a mutation, you first call `useMarkInTransitToLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInTransitToLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInTransitToLabMutation, { data, loading, error }] = useMarkInTransitToLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkInTransitToLabMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkInTransitToLabMutation,
    MarkInTransitToLabMutationVariables
  >,
) {
  return Apollo.useMutation<
    MarkInTransitToLabMutation,
    MarkInTransitToLabMutationVariables
  >(MarkInTransitToLabDocument, baseOptions);
}
export type MarkInTransitToLabMutationHookResult = ReturnType<
  typeof useMarkInTransitToLabMutation
>;
export type MarkInTransitToLabMutationResult =
  Apollo.MutationResult<MarkInTransitToLabMutation>;
export type MarkInTransitToLabMutationOptions = Apollo.BaseMutationOptions<
  MarkInTransitToLabMutation,
  MarkInTransitToLabMutationVariables
>;
export const LinkCocPaperworkDocument = gql`
  mutation linkCOCPaperwork($input: LinkCOCPaperworkInput!) {
    linkCOCPaperwork(input: $input) {
      ...PickupLogOrderFields
    }
  }
  ${PickupLogOrderFieldsFragmentDoc}
`;
export type LinkCocPaperworkMutationFn = Apollo.MutationFunction<
  LinkCocPaperworkMutation,
  LinkCocPaperworkMutationVariables
>;

/**
 * __useLinkCocPaperworkMutation__
 *
 * To run a mutation, you first call `useLinkCocPaperworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkCocPaperworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkCocPaperworkMutation, { data, loading, error }] = useLinkCocPaperworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkCocPaperworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkCocPaperworkMutation,
    LinkCocPaperworkMutationVariables
  >,
) {
  return Apollo.useMutation<
    LinkCocPaperworkMutation,
    LinkCocPaperworkMutationVariables
  >(LinkCocPaperworkDocument, baseOptions);
}
export type LinkCocPaperworkMutationHookResult = ReturnType<
  typeof useLinkCocPaperworkMutation
>;
export type LinkCocPaperworkMutationResult =
  Apollo.MutationResult<LinkCocPaperworkMutation>;
export type LinkCocPaperworkMutationOptions = Apollo.BaseMutationOptions<
  LinkCocPaperworkMutation,
  LinkCocPaperworkMutationVariables
>;
export const PickupVehiclesDocument = gql`
  query pickupVehicles {
    vehicles {
      ...VehicleFields
    }
  }
  ${VehicleFieldsFragmentDoc}
`;

/**
 * __usePickupVehiclesQuery__
 *
 * To run a query within a React component, call `usePickupVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickupVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PickupVehiclesQuery,
    PickupVehiclesQueryVariables
  >,
) {
  return Apollo.useQuery<PickupVehiclesQuery, PickupVehiclesQueryVariables>(
    PickupVehiclesDocument,
    baseOptions,
  );
}
export function usePickupVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickupVehiclesQuery,
    PickupVehiclesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<PickupVehiclesQuery, PickupVehiclesQueryVariables>(
    PickupVehiclesDocument,
    baseOptions,
  );
}
export type PickupVehiclesQueryHookResult = ReturnType<
  typeof usePickupVehiclesQuery
>;
export type PickupVehiclesLazyQueryHookResult = ReturnType<
  typeof usePickupVehiclesLazyQuery
>;
export type PickupVehiclesQueryResult = Apollo.QueryResult<
  PickupVehiclesQuery,
  PickupVehiclesQueryVariables
>;
export const FieldTechniciansDocument = gql`
  query fieldTechnicians {
    fieldTechnicians {
      ...FieldTechnicianFields
    }
  }
  ${FieldTechnicianFieldsFragmentDoc}
`;

/**
 * __useFieldTechniciansQuery__
 *
 * To run a query within a React component, call `useFieldTechniciansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTechniciansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTechniciansQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldTechniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTechniciansQuery,
    FieldTechniciansQueryVariables
  >,
) {
  return Apollo.useQuery<FieldTechniciansQuery, FieldTechniciansQueryVariables>(
    FieldTechniciansDocument,
    baseOptions,
  );
}
export function useFieldTechniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTechniciansQuery,
    FieldTechniciansQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    FieldTechniciansQuery,
    FieldTechniciansQueryVariables
  >(FieldTechniciansDocument, baseOptions);
}
export type FieldTechniciansQueryHookResult = ReturnType<
  typeof useFieldTechniciansQuery
>;
export type FieldTechniciansLazyQueryHookResult = ReturnType<
  typeof useFieldTechniciansLazyQuery
>;
export type FieldTechniciansQueryResult = Apollo.QueryResult<
  FieldTechniciansQuery,
  FieldTechniciansQueryVariables
>;
export const RolesDocument = gql`
  query roles {
    roles {
      ...RoleFields
    }
  }
  ${RoleFieldsFragmentDoc}
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>,
) {
  return Apollo.useQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    baseOptions,
  );
}
export function useRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>,
) {
  return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    baseOptions,
  );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<
  RolesQuery,
  RolesQueryVariables
>;
export const SampleBccInfoDocument = gql`
  query sampleBccInfo($id: ID!) {
    sample(where: { id: $id }) {
      ...SampleBccInfoFields
    }
  }
  ${SampleBccInfoFieldsFragmentDoc}
`;

/**
 * __useSampleBccInfoQuery__
 *
 * To run a query within a React component, call `useSampleBccInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleBccInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleBccInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSampleBccInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SampleBccInfoQuery,
    SampleBccInfoQueryVariables
  >,
) {
  return Apollo.useQuery<SampleBccInfoQuery, SampleBccInfoQueryVariables>(
    SampleBccInfoDocument,
    baseOptions,
  );
}
export function useSampleBccInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SampleBccInfoQuery,
    SampleBccInfoQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SampleBccInfoQuery, SampleBccInfoQueryVariables>(
    SampleBccInfoDocument,
    baseOptions,
  );
}
export type SampleBccInfoQueryHookResult = ReturnType<
  typeof useSampleBccInfoQuery
>;
export type SampleBccInfoLazyQueryHookResult = ReturnType<
  typeof useSampleBccInfoLazyQuery
>;
export type SampleBccInfoQueryResult = Apollo.QueryResult<
  SampleBccInfoQuery,
  SampleBccInfoQueryVariables
>;
export const SampleReportDataDocument = gql`
  query sampleReportData($sampleId: ID!) {
    sample(where: { sampleId: $sampleId }) {
      id
      sampleId
      productName
      lotOrBatchNumber
      metrcPackage {
        sourcePackageLabels
        packageLabel
      }
      harvestOrProductionDate
      weightOrCount
      unitOfMeasure
      isCompliance
      receivedAt
      photo {
        url
        urlSchema
      }
      qrCode {
        url
        urlSchema
      }
      reportTemplateOption {
        reportTemplate {
          name
        }
      }
      matrix {
        ...SampleMatrixFields
        sampleCategory {
          ...SampleCategoryFields
        }
      }
      distributor {
        ...DistributorFields
      }
      producer {
        ...ProducerFields
      }
      orderedPanels {
        id
        name
        standardOperatingProcedures {
          id
          name
        }
      }
      order {
        id
        scheduledPickup {
          dateTime
        }
        sampleSubmission {
          id
          submittedBy {
            id
            business {
              ...BusinessFields
            }
          }
        }
      }
      reportFirstPage
      accession {
        accessionedAt
        amountPerServing {
          ...MeasurementFields
        }
        amountPerPackage {
          ...MeasurementFields
        }
        accessionAmount {
          ...MeasurementFields
        }
      }
      homogenization {
        intakeMeasurement {
          ...MeasurementFields
        }
      }
      sampleApproval {
        approvedAt
        approvedBy {
          ...UserName
          ...UserName
          title
          signatureFile {
            ...FileFields
          }
        }
      }
      complianceType {
        id
        name
        stateReportingRegulations {
          ...StateReportingRegulationFields
          referenceComponent {
            ...ReferenceComponentFields
          }
        }
      }
      panelResults {
        instrumentUsed {
          ...InstrumentFields
          instrumentParameters {
            ...InstrumentParameterFields
            referenceComponent {
              ...ReferenceComponentFields
            }
            panel {
              ...PanelFields
            }
          }
        }
        results
        completedAt
        panel {
          ...PanelFields
          standardOperatingProcedures {
            ...SOPFields
          }
        }
      }
      sampleApproval {
        approvedAt
        approvedBy {
          ...UserName
          title
          signatureFile {
            ...FileFields
          }
        }
      }
      sampleQAEntry {
        verifiedAt
        verifiedBy {
          ...UserName
          title
          signatureFile {
            ...FileFields
          }
        }
      }
    }
  }
  ${SampleMatrixFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
  ${DistributorFieldsFragmentDoc}
  ${ProducerFieldsFragmentDoc}
  ${BusinessFieldsFragmentDoc}
  ${MeasurementFieldsFragmentDoc}
  ${UserNameFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${StateReportingRegulationFieldsFragmentDoc}
  ${ReferenceComponentFieldsFragmentDoc}
  ${InstrumentFieldsFragmentDoc}
  ${InstrumentParameterFieldsFragmentDoc}
  ${PanelFieldsFragmentDoc}
  ${SopFieldsFragmentDoc}
`;

/**
 * __useSampleReportDataQuery__
 *
 * To run a query within a React component, call `useSampleReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleReportDataQuery({
 *   variables: {
 *      sampleId: // value for 'sampleId'
 *   },
 * });
 */
export function useSampleReportDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SampleReportDataQuery,
    SampleReportDataQueryVariables
  >,
) {
  return Apollo.useQuery<SampleReportDataQuery, SampleReportDataQueryVariables>(
    SampleReportDataDocument,
    baseOptions,
  );
}
export function useSampleReportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SampleReportDataQuery,
    SampleReportDataQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    SampleReportDataQuery,
    SampleReportDataQueryVariables
  >(SampleReportDataDocument, baseOptions);
}
export type SampleReportDataQueryHookResult = ReturnType<
  typeof useSampleReportDataQuery
>;
export type SampleReportDataLazyQueryHookResult = ReturnType<
  typeof useSampleReportDataLazyQuery
>;
export type SampleReportDataQueryResult = Apollo.QueryResult<
  SampleReportDataQuery,
  SampleReportDataQueryVariables
>;
export const ReleaseReportToClientDocument = gql`
  mutation releaseReportToClient($sampleId: ID!) {
    releaseReport(sampleId: $sampleId) {
      id
      status
      reportReleasedToClient
    }
  }
`;
export type ReleaseReportToClientMutationFn = Apollo.MutationFunction<
  ReleaseReportToClientMutation,
  ReleaseReportToClientMutationVariables
>;

/**
 * __useReleaseReportToClientMutation__
 *
 * To run a mutation, you first call `useReleaseReportToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseReportToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseReportToClientMutation, { data, loading, error }] = useReleaseReportToClientMutation({
 *   variables: {
 *      sampleId: // value for 'sampleId'
 *   },
 * });
 */
export function useReleaseReportToClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReleaseReportToClientMutation,
    ReleaseReportToClientMutationVariables
  >,
) {
  return Apollo.useMutation<
    ReleaseReportToClientMutation,
    ReleaseReportToClientMutationVariables
  >(ReleaseReportToClientDocument, baseOptions);
}
export type ReleaseReportToClientMutationHookResult = ReturnType<
  typeof useReleaseReportToClientMutation
>;
export type ReleaseReportToClientMutationResult =
  Apollo.MutationResult<ReleaseReportToClientMutation>;
export type ReleaseReportToClientMutationOptions = Apollo.BaseMutationOptions<
  ReleaseReportToClientMutation,
  ReleaseReportToClientMutationVariables
>;
export const SampleTrackerDocument = gql`
  query sampleTracker {
    me {
      id
      business {
        id
        samples {
          ...SampleTrackerFields
        }
      }
    }
  }
  ${SampleTrackerFieldsFragmentDoc}
`;

/**
 * __useSampleTrackerQuery__
 *
 * To run a query within a React component, call `useSampleTrackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleTrackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleTrackerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSampleTrackerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SampleTrackerQuery,
    SampleTrackerQueryVariables
  >,
) {
  return Apollo.useQuery<SampleTrackerQuery, SampleTrackerQueryVariables>(
    SampleTrackerDocument,
    baseOptions,
  );
}
export function useSampleTrackerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SampleTrackerQuery,
    SampleTrackerQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SampleTrackerQuery, SampleTrackerQueryVariables>(
    SampleTrackerDocument,
    baseOptions,
  );
}
export type SampleTrackerQueryHookResult = ReturnType<
  typeof useSampleTrackerQuery
>;
export type SampleTrackerLazyQueryHookResult = ReturnType<
  typeof useSampleTrackerLazyQuery
>;
export type SampleTrackerQueryResult = Apollo.QueryResult<
  SampleTrackerQuery,
  SampleTrackerQueryVariables
>;
export const SampleLogDocument = gql`
  query sampleLog($where: SampleWhereInput) {
    samples(where: $where) {
      ...BaseSampleFields
      status
      receivedAt
      isCompliance
      matrix {
        ...SampleMatrixFields
        sampleCategory {
          ...SampleCategoryFields
          intendedUse {
            ...IntendedUseFields
          }
        }
      }
      processSteps {
        ...ProcessStepFields
      }
      customerNotes
      order {
        id
        shortId
        sampleSubmission {
          id
          submittedAt
          submittedBy {
            id
            business {
              id
              name
            }
          }
        }
      }
      accession {
        isRushOrder
        dueDate
      }
      photo {
        ...FileFields
      }
      reportReleasedToClient
      report {
        ...FileFields
      }
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${SampleMatrixFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
  ${IntendedUseFieldsFragmentDoc}
  ${ProcessStepFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
`;

/**
 * __useSampleLogQuery__
 *
 * To run a query within a React component, call `useSampleLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleLogQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSampleLogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SampleLogQuery,
    SampleLogQueryVariables
  >,
) {
  return Apollo.useQuery<SampleLogQuery, SampleLogQueryVariables>(
    SampleLogDocument,
    baseOptions,
  );
}
export function useSampleLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SampleLogQuery,
    SampleLogQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SampleLogQuery, SampleLogQueryVariables>(
    SampleLogDocument,
    baseOptions,
  );
}
export type SampleLogQueryHookResult = ReturnType<typeof useSampleLogQuery>;
export type SampleLogLazyQueryHookResult = ReturnType<
  typeof useSampleLogLazyQuery
>;
export type SampleLogQueryResult = Apollo.QueryResult<
  SampleLogQuery,
  SampleLogQueryVariables
>;
export const PanelLogDocument = gql`
  query panelLog($panelId: String!, $statusFilter: SampleStatusListFilter) {
    samples(
      where: { orderedPanelIds: { contains: $panelId }, status: $statusFilter }
    ) {
      ...PanelLogFields
    }
  }
  ${PanelLogFieldsFragmentDoc}
`;

/**
 * __usePanelLogQuery__
 *
 * To run a query within a React component, call `usePanelLogQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelLogQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function usePanelLogQuery(
  baseOptions?: Apollo.QueryHookOptions<PanelLogQuery, PanelLogQueryVariables>,
) {
  return Apollo.useQuery<PanelLogQuery, PanelLogQueryVariables>(
    PanelLogDocument,
    baseOptions,
  );
}
export function usePanelLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PanelLogQuery,
    PanelLogQueryVariables
  >,
) {
  return Apollo.useLazyQuery<PanelLogQuery, PanelLogQueryVariables>(
    PanelLogDocument,
    baseOptions,
  );
}
export type PanelLogQueryHookResult = ReturnType<typeof usePanelLogQuery>;
export type PanelLogLazyQueryHookResult = ReturnType<
  typeof usePanelLogLazyQuery
>;
export type PanelLogQueryResult = Apollo.QueryResult<
  PanelLogQuery,
  PanelLogQueryVariables
>;
export const ReadyForQaSamplesDocument = gql`
  query readyForQaSamples($statusFilter: SampleStatusListFilter) {
    samples(where: { status: $statusFilter }) {
      id
      productName
      sampleId
      customerNotes
      order {
        sampleSubmission {
          submittedBy {
            business {
              name
            }
          }
          submittedAt
        }
      }
    }
  }
`;

/**
 * __useReadyForQaSamplesQuery__
 *
 * To run a query within a React component, call `useReadyForQaSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadyForQaSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadyForQaSamplesQuery({
 *   variables: {
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useReadyForQaSamplesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReadyForQaSamplesQuery,
    ReadyForQaSamplesQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadyForQaSamplesQuery,
    ReadyForQaSamplesQueryVariables
  >(ReadyForQaSamplesDocument, baseOptions);
}
export function useReadyForQaSamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadyForQaSamplesQuery,
    ReadyForQaSamplesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadyForQaSamplesQuery,
    ReadyForQaSamplesQueryVariables
  >(ReadyForQaSamplesDocument, baseOptions);
}
export type ReadyForQaSamplesQueryHookResult = ReturnType<
  typeof useReadyForQaSamplesQuery
>;
export type ReadyForQaSamplesLazyQueryHookResult = ReturnType<
  typeof useReadyForQaSamplesLazyQuery
>;
export type ReadyForQaSamplesQueryResult = Apollo.QueryResult<
  ReadyForQaSamplesQuery,
  ReadyForQaSamplesQueryVariables
>;
export const SampleStatisticsDocument = gql`
  query sampleStatistics($input: AnalyticsDateInput) {
    sampleStatistics(dateRange: $input) {
      sampleCount
      uniqueClientCount
      completedSampleCount
      complianceSampleCount
      rushedSampleCount
      averageDaysToCompletion
      sampleStatusCount {
        count
        status
      }
      completedSamplesCountByMonth {
        month
        count
      }
      completedClientSampleCount
    }
  }
`;

/**
 * __useSampleStatisticsQuery__
 *
 * To run a query within a React component, call `useSampleStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSampleStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SampleStatisticsQuery,
    SampleStatisticsQueryVariables
  >,
) {
  return Apollo.useQuery<SampleStatisticsQuery, SampleStatisticsQueryVariables>(
    SampleStatisticsDocument,
    baseOptions,
  );
}
export function useSampleStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SampleStatisticsQuery,
    SampleStatisticsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    SampleStatisticsQuery,
    SampleStatisticsQueryVariables
  >(SampleStatisticsDocument, baseOptions);
}
export type SampleStatisticsQueryHookResult = ReturnType<
  typeof useSampleStatisticsQuery
>;
export type SampleStatisticsLazyQueryHookResult = ReturnType<
  typeof useSampleStatisticsLazyQuery
>;
export type SampleStatisticsQueryResult = Apollo.QueryResult<
  SampleStatisticsQuery,
  SampleStatisticsQueryVariables
>;
export const UpsertTestPrepInfoDocument = gql`
  mutation upsertTestPrepInfo(
    $testPrepInput: UpsertSamplePanelTestPrepInput!
    $aliquotInput: UpsertSampleAliquotsInput!
    $panelId: String!
  ) {
    upsertSampleAliquots(input: $aliquotInput) {
      id
    }
    upsertSamplePanelTestPrep(input: $testPrepInput) {
      ...PanelLogFields
    }
  }
  ${PanelLogFieldsFragmentDoc}
`;
export type UpsertTestPrepInfoMutationFn = Apollo.MutationFunction<
  UpsertTestPrepInfoMutation,
  UpsertTestPrepInfoMutationVariables
>;

/**
 * __useUpsertTestPrepInfoMutation__
 *
 * To run a mutation, you first call `useUpsertTestPrepInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTestPrepInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTestPrepInfoMutation, { data, loading, error }] = useUpsertTestPrepInfoMutation({
 *   variables: {
 *      testPrepInput: // value for 'testPrepInput'
 *      aliquotInput: // value for 'aliquotInput'
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useUpsertTestPrepInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTestPrepInfoMutation,
    UpsertTestPrepInfoMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpsertTestPrepInfoMutation,
    UpsertTestPrepInfoMutationVariables
  >(UpsertTestPrepInfoDocument, baseOptions);
}
export type UpsertTestPrepInfoMutationHookResult = ReturnType<
  typeof useUpsertTestPrepInfoMutation
>;
export type UpsertTestPrepInfoMutationResult =
  Apollo.MutationResult<UpsertTestPrepInfoMutation>;
export type UpsertTestPrepInfoMutationOptions = Apollo.BaseMutationOptions<
  UpsertTestPrepInfoMutation,
  UpsertTestPrepInfoMutationVariables
>;
export const SampleDocument = gql`
  query sample($where: SampleWhereUniqueInput!) {
    sample(where: $where) {
      ...BaseSampleFields
      status
      productName
      sourceMaterial
      isCompliance
      lotOrBatchNumber
      reportTemplateOption {
        id
      }
      matrix {
        ...SampleMatrixFields
        sampleCategory {
          ...SampleCategoryFields
          intendedUse {
            ...IntendedUseFields
          }
        }
      }
      harvestOrProductionDate
      weightOrCount
      unitOfMeasure
      processSteps {
        ...ProcessStepFields
      }
      analysisOptions {
        id
        name
        price
      }
      deliveryOption {
        id
        description
      }
      customerNotes
      producer {
        ...ProducerFields
      }
      distributor {
        ...DistributorFields
      }
      metrcPackage {
        sourcePackageLabels
        packageLabel
      }
      readyForPickupDate
      order {
        id
        status
        shortId
        sampleSubmission {
          id
          submittedAt
          submittedBy {
            id
            email
            business {
              ...BusinessFields
            }
          }
        }
      }
      photo {
        id
        url
      }
      qrCode {
        id
        url
      }
      reportFirstPage
      accession {
        accessionAmount {
          ...MeasurementFields
        }
        amountPerServing {
          ...MeasurementFields
        }
        amountPerPackage {
          ...MeasurementFields
        }
        pickupAmount {
          ...MeasurementFields
        }
        isPrepackaged
        labelClaim
        isRushOrder
        dueDate
      }
      complianceType {
        id
        name
      }
    }
  }
  ${BaseSampleFieldsFragmentDoc}
  ${SampleMatrixFieldsFragmentDoc}
  ${SampleCategoryFieldsFragmentDoc}
  ${IntendedUseFieldsFragmentDoc}
  ${ProcessStepFieldsFragmentDoc}
  ${ProducerFieldsFragmentDoc}
  ${DistributorFieldsFragmentDoc}
  ${BusinessFieldsFragmentDoc}
  ${MeasurementFieldsFragmentDoc}
`;

/**
 * __useSampleQuery__
 *
 * To run a query within a React component, call `useSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSampleQuery(
  baseOptions?: Apollo.QueryHookOptions<SampleQuery, SampleQueryVariables>,
) {
  return Apollo.useQuery<SampleQuery, SampleQueryVariables>(
    SampleDocument,
    baseOptions,
  );
}
export function useSampleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SampleQuery, SampleQueryVariables>,
) {
  return Apollo.useLazyQuery<SampleQuery, SampleQueryVariables>(
    SampleDocument,
    baseOptions,
  );
}
export type SampleQueryHookResult = ReturnType<typeof useSampleQuery>;
export type SampleLazyQueryHookResult = ReturnType<typeof useSampleLazyQuery>;
export type SampleQueryResult = Apollo.QueryResult<
  SampleQuery,
  SampleQueryVariables
>;
export const SignUpCustomerWithNewBusinessDocument = gql`
  mutation signUpCustomerWithNewBusiness($input: SignUpWithNewBusinessInput!) {
    signUpWithNewBusiness(input: $input) {
      newUser {
        id
      }
      loginToken
    }
  }
`;
export type SignUpCustomerWithNewBusinessMutationFn = Apollo.MutationFunction<
  SignUpCustomerWithNewBusinessMutation,
  SignUpCustomerWithNewBusinessMutationVariables
>;

/**
 * __useSignUpCustomerWithNewBusinessMutation__
 *
 * To run a mutation, you first call `useSignUpCustomerWithNewBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpCustomerWithNewBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpCustomerWithNewBusinessMutation, { data, loading, error }] = useSignUpCustomerWithNewBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpCustomerWithNewBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpCustomerWithNewBusinessMutation,
    SignUpCustomerWithNewBusinessMutationVariables
  >,
) {
  return Apollo.useMutation<
    SignUpCustomerWithNewBusinessMutation,
    SignUpCustomerWithNewBusinessMutationVariables
  >(SignUpCustomerWithNewBusinessDocument, baseOptions);
}
export type SignUpCustomerWithNewBusinessMutationHookResult = ReturnType<
  typeof useSignUpCustomerWithNewBusinessMutation
>;
export type SignUpCustomerWithNewBusinessMutationResult =
  Apollo.MutationResult<SignUpCustomerWithNewBusinessMutation>;
export type SignUpCustomerWithNewBusinessMutationOptions =
  Apollo.BaseMutationOptions<
    SignUpCustomerWithNewBusinessMutation,
    SignUpCustomerWithNewBusinessMutationVariables
  >;
export const SubmitSamplesDocument = gql`
  mutation submitSamples($input: CreateSampleSubmissionInput!) {
    submitSamples(input: $input) {
      id
      order {
        id
        samples {
          id
        }
      }
    }
  }
`;
export type SubmitSamplesMutationFn = Apollo.MutationFunction<
  SubmitSamplesMutation,
  SubmitSamplesMutationVariables
>;

/**
 * __useSubmitSamplesMutation__
 *
 * To run a mutation, you first call `useSubmitSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSamplesMutation, { data, loading, error }] = useSubmitSamplesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSamplesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSamplesMutation,
    SubmitSamplesMutationVariables
  >,
) {
  return Apollo.useMutation<
    SubmitSamplesMutation,
    SubmitSamplesMutationVariables
  >(SubmitSamplesDocument, baseOptions);
}
export type SubmitSamplesMutationHookResult = ReturnType<
  typeof useSubmitSamplesMutation
>;
export type SubmitSamplesMutationResult =
  Apollo.MutationResult<SubmitSamplesMutation>;
export type SubmitSamplesMutationOptions = Apollo.BaseMutationOptions<
  SubmitSamplesMutation,
  SubmitSamplesMutationVariables
>;
export const UsersDocument = gql`
  query users {
    users {
      ...UserInfoFields
    }
  }
  ${UserInfoFieldsFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UserDocument = gql`
  query user($id: String!) {
    user(id: $id) {
      ...UserInfoFields
    }
  }
  ${UserInfoFieldsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  return Apollo.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions,
  );
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UserInfoFields
    }
  }
  ${UserInfoFieldsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const ToggleUserDocument = gql`
  mutation toggleUser($userId: ID!) {
    toggleUser(userId: $userId) {
      id
      active
    }
  }
`;
export type ToggleUserMutationFn = Apollo.MutationFunction<
  ToggleUserMutation,
  ToggleUserMutationVariables
>;

/**
 * __useToggleUserMutation__
 *
 * To run a mutation, you first call `useToggleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserMutation, { data, loading, error }] = useToggleUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useToggleUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserMutation,
    ToggleUserMutationVariables
  >,
) {
  return Apollo.useMutation<ToggleUserMutation, ToggleUserMutationVariables>(
    ToggleUserDocument,
    baseOptions,
  );
}
export type ToggleUserMutationHookResult = ReturnType<
  typeof useToggleUserMutation
>;
export type ToggleUserMutationResult =
  Apollo.MutationResult<ToggleUserMutation>;
export type ToggleUserMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserMutation,
  ToggleUserMutationVariables
>;
