import { Box, Container, Grid, Typography, useTheme } from "@material-ui/core";
import firebase from "firebase/app";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useLocation } from "react-router-dom";
import Logo from "src/assets/img/T20 logo_RGB - full color-small-min.png";
import CollapseAlert from "src/components/CollapseAlert";
import FormTextField from "src/components/forms/components/FormikTextField";
import LoadingButton from "src/components/LoadingButton";
import PageLoadingIndicator from "src/components/PageLoadingInicator";
import { useCurrentUser } from "src/hooks/useCurrentUser";

interface FormValues {
  email: string;
  password: string;
}

const initialValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const theme = useTheme();
  const { loading, currentUser } = useCurrentUser();
  const location = useLocation<{ from?: { pathname: string } }>();

  const _handleSignInSubmission = useCallback(
    async (
      { email, password }: FormValues,
      { setStatus }: FormikHelpers<FormValues>,
    ) => {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
      } catch (err) {
        setStatus({
          alert: {
            show: true,
            message: "Invalid email or password",
            severity: "error",
          },
        });
      }
    },
    [],
  );

  if (loading) return <PageLoadingIndicator />;

  if (currentUser && location.state?.from?.pathname)
    return <Redirect to={location.state.from.pathname} />;

  if (currentUser) return <Redirect to="/app" />;

  return (
    <Container
      maxWidth="xs"
      style={{ height: "100vh", display: "flex", alignItems: "center" }}
    >
      <Helmet>
        <title>Think20 Labs Portal - Sign In</title>
      </Helmet>

      <Formik initialValues={initialValues} onSubmit={_handleSignInSubmission}>
        {({ status, isSubmitting }) => (
          <Form noValidate data-testid="sign-in-form">
            <Grid container>
              <Grid item xs={12} container justify="center">
                <Box mb={3}>
                  <img
                    src={Logo}
                    alt="Think20 Labs Logo"
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <CollapseAlert
                  open={!!status?.alert?.show}
                  severity={status?.alert?.severity}
                >
                  {status?.alert?.message}
                </CollapseAlert>
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="email"
                  fullWidth
                  label="Email"
                  autoComplete="email"
                  id="email"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="password"
                  fullWidth
                  label="Password"
                  autoComplete="current-password"
                  id="password"
                  type="password"
                  margin="dense"
                  helperText={
                    <Box display="flex" justifyContent="flex-end">
                      <Link
                        to="/forgot-password"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.hint,
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt={5}>
                  <LoadingButton
                    loading={isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    Sign In
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Box mt={3}>
                  <Link
                    to="/sign-up"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Typography color="textSecondary">
                      Don't have an account? Sign up now
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default SignIn;
