import * as R from "ramda";
import {
  PanelResultFieldsFragment,
  SampleBccInfoFieldsFragment,
} from "src/generated/graphql-hooks";

const DECARB_FACTOR = 0.877;

const calculateDecarboxylatedValue = (value: number) => DECARB_FACTOR * value;

export const calculateTotalTHC = (THCA: number, d9THC: number) =>
  calculateDecarboxylatedValue(THCA) + d9THC;

export const calculateTotalCBD = (CBDA: number, CBD: number) =>
  calculateDecarboxylatedValue(CBDA) + CBD;

export const convertPpmToPercent = (value: number) => value / 10_000;

export const correctForMoisture = (
  componentValue: number,
  moisturePercentage: number = 0,
) => componentValue / (1 - moisturePercentage / 100);

export const correctForDensity = (
  componentValue: number,
  density: number = 1,
) => componentValue * density;

export const deriveTotalCBD = (
  result?: PanelResultFieldsFragment,
  moistureContentPercentage: number = 0,
  density: number = 1,
) =>
  result
    ? calculateTotalCBD(
        correctForDensity(
          correctForMoisture(
            findAnalyteResults({ rawName: "CBDA" })(result.results.components)
              ?.final_amount ?? 0,
            moistureContentPercentage,
          ),
          density,
        ),
        correctForDensity(
          correctForMoisture(
            findAnalyteResults({ rawName: "CBD" })(result.results.components)
              ?.final_amount ?? 0,
            moistureContentPercentage,
          ),
          density,
        ),
      )
    : 0;

export const deriveTotalTHC = (
  result?: PanelResultFieldsFragment,
  moistureContentPercentage: number = 0,
  density: number = 1,
) =>
  result
    ? calculateTotalTHC(
        correctForDensity(
          correctForMoisture(
            findAnalyteResults({ rawName: "THCA" })(result.results.components)
              ?.final_amount ?? 0,
            moistureContentPercentage,
          ),
          density,
        ),
        correctForDensity(
          correctForMoisture(
            findAnalyteResults({ rawName: "d9-THC" })(result.results.components)
              ?.final_amount ?? 0,
            moistureContentPercentage,
          ),
          density,
        ),
      )
    : 0;

export const findAnalyteResults = ({
  rawName,
  analyteProp = "name",
}: {
  rawName: string;
  analyteProp?: string;
}) => R.find<any>(R.propEq(analyteProp, rawName));

export const getMoistureContent = (sample: SampleBccInfoFieldsFragment) =>
  sample.panelResults.find(({ panel }) => panel.name === "Moisture Content")
    ?.results?.moistureContent ?? 0;

export const getDensity = (sample: SampleBccInfoFieldsFragment) =>
  sample.panelResults
    .find(({ panel }) => panel.name === "Density")
    ?.results?.components?.find(
      (component: any) => component.name === "density",
    )?.final_amount ?? 1;
