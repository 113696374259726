import type { SampleValues } from ".";

export const analysisOptions = [
  { id: "1", name: "Full Panel (Excludes Homogeneity)", price: 800 },
  { id: "2", name: "Potency Profile", price: 125 },
  { id: "3", name: "Terpene (GC-MS) Profile", price: 100 },
  { id: "4", name: "Pesticides/Mycotoxin", price: 250 },
  { id: "5", name: "Residual Solvent (GC-MS) Testing", price: 100 },
  { id: "6", name: "Heavy Metals (ICP-MS) Screening", price: 150 },
  { id: "7", name: "Homogeneity", price: 400 },
  { id: "8", name: "Microbial Testing", price: 150 },
];

export const INTENDED_USE_TO_SAMPLE_CATEGORIES_MAPPING = [
  {
    category: "Inhalable Goods",
    intendedUses: ["Inhalable"],
    categoryOptions: [
      "Flower/Plant",
      "Pre-Roll",
      "Inhalable Concentrate - Weight",
    ],
  },
  {
    category: "Non Inhalable Goods",
    intendedUses: ["Non Inhalable"],
    categoryOptions: [
      "Edible - Weight",
      "Edible - Volume",
      "Non Inhalable Cannabis Product",
      "Orally Consumed Cannabis Product - Volume",
      "Topical - Weight",
      "Topical - Volume",
    ],
  },
  {
    category: "Other",
    intendedUses: ["Other"],
    categoryOptions: ["Non Cannabinoid"],
  },
];

export const weightOrCountUnits = ["g", "kg", "lb", "mL", "units"];

export const deliveryMethods = [
  {
    id: "1",
    description: "Ship To: Think20 Labs 3 Mason, Irvine, CA 92618",
    display: (index: number, samples?: SampleValues[]) =>
      samples?.[index]?.sourceMaterial !== "Cannabis",
    shouldDisplay: {
      "!==": [{ var: "sourceMaterial" }, "Cannabis"] as JsonLogicConditional,
    },
  },
  {
    id: "2",
    description:
      "Schedule my facility for a pickup at the account address above between 9am and 5pm",
    display: (index: number, samples?: SampleValues[]) =>
      samples?.some((sample) => sample.sourceMaterial === "Cannabis"),
    shouldDisplay: {
      "===": [{ var: "sourceMaterial" }, "Cannabis"] as JsonLogicConditional,
    },
  },
  {
    id: "3",
    description: "Drop Off: Think20 Labs 3 Mason, Irvine, CA 92618",
    display: (index: number, samples?: SampleValues[]) =>
      samples?.[index]?.compliance !== "Yes",
    shouldDisplay: {
      "!==": [{ var: "compliance" }, "Yes"] as JsonLogicConditional,
    },
  },
  {
    id: "4",
    description:
      "Schedule my facility for a pickup at the address entered in the notes section of this submission between 9am and 5pm",
    display: (index: number, samples?: SampleValues[]) =>
      samples?.some(
        (sample) =>
          sample.compliance !== "Yes" && sample.sourceMaterial === "Cannabis",
      ),
    shouldDisplay: {
      and: [
        {
          "!==": [{ var: "compliance" }, "Yes"] as JsonLogicConditional,
        },
        {
          "===": [
            { var: "sourceMaterial" },
            "Cannabis",
          ] as JsonLogicConditional,
        },
      ],
    },
  },
];

type JsonLogicConditional = [any, any];

export const COA_OPTIONS = [
  {
    id: "1",
    label: "Include Pass/Fail Criteria on COA",
    shouldDisplay: "true",
  },
  {
    id: "2",
    label: "Tested/Not Tested Criteria on COA",
    shouldDisplay: {
      "!==": [{ var: "compliance" }, "Yes"] as JsonLogicConditional,
    },
  },
];

export const BLANK_SAMPLE: SampleValues = {
  sourceMaterial: "",
  compliance: "",
  forMedicalUse: false,
  productName: "",
  lotOrBatchNumber: "",
  category: "",
  matrix: "",
  harvestOrProductionDate: new Date(),
  weightOrCount: 0,
  unitOfMeasure: "",
  reportTemplateOptionId: "",
  intendedUse: "",
  analysisOptionIds: [],
  deliveryMethodId: "",
  notes: "",
  distributorId: "",
  producerId: "",
  readyForPickup: new Date(),
};

export const INTENDED_USES = ["Inhalable", "Non Inhalable"];
