import { ErrorMessage } from "@hookform/error-message";
import { Box } from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import * as React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useFormContext,
} from "react-hook-form";

interface FormFileUploadFieldProps {
  name: string;
  control?: Control;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

const FormFileUploadField: React.FunctionComponent<FormFileUploadFieldProps> = ({
  name,
  control,
  errors,
}) => {
  const formContext = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={(props) => (
        <>
          <DropzoneAreaBase
            {...props}
            fileObjects={props.value ? [props.value] : []}
            onAdd={(files) => {
              props.onChange(files[0]);
            }}
            onDelete={() => {
              props.onChange(null);
            }}
            filesLimit={1}
            showAlerts={false}
            useChipsForPreview
            showPreviewsInDropzone={false}
            showPreviews
            maxFileSize={1000000000}
            onAlert={(message, variant) => console.log(message, variant)}
          />
          <Box color="error.main">
            <ErrorMessage
              name={name}
              errors={errors || formContext?.errors || {}}
            />
          </Box>
        </>
      )}
    />
  );
};

export default FormFileUploadField;
