import { NetworkStatus } from "@apollo/client";
import { Box, Container } from "@material-ui/core";
import * as R from "ramda";
import * as RA from "ramda-adjunct";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicTable from "src/components/BasicTable";
import { BalanceColumn } from "src/components/PanelTable/columns";
import {
  DueDateColumn,
  EditColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  AliquotLogEntryFragment,
  AliquotLogEntryFragmentDoc,
  PanelLogDocument,
  SampleStatus,
  useAliquotLogQuery,
  useUpsertAliquotsMutation,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import {
  buildCannabinoidsAliquotColumn,
  buildDensityAliquotColumn,
  buildHeavyMetalsAliquotColumn,
  buildMicrobialAliquotColumn,
  buildMoistureContentAliquotColumn,
  buildMycotoxinAliquotColumn,
  buildResidualSolventsAliquotColumn,
  buildTerpenesAliquotColumn,
  buildWaterActivityAliquotColumn,
} from "./columns";

type AliquotCellField = {
  panelId: string;
  amount: string;
  unitOfMeasure: "grams" | "milliliters";
};

interface AliquotFormFields {
  balanceId: string;
  microbial: AliquotCellField;
  cannabinoids: AliquotCellField;
  terpenes: AliquotCellField;
  mycotoxin: AliquotCellField;
  solvents: AliquotCellField;
  moistureContent: AliquotCellField;
  waterActivity: AliquotCellField;
  heavyMetals: AliquotCellField;
  density: AliquotCellField;
}

export default function AliquotPage() {
  const { data: remoteData, networkStatus } = useAliquotLogQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        status: {
          in: [
            SampleStatus.InProcess,
            SampleStatus.QaReview,
            SampleStatus.PendingComplete,
          ],
        },
      },
    },
  });
  const { data, triggerDataUpdate } = useTableData({
    remoteData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  const panels = React.useMemo(() => remoteData?.availablePanels ?? [], [
    remoteData,
  ]);

  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);

  const [upsertAliquots] = useUpsertAliquotsMutation({
    refetchQueries: panels.map(({ id }) => ({
      query: PanelLogDocument,
      variables: {
        panelId: id,
      },
    })),
    update(cache, { data }) {
      const currentSample = cache.readFragment<AliquotLogEntryFragment>({
        id: `Sample:${editingRowId}`,
        fragment: AliquotLogEntryFragmentDoc,
        fragmentName: "AliquotLogEntry",
      });
      if (currentSample) {
        cache.writeFragment({
          id: `Sample:${editingRowId}`,
          fragment: AliquotLogEntryFragmentDoc,
          fragmentName: "AliquotLogEntry",
          data: {
            ...currentSample,
            aliquots: data?.upsertSampleAliquots.aliquots,
          },
        });
      }
    },
  });

  const columns = React.useMemo(() => {
    return [
      EditColumn,
      SampleNameColumn,
      SampleIdColumn,
      RushColumn,
      DueDateColumn,
      BalanceColumn(true),
      ...[
        buildMicrobialAliquotColumn(panels),
        buildCannabinoidsAliquotColumn(panels),
        buildTerpenesAliquotColumn(panels),
        buildMycotoxinAliquotColumn(panels),
        buildResidualSolventsAliquotColumn(panels),
        buildMoistureContentAliquotColumn(panels),
        buildWaterActivityAliquotColumn(panels),
        buildHeavyMetalsAliquotColumn(panels),
        buildDensityAliquotColumn(panels),
      ].filter((item) => !!item),
    ];
  }, [panels]);
  const methods = useForm<AliquotFormFields>();
  const { handleSubmit } = methods;

  return (
    <Container maxWidth="xl">
      <Box py={3}>
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={handleSubmit(async (values) => {
              if (!editingRowId) return;
              try {
                await upsertAliquots({
                  variables: {
                    input: {
                      sampleId: editingRowId,
                      balanceId: values.balanceId,
                      aliquots: Object.values(values)
                        .filter(isValidAliquotCellField)
                        .map(({ panelId, amount, unitOfMeasure }) => ({
                          panelId,
                          amount: parseFloat(amount),
                          unitOfMeasure,
                        })),
                    },
                  },
                });
                triggerDataUpdate();
                setEditingRowId(null);
              } catch (err) {
                console.error(err);
              }
            })}
          >
            <BasicTable
              title="Aliquot"
              columns={columns}
              data={data?.samples ?? []}
              editingRowId={editingRowId}
              customActions={{
                onEditRowClick: (rowId) => setEditingRowId(rowId),
                onCancelRowClick: () => setEditingRowId(null),
              }}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </form>
        </FormProvider>
      </Box>
    </Container>
  );
}

const isValidAliquotCellField = (obj: any) =>
  R.has("panelId")(obj) &&
  RA.isValidNumber(parseFloat(obj?.amount)) &&
  RA.isNonEmptyString(obj?.unitOfMeasure);
