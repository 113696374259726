import { NetworkStatus } from "@apollo/client";
import * as React from "react";
import { usePanelProcessingJobsQuery } from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import BasicTable from "../BasicTable";
import ErrorCard from "../ErrorCard";
import LoadingCard from "../LoadingCard";
import Columns from "./columns";

const POLLING_INTERVAL = 10_000;

const PanelProcessingJobsTable = () => {
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);
  const {
    data: remoteData,
    error,
    networkStatus,
  } = usePanelProcessingJobsQuery({
    pollInterval: POLLING_INTERVAL,
    notifyOnNetworkStatusChange: true,
  });
  const { data } = useTableData({
    remoteData,
    isFetching: networkStatus < NetworkStatus.ready,
  });
  const columns = React.useMemo(() => Columns, []);
  if (networkStatus === NetworkStatus.loading)
    return <LoadingCard message={`Loading processing jobs...`} />;
  if (error) return <ErrorCard />;
  return (
    <BasicTable
      title="Processing Jobs"
      columns={columns}
      data={data?.panelProcessingJobs ?? []}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
  );
};

export default PanelProcessingJobsTable;
