import { ErrorMessage } from "@hookform/error-message";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import type { Maybe } from "graphql/jsutils/Maybe";
import jsonLogic from "json-logic-js";
import React, { FunctionComponent, useState } from "react";
import { Controller, get, useFormContext, useWatch } from "react-hook-form";
import ExpandButton from "src/components/ExpandButton";
import {
  Address,
  Distributor,
  Producer,
  useIntendedUsesQuery,
} from "src/generated/graphql-hooks";
import type { SampleValues } from "..";
import FormCheckboxField from "../../components/FormCheckboxField";
import FormDatePicker from "../../components/FormDatePicker";
import FormRadioGroupField from "../../components/FormRadioGroupField";
import FormTextField from "../../components/FormTextField";
import {
  analysisOptions,
  BLANK_SAMPLE,
  COA_OPTIONS,
  deliveryMethods,
  weightOrCountUnits,
} from "../config";

interface SampleSectionProps {
  index: number;
  onDelete: (index: number) => void;
  allowDelete?: boolean;
  onAddDistributor: (index: number) => void;
  onAddProducer: (index: number) => void;
  producers: Maybe<
    { __typename?: "Producer" } & Pick<
      Producer,
      "id" | "name" | "licenseNumber"
    > & {
        address: { __typename?: "Address" } & Pick<
          Address,
          "street1" | "city" | "state" | "zip"
        >;
      }
  >[];
  distributors: Maybe<
    { __typename?: "Distributor" } & Pick<
      Distributor,
      "id" | "name" | "licenseNumber"
    > & {
        address: { __typename?: "Address" } & Pick<
          Address,
          "street1" | "city" | "state" | "zip"
        >;
      }
  >[];
}

const isCompliance = (sample: SampleValues) => sample.compliance === "Yes";

const SampleSection: FunctionComponent<SampleSectionProps> = ({
  index,
  allowDelete,
  onDelete,
  distributors = [],
  producers = [],
  onAddDistributor,
  onAddProducer,
}) => {
  const { data: intendedUsesData } = useIntendedUsesQuery({
    fetchPolicy: "cache-first",
  });
  const [expanded, setExpanded] = useState(true);
  const theme = useTheme();
  const { control, setValue, errors } = useFormContext();
  const sample = useWatch({
    control,
    name: `samples[${index}]`,
    defaultValue: { ...BLANK_SAMPLE },
  });

  const _handleSourceMaterialChange = (value: string) => {
    if (value === "Hemp") {
      setValue(`samples[${index}].compliance`, "No");
    }
    setValue(`samples[${index}].deliveryMethodId`, "");
    setValue(`samples[${index}].reportTemplateOptionId`, "");
  };

  const _handleComplianceChange = (value: string) => {
    if (value === "Yes") {
      const fullPanelAnalysisOptionId = analysisOptions.find(
        (option) => !!option.name.match(/Full Panel/),
      )?.id;
      setValue(
        `samples[${index}].analysisOptionIds`,
        fullPanelAnalysisOptionId ? [fullPanelAnalysisOptionId] : [],
      );
    }
    setValue(`samples[${index}].deliveryMethodId`, "");
    setValue(`samples[${index}].reportTemplateOptionId`, "");
  };

  return (
    <Grid item xs={12}>
      <Card data-testid={`sample-card-${index}`}>
        <CardHeader
          title={`Sample ${index + 1}`}
          subheader={sample?.productName}
          style={{
            color: !!get(errors, `samples[${index}]`)
              ? theme.palette.error.main
              : theme.palette.text.primary,
          }}
          action={
            <>
              {allowDelete && (
                <IconButton
                  onClick={() => {
                    onDelete(index);
                  }}
                  size="small"
                  data-testid={`sample-delete-${index}`}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <ExpandButton
                isExpanded={expanded}
                onClick={() => setExpanded(!expanded)}
                size="medium"
              />
            </>
          }
        />
        <CardContent
          style={{
            padding: expanded ? theme.spacing(0, 2, 3) : theme.spacing(0, 2, 0),
          }}
        >
          <Collapse in={expanded}>
            <Grid container spacing={3}>
              <Grid item lg={2} xs={12} container spacing={1}>
                <Grid item lg={12} md={3} sm={4} xs={6}>
                  <Card elevation={4}>
                    <CardContent style={{ padding: theme.spacing(1, 2) }}>
                      <FormRadioGroupField
                        name={`samples[${index}].sourceMaterial`}
                        control={control}
                        errors={errors}
                        label="Source Material"
                        options={[
                          { value: "Hemp", label: "Hemp" },
                          { value: "Cannabis", label: "Cannabis" },
                        ]}
                        defaultValue={sample?.sourceMaterial}
                        onChangeCallback={(e) =>
                          _handleSourceMaterialChange(e.target.value)
                        }
                        FormControlProps={{
                          fullWidth: true,
                          margin: "dense",
                          required: true,
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={12} md={3} sm={4} xs={6}>
                  <Card elevation={4}>
                    <CardContent style={{ padding: theme.spacing(1, 2) }}>
                      <FormRadioGroupField
                        name={`samples[${index}].compliance`}
                        control={control}
                        errors={errors}
                        label="California BCC Compliance"
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        defaultValue={sample?.compliance}
                        FormControlProps={{
                          fullWidth: true,
                          margin: "dense",
                          required: true,
                          disabled: sample.sourceMaterial === "Hemp",
                        }}
                        onChangeCallback={(e) =>
                          _handleComplianceChange(e.target.value)
                        }
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={12} md={3} sm={4} xs={12}>
                  <Card elevation={4}>
                    <CardContent style={{ padding: theme.spacing(1, 2) }}>
                      <FormCheckboxField
                        label="Medical Use Only"
                        name={`samples[${index}].forMedicalUse`}
                        control={control}
                        defaultValue={sample?.forMedicalUse}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid item lg={10} xs={12}>
                <Card elevation={4} data-testid={`sample-info-card-${index}`}>
                  <CardHeader title="Sample Information" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormTextField
                          id={`sample-product-name${index}`}
                          name={`samples[${index}].productName`}
                          control={control}
                          errors={errors}
                          label="Product Name"
                          fullWidth
                          margin="dense"
                          defaultValue={sample?.productName}
                          required
                        />
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormTextField
                          id={`sample-lot-or-batch-${index}`}
                          name={`samples[${index}].lotOrBatchNumber`}
                          control={control}
                          errors={errors}
                          label="Lot/Batch"
                          fullWidth
                          margin="dense"
                          defaultValue={sample?.lotOrBatchNumber}
                          required
                        />
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormTextField
                          id={`sample-intended-use-select-${index}`}
                          name={`samples[${index}].intendedUse`}
                          control={control}
                          errors={errors}
                          label="Intended Use"
                          fullWidth
                          margin="dense"
                          defaultValue={sample?.intendedUse}
                          select
                          required
                        >
                          {intendedUsesData?.intendedUses.map(
                            ({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ),
                          )}
                        </FormTextField>
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormTextField
                          id={`sample-category-select-${index}`}
                          name={`samples[${index}].category`}
                          control={control}
                          errors={errors}
                          label="Sample Category"
                          fullWidth
                          margin="dense"
                          select
                          defaultValue={sample?.category}
                          disabled={!sample.intendedUse}
                          required
                        >
                          {intendedUsesData?.intendedUses
                            .find(({ id }) => id === sample?.intendedUse)
                            ?.sampleCategories.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            )) ?? <MenuItem />}
                        </FormTextField>
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormTextField
                          id={`sample-matrix-select-${index}`}
                          name={`samples[${index}].matrix`}
                          control={control}
                          errors={errors}
                          label="Sample Matrix"
                          fullWidth
                          margin="dense"
                          select
                          defaultValue={sample?.matrix}
                          disabled={!sample.category}
                          required
                        >
                          {intendedUsesData?.intendedUses
                            .find(({ id }) => id === sample?.intendedUse)
                            ?.sampleCategories?.find(
                              ({ id }) => id === sample?.category,
                            )
                            ?.sampleMatrices?.map(({ id, name }) => (
                              <MenuItem value={id} key={id}>
                                {name}
                              </MenuItem>
                            )) ?? <MenuItem />}
                        </FormTextField>
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <FormDatePicker
                          id={`sample-harvest-or-production-date-picker-${index}`}
                          name={`samples[${index}].harvestOrProductionDate`}
                          control={control}
                          errors={errors}
                          label="Harvest Or Production Date"
                          disableFuture
                          margin="dense"
                          fullWidth
                          autoOk
                          variant="inline"
                          defaultValue={sample?.harvestOrProductionDate}
                          required={isCompliance(sample)}
                        />
                      </Grid>
                      <Grid item lg={3} md={4} xs={6}>
                        <FormTextField
                          id={`sample-weight-or-count-${index}`}
                          name={`samples[${index}].weightOrCount`}
                          control={control}
                          errors={errors}
                          label="Batch Count/Weight"
                          fullWidth
                          margin="dense"
                          type="number"
                          defaultValue={sample?.weightOrCount}
                          required={isCompliance(sample)}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xs={6}>
                        <FormTextField
                          id={`sample-uom-select-${index}`}
                          name={`samples[${index}].unitOfMeasure`}
                          control={control}
                          errors={errors}
                          label="Unit Of Measure"
                          fullWidth
                          margin="dense"
                          select
                          defaultValue={sample?.unitOfMeasure}
                          required={isCompliance(sample)}
                        >
                          {weightOrCountUnits.map((unit) => (
                            <MenuItem key={unit} value={unit}>
                              {unit}
                            </MenuItem>
                          ))}
                        </FormTextField>
                      </Grid>
                      <Grid item lg={4} md={5} sm={6} xs={12}>
                        <FormTextField
                          id={`report-template-select-${index}`}
                          label="Report Template"
                          name={`samples[${index}].reportTemplateOptionId`}
                          control={control}
                          errors={errors}
                          defaultValue={sample?.reportTemplateOptionId}
                          required
                          margin="dense"
                          fullWidth
                          select
                        >
                          {COA_OPTIONS.map(
                            ({ id, label, shouldDisplay }) =>
                              jsonLogic.apply(shouldDisplay, sample) && (
                                <MenuItem key={id} value={id}>
                                  {label}
                                </MenuItem>
                              ),
                          )}
                        </FormTextField>
                      </Grid>
                      <Grid item lg={8} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`samples[${index}].analysisOptionIds`}
                          defaultValue={sample.analysisOptionIds}
                          render={(props) => (
                            <TextField
                              {...props}
                              id={`sample-analysis-options-select-${index}`}
                              error={!!get(errors, props.name)}
                              helperText={
                                <ErrorMessage
                                  errors={errors}
                                  name={props.name}
                                />
                              }
                              label="Analysis Options"
                              fullWidth
                              margin="dense"
                              select
                              required
                              disabled={isCompliance(sample)}
                              defaultValue={sample?.analysisOptionIds}
                              SelectProps={{
                                multiple: true,
                                renderValue: (selected) => (
                                  <Box display="flex" flexWrap="wrap">
                                    {(selected as string[]).map((value) => (
                                      <Chip
                                        key={value}
                                        color="secondary"
                                        style={{
                                          flexWrap: "wrap",
                                          margin: 2,
                                        }}
                                        label={
                                          analysisOptions.find(
                                            (option) => option.id === value,
                                          )?.name
                                        }
                                        size="small"
                                      />
                                    ))}
                                  </Box>
                                ),
                              }}
                            >
                              {analysisOptions.map(({ id, name, price }) => (
                                <MenuItem key={id} value={id}>
                                  <Checkbox
                                    checked={props.value.indexOf(id) > -1}
                                  />
                                  <ListItemText
                                    primary={name}
                                    secondary={`$${price}`}
                                  />
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormTextField
                          name={`samples[${index}].deliveryMethodId`}
                          control={control}
                          errors={errors}
                          defaultValue={sample?.deliveryMethodId}
                          id={`sample-delivery-method-select-${index}`}
                          label="Delivery Method"
                          fullWidth
                          margin="dense"
                          select
                          required
                        >
                          {deliveryMethods.map(
                            ({ id, description, shouldDisplay }) =>
                              jsonLogic.apply(shouldDisplay, sample) && (
                                <MenuItem key={id} value={id}>
                                  {description}
                                </MenuItem>
                              ),
                          )}
                        </FormTextField>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormTextField
                          id={`sample-notes-${index}`}
                          name={`samples[${index}].notes`}
                          control={control}
                          errors={errors}
                          label="Notes"
                          fullWidth
                          margin="dense"
                          multiline
                          defaultValue={sample?.notes}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={sample?.compliance === "Yes"}>
                  <Card elevation={4} data-testid="compliance-card">
                    <CardHeader title="Compliance Information" />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                          <FormTextField
                            name={`samples[${index}].producerId`}
                            defaultValue={sample?.producerId}
                            control={control}
                            errors={errors}
                            label="Producer"
                            select
                            fullWidth
                            margin="dense"
                            required={isCompliance(sample)}
                            id={`producer-select-${index}`}
                          >
                            {producers.map(
                              (producer) =>
                                producer && (
                                  <MenuItem
                                    key={producer.id}
                                    value={producer.id}
                                  >
                                    <Typography component="div">
                                      <Box fontWeight={700}>
                                        {`${producer.name}`}
                                      </Box>
                                      {`${producer.licenseNumber}`}
                                      <Box fontSize="small">
                                        {`${producer.address.street1} ${producer.address.city}, ${producer.address.state} ${producer.address.zip}`}
                                      </Box>
                                    </Typography>
                                  </MenuItem>
                                ),
                            )}
                            {producers.length > 0 && <Divider />}
                            <Button
                              fullWidth
                              onClick={() => onAddProducer(index)}
                            >
                              Add new producer
                            </Button>
                          </FormTextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormTextField
                            name={`samples[${index}].distributorId`}
                            defaultValue={sample?.distributorId}
                            control={control}
                            errors={errors}
                            label="Distributor"
                            select
                            fullWidth
                            margin="dense"
                            required={isCompliance(sample)}
                            id={`distributor-select-${index}`}
                          >
                            {distributors.map(
                              (distributor) =>
                                distributor && (
                                  <MenuItem
                                    key={distributor.id}
                                    value={distributor?.id}
                                  >
                                    <Typography component="div">
                                      <Box fontWeight={700}>
                                        {`${distributor.name}`}
                                      </Box>
                                      {`${distributor.licenseNumber}`}
                                      <Box fontSize="small">
                                        {`${distributor.address.street1} ${distributor.address.city}, ${distributor.address.state} ${distributor.address.zip}`}
                                      </Box>
                                    </Typography>
                                  </MenuItem>
                                ),
                            )}
                            {distributors.length > 0 && <Divider />}
                            <Button
                              fullWidth
                              onClick={() => onAddDistributor(index)}
                            >
                              Add new distributor
                            </Button>
                          </FormTextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormDatePicker
                            name={`samples[${index}].readyForPickup`}
                            control={control}
                            errors={errors}
                            label="Ready For Pickup"
                            margin="dense"
                            fullWidth
                            autoOk
                            disablePast
                            variant="inline"
                            defaultValue={sample?.readyForPickup}
                            required={isCompliance(sample)}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Collapse>
              </Grid>
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SampleSection;
