import { NetworkStatus } from "@apollo/client";
import {
  Container,
  IconButton,
  useTheme,
  Box,
  Tooltip,
} from "@material-ui/core";
import * as React from "react";
import { Helmet } from "react-helmet";
import BasicTable from "src/components/BasicTable";
import ErrorCard from "src/components/ErrorCard";
import LoadingCard from "src/components/LoadingCard";
import { useSampleTrackerQuery } from "src/generated/graphql-hooks";
import { useTableData } from "src/hooks/useTableData";
import columns from "./columns";
import PastIcon from "@material-ui/icons/Restore";
import RequestPastCOAsDiaglog from "./RequestPastCOAsDiaglog";

const SampleTrackerPage: React.FunctionComponent = () => {
  const theme = useTheme();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [requestModalOpen, setRequestModalOpen] = React.useState(false);
  const {
    data: trackerData,
    networkStatus,
    loading,
    error,
  } = useSampleTrackerQuery({
    notifyOnNetworkStatusChange: true,
  });

  const { data } = useTableData({
    remoteData: trackerData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  if (loading)
    return (
      <Container style={{ paddingTop: theme.spacing(3) }} maxWidth="xl">
        <LoadingCard message={`Loading sample tracker...`} />
      </Container>
    );

  if (error) return <ErrorCard />;

  return (
    <Container style={{ paddingTop: theme.spacing(3) }} maxWidth="xl">
      <Helmet>
        <title>Think20 Labs Portal - Sample Tracker</title>
      </Helmet>
      <BasicTable
        title="Sample Tracker"
        //@ts-ignore
        columns={columns}
        data={data?.me?.business?.samples ?? []}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        toolbarButtons={[
          <Tooltip title="Request past COAs">
            <Box color="white">
              <IconButton
                color="inherit"
                onClick={() => setRequestModalOpen(true)}
              >
                <PastIcon />
              </IconButton>
            </Box>
          </Tooltip>,
        ]}
      />
      <RequestPastCOAsDiaglog
        open={requestModalOpen}
        onClose={() => setRequestModalOpen(false)}
      />
    </Container>
  );
};

export default SampleTrackerPage;
