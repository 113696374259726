import type { TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { useAvailableInstrumentsQuery } from "src/generated/graphql-hooks";
import FormTextField from "../../components/FormTextField";
import { generateMenuItems } from "../utils";

const InstrumentSelect: React.FunctionComponent<TextFieldProps> = (props) => {
  const { data } = useAvailableInstrumentsQuery();
  const instruments = data?.availableInstruments ?? [];
  return (
    <FormTextField name="instrumentId" select fullWidth {...props}>
      {generateMenuItems(instruments)}
    </FormTextField>
  );
};

export default InstrumentSelect;
