import { DropzoneDialog } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import * as React from "react";
import BasicTable from "src/components/BasicTable";
import ErrorCard from "src/components/ErrorCard";
import LoadingCard from "src/components/LoadingCard";
import SchedulePickUpModal from "src/components/SchedulePickupModal";
import {
  PickupLogOrderFieldsFragment,
  UrlSchema,
  useLinkCocPaperworkMutation,
  useMarkInTransitToLabMutation,
  useMarkOutForPickupMutation,
  usePickupLogOrdersQuery,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import headers from "./headers";
import firebase from "firebase/app";

interface PickUpLogProps {}

const PickupLog: React.FunctionComponent<PickUpLogProps> = () => {
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: remoteData,
    loading,
    error,
    refetch,
  } = usePickupLogOrdersQuery();
  const [markOutForPickup] = useMarkOutForPickupMutation();
  const [markInTransitToLab] = useMarkInTransitToLabMutation();
  const [linkCOCPaperwork] = useLinkCocPaperworkMutation();
  const [
    orderToSchedule,
    setOrderToSchedule,
  ] = React.useState<PickupLogOrderFieldsFragment | null>(null);
  const [
    orderIdToUploadCOCPaperwork,
    setOrderIdToUploadCOCPaperwork,
  ] = React.useState<string | null>(null);

  const _handleOpenScheduleModal = (order: PickupLogOrderFieldsFragment) =>
    setOrderToSchedule(order);
  const _handleCloseScheduleModal = () => setOrderToSchedule(null);

  const _handleOutForPickup = async (orderId: string) => {
    try {
      await markOutForPickup({ variables: { input: { orderId } } });
      triggerDataUpdate();
    } catch (err) {
      enqueueSnackbar("Error occurred while updating order status", {
        variant: "error",
      });
    }
  };

  const _handleInTransitToLab = async (orderId: string) => {
    try {
      await markInTransitToLab({ variables: { input: { orderId } } });
      triggerDataUpdate();
    } catch (err) {
      enqueueSnackbar("Error occurred while updating order status", {
        variant: "error",
      });
    }
  };

  const _handleUploadCOCPaperworkClick = async (orderId: string) => {
    setOrderIdToUploadCOCPaperwork(orderId);
  };

  const _uploadCOCPaperwork = async (files: File[]) => {
    if (!orderIdToUploadCOCPaperwork) return;
    try {
      setOrderIdToUploadCOCPaperwork(null);
      const uploadedFiles = await Promise.all(
        files.map((file) =>
          firebase
            .storage()
            .ref(`chainOfCustody/${orderIdToUploadCOCPaperwork}/${file.name}`)
            .put(file),
        ),
      );
      await linkCOCPaperwork({
        variables: {
          input: {
            orderId: orderIdToUploadCOCPaperwork,
            files: files.map(({ size, type }, index) => ({
              size,
              extension: type,
              urlSchema: UrlSchema.GcsRef,
              url: uploadedFiles[index].ref.toString(),
            })),
          },
        },
      });
      enqueueSnackbar("Chain of custody documentation uploaded", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(
        "Error occurred while attempting to upload chain of custody documentation",
        { variant: "error" },
      );
    }
  };

  const { data, triggerDataUpdate } = useTableData({
    remoteData,
    isFetching: loading,
  });

  const columns = React.useMemo(() => headers, []);

  if (loading) return <LoadingCard message="Fetching orders..." />;

  if (error) return <ErrorCard />;

  return (
    <>
      <BasicTable
        title="Pickup Log"
        data={data?.orders ?? []}
        //@ts-ignore
        columns={columns}
        customActions={{
          onDataUpdate: triggerDataUpdate,
          onSchedulePickupClick: _handleOpenScheduleModal,
          onOutForPickupClick: _handleOutForPickup,
          onInTransitToLabClick: _handleInTransitToLab,
          onUploadCOCPaperworkClick: _handleUploadCOCPaperworkClick,
        }}
        onRefresh={async () => {
          await refetch();
          triggerDataUpdate();
        }}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <SchedulePickUpModal
        order={orderToSchedule}
        onClose={_handleCloseScheduleModal}
        onDataUpdate={triggerDataUpdate}
      />
      <DropzoneDialog
        open={!!orderIdToUploadCOCPaperwork}
        onClose={() => setOrderIdToUploadCOCPaperwork(null)}
        dialogTitle="Upload Chain of Custody Paperwork"
        submitButtonText="Upload"
        onSave={_uploadCOCPaperwork}
        showAlerts={false}
      />
    </>
  );
};

export default PickupLog;
