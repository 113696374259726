import { Container, Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { Helmet } from "react-helmet";
import BusinessInfoCard from "src/components/BusinessInfoCard";
import SampleSubmissionForm from "src/components/forms/SampleSubmissionForm";
import PageTitleCard from "src/components/PageTitleCard";
import UserInfoCard from "src/components/UserInfoCard";
import type { User } from "src/generated/graphql-hooks";
import { useCurrentUser } from "src/hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(3),
    paddingBlock: theme.spacing(3),
  },
}));

const SampleSubmission = () => {
  const classes = useStyles();
  const currentUser = useCurrentUser().currentUser as User;

  return (
    <Container className={classes.pageContainer}>
      <Helmet>
        <title>Think20 Labs Portal - Sample Submission</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageTitleCard title="Sample Submission" />
        </Grid>
        {currentUser.business && (
          <Grid item md={3} sm={4} xs={6}>
            <BusinessInfoCard business={currentUser.business} />
          </Grid>
        )}
        <Grid item md={3} sm={4} xs={6}>
          <UserInfoCard
            name={`${currentUser.firstName} ${currentUser.lastName}`}
            email={currentUser.email}
            phoneNumber={currentUser.phoneNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            The information entered below is copied to the final COA - please
            review carefully before submitting
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <SampleSubmissionForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SampleSubmission;
