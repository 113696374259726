import { Chip, useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import React, { FunctionComponent } from "react";
import type { ProcessStepFieldsFragment } from "src/generated/graphql-hooks";

interface ProcessStepChipProps {
  processStep: ProcessStepFieldsFragment;
  displayStatus?: boolean;
  onClick?: () => void;
  clickable?: boolean;
}

const ProcessStepChip: FunctionComponent<ProcessStepChipProps> = ({
  processStep: { name, required, inProgress, completed },
  displayStatus,
  onClick,
  clickable,
}) => {
  const theme = useTheme();

  if (!displayStatus)
    return (
      <Chip
        label={name}
        onClick={onClick}
        clickable={clickable}
        color={required ? "primary" : "default"}
      />
    );
  if (!required)
    return <Chip label={name} icon={<HighlightOffIcon />} clickable={false} />;
  if (completed)
    return (
      <Chip
        label={name}
        onClick={onClick}
        icon={<CheckCircleIcon style={{ color: theme.palette.common.white }} />}
        clickable={clickable}
        style={{
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
        }}
      />
    );
  if (inProgress)
    return (
      <Chip
        label={name}
        onClick={onClick}
        icon={<WatchLaterIcon style={{ color: theme.palette.common.white }} />}
        clickable={clickable}
        style={{
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.common.white,
        }}
      />
    );
  return (
    <Chip
      label={name}
      onClick={onClick}
      icon={<ErrorIcon style={{ color: theme.palette.error.contrastText }} />}
      clickable={clickable}
      style={{
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }}
    />
  );
};

export default ProcessStepChip;
