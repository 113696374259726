import { NetworkStatus } from "@apollo/client";
import * as React from "react";
import BasicTable from "src/components/BasicTable";
import ErrorCard from "src/components/ErrorCard";
import LoadingCard from "src/components/LoadingCard";
import {
  SampleStatus,
  useReadyForQaSamplesQuery,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import ReadyForQaColumns from "./columns";

interface readyForQaTableProps {
  setSampleId: (sampleId: string) => void;
  filterStatus: SampleStatus;
  title: string;
}

const ReadyForQaTable = ({
  setSampleId,
  filterStatus,
  title,
}: readyForQaTableProps) => {
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);

  const {
    data: samplesData,
    networkStatus,
    loading,
    error,
  } = useReadyForQaSamplesQuery({
    variables: {
      statusFilter: {
        in: [filterStatus],
      },
    },
  });

  const { data, triggerDataUpdate } = useTableData({
    remoteData: samplesData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  React.useEffect(() => {
    triggerDataUpdate();
  }, [filterStatus, triggerDataUpdate]);

  const columns = React.useMemo(() => ReadyForQaColumns, []);

  if (loading)
    return <LoadingCard message={`Loading samples ready for ${title}...`} />;

  if (error) return <ErrorCard />;

  return (
    <>
      <BasicTable
        title={`Samples ready for ${title}`}
        //@ts-ignore
        columns={columns}
        data={data?.samples ?? []}
        customActions={{
          onSampleDetailClick: (id) => setSampleId(id),
        }}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </>
  );
};

export default ReadyForQaTable;
