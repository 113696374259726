import * as React from "react";
import FormTextField from "src/components/forms/components/FormTextField";
import {
  BarcodeColumn,
  SampleAmountColumn,
  BalanceColumn,
} from "src/components/PanelTable/columns";
import {
  AnalysisCompleteColumn,
  DueDateColumn,
  editableCell,
  EditableInstrumentColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import type { PanelLogFieldsFragment } from "src/generated/graphql-hooks";

const MoistureContentColumn = {
  Header: "Moisture Content (%)",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="moistureContent"
      defaultValue={
        cell.row.original.panelResults?.[0]?.results?.moistureContent ?? ""
      }
    />
  ))(
    (cell) =>
      cell.row.original.panelResults?.[0]?.results?.moistureContent ?? null,
  ),
};

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("MWA"),
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  EditableInstrumentColumn,
  MoistureContentColumn,
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
