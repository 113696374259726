import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Container,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import clsx from "clsx";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import { DateTime } from "luxon";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import {
  Cell,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ProcessStepChip from "src/components/ProcessStepChip";
import SampleDetailModal from "src/components/SampleDetailModal";
import { DueDateColumn, RushColumn } from "src/components/tableColumns";
import TablePaginationActions from "src/components/TablePaginationActions";
import {
  ALIQUOT,
  HOMOGENIZATION,
  PANELS,
  SAMPLE_ACCESSION,
} from "src/config/routes";
import {
  SampleLogQuery,
  SampleStatus,
  useSampleLogQuery,
} from "src/generated/graphql-hooks";
import GlobalTableFilter from "../../../components/GlobalTableFilter";
import RowActions from "./RowActions";
import RowExpandButton from "./RowExpandButton";

const SubRow: React.FunctionComponent<{
  row: any;
  rowProps: any;
  visibleColumns: any;
  isExpanded: boolean;
}> = ({ row, rowProps, visibleColumns, isExpanded }) => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();

  return (
    <TableRow
      className={clsx({
        [classes.complianceRow]: row.original.isCompliance,
      })}
    >
      <TableCell
        colSpan={visibleColumns.length}
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        <Collapse in={isExpanded}>
          <Card variant="outlined" style={{ marginBottom: theme.spacing(1) }}>
            <CardHeader subheader="Status" />
            <CardContent>
              <Grid container spacing={1}>
                {row.original.processSteps.map((step: any, index: number) => (
                  <Grid item>
                    <ProcessStepChip
                      processStep={step}
                      displayStatus
                      clickable={!!row.original.sampleId}
                      onClick={() => {
                        if (!row.original.sampleId) return;
                        if (
                          step.name.match(/QA/) ||
                          step.name.match(/Reporting/)
                        )
                          return;
                        if (step.name.match(/Accessioning/)) {
                          history.push(
                            `${SAMPLE_ACCESSION}?sample_id=${row.original.sampleId}`,
                          );
                          return;
                        }
                        if (step.name.match(/Homogenization/)) {
                          history.push(
                            `${HOMOGENIZATION}?search=${row.original.sampleId}`,
                          );
                          return;
                        }
                        if (step.name.match(/Aliquot/)) {
                          history.push(
                            `${ALIQUOT}?search=${row.original.sampleId}`,
                          );
                          return;
                        }
                        history.push(
                          `${PANELS}/${kebabCase(step.name)}?search=${
                            row.original.sampleId
                          }`,
                        );
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const SampleLog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { data: remoteData, loading, refetch } = useSampleLogQuery({
    variables: { where: { status: { notIn: [SampleStatus.Canceled] } } },
  });
  const [detailSampleId, setDetailSampleId] = React.useState<string | null>(
    null,
  );
  const [updateDataCount, setUpdateDataCount] = React.useState(0);
  const data: SampleLogQuery | undefined = React.useMemo(() => {
    return remoteData;
  }, [updateDataCount, loading]); // eslint-disable-line

  const _triggerDataUpdate = () => {
    setUpdateDataCount(updateDataCount + 1);
  };

  const _openSampleDetailModal = (sampleId: string) =>
    setDetailSampleId(sampleId);

  const _closeSampleDetailModal = () => setDetailSampleId(null);

  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: (cell: Cell<SampleLogQuery["samples"][number]>) => (
          <RowExpandButton {...cell} />
        ),
        disableSortBy: true,
      },
      { Header: "Sample Name", accessor: "productName" },
      { Header: "Order ID", accessor: "order.shortId" },
      { Header: "Sample ID", accessor: "sampleId" },
      {
        Header: "Photo",
        accessor: "photo.url",
        Cell: (cell: Cell<SampleLogQuery["samples"][number]>) =>
          cell.row.original.photo?.url ? (
            <img
              src={cell.row.original.photo?.url}
              style={{ maxHeight: 100, borderRadius: 5 }}
              alt="Sample"
            />
          ) : null,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        Header: "Submitted On",
        accessor: (sample: SampleLogQuery["samples"][number]) =>
          new Date(sample.order.sampleSubmission!.submittedAt),
        Cell: ({ row }: Cell<SampleLogQuery["samples"][number]>) =>
          DateTime.fromISO(
            row.original.order.sampleSubmission!.submittedAt,
          ).toLocaleString(DateTime.DATE_SHORT),
        sortType: "datetime",
      },
      RushColumn,
      DueDateColumn,
      {
        Header: "Business",
        accessor: "order.sampleSubmission.submittedBy.business.name",
      },
      { Header: "Matrix", accessor: "matrix.name" },
      { Header: "Notes", accessor: "customerNotes" },
      {
        Header: "Status",
        accessor: (row: SampleLogQuery["samples"][number]) =>
          startCase(row.status ?? ""),
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: (cell: Cell<SampleLogQuery["samples"][number]>) => (
          <RowActions {...cell} />
        ),
        disableSortBy: true,
      },
    ],
    [],
  );
  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: data?.samples ?? [],
      autoResetPage: false,
      onDataUpdate: _triggerDataUpdate,
      onSampleDetailClick: _openSampleDetailModal,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    page,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, pageIndex, globalFilter },
  } = tableInstance;

  React.useEffect(() => {
    globalFilter && gotoPage(0);
  }, [globalFilter, gotoPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Container style={{ paddingTop: theme.spacing(3) }} maxWidth="xl">
      <Helmet>
        <title>Think20 Labs Portal - Sample Log</title>
      </Helmet>
      <Paper>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            flexDirection="column"
            padding={theme.spacing(5, 0)}
          >
            <CircularProgress />
            <Typography>Fetching samples...</Typography>
          </Box>
        ) : (
          <>
            <Toolbar
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Box color={theme.palette.primary.contrastText} mr={5}>
                <Typography variant="h6">Sample Log</Typography>
              </Box>
              <GlobalTableFilter
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
              />
              <Box display="flex" flex="1" />
              <Tooltip title="Refresh sample log">
                <IconButton
                  style={{ color: theme.palette.primary.contrastText }}
                  onClick={async () => {
                    await refetch();
                    setUpdateDataCount(updateDataCount + 1);
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
            <TableContainer
              style={{
                maxHeight: `calc(90vh - ${
                  theme.spacing(8) * 2
                }px - ${theme.spacing(3)}px)`,
              }}
            >
              <Table {...getTableProps()} size="small" stickyHeader>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <TableCell
                          {...(column.id === "selection"
                            ? column.getHeaderProps()
                            : column.getHeaderProps(
                                column.getSortByToggleProps(),
                              ))}
                        >
                          {!column.disableSortBy &&
                          column.id !== "selection" ? (
                            <TableSortLabel
                              active={column.isSorted}
                              direction={column.isSortedDesc ? "desc" : "asc"}
                            >
                              {column.render("Header")}
                            </TableSortLabel>
                          ) : (
                            column.render("Header")
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <React.Fragment key={rowProps.key}>
                        <TableRow
                          {...row.getRowProps()}
                          className={clsx(classes.root, {
                            [classes.complianceRow]: row.original.isCompliance,
                          })}
                        >
                          {row.cells.map((cell, index) => (
                            <TableCell
                              {...cell.getCellProps()}
                              style={{
                                background:
                                  index === 0 ? "transparent" : "white",
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                        <SubRow
                          isExpanded={row.isExpanded}
                          row={row}
                          rowProps={rowProps}
                          visibleColumns={visibleColumns}
                        />
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={columns.length}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </>
        )}
      </Paper>
      <SampleDetailModal
        sampleId={detailSampleId}
        onClose={_closeSampleDetailModal}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    expand: {
      transform: "rotate(-90deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(0deg)",
    },
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    complianceRow: {
      background: `linear-gradient(90deg, ${
        theme.palette.secondary.main
      } ${theme.spacing(1)}px, ${theme.palette.common.white} ${theme.spacing(
        1,
      )}px)`,
    },
  }),
);

export default SampleLog;
