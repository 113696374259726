import {
  Paper,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";

interface SearchBoxProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  searchProcessing?: boolean;
}

const SearchBox = ({
  label,
  value,
  onChange,
  onSubmit,
  searchProcessing = false,
}: SearchBoxProps) => {
  return (
    <Paper>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(value);
        }}
      >
        <TextField
          fullWidth
          label={label}
          variant="outlined"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
          InputProps={{
            endAdornment: searchProcessing ? (
              <CircularProgress size="1rem" />
            ) : (
              <IconButton size="small" type="submit">
                {searchProcessing ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <Tooltip title="Search">
                    <SearchIcon />
                  </Tooltip>
                )}
              </IconButton>
            ),
          }}
        />
      </form>
    </Paper>
  );
};

export default SearchBox;
