import { Box, Container } from "@material-ui/core";
import * as React from "react";
import { Helmet } from "react-helmet";
import PanelProcessingJobsTable from "src/components/PanelProcessingJobsTable";

const ProcessingJobsPage: React.FunctionComponent = () => {
  return (
    <Container>
      <Helmet>
        <title>Think20 Labs Portal - Processing Jobs</title>
      </Helmet>
      <Box py={3}>
        <PanelProcessingJobsTable />
      </Box>
    </Container>
  );
};

export default ProcessingJobsPage;
