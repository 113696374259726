import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { FunctionComponent } from "react";

interface ExpandButtonProps {
  isExpanded: boolean;
  onClick?: () => void;
  size?: "small" | "medium";
}

const ExpandButton: FunctionComponent<ExpandButtonProps> = ({
  isExpanded,
  onClick = () => {},
  size = "small",
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: isExpanded,
      })}
      size={size}
      onClick={() => onClick()}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  }),
);

export default ExpandButton;
