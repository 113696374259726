import { IconButton, SvgIconTypeMap, Tooltip } from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";
import EditIcon from "@material-ui/icons/Edit";
import * as R from "ramda";
import * as React from "react";
import type { Row } from "react-table";

interface ActionButtonShape {
  name: string;
  display: boolean;
  tooltip: string;
  onClick: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

interface AdminRowActionsProps<T extends { id: string }> {
  row: Row<T>;
  onEditClick: (id: string) => void;
}

const AdminRowActions = <T extends { id: string }>({
  row,
  onEditClick = () => {},
}: AdminRowActionsProps<T>) => {
  const createActionButtons = R.map<
    ActionButtonShape,
    React.ReactElement | null
  >(({ name, display, tooltip, onClick, Icon }) =>
    display ? (
      <Tooltip title={tooltip} key={name}>
        <IconButton size="small" onClick={onClick}>
          <Icon />
        </IconButton>
      </Tooltip>
    ) : null,
  );

  const actionsButtons = createActionButtons([
    {
      name: "edit",
      display: true,
      tooltip: "Edit",
      onClick: () => onEditClick(row.original.id),
      Icon: EditIcon,
    },
  ]);

  return <>{actionsButtons}</>;
};

export default AdminRowActions;
