import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import AppBar from "src/components/AppBar";
import Drawer from "src/components/Drawer";
import PrivateRoute from "src/components/routing/PrivateRoute";
import {
  ACCESSION_SAMPLES,
  ALIQUOT_SAMPLES_PERMISSION,
  APPROVE_RESULTS_PERMISSION,
  HOMOGENIZE_SAMPLES_PERMISSION,
  MANAGE_USERS_PERMISSION,
  SUBMIT_SAMPLES_PERMISSION,
  UPLOAD_RESULTS_PERMISSION,
  VIEW_ANALYTICS,
  VIEW_PICKUP_LOG,
  VIEW_SAMPLE_LOG,
  VIEW_SAMPLE_TRACKER_PERMISSION,
} from "src/config/permissions";
import {
  ALIQUOT,
  APP,
  APPROVE_RESULTS_GENERIC,
  BLOCKED_ACCESS,
  HOMOGENIZATION,
  PICKUP_LOG,
  PROCESSING_JOBS,
  SAMPLE_ACCESSION,
  SAMPLE_LOG,
  SAMPLE_SUBMISSION,
  SAMPLE_TRACKER,
  SPECIFIC_PANEL,
  USER_MANAGEMENT,
} from "src/config/routes";
import { DrawerProvider } from "src/hooks/useDrawer";
import AdminPanel from "./Admin/UsersContainer/";
import AliquotPage from "./Aliquot";
import AnalyticsDashboard from "./AnalyticsDashboard";
import ApproveResultsPage from "./ApproveResults";
import BlockedAccess from "./BlockedAccess";
import HomogenizationPage from "./Homogenization";
import PanelsPage from "./Panels";
import PickupLog from "./PickupLog";
import ProcessingJobsPage from "./ProcessingJobs";
import SampleAccession from "./SampleAccession";
import SampleLog from "./SampleLog";
import SampleSubmission from "./SampleSubmission";
import SampleTrackerPage from "./SampleTracker";

const App = () => {
  return (
    <>
      <Helmet>
        <title>Think20 Labs Portal</title>
      </Helmet>
      <DrawerProvider>
        <AppBar />
        <Drawer />
        <Switch>
          <PrivateRoute
            path={SAMPLE_SUBMISSION}
            requiredPermission={SUBMIT_SAMPLES_PERMISSION}
          >
            <SampleSubmission />
          </PrivateRoute>
          <PrivateRoute
            path={SAMPLE_TRACKER}
            requiredPermission={VIEW_SAMPLE_TRACKER_PERMISSION}
          >
            <SampleTrackerPage />
          </PrivateRoute>
          <PrivateRoute path={PICKUP_LOG} requiredPermission={VIEW_PICKUP_LOG}>
            <PickupLog />
          </PrivateRoute>
          <PrivateRoute path={SAMPLE_LOG} requiredPermission={VIEW_SAMPLE_LOG}>
            <SampleLog />
          </PrivateRoute>
          <PrivateRoute
            path={SAMPLE_ACCESSION}
            requiredPermission={ACCESSION_SAMPLES}
          >
            <SampleAccession />
          </PrivateRoute>
          <PrivateRoute
            path={PROCESSING_JOBS}
            requiredPermission={UPLOAD_RESULTS_PERMISSION}
          >
            <ProcessingJobsPage />
          </PrivateRoute>
          <Route path={SPECIFIC_PANEL}>
            <PanelsPage />
          </Route>
          <PrivateRoute
            path={ALIQUOT}
            requiredPermission={ALIQUOT_SAMPLES_PERMISSION}
          >
            <AliquotPage />
          </PrivateRoute>
          <PrivateRoute
            path={HOMOGENIZATION}
            requiredPermission={HOMOGENIZE_SAMPLES_PERMISSION}
          >
            <HomogenizationPage />
          </PrivateRoute>
          <PrivateRoute
            path={APPROVE_RESULTS_GENERIC}
            requiredPermission={APPROVE_RESULTS_PERMISSION}
          >
            <ApproveResultsPage />
          </PrivateRoute>
          <PrivateRoute
            path={USER_MANAGEMENT}
            requiredPermission={MANAGE_USERS_PERMISSION}
          >
            <AdminPanel />
          </PrivateRoute>

          <PrivateRoute path={APP} requiredPermission={VIEW_ANALYTICS}>
            <AnalyticsDashboard />
          </PrivateRoute>

          <PrivateRoute path={BLOCKED_ACCESS}>
            <BlockedAccess />
          </PrivateRoute>
        </Switch>
      </DrawerProvider>
    </>
  );
};

export default App;
