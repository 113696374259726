import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import * as React from "react";

interface LoadingCardProps {
  message?: string;
}

const LoadingCard: React.FunctionComponent<LoadingCardProps> = ({
  message = "Loading...",
}) => {
  const theme = useTheme();
  return (
    <Paper>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection="column"
        padding={theme.spacing(5, 0)}
      >
        <CircularProgress />
        <Typography>{message}</Typography>
      </Box>
    </Paper>
  );
};

export default LoadingCard;
