import { Checkbox, MenuItem } from "@material-ui/core";
import { DateTime } from "luxon";
import * as React from "react";
import type { Cell, Column } from "react-table";
import FormTextField from "src/components/forms/components/FormTextField";
import BalanceSelect from "src/components/forms/uploadFormFields/BalanceSelect";
import DispenserSelect from "src/components/forms/uploadFormFields/DispenserSelect";
import PipetteSelect from "src/components/forms/uploadFormFields/PipetteSelect";
import SolventSelect from "src/components/forms/uploadFormFields/SolventSelect";
import {
  editableCell,
  editableCheckboxCellFactory,
  UOM_ABBREVIATIONS,
} from "src/components/tableColumns";
import type {
  AliquotFieldsFragment,
  PanelLogFieldsFragment,
} from "src/generated/graphql-hooks";

export const getComponentAmount = (
  panelName: string,
  componentKey: string = "name",
  valueKey: string = "final_amount",
) => (cell: Cell<PanelLogFieldsFragment>) => {
  return (
    getPanelResults(panelName)(cell)?.results.components.find(
      (component: any) =>
        component[componentKey]?.toLowerCase() === cell.column.id.toLowerCase(),
    )?.[valueKey] ?? null
  );
};

export const getPanelResults = (panelName: string) => (
  cell: Cell<PanelLogFieldsFragment>,
) =>
  cell.row.original.panelResults.find(
    (panelResult) => panelResult.panel.name === panelName,
  );

export const BalanceColumn = <
  T extends { id: string; aliquots: AliquotFieldsFragment[] }
>(
  editable = false,
) => ({
  Header: "Balance",
  minWidth: 150,
  Cell: (cell: Cell<T>) =>
    editable && cell.state.editingRowId === cell.row.original.id ? (
      <BalanceSelect
        defaultValue={cell.row.original.aliquots?.[0]?.balanceUsed?.id ?? ""}
      />
    ) : (
      cell.row.original.aliquots?.[0]?.balanceUsed?.name ?? null
    ),
});

export const DispenserColumn = {
  Header: "Dispenser",
  Cell: (cell: Cell<PanelLogFieldsFragment>) =>
    cell.state.editingRowId === cell.row.original.id ? (
      <DispenserSelect
        defaultValue={cell.row.original.testPreps[0]?.dispenser?.id ?? ""}
      />
    ) : (
      cell.row.original.testPreps[0]?.dispenser?.name ?? null
    ),
};

export const SolventColumn = {
  Header: "Solvent",
  Cell: (cell: Cell<PanelLogFieldsFragment>) =>
    cell.state.editingRowId === cell.row.original.id ? (
      <SolventSelect
        defaultValue={
          cell.row.original.testPreps[0]?.solventApplication?.solventUsed?.id ??
          ""
        }
      />
    ) : (
      cell.row.original.testPreps[0]?.solventApplication?.solventUsed?.name ??
      null
    ),
};

export const SolventMassColumn = (index: number) => ({
  Header: `Solvent Mass ${index + 1}`,
  Cell: (cell: Cell<PanelLogFieldsFragment>) =>
    cell.state.editingRowId === cell.row.original.id ? (
      <FormTextField
        name={`solventMasses[${index}]`}
        defaultValue={
          cell.row.original.testPreps[0]?.solventApplication?.masses?.[index] ??
          0
        }
        type="number"
      />
    ) : (
      cell.row.original.testPreps[0]?.solventApplication?.masses?.[index] ??
      null
    ),
});

export const SolventVolumeColumnFactory = ({
  defaultValue = 0,
}: {
  defaultValue?: number;
} = {}) => ({
  Header: "Solvent Volume (mL)",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="solventVolume"
      defaultValue={
        cell.row.original.testPreps[0]?.solventApplication?.volume ??
        defaultValue
      }
    />
  ))(
    (cell) =>
      cell.row.original.testPreps[0]?.solventApplication?.volume ?? null,
  ),
});

export const SolventDensityColumn = {
  Header: "Solvent Density",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="solventDensity"
      defaultValue={
        cell.row.original.testPreps[0]?.solventApplication?.density ?? 0
      }
    />
  ))(
    (cell) =>
      cell.row.original.testPreps[0]?.solventApplication?.density ?? null,
  ),
};

export const AliquotVolumeColumn = {
  Header: "Aliquot Volume (μL)",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="aliquotVolume"
      defaultValue={cell.row.original.testPreps[0]?.aliquotVolume ?? 0}
    />
  ))((cell) => cell.row.original.testPreps[0]?.aliquotVolume ?? null),
};

export const DilutionFactorColumn = {
  Header: "Dilution Factor",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="dilutionFactor"
      defaultValue={
        cell.row.original.testPreps[0]?.solventApplication?.dilutionFactor ?? 0
      }
      type="number"
    />
  ))(
    (cell) =>
      cell.row.original.testPreps[0]?.solventApplication?.dilutionFactor ??
      null,
  ),
};

export const PipetteColumn = (index: number) => ({
  Header: `Pipette ${index + 1} ID`,
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <PipetteSelect
      index={index}
      defaultValue={
        cell.row.original.testPreps[0]?.pipetteUsages?.find(
          ({ index: pipetteIndex }) => pipetteIndex === index + 1,
        )?.pipette.id ?? ""
      }
    />
  ))(
    (cell) =>
      cell.row.original.testPreps[0]?.pipetteUsages?.find(
        ({ index: pipetteIndex }) => pipetteIndex === index + 1,
      )?.pipette.name ?? null,
  ),
});

export const PipetteTypeColumn = (index: number) => ({
  Header: `Pipette ${index + 1} Type`,
  Cell: (cell: Cell<PanelLogFieldsFragment>) =>
    cell.row.original.testPreps[0]?.pipetteUsages?.find(
      ({ index: pipetteIndex }) => pipetteIndex === index + 1,
    )?.pipette.type ?? null,
});

export const TotalDilutionFactorColumn = {
  Header: "Total Dilution Factor",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="totalDilutionFactor"
      defaultValue={
        cell.row.original.testPreps[0]?.solventApplication
          ?.totalDilutionFactor ?? 0
      }
      type="number"
    />
  ))(
    (cell) =>
      cell.row.original.testPreps[0]?.solventApplication?.totalDilutionFactor ??
      null,
  ),
};

export const BatchIdColumn = {
  Header: "Batch ID",
  accessor: "panelResults[0].sampleBatch.id",
  minWidth: 200,
  maxWidth: 200,
  style: {
    position: "sticky",
    left: 500,
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  cellStyle: {
    zIndex: 998,
  },
};

export const InstrumentColumn = {
  Header: "Instrument",
  accessor: "panelResults[0].sampleBatch.instrument.name",
};

export const CompletedAtColumn = {
  Header: "Completed On",
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    const { completedAt } =
      cell.row.original.panelResults[0]?.sampleBatch?.panelProcessingJob ?? {};
    return completedAt
      ? DateTime.fromISO(completedAt).toLocaleString(DateTime.DATE_SHORT)
      : null;
  },
};

export const CompletedByColumn = {
  Header: "Completed By",
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    const { initiatedBy } =
      cell.row.original.panelResults[0]?.sampleBatch?.panelProcessingJob ?? {};

    return initiatedBy
      ? `${initiatedBy.firstName} ${initiatedBy.lastName}`
      : null;
  },
};

export const CompletedColumn = (panelName: string) => ({
  Header: "Completed",
  id: "completed",
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    return (
      <Checkbox
        checked={!!getPanelResults(panelName)(cell)}
        readOnly
        disableRipple
        style={{ cursor: "default" }}
      />
    );
  },
  disableSortBy: true,
});

export const BarcodeColumn = (prefix: string): Column => ({
  Header: "Barcode",
  id: "barcode",
  // @ts-ignore
  accessor: (row: PanelLogFieldsFragment) =>
    row.sampleId ? `${prefix}${row.sampleId}` : "",
  minWidth: 150,
  maxWidth: 150,
  style: {
    position: "sticky",
    left: 350,
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  cellStyle: {
    zIndex: 998,
  },
});

export const SampleAmountColumn = {
  Header: "Sample Amount",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => {
    const aliquot = cell.row.original.aliquots[0];
    return (
      <>
        <FormTextField
          name={"aliquotAmount"}
          defaultValue={aliquot?.measurement?.value ?? ""}
          style={{ width: "60%" }}
          inputMode="numeric"
          inputProps={{ style: { textAlign: "right" } }}
        />
        <FormTextField
          name={"aliquotUnitOfMeasure"}
          select
          style={{ width: "40%" }}
          SelectProps={{ style: { textAlign: "center" } }}
          defaultValue={aliquot?.measurement?.units ?? "grams"}
        >
          <MenuItem value="grams">g</MenuItem>
          <MenuItem value="milliliters">mL</MenuItem>
        </FormTextField>
      </>
    );
  })((cell) => {
    const aliquot = cell.row.original.aliquots[0];
    return aliquot
      ? `${aliquot.measurement.value} ${
          UOM_ABBREVIATIONS[aliquot.measurement.units] ??
          aliquot.measurement.units
        }`
      : null;
  }),
};

export const NotesColumn = {
  Header: "Notes",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="notes"
      defaultValue={cell.row.original.testPreps?.[0]?.notes ?? ""}
      multiline
    />
  ))((cell) => cell.row.original.testPreps?.[0]?.notes ?? null),
};

export const SamplePrepCompleteColumn = {
  Header: "Sample Prep Complete",
  Cell: editableCheckboxCellFactory<PanelLogFieldsFragment>({
    name: "samplePrepComplete",
    defaultValuePath: "testPreps[0].samplePrepComplete",
  }),
};

export const SamplePrepCompletedByColumn = {
  Header: "Sample Prep Completed By",
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    const { firstName, lastName } =
      cell.row.original.testPreps?.[0]?.samplePrepCompletedBy ?? {};
    return firstName && lastName ? `${firstName} ${lastName}` : null;
  },
};

export const VesselNumberColumn = {
  Header: "Vessel Number",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="vesselNumber"
      defaultValue={cell.row.original.testPreps?.[0]?.vesselNumber}
    />
  ))((cell) => cell.row.original.testPreps?.[0]?.vesselNumber ?? null),
};

export const FinalVolumeColumn = {
  Header: "Final Volume (mL)",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="finalVolume"
      defaultValue={cell.row.original.testPreps?.[0]?.finalVolume ?? 50}
    />
  ))((cell) => cell.row.original.testPreps?.[0]?.finalVolume ?? null),
};

export const GoldSpikeColumn = {
  Header: "Gold Spike (mL)",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <FormTextField
      name="goldSpikeVolume"
      defaultValue={cell.row.original.testPreps?.[0]?.goldSpikeVolume ?? 0.5}
    />
  ))((cell) => cell.row.original.testPreps?.[0]?.goldSpikeVolume ?? null),
};
