import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import type { FileObject } from "material-ui-dropzone";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { mixed, object, string } from "yup";
import FormFileUploadField from "../components/FormFileUploadField";
import FormTextField from "../components/FormTextField";
import InstrumentSelect from "../uploadFormFields/InstrumentSelect";

interface UploadResultsFormProps {
  id: string;
  onSubmit: SubmitHandler<UploadResultsFormValues>;
}

export type UploadResultsFormValues = {
  instrumentId: string;
  batchId: string;
  notes?: string | null;
  resultsFile: FileObject | null;
};

const validationSchema = object({
  batchId: string().required("Please enter a batch ID"),
  instrumentId: string().required("Please select an instrument"),
  notes: string(),
  resultsFile: object({
    file: mixed().required(),
    data: mixed().required(),
  }).required("Please select a file to upload"),
});

const UploadResultsForm = ({
  id,
  onSubmit,
}: React.PropsWithChildren<UploadResultsFormProps>) => {
  const methods = useForm<UploadResultsFormValues>({
    defaultValues: {
      batchId: "",
      instrumentId: "",
      notes: "",
      resultsFile: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors, reset } = methods;

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={3}
        component="form"
        noValidate
        id={id}
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          reset();
        })}
      >
        <Grid item sm={6} xs={12}>
          <FormTextField name="batchId" label="Batch ID" fullWidth required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InstrumentSelect required label="Instrument" />
        </Grid>
        <Grid item xs={12}>
          <FormTextField name="notes" label="Notes" fullWidth multiline />
        </Grid>
        <Grid item xs={12}>
          <FormFileUploadField name="resultsFile" errors={errors} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default UploadResultsForm;
