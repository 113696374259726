import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import * as React from "react";
import { useForm } from "react-hook-form";
import { DateTime, Duration } from "luxon";
import FormDatePicker from "src/components/forms/components/FormDatePicker";
import { useSnackbar } from "notistack";
import { useSubmitPastCoAsRequestMutation } from "src/generated/graphql-hooks";

interface RequestPastCOAsDiaglogProps {
  open: boolean;
  onClose: () => void;
}

interface RequestPastCOAsFormFields {
  startDate: Date;
  endDate: Date;
}

const REQUEST_FORM_ID = "request-past-coa-form";

export default function RequestPastCOAsDiaglog({
  open,
  onClose,
}: RequestPastCOAsDiaglogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState } = useForm<
    RequestPastCOAsFormFields
  >({
    defaultValues: {
      startDate: DateTime.now()
        .minus(Duration.fromObject({ weeks: 1 }))
        .toJSDate(),
      endDate: DateTime.now().toJSDate(),
    },
  });
  const [submitPastCOAsRequest] = useSubmitPastCoAsRequestMutation();

  const _handleSubmit = async ({
    startDate,
    endDate,
  }: RequestPastCOAsFormFields) => {
    try {
      const res = await submitPastCOAsRequest({
        variables: {
          startDate: DateTime.fromJSDate(startDate).toISODate(),
          endDate: DateTime.fromJSDate(endDate).toISODate(),
        },
      });
      if (!res) {
        throw new Error("Request returned false response");
      }
      onClose();
      enqueueSnackbar("Your request has been successfully submitted.", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "An error occurred while submitting request. Please try again later.",
        { variant: "error" },
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Request Past COAs</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you would like to recieve past COAs that are not currently shown in
          your sample tracker, enter the date range for the COAs that you would
          like to receive and we will email them to you.
        </DialogContentText>
        <Grid
          container
          component="form"
          spacing={3}
          onSubmit={handleSubmit((values) => _handleSubmit(values))}
          id={REQUEST_FORM_ID}
        >
          <Grid item xs={6}>
            <FormDatePicker
              name="startDate"
              control={control}
              fullWidth
              label="Start Date"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              defaultValue={DateTime.now()
                .minus(Duration.fromObject({ weeks: 1 }))
                .toJSDate()}
              autoOk
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              name="endDate"
              control={control}
              fullWidth
              label="End Date"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              defaultValue={DateTime.now().toJSDate()}
              autoOk
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          form={REQUEST_FORM_ID}
          type="submit"
          disabled={formState.isSubmitting}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
