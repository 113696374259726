import { IconButton, SvgIconTypeMap, Tooltip } from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";
import OutForPickupIcon from "@material-ui/icons/LocalShipping";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PostAddIcon from "@material-ui/icons/PostAdd";
import * as R from "ramda";
import * as React from "react";
import type { Cell } from "react-table";
import { TestTubeIcon } from "src/components/icons";
import {
  PICKUP_SAMPLES_PERMISSION,
  SCHEDULE_PICKUP_PERMISSION,
} from "src/config/permissions";
import {
  PickupLogOrderFieldsFragment,
  SampleStatus,
} from "src/generated/graphql-hooks";
import { useCurrentUser } from "src/hooks/useCurrentUser";

interface ActionButtonShape {
  name: string;
  display: boolean;
  tooltip: string;
  onClick: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const PickUpLogRowActions: React.FunctionComponent<Cell<
  PickupLogOrderFieldsFragment
>> = ({
  row,
  onSchedulePickupClick = () => {},
  onOutForPickupClick = () => {},
  onInTransitToLabClick = () => {},
  onUploadCOCPaperworkClick = () => {},
}) => {
  const { currentUser } = useCurrentUser();

  const createActionButtons = R.map<
    ActionButtonShape,
    React.ReactElement | null
  >(({ name, display, tooltip, onClick, Icon }) =>
    display ? (
      <Tooltip title={tooltip} key={name}>
        <IconButton size="small" onClick={onClick}>
          <Icon />
        </IconButton>
      </Tooltip>
    ) : null,
  );

  const actionsButtons = createActionButtons([
    {
      name: "schedulePickup",
      display:
        row.original.status === SampleStatus.SchedulePending &&
        !!currentUser?.role.permissions?.includes(SCHEDULE_PICKUP_PERMISSION),
      tooltip: "Schedule order pickup",
      onClick: () => onSchedulePickupClick(row.original),
      Icon: ScheduleIcon,
    },
    {
      name: "outForPickup",
      display:
        row.original.status === SampleStatus.Scheduled &&
        !!currentUser?.role.permissions?.includes(PICKUP_SAMPLES_PERMISSION),
      tooltip: "Out for pickup",
      onClick: () => onOutForPickupClick(row.original.id),
      Icon: OutForPickupIcon,
    },
    {
      name: "inTransitToLab",
      display:
        row.original.status === SampleStatus.OutForPickup &&
        !!currentUser?.role.permissions?.includes(PICKUP_SAMPLES_PERMISSION),
      tooltip: "In transit to lab",
      onClick: () => onInTransitToLabClick(row.original.id),
      Icon: TestTubeIcon,
    },
    {
      name: "uploadCOC",
      display:
        row.original.status === SampleStatus.Received &&
        !!currentUser?.role.permissions?.includes(PICKUP_SAMPLES_PERMISSION),
      tooltip: "Upload COC Paperwork",
      onClick: () => onUploadCOCPaperworkClick(row.original.id),
      Icon: PostAddIcon,
    },
  ]);

  return <>{actionsButtons}</>;
};

export default PickUpLogRowActions;
