import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import get from "lodash/get";
import { DateTime } from "luxon";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { Cell, Column } from "react-table";
import {
  PanelLogFieldsFragment,
  PassOrFail,
} from "src/generated/graphql-hooks";
import { graphQLDateToDateTime } from "src/utils";
import FormTextField from "../forms/components/FormTextField";
import InstrumentSelect from "../forms/uploadFormFields/InstrumentSelect";

export const UOM_ABBREVIATIONS: { [key: string]: string | undefined } = {
  grams: "g",
  millileters: "mL",
};

export const editableCheckboxCellFactory = <T extends { id: string }>({
  name,
  defaultValuePath,
}: {
  name: string;
  defaultValuePath: string;
}) =>
  editableCell<T>((cell) => (
    <Controller
      name={name}
      defaultValue={!!get(cell, `row.original.${defaultValuePath}`)}
      render={(props) => (
        <Checkbox
          {...props}
          checked={!!props.value}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      )}
    />
  ))((cell) => (
    <Checkbox
      checked={!!get(cell, `row.original.${defaultValuePath}`)}
      readOnly
      disableRipple
      style={{ cursor: "default" }}
    />
  ));

export const EditColumn: Column = {
  Header: () => null,
  id: "edit",
  maxWidth: 100,
  minWidth: 100,
  // @ts-ignore
  style: {
    position: "sticky",
    left: 0,
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  cellStyle: {
    zIndex: 998,
  },
  Cell: (cell: Cell<{ id: string }>) =>
    cell.state.editingRowId === cell.row.original.id ? (
      <Box display="flex">
        <IconButton size="small" type="submit">
          <SaveIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => cell.onCancelRowClick && cell.onCancelRowClick()}
        >
          <CancelIcon />
        </IconButton>
      </Box>
    ) : (
      <IconButton
        size="small"
        onClick={() => {
          cell.onEditRowClick && cell.onEditRowClick(cell.row.original.id);
        }}
      >
        <EditIcon />
      </IconButton>
    ),
  disableSortBy: true,
};

export const editableCell = <T extends { id: string }>(
  editElement: (cell: Cell<T>) => JSX.Element,
) => (
  displayElement: (cell: Cell<T>) => JSX.Element | React.ReactText | null,
) => (cell: Cell<T>) =>
  cell.state.editingRowId === cell.row.original.id
    ? editElement(cell)
    : displayElement(cell);

export const SampleNameColumn = {
  Header: "Sample Name",
  accessor: "productName",
  minWidth: 150,
  maxWidth: 150,
  style: {
    position: "sticky",
    left: 100,
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  cellStyle: {
    zIndex: 998,
  },
};

export const SampleIdColumn = {
  Header: "Sample ID",
  accessor: "sampleId",
  minWidth: 100,
  maxWidth: 100,
  style: {
    position: "sticky",
    left: 250,
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  cellStyle: {
    zIndex: 998,
  },
};

export const measurementColumnFactory = <T extends { id: string }>({
  Header,
  id,
  idField,
  valueField,
  unitsField,
  display,
  editable = () => true,
}: {
  Header: string;
  id?: string;
  idField: {
    name: string;
  };
  valueField: {
    name: string;
    defaultValue: (cell: Cell<T>) => React.ReactText;
  };
  unitsField: {
    name: string;
    defaultValue: (cell: Cell<T>) => React.ReactText;
  };
  display: (cell: Cell<T>) => React.ReactText | null;
  editable?: (cell: Cell<T>) => boolean;
}) => ({
  Header,
  id,
  minWidth: 200,
  Cell: editableCell<T>((cell) => (
    <>
      <FormTextField
        name={idField.name}
        type="hidden"
        defaultValue={cell.column.id}
        disabled={!editable(cell)}
      />
      <FormTextField
        name={valueField.name}
        defaultValue={valueField.defaultValue(cell)}
        style={{ width: "60%" }}
        inputMode="numeric"
        inputProps={{ style: { textAlign: "right" } }}
        disabled={!editable(cell)}
      />
      <FormTextField
        name={unitsField.name}
        select
        style={{ width: "40%" }}
        SelectProps={{ style: { textAlign: "center" } }}
        defaultValue={unitsField.defaultValue(cell)}
        disabled={!editable(cell)}
      >
        <MenuItem value="grams">g</MenuItem>
        <MenuItem value="milliliters">mL</MenuItem>
      </FormTextField>
    </>
  ))(display),
});

export const EditableInstrumentColumn = {
  Header: "Instrument",
  accessor: "panelResults[0].instrumentUsed.name",
  Cell: editableCell<PanelLogFieldsFragment>((cell) => (
    <InstrumentSelect
      defaultValue={cell.row.original.panelResults[0]?.instrumentUsed.id ?? ""}
    />
  ))(
    (cell) => cell.row.original.panelResults?.[0]?.instrumentUsed.name ?? null,
  ),
};

export const PassOrFailSelect = ({
  name,
  defaultValue,
}: {
  name: string;
  defaultValue: string;
}) => {
  const theme = useTheme();
  const { watch } = useFormContext();
  const value = watch(name, defaultValue);
  return (
    <FormTextField
      name={name}
      select
      fullWidth
      defaultValue={defaultValue}
      InputProps={{
        style: {
          color:
            value === PassOrFail.Pass
              ? theme.palette.success.main
              : value === PassOrFail.Fail
              ? theme.palette.error.main
              : theme.palette.text.primary,
        },
      }}
    >
      <MenuItem
        value={PassOrFail.Pass}
        style={{ color: theme.palette.success.main }}
      >
        {PassOrFail.Pass}
      </MenuItem>
      <MenuItem
        value={PassOrFail.Fail}
        style={{ color: theme.palette.error.main }}
      >
        {PassOrFail.Fail}
      </MenuItem>
    </FormTextField>
  );
};

export const PassOrFailDisplay = ({ value }: { value: PassOrFail | null }) => {
  const theme = useTheme();
  return (
    <Typography
      style={{
        color:
          value === PassOrFail.Pass
            ? theme.palette.success.main
            : theme.palette.error.main,
      }}
    >
      {value}
    </Typography>
  );
};

export const AnalysisCompleteColumn = {
  Header: "Analysis Complete",
  accessor: "panelResults[0].analysisComplete",
  Cell: editableCheckboxCellFactory<PanelLogFieldsFragment>({
    name: "analysisComplete",
    defaultValuePath: "panelResults[0].analysisComplete",
  }),
  disableSortBy: true,
};

export const ManuallyCompletedByColumn = {
  Header: "Completed By",
  accessor: (row: PanelLogFieldsFragment) => {
    const { completedBy } = row.panelResults?.[0] ?? {};
    return completedBy
      ? `${completedBy.firstName} ${completedBy.lastName}`
      : null;
  },
};

export const ManuallyCompletedAtColumn = {
  Header: "Completed Date",
  Cell: (cell: Cell<PanelLogFieldsFragment>) => {
    return cell.row.original.panelResults?.[0]?.completedAt
      ? DateTime.fromISO(
          cell.row.original.panelResults?.[0]?.completedAt,
        ).toLocaleString(DateTime.DATE_SHORT)
      : null;
  },
};

export const checkboxDisplayColumnFactory = <T extends object>(
  column: Column,
) => ({
  ...column,
  Cell: (cell: Cell<T>) => {
    return (
      <Checkbox
        checked={!!get(cell, `row.original.${column.accessor}`)}
        readOnly
        disableRipple
        style={{ cursor: "default" }}
      />
    );
  },
});

export const RushColumn = checkboxDisplayColumnFactory({
  Header: "Rush?",
  accessor: "accession.isRushOrder",
});

export const DueDateColumn = {
  Header: "Due Date",
  // Default to next millennium to ensure proper sorting behavior
  accessor: (row: any) =>
    new Date(get(row, "accession.dueDate") || "3000/01/01"),
  Cell: (cell: Cell) =>
    DateTime.fromISO(get(cell.row.original, "accession.dueDate")).isValid
      ? graphQLDateToDateTime(
          get(cell.row.original, "accession.dueDate"),
        ).toLocaleString(DateTime.DATE_SHORT)
      : null,
  sortType: "datetime",
};
