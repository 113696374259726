import * as React from "react";
import { useLocation } from "react-router-dom";

export default function useSearchQueryParam(
  setSearchText: (value: string) => void,
) {
  const { search } = useLocation();

  React.useEffect(() => {
    const searchText = new URLSearchParams(search).get("search");
    if (searchText) {
      setSearchText(searchText);
    }
  }, [search, setSearchText]);
}
