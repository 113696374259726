import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from "react";

interface DrawerContextProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextProps>({
  isOpen: false,
  toggleDrawer: () => {},
});

export const DrawerProvider: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        toggleDrawer: () => {
          setIsOpen(!isOpen);
        },
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
