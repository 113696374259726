import {
  AnalysisCompleteColumn,
  DueDateColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  FinalVolumeColumn,
  getComponentAmount,
  GoldSpikeColumn,
  InstrumentColumn,
  PipetteColumn,
  PipetteTypeColumn,
  SampleAmountColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  SolventColumn,
  SolventVolumeColumnFactory,
  VesselNumberColumn,
} from "./index";

const getHeavyMetalsComponentAmount = getComponentAmount("Heavy Metals");

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("HMCT"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  VesselNumberColumn,
  SolventColumn,
  SolventVolumeColumnFactory({ defaultValue: 9 }),
  PipetteTypeColumn(0),
  PipetteColumn(0),
  FinalVolumeColumn,
  GoldSpikeColumn,
  SamplePrepCompleteColumn,
  SamplePrepCompletedByColumn,
  InstrumentColumn,
  {
    Header: "Arsenic (ppb)",
    id: "as75",
    Cell: getHeavyMetalsComponentAmount,
  },
  {
    Header: "Cadmium (ppb)",
    id: "cd111",
    Cell: getHeavyMetalsComponentAmount,
  },
  {
    Header: "Mercury (ppb)",
    id: "hg202",
    Cell: getHeavyMetalsComponentAmount,
  },
  {
    Header: "Lead (ppb)",
    id: "pb208",
    Cell: getHeavyMetalsComponentAmount,
  },
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
