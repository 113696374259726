import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import firebase from "firebase/app";
import React, { useState } from "react";
import { useCurrentUser } from "src/hooks/useCurrentUser";

const AccountButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentUser } = useCurrentUser();

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="primary-account-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="inherit"
        data-testid="account-button"
      >
        <AccountCircle />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id="primary-account-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        <Box px={2} py={2}>
          <Typography>
            {`${currentUser?.firstName} ${currentUser?.lastName} @ ${currentUser?.business?.name}`}
          </Typography>
        </Box>
        <Divider />
        <MenuList>
          <MenuItem onClick={() => firebase.auth().signOut()}>
            Sign out
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

export default AccountButton;
