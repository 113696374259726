import { CircularProgress } from "@material-ui/core";
import * as React from "react";

const LoadingIndicator: React.FunctionComponent = () => (
  <CircularProgress
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      marginTop: "-20px",
      marginLeft: "-20px",
    }}
  />
);

export default LoadingIndicator;
