import { Container, Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import PageTitleCard from "src/components/PageTitleCard";

const BlockedAccess = () => {
  return (
    <Container
      maxWidth="xs"
      style={{ height: "100vh", display: "flex", alignItems: "center" }}
    >
      <Helmet>
        <title>Think20 Labs Portal - Blocked Access</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageTitleCard title="You don't have access to this page." />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BlockedAccess;
