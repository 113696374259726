import type { TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { useAvailableDispensersQuery } from "src/generated/graphql-hooks";
import FormTextField from "../../components/FormTextField";
import { generateMenuItems } from "../utils";

const DispenserSelect: React.FunctionComponent<TextFieldProps> = (props) => {
  const { data } = useAvailableDispensersQuery();
  const dispensers = data?.availableDispensers ?? [];
  return (
    <FormTextField name="dispenserId" select fullWidth {...props}>
      {generateMenuItems(dispensers)}
    </FormTextField>
  );
};

export default DispenserSelect;
