import { Cell } from "react-table";
import { SampleTrackerFieldsFragment } from "src/generated/graphql-hooks";
import * as React from "react";
import startCase from "lodash/startCase";
import { IconButton } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { DateTime } from "luxon";
import get from "lodash/get";
import { graphQLDateToDateTime } from "src/utils";

const columns = [
  { Header: "Sample Name", accessor: "productName" },
  { Header: "Lot / Batch", accessor: "lotOrBatchNumber" },
  { Header: "Sample ID", accessor: "sampleId" },
  {
    Header: "Status",
    accessor: (sample: SampleTrackerFieldsFragment) => startCase(sample.status),
  },
  {
    Header: "Photo",
    accessor: "photo.url",
    Cell: (cell: Cell<SampleTrackerFieldsFragment>) =>
      cell.row.original.photo?.url ? (
        <img
          src={cell.row.original.photo?.url}
          style={{ maxHeight: 100, borderRadius: 5 }}
          alt="Sample"
        />
      ) : null,
    disableSortBy: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Category",
    accessor: "matrix.sampleCategory.name",
  },
  { Header: "Matrix", accessor: "matrix.name" },
  { Header: "Metrc Source UID", accessor: "metrcPackage.sourcePackageLabels" },
  {
    Header: "Date Reported",
    // Default to next millennium to ensure proper sorting behavior
    accessor: (row: any) =>
      new Date(get(row, "sampleApproval.approvedAt") || "3000/01/01"),
    Cell: (cell: Cell) =>
      DateTime.fromISO(get(cell.row.original, "sampleApproval.approvedAt"))
        .isValid
        ? graphQLDateToDateTime(
            get(cell.row.original, "sampleApproval.approvedAt"),
          ).toLocaleString(DateTime.DATE_SHORT)
        : null,
    sortType: "datetime",
  },
  {
    Header: "Report",
    accessor: "report.url",
    Cell: (cell: Cell<SampleTrackerFieldsFragment>) =>
      cell.row.original.report && cell.row.original.reportReleasedToClient ? (
        <IconButton
          component="a"
          href={cell.row.original.report?.url}
          target="_blank"
        >
          <DescriptionIcon />
        </IconButton>
      ) : null,
    disableSortBy: true,
    disableGlobalFilter: true,
  },
  {
    Header: "QR Code",
    accessor: "qrCode.url",
    Cell: (cell: Cell<SampleTrackerFieldsFragment>) =>
      cell.row.original.qrCode && cell.row.original.reportReleasedToClient ? (
        <img
          alt={`${cell.row.original.productName} QR Code`}
          src={cell.row.original.qrCode.url}
          height={100}
          width={100}
        />
      ) : null,
    disableSortBy: true,
    disableGlobalFilter: true,
  },
];

export default columns;
