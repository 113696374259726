import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import ApproveIcon from "@material-ui/icons/Check";
import { ReportViewer } from "@think20/reporting";
import { useSnackbar } from "notistack";
import * as React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import LoadingButton from "src/components/LoadingButton";
import PageTitleCard from "src/components/PageTitleCard";
import SearchBox from "src/components/SearchBox";
import {
  APPROVE_RESULTS_PERMISSION,
  QA_RESULTS_PERMISSION,
} from "src/config/permissions";
import {
  BasicUserFieldsFragment,
  SampleStatus,
  useApproveSampleQaMutation,
  useApproveSampleReportMutation,
  useSampleReportDataQuery,
} from "src/generated/graphql-hooks";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import ReadyForQaTable from "./ReadyForQaTable";

interface ApproveResultsPageProps {}

const shouldDisplayApprovalButton = (
  mode: string,
  user?: BasicUserFieldsFragment | null,
) => {
  if (!user) return false;
  if (user.role.permissions?.includes(QA_RESULTS_PERMISSION) && mode === "qa")
    return true;
  if (
    user.role.permissions?.includes(APPROVE_RESULTS_PERMISSION) &&
    mode === "final-approval"
  )
    return true;
  return false;
};

const ApproveResultsPage: React.FunctionComponent<ApproveResultsPageProps> = () => {
  const { currentUser } = useCurrentUser();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const { mode } = useParams<{ mode: string }>();
  const [searchValue, setSearchValue] = React.useState("4000000");
  const [sampleId, setSampleId] = React.useState("");
  const [
    approveSampleQa,
    { loading: qaSubmitting },
  ] = useApproveSampleQaMutation();
  const [
    approveSampleReport,
    { loading: approvalSubmitting },
  ] = useApproveSampleReportMutation();
  React.useEffect(() => {
    const queryStringSampleId = new URLSearchParams(search).get("sample_id");
    if (queryStringSampleId) {
      setSampleId(queryStringSampleId);
      setSearchValue(queryStringSampleId);
    }
  }, [search]);

  const { data, loading } = useSampleReportDataQuery({
    variables: {
      sampleId,
    },
    skip: !sampleId,
  });

  React.useEffect(() => {
    setSampleId("");
    setSearchValue("4000000");
  }, [mode]);

  const pageTitle = `Approve Results - ${
    mode === "final-approval" ? "Reporting" : "QA"
  }`;

  return (
    <Container>
      <Helmet>
        <title>Think20 Labs Portal - Approve Results</title>
      </Helmet>
      <Box py={3}>
        <PageTitleCard title={pageTitle} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <SearchBox
            value={searchValue}
            label="Sample ID"
            onChange={setSearchValue}
            onSubmit={setSampleId}
            searchProcessing={loading}
          />
        </Grid>
        <Grid item xs={5} />
        {data?.sample && shouldDisplayApprovalButton(mode, currentUser) && (
          <>
            <Grid container item xs={3} spacing={1}>
              <Grid item>
                <LoadingButton
                  loading={qaSubmitting || approvalSubmitting}
                  variant="contained"
                  fullWidth
                  size="large"
                  startIcon={<ApproveIcon />}
                  color="primary"
                  onClick={async () => {
                    try {
                      await (mode === "final-approval"
                        ? approveSampleReport({
                            variables: { sampleId: data.sample!.id },
                          })
                        : approveSampleQa({
                            variables: { sampleId: data.sample!.id },
                          }));
                      enqueueSnackbar("Approval recorded", {
                        variant: "success",
                      });
                    } catch (err) {
                      console.error(err);
                      enqueueSnackbar(
                        "An error occurred while approving report. Please try again later.",
                        { variant: "error" },
                      );
                    }
                  }}
                >
                  Approve
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setSampleId("");
                    setSearchValue("4000000");
                  }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {data?.sample ? (
          <Grid item xs={12}>
            <ErrorBoundary
              FallbackComponent={PDFGenerationError}
              onError={console.error}
            >
              <ReportViewer
                style={{
                  width: "100%",
                  height: `calc(90vh - ${theme.spacing(8) * 2}px - ${
                    theme.spacing(3) * 2
                  }px)`,
                }}
                //@ts-ignore
                sample={data.sample}
              />
            </ErrorBoundary>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReadyForQaTable
              setSampleId={(id) => {
                setSampleId(id);
                setSearchValue(id.toString());
              }}
              title={mode === "final-approval" ? "Reporting" : "QA"}
              filterStatus={
                mode === "final-approval"
                  ? SampleStatus.QaReview
                  : SampleStatus.ReadyForQaReview
              }
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

const PDFGenerationError = (props: FallbackProps) => (
  <Box>
    <Typography>An unexpected error occurred when generating PDF</Typography>
    <pre>{props.error.message}</pre>
  </Box>
);

export default ApproveResultsPage;
