export const SUBMIT_SAMPLES_PERMISSION = "submit_samples";
export const VIEW_SAMPLE_TRACKER_PERMISSION = "view_sample_tracker";
export const VIEW_SAMPLE_LOG = "view_sample_log";
export const ACCESSION_SAMPLES = "accession_samples";
export const VIEW_PICKUP_LOG = "view_pickup_log";
export const SCHEDULE_PICKUP_PERMISSION = "schedule_pickup";
export const PICKUP_SAMPLES_PERMISSION = "pickup_samples";
export const VIEW_PANELS_PERMISSION = "view_panels";
export const UPLOAD_RESULTS_PERMISSION = "upload_results";
export const ALIQUOT_SAMPLES_PERMISSION = "aliquot_samples";
export const HOMOGENIZE_SAMPLES_PERMISSION = "homogenize_samples";
export const QA_RESULTS_PERMISSION = "qa_results";
export const VIEW_QA_RESULTS_PERMISSION = "view_qa_results";
export const APPROVE_RESULTS_PERMISSION = "approve_results";
export const VIEW_ANALYTICS = "view_analytics";
export const MANAGE_USERS_PERMISSION = "manage_users";
