import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as React from "react";
import { Helmet } from "react-helmet";
import { UsersQuery, useUsersQuery } from "src/generated/graphql-hooks";
import UserTable from "./UserTable";
const UsersList = () => {
  const theme = useTheme();
  const { data: userData, loading, refetch } = useUsersQuery();

  const [updateDataCount, setUpdateDataCount] = React.useState(0);

  const data: UsersQuery | undefined = React.useMemo(() => {
    return userData;
  }, [updateDataCount, loading]); //eslint-disable-line

  const _triggerDataUpdate = React.useCallback(() => {
    setUpdateDataCount((prev) => prev + 1);
  }, [setUpdateDataCount]);

  return (
    <Container style={{ paddingTop: theme.spacing(3) }} maxWidth="xl">
      <Helmet>
        <title>Think20 Labs Portal - Admin</title>
      </Helmet>
      <Paper>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            flexDirection="column"
            padding={theme.spacing(5, 0)}
          >
            <CircularProgress />
            <Typography>Fetching users...</Typography>
          </Box>
        ) : (
          <>
            <Toolbar
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Box color={theme.palette.primary.contrastText} mr={5}>
                <Typography variant="h6">Admin Panel</Typography>
              </Box>

              <Box display="flex" flex="1" />
              <Tooltip title="Refresh user list">
                <IconButton
                  style={{ color: theme.palette.primary.contrastText }}
                  onClick={async () => {
                    await refetch();
                    setUpdateDataCount(updateDataCount + 1);
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
            <UserTable data={data} _triggerDataUpdate={_triggerDataUpdate} />
          </>
        )}
      </Paper>
    </Container>
  );
};

export default UsersList;
