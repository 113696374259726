import { NetworkStatus } from "@apollo/client";
import { Box, Container } from "@material-ui/core";
import * as RA from "ramda-adjunct";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicTable from "src/components/BasicTable";
import {
  DueDateColumn,
  EditColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  SampleStatus,
  useHomogenizationLogQuery,
  useUpsertHomogenizationMutation,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import {
  BalanceColumn,
  CompletedAtColumn,
  CompletedByColumn,
  CompletedColumn,
  GrossAmountColumn,
  HomogenizationMethodColumn,
  IntakeAmountColumn,
  NotesColumn,
  PackageAmountColumn,
  ServingAmountColumn,
} from "./columns";

type Measurement = {
  value: string;
  units: string;
};
interface HomogenizationRowValues {
  balanceId: string;
  method: string;
  intakeMeasurement: Measurement;
  grossMeasurement: Measurement;
  notes: string;
  completed: boolean;
}

const HomogenizationPage = () => {
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);
  const { data: remoteData, networkStatus } = useHomogenizationLogQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        completedPanelNames: { contains: "Foreign Material" },
        status: {
          in: [
            SampleStatus.SamplePrep,
            SampleStatus.InProcess,
            SampleStatus.ReadyForQaReview,
            SampleStatus.QaReview,
          ],
        },
      },
    },
  });

  const [upsertSampleHomogenization] = useUpsertHomogenizationMutation();

  const { data, triggerDataUpdate } = useTableData({
    remoteData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  const methods = useForm<HomogenizationRowValues>();
  const { handleSubmit } = methods;

  const columns = React.useMemo(
    () => [
      EditColumn,
      SampleNameColumn,
      SampleIdColumn,
      RushColumn,
      DueDateColumn,
      BalanceColumn(true),
      HomogenizationMethodColumn,
      IntakeAmountColumn,
      GrossAmountColumn,
      ServingAmountColumn,
      PackageAmountColumn,
      NotesColumn,
      CompletedColumn,
      CompletedByColumn,
      CompletedAtColumn,
    ],
    [],
  );
  return (
    <Container maxWidth="xl">
      <Box py={3}>
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={handleSubmit(
              async ({
                balanceId,
                method,
                intakeMeasurement,
                grossMeasurement,
                notes,
                completed,
              }) => {
                if (!editingRowId) return;
                try {
                  await upsertSampleHomogenization({
                    variables: {
                      input: {
                        sampleId: editingRowId,
                        balanceId,
                        method,
                        notes,
                        completed,
                        intakeAmount: convertAmount(intakeMeasurement),
                        grossAmount: convertAmount(grossMeasurement),
                      },
                    },
                  });
                  triggerDataUpdate();
                  setEditingRowId(null);
                } catch (err) {
                  console.error(err);
                }
              },
            )}
          >
            <BasicTable
              title="Homogenization"
              columns={columns}
              data={data?.samples ?? []}
              editingRowId={editingRowId}
              customActions={{
                onEditRowClick: (rowId) => setEditingRowId(rowId),
                onCancelRowClick: () => setEditingRowId(null),
              }}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </form>
        </FormProvider>
      </Box>
    </Container>
  );
};

const isValidAmount = (obj: any) =>
  RA.isValidNumber(parseFloat(obj?.value)) && RA.isNonEmptyString(obj?.units);

const convertAmount = (obj: any) =>
  isValidAmount(obj)
    ? {
        value: parseFloat(obj.value),
        units: obj.units,
      }
    : RA.stubUndefined();

export default HomogenizationPage;
