import {
  AnalysisCompleteColumn,
  DueDateColumn,
  EditColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
} from "src/components/tableColumns";
import {
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  getComponentAmount,
  InstrumentColumn,
  SampleAmountColumn,
} from "./index";

const getWaterActivityComponentAmount = getComponentAmount("Water Activity");

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("MWA"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  InstrumentColumn,
  {
    Header: "Water Activity (aw)",
    id: "water_activity",
    Cell: getWaterActivityComponentAmount,
  },
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
