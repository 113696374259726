import type { TextFieldProps } from "@material-ui/core";
import { isNil } from "ramda";
import * as React from "react";
import { useAvailablePipettesQuery } from "src/generated/graphql-hooks";
import FormTextField from "../../components/FormTextField";
import { generateMenuItems } from "../utils";

interface PipetteSelectProps {
  index?: number;
}

const PipetteSelect: React.FunctionComponent<
  TextFieldProps & PipetteSelectProps
> = ({ index, ...rest }) => {
  const { data } = useAvailablePipettesQuery();
  const pipettes = data?.availablePipettes ?? [];
  const name = isNil(index) ? "pipetteId" : `pipetteIds[${index}]`;
  return (
    <FormTextField name={name} fullWidth {...rest} select>
      {generateMenuItems(pipettes)}
    </FormTextField>
  );
};

export default PipetteSelect;
