import { NetworkStatus } from "@apollo/client";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicTable from "src/components/BasicTable";
import ErrorCard from "src/components/ErrorCard";
import LoadingCard from "src/components/LoadingCard";
import {
  PanelFieldsFragment,
  PassOrFail,
  SampleStatus,
  usePanelLogQuery,
  useUpsertMicrobialResultsMutation,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import { identityIfDefined, parseFloatIfDefined } from "src/utils";
import microbialColumns from "./columns";

interface MicrobialRowFields {
  instrumentId: string;
  pipetteIds: [string, string, string];
  tsbVolume: string;
  incubationStarted: boolean;
  analysisComplete: boolean;
  aspergillusFlavus: PassOrFail;
  aspergillusFumigatus: PassOrFail;
  aspergillusNiger: PassOrFail;
  aspergillusTerreus: PassOrFail;
  escherichiaColi: PassOrFail;
  salmonellaSpp: PassOrFail;
  balanceId?: string;
  aliquotAmount?: string;
  aliquotUnitOfMeasure?: string;
}

const MicrobialPage = ({ id: panelId, name }: PanelFieldsFragment) => {
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);

  const { data: logData, networkStatus, loading, error } = usePanelLogQuery({
    variables: {
      panelId,
      statusFilter: {
        in: [
          SampleStatus.InProcess,
          SampleStatus.QaReview,
          SampleStatus.ReadyForQaReview,
          SampleStatus.PendingComplete,
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data, triggerDataUpdate } = useTableData({
    remoteData: logData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  const [upsertMicrobialResults] = useUpsertMicrobialResultsMutation();

  const columns = React.useMemo(() => microbialColumns, []);

  const methods = useForm<MicrobialRowFields>({});
  const { handleSubmit } = methods;

  if (loading)
    return (
      <LoadingCard message={`Loading ${name.toLocaleLowerCase()} results...`} />
    );

  if (error) return <ErrorCard />;

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={handleSubmit(
          async ({
            instrumentId,
            pipetteIds,
            tsbVolume,
            incubationStarted,
            analysisComplete,
            balanceId,
            aliquotAmount,
            aliquotUnitOfMeasure,

            ...rest
          }) => {
            if (
              !editingRowId ||
              !balanceId ||
              !aliquotUnitOfMeasure ||
              !aliquotAmount ||
              (Object.values(rest).some((item) => item !== "") && !instrumentId)
            )
              return;
            try {
              await upsertMicrobialResults({
                variables: {
                  panelId,
                  microbialInput: {
                    sampleId: editingRowId,
                    incubationStarted,
                    instrumentId: identityIfDefined(instrumentId),
                    tsbVolume: parseFloatIfDefined(tsbVolume),
                    analysisComplete,
                    pipette1Id: identityIfDefined(pipetteIds[0]),
                    pipette2Id: identityIfDefined(pipetteIds[1]),
                    pipette3Id: identityIfDefined(pipetteIds[2]),
                    ...(Object.values(rest).some((item) => item !== "") && {
                      results: {
                        ...Object.entries(rest).reduce(
                          (acc, [key, value]) =>
                            value === "" ? acc : { ...acc, [key]: value },
                          {},
                        ),
                      },
                    }),
                  },
                  aliquotInput: {
                    balanceId,
                    sampleId: editingRowId,
                    aliquots: [
                      {
                        panelId,
                        amount: parseFloatIfDefined(aliquotAmount),
                        unitOfMeasure: aliquotUnitOfMeasure,
                      },
                    ],
                  },
                },
              });
              triggerDataUpdate();
              setEditingRowId(null);
            } catch (err) {
              console.error(err);
            }
          },
        )}
      >
        <BasicTable
          title="Microbial"
          //@ts-ignore
          columns={columns}
          data={data?.samples ?? []}
          editingRowId={editingRowId}
          customActions={{
            onEditRowClick: (rowId) => setEditingRowId(rowId),
            onCancelRowClick: () => setEditingRowId(null),
          }}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </form>
    </FormProvider>
  );
};

export default MicrobialPage;
