import {
  DueDateColumn,
  EditColumn,
  RushColumn,
  SampleIdColumn,
  SampleNameColumn,
  AnalysisCompleteColumn,
  ManuallyCompletedAtColumn,
  ManuallyCompletedByColumn,
} from "src/components/tableColumns";
import {
  BalanceColumn,
  BarcodeColumn,
  BatchIdColumn,
  DilutionFactorColumn,
  DispenserColumn,
  getComponentAmount,
  InstrumentColumn,
  PipetteColumn,
  PipetteTypeColumn,
  SampleAmountColumn,
  SolventColumn,
  SolventDensityColumn,
  SolventMassColumn,
  TotalDilutionFactorColumn,
} from "./index";

const getCannabinoidsComponentAmount = getComponentAmount("Cannabinoids");

const columns = [
  EditColumn,
  SampleNameColumn,
  SampleIdColumn,
  BarcodeColumn("CAST"),
  BatchIdColumn,
  RushColumn,
  DueDateColumn,
  BalanceColumn(true),
  SampleAmountColumn,
  DispenserColumn,
  SolventColumn,
  SolventMassColumn(0),
  SolventMassColumn(1),
  SolventDensityColumn,
  DilutionFactorColumn,
  PipetteTypeColumn(0),
  PipetteColumn(0),
  PipetteTypeColumn(1),
  PipetteColumn(1),
  TotalDilutionFactorColumn,

  InstrumentColumn,
  {
    Header: "Moisture Content (%)",
    accessor: "moistureContent",
  },
  {
    Header: "CBDVA",
    id: "CBDVA",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBDV",
    id: "CBDV",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBDA",
    id: "CBDA",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBGA",
    id: "CBGA",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBG",
    id: "CBG",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBD",
    id: "CBD",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "THCV",
    id: "THCV",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBN",
    id: "CBN",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "Δ9-THC",
    id: "d9-THC",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "Δ8-THC",
    id: "d8-THC",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "CBC",
    id: "CBC",
    Cell: getCannabinoidsComponentAmount,
  },
  {
    Header: "THCA",
    id: "THCA",
    Cell: getCannabinoidsComponentAmount,
  },
  AnalysisCompleteColumn,
  ManuallyCompletedByColumn,
  ManuallyCompletedAtColumn,
];

export default columns;
