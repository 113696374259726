import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";
import {
  PickupLogOrderFieldsFragment,
  useSchedulePickupMutation,
} from "src/generated/graphql-hooks";
import { useSmoothPropChanges } from "src/hooks/useSmoothPropChanges";
import SchedulePickupForm, {
  SchedulePickupFormFields,
} from "../forms/SchedulePickupForm";
import LoadingButton from "../LoadingButton";

interface SchedulePickUpModalProps {
  order: PickupLogOrderFieldsFragment | null;
  onClose: () => void;
  onDataUpdate: () => void;
}

const SCHEDULE_PICKUP_FORM_ID = "schedule-pickup-form";

const SchedulePickUpModal: React.FunctionComponent<SchedulePickUpModalProps> = ({
  order,
  onClose,
  onDataUpdate,
}) => {
  const [schedulePickup, { loading }] = useSchedulePickupMutation();
  const { enqueueSnackbar } = useSnackbar();
  const smoothedOrder = useSmoothPropChanges<PickupLogOrderFieldsFragment>(
    order,
  );

  const _handleSubmit = async (values: SchedulePickupFormFields) => {
    try {
      if (!order) return;
      await schedulePickup({
        variables: {
          input: { ...values, orderId: order.id },
        },
      });
      onClose();
      onDataUpdate();
      enqueueSnackbar("Pickup scheduled", { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to schedule pickup", { variant: "error" });
    }
  };

  return (
    <Dialog open={!!order} onClose={onClose} maxWidth="md">
      <DialogTitle>Schedule Order Pickup</DialogTitle>
      <DialogContent>
        <SchedulePickupForm
          id={SCHEDULE_PICKUP_FORM_ID}
          onSubmit={_handleSubmit}
          order={smoothedOrder}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          form={SCHEDULE_PICKUP_FORM_ID}
          loading={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          Schedule
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SchedulePickUpModal;
