import { useDebounce } from "use-debounce/lib";
import * as React from "react";
import { useCorrespondingMetrcSourcePackageUidLazyQuery } from "../../../generated/graphql-hooks";

export const useMetrcPackageCheck = (metrcPackageUID?: string | null) => {
  const [debouncedMetrcPackageUID] = useDebounce(metrcPackageUID, 1000);
  const [
    getCorrespondingMetrcSourcePackageUID,
    { data, loading, called },
  ] = useCorrespondingMetrcSourcePackageUidLazyQuery();
  React.useEffect(() => {
    if (debouncedMetrcPackageUID) {
      getCorrespondingMetrcSourcePackageUID({
        variables: { metrcPackageUID: debouncedMetrcPackageUID },
      });
    }
  }, [debouncedMetrcPackageUID, getCorrespondingMetrcSourcePackageUID]);

  return {
    packageFound: !!data?.correspondingMetrcSourcePackageUID,
    checking: loading,
    hasBeenChecked: called,
  };
};
