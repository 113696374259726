import { Box, Button, ButtonGroup } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";
import * as React from "react";

interface FilterBoxProps {
  onFilterChange: (filter: Range) => void;
}

interface Range {
  startDate: DateTime;
  endDate: DateTime;
}

export const FilterBox = ({ onFilterChange }: FilterBoxProps) => {
  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);
  const buttonGroupref = React.useRef<HTMLDivElement>(null);
  const [activeFilter, setActiveFilter] = React.useState("YTD");

  React.useEffect(() => {
    if (startDate && endDate) {
      onFilterChange({ startDate, endDate });
    }
  }, [startDate, endDate, onFilterChange]);

  const handleClick = (months: number, active: string) => {
    if (active === "Custom") {
      toggle();
      setActiveFilter(active);
    } else {
      setActiveFilter(active);
      onFilterChange({
        startDate: DateTime.now(),
        endDate: DateTime.now().minus({ month: months }),
      });
    }
  };

  return (
    <ButtonGroup
      orientation="vertical"
      variant="text"
      fullWidth={true}
      size="small"
    >
      {!open && (
        <ButtonGroup
          orientation="vertical"
          variant="text"
          fullWidth={true}
          size="small"
          ref={buttonGroupref}
        >
          <Button
            onClick={() => handleClick(12, "YTD")}
            color={activeFilter === "YTD" ? "primary" : "inherit"}
          >
            YTD
          </Button>
          <Button
            onClick={() => {
              handleClick(3, "Last Quater");
            }}
            color={activeFilter === "Last Quater" ? "primary" : "inherit"}
          >
            Last Quater
          </Button>
          <Button
            onClick={() => {
              handleClick(1, "Last Month");
            }}
            color={activeFilter === "Last Month" ? "primary" : "inherit"}
          >
            Last Month
          </Button>
          <Button
            onClick={() => {
              handleClick(0, "Current Month");
            }}
            color={activeFilter === "Current Month" ? "primary" : "inherit"}
          >
            Current Month
          </Button>
        </ButtonGroup>
      )}

      <Button
        onClick={() => handleClick(0, "Custom")}
        color={activeFilter === "Custom" ? "primary" : "inherit"}
      >
        Custom
      </Button>

      {open && (
        <Box height={buttonGroupref?.current?.offsetHeight}>
          <DatePicker
            label="Start Date"
            disableToolbar
            variant="inline"
            format="MMM d yyyy"
            onChange={setEndDate}
            value={endDate}
          />
          <DatePicker
            label="End Date"
            disableToolbar
            variant="inline"
            format="MMM d yyyy"
            onChange={setStartDate}
            value={startDate}
          />
        </Box>
      )}
    </ButtonGroup>
  );
};

export default FilterBox;
