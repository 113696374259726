import { ErrorMessage } from "@hookform/error-message";
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import React, { FunctionComponent } from "react";
import { Control, Controller, DeepMap, FieldError, get } from "react-hook-form";

interface FormDatetimePickerProps {
  control: Control;
  name: string;
  defaultValue: Date;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

const FormDatetimePicker: FunctionComponent<
  FormDatetimePickerProps & Omit<DateTimePickerProps, "value" | "onChange">
> = ({ control, name, defaultValue, errors = {}, ...rest }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={(props) => (
      <DateTimePicker
        {...props}
        {...rest}
        onChange={(value) => props.onChange(value?.toJSDate())}
        helperText={<ErrorMessage errors={errors} name={name} />}
        error={!!get(errors, name)}
      />
    )}
  />
);

export default FormDatetimePicker;
