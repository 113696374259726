import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import { APP, FORGOT_PASSWORD, SIGN_IN, SIGN_UP } from "./config/routes";
import App from "./pages/App";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

const Root = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to={APP} />
      </Route>
      <Route path={SIGN_IN}>
        <SignIn />
      </Route>
      <Route path={SIGN_UP}>
        <SignUp />
      </Route>

      <Route path={FORGOT_PASSWORD}>
        <ForgotPasswordPage />
      </Route>
      <PrivateRoute path={APP} redirectTo={SIGN_IN}>
        <App />
      </PrivateRoute>
    </Switch>
  );
};

export default Root;
