import { ApolloError, useApolloClient } from "@apollo/client";
import firebase from "firebase/app";
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BasicUserFieldsFragment,
  useLoggedInUserQuery,
} from "../generated/graphql-hooks";

interface UserStatus {
  currentUser?: BasicUserFieldsFragment | null;
  loading: boolean;
  error?: ApolloError;
}

export const UserContext = createContext<UserStatus>({ loading: true });

export const UserProvider: FunctionComponent = ({ children }) => {
  const [connectedToFirebase, setConnectedToFirebase] = useState(false);
  const { data, loading, error, refetch } = useLoggedInUserQuery({
    fetchPolicy: "network-only",
    skip: !connectedToFirebase,
  });
  const client = useApolloClient();

  useEffect(
    () =>
      firebase.auth().onIdTokenChanged(async (user) => {
        if (!user) {
          client.stop();
          await client.resetStore();
        }
        refetch();
        setConnectedToFirebase(true);
      }),
    [client, refetch],
  );

  return (
    <UserContext.Provider
      value={{
        currentUser: data?.me,
        loading: !connectedToFirebase || loading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(UserContext);
