import { MenuItem } from "@material-ui/core";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import * as R from "ramda";
import * as RA from "ramda-adjunct";
import * as React from "react";
import FormTextField from "src/components/forms/components/FormTextField";
import { editableCell, UOM_ABBREVIATIONS } from "src/components/tableColumns";
import type {
  AliquotLogEntryFragment,
  PanelFieldsFragment,
} from "src/generated/graphql-hooks";

export const findPanelByName = (panelName: string) =>
  R.find<PanelFieldsFragment>(({ name }) => panelName === name);

const aliquotColumnFactory = ({ name, id }: PanelFieldsFragment) => ({
  Header: startCase(name),
  id,
  minWidth: 200,
  Cell: editableCell<AliquotLogEntryFragment>((cell) => (
    <>
      <FormTextField
        name={`${camelCase(name)}.panelId`}
        type="hidden"
        defaultValue={cell.column.id}
      />
      <FormTextField
        name={`${camelCase(name)}.amount`}
        defaultValue={
          cell.row.original.aliquots.find(
            ({ panel }) => panel.id === cell.column.id,
          )?.measurement.value ?? ""
        }
        style={{ width: "60%" }}
        inputMode="numeric"
        inputProps={{ style: { textAlign: "right" } }}
      />
      <FormTextField
        name={`${camelCase(name)}.unitOfMeasure`}
        select
        style={{ width: "40%" }}
        SelectProps={{ style: { textAlign: "center" } }}
        defaultValue={
          cell.row.original.aliquots.find(
            ({ panel }) => panel.id === cell.column.id,
          )?.measurement.units ?? ""
        }
      >
        <MenuItem value="grams">g</MenuItem>
        <MenuItem value="milliliters">mL</MenuItem>
      </FormTextField>
    </>
  ))((cell) => {
    const aliquot = cell.row.original.aliquots.find(
      ({ panel }) => panel.id === cell.column.id,
    );
    return aliquot
      ? `${aliquot.measurement.value} ${
          UOM_ABBREVIATIONS[aliquot.measurement.units] ??
          aliquot.measurement.units
        }`
      : null;
  }),
});

const buildAliquotColumn = (panelName: string) =>
  R.pipe(
    findPanelByName(panelName),
    R.ifElse(R.isNil, RA.stubNull, aliquotColumnFactory),
  );

export const buildMicrobialAliquotColumn = buildAliquotColumn("Microbial");
export const buildCannabinoidsAliquotColumn = buildAliquotColumn(
  "Cannabinoids",
);
export const buildTerpenesAliquotColumn = buildAliquotColumn("Terpenes");
export const buildMycotoxinAliquotColumn = buildAliquotColumn("Mycotoxin");
export const buildResidualSolventsAliquotColumn = buildAliquotColumn(
  "Residual Solvents",
);
export const buildMoistureContentAliquotColumn = buildAliquotColumn(
  "Moisture Content",
);
export const buildWaterActivityAliquotColumn = buildAliquotColumn(
  "Water Activity",
);
export const buildHeavyMetalsAliquotColumn = buildAliquotColumn("HeavyMetals");
export const buildDensityAliquotColumn = buildAliquotColumn("Density");
