import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import firebase from "firebase/app";

const uri = process.env.REACT_APP_GRAPHQL_URL;

export const authLink = setContext(async (_, { headers }) => {
  const { currentUser } = firebase.auth();
  if (currentUser) {
    const token = await currentUser.getIdToken();
    return { headers: { ...headers, authorization: `Bearer ${token}` } };
  }
  return { headers: { ...headers } };
});

const createApolloClient = () => {
  const cache = new InMemoryCache();

  const httpLink = createHttpLink({
    uri,
  });

  const link = ApolloLink.from([authLink, httpLink]);

  const client = new ApolloClient({
    link,
    cache,
    assumeImmutableResults: true,
  });

  return client;
};

export default createApolloClient;
