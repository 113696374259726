import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Controller, get, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  useBusinessesQuery,
  useRolesQuery,
  useUpdateUserMutation,
  useUserQuery,
} from "src/generated/graphql-hooks";
import { UsersEditFormSchema } from "./validation";

interface UserEditFormProps {
  basePath: string;
}

interface UserEditFormFields {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: { id: string; name: string };
  business: { id: string; name: string };
}

const UsersEdit = ({ basePath }: UserEditFormProps) => {
  const { control, errors, handleSubmit, register } = useForm<
    UserEditFormFields
  >({
    resolver: yupResolver(UsersEditFormSchema),
  });
  const classes = useStyles();
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const { data: user, loading } = useUserQuery({ variables: { id: userId } });
  const { data: roles } = useRolesQuery();
  const { data: businesses } = useBusinessesQuery();
  const { enqueueSnackbar } = useSnackbar();

  const [updateUser] = useUpdateUserMutation();

  const theme = useTheme();

  return (
    <Container className={classes.pageContainer}>
      <Helmet>
        <title>Think20 Labs Portal - Edit User Info</title>
      </Helmet>
      <Grid container spacing={3}>
        <Card>
          <CardHeader title="Edit User Info" />
          <CardContent>
            {loading ? (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                flexDirection="column"
                padding={theme.spacing(5, 0)}
              >
                <CircularProgress />
                <Typography>Fetching users...</Typography>
              </Box>
            ) : (
              <Grid
                container
                spacing={2}
                component="form"
                id="update-user-form"
                noValidate
                onSubmit={handleSubmit(async ({ role, business, ...rest }) => {
                  try {
                    await updateUser({
                      variables: {
                        input: {
                          ...rest,
                          roleId: role.id,
                          businessId: business?.id ?? null,
                        },
                      },
                    });
                    enqueueSnackbar("User succesfully updated", {
                      variant: "success",
                    });
                    history.push(basePath);
                  } catch (e) {
                    console.log(e);
                    enqueueSnackbar("Failed to update user.", {
                      variant: "error",
                    });
                  }
                })}
              >
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <input
                    ref={register()}
                    type="hidden"
                    defaultValue={user?.user?.id}
                    name="id"
                  />
                  <TextField
                    label="First Name"
                    name="firstName"
                    inputRef={register}
                    error={!!get(errors, "firstName")}
                    fullWidth
                    margin="dense"
                    defaultValue={user?.user?.firstName}
                    placeholder={user?.user?.firstName}
                    required
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    inputRef={register}
                    error={!!get(errors, "lastName")}
                    fullWidth
                    margin="dense"
                    defaultValue={user?.user?.lastName}
                    placeholder={user?.user?.lastName}
                    required
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <TextField
                    name="email"
                    label="Email"
                    inputRef={register}
                    error={!!get(errors, "email")}
                    fullWidth
                    margin="dense"
                    defaultValue={user?.user?.email}
                    placeholder={user?.user?.email}
                    required
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    inputRef={register}
                    error={!!get(errors, "phoneNumber")}
                    fullWidth
                    margin="dense"
                    defaultValue={user?.user?.phoneNumber}
                    placeholder={user?.user?.phoneNumber || "777-111-7777"}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Controller
                    name="role"
                    control={control}
                    defaultValue={user?.user?.role}
                    render={(props) => (
                      <Autocomplete
                        options={roles?.roles || []}
                        {...props}
                        getOptionLabel={(role) => role.name}
                        renderOption={(option) => option.name}
                        onChange={(_, newValue) => props.onChange(newValue)}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Role"
                            placeholder={user?.user?.role.name}
                            error={!!get(errors, `role`)}
                            required
                            margin="dense"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Controller
                    name="business"
                    control={control}
                    defaultValue={user?.user?.business || ""}
                    render={(props) => (
                      <Autocomplete
                        options={businesses?.businesses || []}
                        {...props}
                        getOptionLabel={(business) => business.name}
                        renderOption={(option) => option.name}
                        defaultValue={user?.user?.business || ""}
                        onChange={(_, newValue) => props.onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Business"
                            placeholder={user?.user?.business?.name || ""}
                            error={!!get(errors, `role`)}
                            margin="dense"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <TextField
                    name="title"
                    label="Title"
                    inputRef={register}
                    error={!!get(errors, "title")}
                    fullWidth
                    margin="dense"
                    defaultValue={user?.user?.title}
                    placeholder={user?.user?.title || ""}
                  />
                </Grid>
              </Grid>
            )}
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <Box m={1}>
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  form="update-user-form"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
              <Box m={1}>
                <Button
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.common.white,
                  }}
                  variant="contained"
                  size="medium"
                  type="submit"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(3),
    paddingBlock: theme.spacing(3),
  },
}));

export default UsersEdit;
