import { NetworkStatus } from "@apollo/client";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicTable from "src/components/BasicTable";
import ErrorCard from "src/components/ErrorCard";
import LoadingCard from "src/components/LoadingCard";
import {
  PanelFieldsFragment,
  SampleStatus,
  usePanelLogQuery,
  useUpsertMoistureContentResultsMutation,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import { parseFloatIfDefined } from "src/utils";
import moistrueContentColumns from "./columns";

interface MicrobialRowFields {
  instrumentId: string;
  moistureContent: number;
  analysisComplete: boolean;
  aliquotAmount?: string;
  aliquotUnitOfMeasure?: string;
  balanceId?: string;
}

const MoistureContentPage = ({ id: panelId, name }: PanelFieldsFragment) => {
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);

  const { data: logData, networkStatus, loading, error } = usePanelLogQuery({
    variables: {
      panelId,
      statusFilter: {
        in: [
          SampleStatus.InProcess,
          SampleStatus.ReadyForQaReview,
          SampleStatus.QaReview,
          SampleStatus.PendingComplete,
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data, triggerDataUpdate } = useTableData({
    remoteData: logData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  const [upsertMoistureContent] = useUpsertMoistureContentResultsMutation();

  const columns = React.useMemo(() => moistrueContentColumns, []);

  const methods = useForm<MicrobialRowFields>({});
  const { handleSubmit } = methods;

  if (loading)
    return (
      <LoadingCard message={`Loading ${name.toLocaleLowerCase()} results...`} />
    );

  if (error) return <ErrorCard />;

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={handleSubmit(
          async ({
            instrumentId,
            moistureContent,
            analysisComplete,
            balanceId,
            aliquotUnitOfMeasure,
            aliquotAmount,
          }) => {
            if (
              !editingRowId ||
              !balanceId ||
              !aliquotUnitOfMeasure ||
              !aliquotAmount
            )
              return;
            try {
              await upsertMoistureContent({
                variables: {
                  panelId,
                  moistureInput: {
                    sampleId: editingRowId,
                    instrumentId,
                    moistureContent: parseFloatIfDefined(moistureContent),
                    analysisComplete,
                  },
                  aliquotInput: {
                    balanceId,
                    sampleId: editingRowId,
                    aliquots: [
                      {
                        panelId,
                        amount: parseFloatIfDefined(aliquotAmount),
                        unitOfMeasure: aliquotUnitOfMeasure,
                      },
                    ],
                  },
                },
              });
              triggerDataUpdate();
              setEditingRowId(null);
            } catch (err) {
              console.error(err);
            }
          },
        )}
      >
        <BasicTable
          title="Moisture Content"
          //@ts-ignore
          columns={columns}
          data={data?.samples ?? []}
          editingRowId={editingRowId}
          customActions={{
            onEditRowClick: (rowId) => setEditingRowId(rowId),
            onCancelRowClick: () => setEditingRowId(null),
          }}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </form>
    </FormProvider>
  );
};

export default MoistureContentPage;
