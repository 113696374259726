import {
  BusinessPartnersDocument,
  useBusinessPartnersQuery,
  useCreateDistributorMutation,
  useCreateProducerMutation,
} from "src/generated/graphql-hooks";

const useBusinessPartners = () => {
  const [addProducer] = useCreateProducerMutation({
    refetchQueries: [{ query: BusinessPartnersDocument }],
    awaitRefetchQueries: true,
  });
  const [addDistributor] = useCreateDistributorMutation({
    refetchQueries: [{ query: BusinessPartnersDocument }],
    awaitRefetchQueries: true,
  });
  const { data, loading, error } = useBusinessPartnersQuery({
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    error,
    producers: data?.me?.business?.producers,
    distributors: data?.me?.business?.distributors,
    addProducer,
    addDistributor,
  };
};

export default useBusinessPartners;
