import { NetworkStatus } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import firebase from "firebase/app";
import type { FileObject } from "material-ui-dropzone";
import * as RA from "ramda-adjunct";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicTable from "src/components/BasicTable";
import ErrorCard from "src/components/ErrorCard";
import LoadingCard from "src/components/LoadingCard";
import {
  PanelFieldsFragment,
  PassOrFail,
  SampleStatus,
  UrlSchema,
  usePanelLogQuery,
  useUpsertForeignMaterialResultMutation,
} from "src/generated/graphql-hooks";
import useSearchQueryParam from "src/hooks/useSearchQueryParam";
import { useTableData } from "src/hooks/useTableData";
import { array, boolean, mixed, object, string } from "yup";
import foreignMaterialColumns from "./columns";

interface ForeignMaterialRowFields {
  instrumentId: string;
  notes: string;
  images: FileObject[];
  inspectionResult: PassOrFail;
  analysisComplete: boolean;
}

const validationSchema = object({
  instrumentId: string().required(),
  notes: string(),
  images: array().of(mixed()),
  inspectionResult: string(),
  analysisComplete: boolean(),
});

const ForeignMaterialPage = ({ id: panelId, name }: PanelFieldsFragment) => {
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = React.useState("");
  useSearchQueryParam(setGlobalFilter);

  const { data: logData, networkStatus, loading, error } = usePanelLogQuery({
    variables: {
      panelId,
      statusFilter: {
        in: [
          SampleStatus.SamplePrep,
          SampleStatus.InProcess,
          SampleStatus.QaReview,
          SampleStatus.ReadyForQaReview,
          SampleStatus.PendingComplete,
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const [upsertForeignMaterial] = useUpsertForeignMaterialResultMutation();

  const { data, triggerDataUpdate } = useTableData({
    remoteData: logData,
    isFetching: networkStatus < NetworkStatus.ready,
  });

  const columns = React.useMemo(() => foreignMaterialColumns, []);

  const methods = useForm<ForeignMaterialRowFields>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  if (loading)
    return (
      <LoadingCard message={`Loading ${name.toLocaleLowerCase()} results...`} />
    );

  if (error) return <ErrorCard />;

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={handleSubmit(
          async ({
            instrumentId,
            notes,
            images,
            inspectionResult,
            analysisComplete,
          }) => {
            if (!editingRowId) return;
            try {
              const uploadedImages = await Promise.all(
                images.map((image) =>
                  firebase
                    .storage()
                    .ref(`foreignMaterial/${editingRowId}/${image.file.name}`)
                    .put(image.file),
                ),
              );
              await upsertForeignMaterial({
                variables: {
                  panelId,
                  input: {
                    sampleId: editingRowId,
                    instrumentId,
                    notes,
                    images: images.map(({ file: { size, type } }, index) => ({
                      size,
                      url: uploadedImages[index].ref.toString(),
                      extension: type,
                      urlSchema: UrlSchema.GcsRef,
                    })),
                    inspectionResult: RA.isNonEmptyString(inspectionResult)
                      ? inspectionResult
                      : RA.stubUndefined(),
                    analysisComplete,
                  },
                },
              });
              triggerDataUpdate();
              setEditingRowId(null);
            } catch (err) {
              console.error(err);
            }
          },
        )}
      >
        <BasicTable
          title="Foreign Material"
          //@ts-ignore
          columns={columns}
          data={data?.samples ?? []}
          editingRowId={editingRowId}
          customActions={{
            onEditRowClick: (rowId) => setEditingRowId(rowId),
            onCancelRowClick: () => setEditingRowId(null),
          }}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </form>
    </FormProvider>
  );
};

export default ForeignMaterialPage;
